import React, { useReducer, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import InputLabel from '@material-ui/core/InputLabel'
import { postGig, updateGig } from '../../store/actions/gigActions'
import { connect } from 'react-redux'
import moment from 'moment'
import Api from '../../Api'

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: 'wrap',
    boxShadow: 'none',
    background: 'inherit',
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    flexBasis: 200,
  },
}))

function CreateContest({ token, props, history }) {
  const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }), {
    contestName: '',
    contestDescription: '',
    contestPrize: '',
    contestStartDate: '',
    contestEndDate: '',
    contestSponsor: '',
    showOne: false,
    showTwo: false,
    isCompelte: false,
  })

  useEffect(() => {
    document.title = 'Seensta | Post a Contest'
    if (!token) history.push('/signup')
  }, [history, token])
  const handleChange = (event) => {
    const name = event.target.name
    const newValue = event.target.value
    setUserInput({ [name]: newValue })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const contestData = {
      description: userInput.contestDescription,
      title: userInput.contestName,
      sponsor: userInput.contestSponsor,
      value: userInput.contestPrize,
      start_date: moment(userInput.contestStartDate).format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(userInput.contestEndDate).format('YYYY-MM-DD HH:mm:ss'),
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    }
    try {
      const endpoint = 'create_contest'
      const response = await Api.post(endpoint, contestData, { headers })
      if (response) {
        setUserInput['isComplete'] = true
      }
    } catch (err) {
      console.log('Error', err.message)
    }
  }

  const classes = useStyles()
  if (!userInput.isCompelte) {
    return (
      <div>
        <div style={{ marginTop: 10 }}>
          <Paper
            elevation={0}
            style={{ maxWidth: 600, width: '100%', margin: '0 auto', background: 'inherit' }}
          >
            <Typography variant="h6" style={{ textAlign: 'center' }}>
              {props.location.state ? 'Update Gig' : 'Create a Contest'}
            </Typography>
            <Divider />
            <div className={classes.root}>
              <form style={{ padding: 20 }} onSubmit={(e) => handleSubmit(e)}>
                <div style={{ marginTop: 10 }}>
                  <InputLabel>Contest Name</InputLabel>
                </div>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="contestName"
                  onChange={handleChange}
                  value={userInput.contestName}
                  required
                />
                <div style={{ marginTop: 10 }}>
                  <InputLabel>Contest Description</InputLabel>
                </div>
                <TextField
                  variant="outlined"
                  name="contestDescription"
                  required
                  value={userInput.contestDescription}
                  onChange={handleChange}
                  fullWidth
                  multiline
                />
                <div style={{ marginTop: 10 }}>
                  <InputLabel>Contest Prize</InputLabel>
                </div>
                <TextField
                  fullWidth
                  name="contestPrize"
                  value={userInput.contestPrize}
                  required
                  onChange={handleChange}
                  variant="outlined"
                />
                <div style={{ marginTop: 10 }}>
                  <InputLabel>Contest Sponsor</InputLabel>
                </div>
                <TextField
                  name="contestSponsor"
                  value={userInput.contestSponsor}
                  required
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />

                <div style={{ marginTop: 10 }}>
                  <InputLabel>Start Date</InputLabel>
                </div>
                <TextField
                  variant="outlined"
                  type="date"
                  value={userInput.contestStartDate}
                  required
                  name="contestStartDate"
                  onChange={handleChange}
                />
                <div style={{ marginTop: 10 }}>
                  <InputLabel>End Date</InputLabel>
                </div>
                <TextField
                  variant="outlined"
                  type="date"
                  name="contestEndDate"
                  required
                  value={userInput.contestEndDate}
                  onChange={handleChange}
                />

                <div style={{ textAlign: 'center', width: '100%', padding: 10 }}>
                  <div style={{ display: 'block', textAlign: 'center' }}></div>
                  <Button variant="contained" size="large" color="primary" type="submit">
                    Post a Contest
                  </Button>
                </div>
              </form>
            </div>
          </Paper>
        </div>
      </div>
    )
  } else {
    return (
      <Paper
        elevation={3}
        style={{ maxWidth: 600, width: '100%', margin: '0 auto', minHeight: 200 }}
      >
        <Typography variant="h6" style={{ textAlign: 'center', padding: 10 }}>
          Thank you. Your Gig has been posted.
        </Typography>
        <Divider />
        <div style={{ textAlign: 'center', width: '100%', marginTop: 20 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              props.history.push('/gigs/admin/self')
            }}
          >
            View Posted Gig
          </Button>

          <Button
            variant="text"
            color="primary"
            onClick={() => {
              setUserInput({})
            }}
          >
            Post Another
          </Button>
        </div>
      </Paper>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    token: state.auth.token,
    props: ownProps,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    postGig: (data, token) => dispatch(postGig(data, token)),
    updateGig: (data, token) => dispatch(updateGig(data, token)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateContest)
