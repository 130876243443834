import React from 'react'
import ContestDialog from './contest_dialog'
import Paper from '@material-ui/core/Paper'
import Label from '../display/label'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import SignUpDialogContest from '../auth/sign_up_dialog_contest'
import FacebookShareButton from 'react-share/lib/FacebookShareButton'
import WhatsappShareButton from 'react-share/lib/WhatsappShareButton'
import TwitterShareButton from 'react-share/lib/TwitterShareButton'
import { SocialIcon } from 'react-social-icons'
import { Grid } from '@material-ui/core'
import AccountIcon from '@material-ui/icons/SupervisorAccount'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import Moment from 'react-moment'

function ContestCard({ featured, contest, classes, auth, history }) {
  const shareUrl = window.location.href
  const titleContent = `Participate in current Seensta Contest: ${contest.title} | Click link to enter `

  return (
    <div className={classes.paper}>
      <Paper
        className={classes.root}
        style={{
          padding: 10,
          background: '#ffffff',
          border: '1px solid #F0EFEF',
          boxShadow: 'none',
        }}
        elevation={1}
      >
        <Grid container direction="row" alignItems="center">
          <Grid item xs={8}>
            <Label label="title" fontWeight={200} />
            <Typography className={classes.heading} variant="h5" style={{ fontSize: 14 }}>
              {contest.title}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            {auth ? (
              <div>
                <ContestDialog
                  photo={contest.imagePath}
                  contestid={contest.contest_id}
                  contestName={contest.title}
                  contestDescription={contest.description}
                ></ContestDialog>
                <Button
                  size="small"
                  variant="text"
                  style={{ color: '#504D4D', paddingRight: 0 }}
                  startIcon={<AccountIcon />}
                >
                  <span style={{ fontSize: 10, textTransform: 'lowercase' }}>
                    {contest.entry_count} participants
                  </span>
                </Button>
              </div>
            ) : (
              <div>
                <SignUpDialogContest
                  buttonText="enter contest"
                  variant="outlined"
                  color="primary"
                  headerMessage="Please Join to enter this contest."
                  resourceId={contest.contest_id}
                  action="contest"
                />
                <Button
                  size="small"
                  variant="text"
                  style={{ color: '#504D4D', paddingRight: 0 }}
                  startIcon={<AccountIcon />}
                >
                  <span style={{ fontSize: 10, textTransform: 'lowercase' }}>
                    {contest.entry_count} participants
                  </span>
                </Button>
              </div>
            )}
          </Grid>
        </Grid>

        {!featured && (
          <Box style={{ marginTop: 25 }}>
            <Label label="instructions" fontWeight={200} />
            <Typography
              variant="body1"
              style={{
                paddingBottom: 5,
                color: '#504D4D',
                textTransform: 'lowercase',
                fontSize: 14,
              }}
            >
              {contest.description}
            </Typography>
            <Label label="prizes" fontWeight={200} />
            <Typography
              variant="body1"
              style={{
                paddingBottom: 5,
                color: '#504D4D',
                textTransform: 'lowercase',
                fontSize: 14,
              }}
            >
              {contest.value}
            </Typography>
          </Box>
        )}
        <Label label="submission dates" fontWeight={200} />

        <Typography
          variant="body1"
          style={{ paddingBottom: 5, color: '#504D4D', textTransform: 'lowercase', fontSize: 14 }}
        >
          <Moment format="D MMM YYYY">{contest.end_date}</Moment>
        </Typography>

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flexDirection: 'column' }}>
            <div>
              <Label label="sponsor" fontWeight={200} />
            </div>
            <div>
              <Typography variant="body1" style={{ paddingBottom: 5, fontSize: 14 }}>
                {contest.sponsor}
              </Typography>
            </div>
          </div>
          <div style={{ marginLeft: 20 }}>
            <div style={{ flexDirection: 'column' }}>
              <div>
                <Label label="share" fontWeight={200} />
              </div>
              <div>
                <FacebookShareButton
                  url={shareUrl}
                  style={{ display: 'inline', width: 10, height: 10, padding: 5 }}
                  quote={titleContent}
                >
                  <SocialIcon style={{ width: 20, height: 20 }} network="facebook" />
                </FacebookShareButton>
                <WhatsappShareButton
                  url={shareUrl}
                  style={{ display: 'inline', width: 10, height: 10, padding: 5 }}
                  title={titleContent}
                >
                  <SocialIcon style={{ width: 20, height: 20 }} network="whatsapp" />
                </WhatsappShareButton>

                <TwitterShareButton
                  url={shareUrl}
                  style={{ display: 'inline', width: 10, height: 10, padding: 5 }}
                  title={titleContent}
                >
                  <SocialIcon style={{ width: 20, height: 20 }} network="twitter" />
                </TwitterShareButton>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            padding: 10,
            textAlign: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            height: 30,
          }}
        >
          <div style={{ float: 'right' }}>
            <Button
              variant="text"
              size="small"
              onClick={(e) => {
                e.stopPropagation()
                e.nativeEvent.stopImmediatePropagation()
                history.push('/contests/leaderboard/' + contest.contest_id)
              }}
              color="primary"
              style={{ textTransform: 'lowercase' }}
              endIcon={<ArrowRightAltIcon />}
            >
              View Leaderboard
            </Button>
          </div>
        </div>
      </Paper>
    </div>
  )
}
export default ContestCard
