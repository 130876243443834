import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Moment from 'react-moment'
import FormatDateTime from '../../components/display/format_date_time'
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    marginTop: 10,
  },
  label: {
    marginTop: 10,
    display: 'flex',
    fontWeight: 700,
  },
}))
function GigFeaturedLists({ gig, history }) {
  const classes = useStyles()

  return (
    <Box
      className={classes.root}
      onClick={() =>
        history
          ? history.push('/gigs/details/' + gig.gig_id, { gig })
          : (window.location.href = '/gigs/details/' + gig.gig_id)
      }
    >
      <label className={classes.label} style={{ color: '#ccc', float: 'right' }}>
        <FormatDateTime dateTime={gig.created_at} />
      </label>
      <Typography variant="h5" component="h3">
        {gig.gig_title}
      </Typography>
      <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box>
          <label className={classes.label}>Start Date</label>
          <Typography component="p">
            <Moment format="D MMM YYYY">{gig.gig_start_date}</Moment>
          </Typography>
        </Box>
        <Box>
          <label className={classes.label}>End Date</label>
          <Typography component="p">
            <Moment format="D MMM YYYY">{gig.gig_end_date}</Moment>
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default GigFeaturedLists
