import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import VoteFooter from '../vote/vote_footer'
import { withRouter } from 'react-router-dom'
import { awsImage } from '../../Helpers'
import { makeStyles } from '@material-ui/core/styles'
import UserHeader from '../portfolio/singleimage/user_header'
import VotesCountDisplay from '../display/votes_count_display'
import { Button } from '@material-ui/core'
import ScheduleIcon from '@material-ui/icons/Schedule'

const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
  },
  challengeGrid: {
    paddingTop: 0,
    paddingBottom: 0,
    maxWidth: 400,
    minHeight: 300,
    width: '100%',
    cursor: 'pointer',
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  vsContainer: {
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
  },
  vsTop: {
    borderRadius: '50%',
    backgroundColor: '#504D4D',
    fontSize: 12,
    margin: '0 auto',
    color: 'white',
    height: 14,
    width: 14,
    padding: 4,
  },
  challengerContainer: {
    float: 'left',
    textDecoration: 'none',
    widt: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  champContainer: {
    float: 'left',
    textDecoration: 'none',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  timeLeft: {
    color: 'white',
    height: 20,
    background: 'black',
    width: '100%',
    textAlign: 'center',
    bottom: 0,
    display: 'inline-block',
  },
  avatar: {
    width: 20,
    height: 20,
  },
  avatarTitle: {
    color: '#504D4D',
    textDecoration: 'none',
    fontSize: 13,
  },
  voteCount: {
    color: '#504D4D',
    paddingRight: 0,
    paddingLeft: 50,
    top: -10,
  },
  voteCountTitle: {
    fontSize: 10,
    textTransform: 'lowercase',
    color: '#52183E',
  },
}))

const ChallengeCard = ({
  challengerPhoto,
  champPhoto,
  randomid,
  history,
  id,
  challengedVotes,
  timeleft,
  champUser,
  challengedUser,
  challengerVotes,
  champVotes,
  isSingle,
}) => {
  const [challengedVotesSum, setChallengedVotesSum] = useState(
    champVotes ? champVotes : challengedVotes
  )
  const [challengerVotesSum, setChallengerVotesSum] = useState(challengerVotes)

  const handleVoted = (e, type) => {
    switch (type) {
      case 'challenger':
        setChallengerVotesSum(challengerVotesSum + 1)

        break
      case 'challenged':
        setChallengedVotesSum(challengedVotesSum + 1)
        break
      default:
        setChallengerVotesSum(0)
        setChallengedVotesSum(0)
    }
  }

  const challengerChallengedPhotos = challengerPhoto.includes('http')
    ? challengerPhoto
    : awsImage('folio', challengerPhoto, 250, 'contain')
  const champChallengedPhoto = champPhoto.includes('http')
    ? champPhoto
    : awsImage('folio', champPhoto, 250, 'contain')

  const classes = useStyles()

  return (
    <Box style={{ padding: 10 }}>
      <Box className={classes.challengeGrid}>
        <Box style={{ padding: 10 }}>
          <Button
            variant="outlined"
            color="default"
            style={{ backgroundColor: '#FFF5F5', color: '#E83E3E', borderColor: '#E83E3E' }}
            startIcon={<ScheduleIcon />}
          >
            {timeleft}
          </Button>
        </Box>

        <Grid
          onClick={(e) => {
            if (!isSingle) {
              e.stopPropagation()
              history.push('/challenges/' + randomid)
            }
            e.stopPropagation()
            return
          }}
          item
          style={{ display: 'flex', justifyContent: 'center' }}
          xs={12}
        >
          <Grid item xs={5} className={classes.challengerContainer}>
            <Box>
              {challengedUser.length > 0 && challengedUser[0].user && (
                <UserHeader
                  photoPath={
                    challengedUser[0].user.avatar && challengedUser[0].user.avatar.photoPath
                  }
                  userName={challengedUser[0].user.username}
                  firstName={challengedUser[0].user.firstName}
                  lastName={challengedUser[0].user.lastName}
                  fullwidth={true}
                  classes={classes}
                />
              )}
              <VotesCountDisplay
                votes={challengerVotesSum}
                verb="votes"
                type={'like'}
                classes={classes}
              />
              <Box
                style={{
                  width: 148,
                  height: 105,
                  display: 'inline-block',
                  backgroundSize: 'cover',
                  backgroundImage: `url('${challengerChallengedPhotos}')`,
                }}
              />

              <VoteFooter
                title="challenger"
                photoPath={challengerPhoto}
                battleId={id}
                votes={challengerVotes}
                float="inherit"
                isButton={false}
                createVote={handleVoted}
              />
            </Box>
          </Grid>
          <Grid
            item
            style={{
              display: 'flex',
              minHeight: 240,
              alignItems: 'center',
              float: 'left',
              justifyContent: 'center',
              width: 37,
            }}
          >
            <div className={classes.vsContainer}>
              <div className={classes.vsTop}>vrs</div>
            </div>
          </Grid>
          <Grid item xs={5} className={classes.champContainer}>
            <Box>
              {champUser.length > 0 && champUser[0].user && (
                <UserHeader
                  photoPath={champUser[0].user.avatar && champUser[0].user.avatar.photoPath}
                  userName={champUser[0].user.username}
                  firstName={champUser[0].user.firstName}
                  lastName={champUser[0].user.lastName}
                  fullwidth={true}
                  classes={classes}
                />
              )}
              <VotesCountDisplay
                votes={challengedVotesSum}
                verb="votes"
                type={'like'}
                classes={classes}
              />
              <Box
                style={{
                  width: 148,
                  height: 105,
                  display: 'inline-block',
                  backgroundSize: 'cover',
                  backgroundImage: `url('${champChallengedPhoto}')`,
                }}
              />

              <VoteFooter
                title="challenged"
                photoPath={champPhoto}
                battleId={id}
                votes={challengedVotes}
                float="right"
                createVote={handleVoted}
              />
            </Box>
          </Grid>
          {/* <span className={classes.timeLeft}>{timeleft}</span> */}
        </Grid>
      </Box>
    </Box>
  )
}
export default withRouter(ChallengeCard)
