import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(() => ({
  label: {
    marginTop: 10,
    marginBottom: 5,
    display: 'flex',
    fontWeight: (props) => props.fontWeight,
  },
}))

function Label(props) {
  const classes = useStyles(props)
  return <label className={classes.label}>{props.label}</label>
}

export default Label
