import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { fetchEntry, voteContest } from '../../store/actions/contestActions'
import { SocialIcon } from 'react-social-icons'
import { Mixpanel } from '../../Mixpanel'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share'
import { withRouter } from 'react-router-dom'
import EmptyState from '../appmessages/empty_state.'
import { awsImage } from '../../Helpers'
import SignUpDialogContest from '../auth/sign_up_dialog_contest'
import Alert from '@material-ui/lab/Alert'
import { loginUserSocial } from '../../store/actions/authActions'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import UserHeader from '../portfolio/singleimage/user_header'
import InstallApp from '../display/install_app'
import { Box, Divider } from '@material-ui/core'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import VotesCountDisplay from '../display/votes_count_display'

const useStyles = makeStyles(() => ({
  card: {
    maxWidth: 400,
    marginBottom: 20,
    margin: '0 auto',
    boxShadow: 'none',
  },
  sharebuttons: {
    display: 'inline-block',
    padding: 5,
  },
  avatar: {
    backgroundColor: '#9c27b0',
    width: 25,
    height: 25,
    marginRight: -13,
  },
  avatarTitle: {
    color: '#504D4D',
    textDecoration: 'none',
    fontSize: 13,
  },
  voteCount: {
    color: '#504D4D',
    fontSize: 16,
  },
  voteCountTitle: {
    fontSize: 16,
    textTransform: 'lowercase',
    color: '#504D4D',
  },
}))

function ContestEntry({ fetchEntry, voteContest, props, entry, auth, loginUserSocial }) {
  const [votes, setVotes] = useState()
  const [error, setError] = useState('')
  const [didVote, setDidVote] = useState(false)
  const [fetching, setFetching] = useState(true)
  const [exists, setExists] = useState(false)
  const [copiedToClipboard, setCopiedToClipboard] = useState(false)

  useEffect(() => {
    fetchEntry(props.match.params.entryid || props.entryid)
      .then((res) => {
        if (res.status === 200) {
          setVotes(res.data.votes)
          setFetching(false)
          setExists(true)
        } else if (res.status === 204) {
          setFetching(false)
          setExists(false)
        }
      })
      .catch(() => {
        setFetching(false)
      })
    Mixpanel.track('Contest Entry Pageview')
  }, [fetchEntry, props.entryid, props.match.params.entryid])

  const responseFacebook = async (response) => {
    if (response.email) {
      const name = response.name.split(' ')
      setFetching(true)

      const socialUserData = {
        firstName: name[1],
        lastName: name[0],
        email: response.email,
        access_token: response.accessToken,
        service: 'facebook',
        expires_in: response.expiresIn,
        user_id: response.id,
      }

      if (response.picture.data) {
        socialUserData.avatar = response.picture.data.url
      }

      const createdUser = await loginUserSocial(socialUserData)

      if (createdUser && createdUser.status === 200) {
        Mixpanel.track('Contest Vote Registration')
        const postData = {
          contest_id: entry.entry.id,
        }
        const actionResponse = await voteContest(postData, createdUser.data.data.access_token)
        if (actionResponse.status === 212) {
          setError(
            `You've already voted! You can vote again in ${parseInt(
              actionResponse.data.timeLeft,
              10
            )} hours.`
          )
          return
        }
        setVotes(votes + 1)
        Mixpanel.track('Contest Voted')
        setDidVote(true)
      }
      setFetching(false)
    }
  }

  const handleVote = async () => {
    var postdata = {
      contest_id: entry.entry.id,
    }
    const voteResponse = await voteContest(postdata)
    if (voteResponse.status === 212) {
      setError(
        `You've already voted! You can vote again in ${parseInt(
          voteResponse.data.timeLeft,
          10
        )} hours.`
      )
      return
    }
    setVotes(votes + 1)
    Mixpanel.track('Contest Voted')
    setDidVote(true)
  }

  const classes = useStyles()

  if (entry.length !== 0)
    return (
      <div
        style={{
          width: '80%',
          height: '100%',
          overflow: 'scroll',
          margin: '0 auto',
          textAlign: 'center',
          paddingTop: 25,
        }}
      >
        <Typography
          gutterBottom
          style={{ color: '#504D4D', textTransform: 'lowercase', fontSize: 14 }}
        >
          help{' '}
          <span style={{ fontWeight: 600 }}>
            {entry.entry.user.firstName} {entry.entry.user.lastName}
          </span>{' '}
          to win the
          <span style={{ fontWeight: 600 }}> {entry.entry.contest.title} contest.</span>
        </Typography>

        <Card className={classes.card} style={{ marginTop: 10, marginBottom: 50 }}>
          <UserHeader
            photoPath={entry.entry.user.avatar && entry.entry.user.avatar.photoPath}
            userName={entry.entry.user.username}
            firstName={entry.entry.user.firstName}
            lastName={entry.entry.user.lastName}
            classes={classes}
            fullwidth={true}
          />
          <CardMedia
            component="img"
            alt="Contest Image"
            image={
              entry.entry.image.includes('http')
                ? entry.entry.image
                : awsImage('folio', entry.entry.image, 500, 'cover')
            }
            title={`${entry.entry.user.firstName} ${entry.entry.user.lastName}`}
            style={{ borderRadius: 6 }}
          />
          <CardContent>
            {props.location.state && props.location.state.created === 'new' && (
              <div style={{ display: 'block', width: '100%', paddingBottom: 30 }}>
                <Alert security="success">
                  Success. Your entry was created. Share this page on your social platforms to get
                  votes. You can share by using the share buttons below or you can copy the url and
                  share where you want.{' '}
                  <Button
                    color="primary"
                    onClick={() => {
                      window.navigator.clipboard.writeText(window.location.href)
                      setCopiedToClipboard(true)
                    }}
                  >
                    {copiedToClipboard ? 'Copied!' : 'Copy Entry Url'}
                  </Button>
                </Alert>
              </div>
            )}

            {auth && !error.includes('hours') && !didVote && (
              <Box display="flex" justifyContent="space-between">
                <VotesCountDisplay votes={votes} verb="votes" type={'contest'} classes={classes} />

                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={handleVote}
                  startIcon={<ThumbUpIcon />}
                >
                  Vote
                </Button>
              </Box>
            )}
            {!auth && !didVote && (
              <div>
                {fetching && (
                  <div style={{ display: 'block', textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                )}

                <FacebookLogin
                  appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
                  autoLoad={false}
                  fields="name,email,picture"
                  redirectUri={`${window.location.origin}/fb/callback`}
                  callback={(e) => responseFacebook(e)}
                  state={`contestId=${entry.entry.id}`}
                  render={(renderProps) => (
                    <Button
                      variant="contained"
                      style={{ color: '#fff', background: '#3b5998' }}
                      onClick={renderProps.onClick}
                      disabled={fetching}
                    >
                      {`Vote using Facebook`}
                    </Button>
                  )}
                />
                <div style={{ padding: 10, textAlign: 'center' }}>Or</div>
              </div>
            )}

            <div style={{ padding: 10 }}>
              <SignUpDialogContest
                setVotes={setVotes}
                votes={votes}
                action="contest-vote"
                buttonText={`Join to vote for ${entry.entry.user.firstName} ${entry.entry.user.lastName}`}
                headerMessage={`Register to vote for ${entry.entry.user.firstName} ${entry.entry.user.lastName}`}
                variant="contained"
                color="primary"
                buttonActionText={`Vote for ${entry.entry.user.firstName}`}
                resourceId={entry.entry.id}
              />
            </div>

            {props.voted ? (
              <div style={{ display: 'block', width: '100%', textAlign: 'center' }}>
                <Typography
                  variant="body2"
                  color="secondary"
                  style={{ display: 'flex', width: '100%' }}
                >
                  Thank You, Please share.
                </Typography>
              </div>
            ) : null}
            <div style={{ display: 'block', width: '100%' }}>
              {error && <Alert severity="info">{error}</Alert>}

              <Typography
                variant="body2"
                color="primary"
                style={{ width: '100%', textAlign: 'center' }}
              >
                {didVote && `Voted! Please share this contestant so they can get more votes.`}
              </Typography>
              <Divider />
              <Typography
                variant="subtitle1"
                style={{ width: '100%', textAlign: 'center', color: '#504D4D' }}
              >
                Share on social media
              </Typography>

              <FacebookShareButton
                url={window.location.href}
                style={{ display: 'inline-block', width: 10, height: 10, padding: 15 }}
                quote={'Vote for ' + entry.entry.user.firstName}
              >
                <SocialIcon style={{ width: 20, height: 20 }} network="facebook" />
              </FacebookShareButton>
              <WhatsappShareButton
                url={window.location.href}
                style={{ display: 'inline-block', width: 10, height: 10, padding: 15 }}
                title={'Vote for ' + entry.entry.user.firstName}
              >
                <SocialIcon style={{ width: 20, height: 20 }} network="whatsapp" />
              </WhatsappShareButton>

              <TwitterShareButton
                url={window.location.href}
                style={{ display: 'inline-block', width: 10, height: 10, padding: 15 }}
                title={'Vote for ' + entry.entry.user.firstName}
              >
                <SocialIcon style={{ width: 20, height: 20 }} network="twitter" />
              </TwitterShareButton>
            </div>
            <Divider />
            <InstallApp isFor={false} />
          </CardContent>
        </Card>
      </div>
    )
  if (!exists && !fetching) {
    return <EmptyState title="Contest Entry" message="This entry no longer exists" />
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </div>
  )
}
const mapStateToProps = (state, ownProps) => {
  return {
    entry: state.contest.entry,
    entryid: ownProps.match.params.entryid,
    voted: state.contest.voted,
    auth: state.auth.auth,
    props: ownProps,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntry: (contest_id) => dispatch(fetchEntry(contest_id)),
    voteContest: (contest_id) => dispatch(voteContest(contest_id)),
    loginUserSocial: (credentials) => dispatch(loginUserSocial(credentials)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContestEntry))
