import React, { useState, useEffect, useRef } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Alert from '@material-ui/lab/Alert'
import { createUserandResponse } from '../../store/actions/authActions'
import { Mixpanel } from '../../Mixpanel'
import CustomizedSnackbars from '../appmessages/customized_snackbars'
import ContestUpload from '../upload/contest_upload'
import { handleImage } from '../../Helpers'
import { createContest, voteContest } from '../../store/actions/contestActions'
import WelcomeIntro from '../appmessages/welcome_intro'
import ReCAPTCHA from 'react-google-recaptcha'
import DownloadApp from '../../shared/components/download_app'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage:
      'url(https://d1sb0aptwihzht.cloudfront.net/eyJidWNrZXQiOiJzZWVuc3RhLWNvbnRlbnQiLCJrZXkiOiJmb2xpby9jN08wcUlfMEQ0NjNENTYtNjE3Qy00QTVFLThDMDUtNzMyNDdEMDcyQUYyLmpwZyIsImVkaXRzIjp7InJlc2l6ZSI6eyJ3aWR0aCI6NzAwLCJmaXQiOiJjb250YWluIn19fQ==)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  modalWidth: {
    minWidth: 240,
  },
}))
function SignUpDialogContest({
  buttonText,
  color,
  variant,
  headerMessage,
  auth,
  createUserandResponse,
  createError,
  history,
  resourceId,
  action,
  createContest,
  setVotes,
  votes,
  buttonActionText,
  voteContest,
}) {
  const [open, setOpen] = useState(false)
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [filearray, setFilearray] = useState([])
  const [fetching, setFetching] = useState(false)
  const [complete, setComplete] = useState(false)
  const [contestImageUpload, setContestImageUpload] = useState(null)
  const [finishMessage, setFinishMessage] = useState()
  const [contestUrl, setContestUrl] = useState(null)
  const [confirmEmail, setConfirmEmail] = useState(null)
  let actionResponse
  const recaptchaRef = useRef(0)
  const handleSubmit = async (e) => {
    if (confirmEmail) {
      return
    }

    e.preventDefault()
    const captchaToken = await recaptchaRef.current.executeAsync()

    const user = {
      firstname: firstName,
      lastname: lastName,
      email: email,
      password: password,
      token: captchaToken,
    }
    setFetching(true)
    if (captchaToken) {
      const token = await createUserandResponse(user)

      if (filearray.length === 0 && action === 'contest') {
        setContestImageUpload('Please upload a photo for entry into this contest.')
        Mixpanel.track('Contest No Photo')
        return
      }

      if (token && action === 'contest') {
        window.fbq('track', 'CompleteRegistration')
        //Mixpanel.track('Contest Entry Registration')
        //console.log(token)
        const imageUploadResponse = await handleImage(filearray, 'folio', token)
        if (imageUploadResponse) {
          if (action === 'contest') {
            const contestPostData = {
              contest_id: resourceId,
              image: imageUploadResponse.data
                ? imageUploadResponse.data.filename
                : imageUploadResponse.image,
            }
            actionResponse = await createContest(contestPostData, token)
            e.preventDefault()

            if (actionResponse) {
              if (actionResponse.status === 200) {
                Mixpanel.track('Contest Entry')
                //window.fbq('track', 'Lead')
                setComplete(true)
                setContestUrl(`/contests/entry/${actionResponse.data.contest_id}`)
                setFinishMessage('Thank You, Contest Entry Complete')
              }
              setFetching(false)
              history.push(`/contests/entry/${actionResponse.data.contest_id}`, { created: 'new' })
            }
          }
        }
        setFetching(false)
      } else if (token && action === 'contest-vote') {
        Mixpanel.track('Contest Vote Registration')
        const postData = {
          contest_id: resourceId,
        }
        actionResponse = await voteContest(postData, token)
        if (actionResponse.status === 200) {
          setVotes(votes + 1)
          Mixpanel.track('Contest Vote Casted')
          setFinishMessage('Thank You, Vote Completed')
          setComplete(true)
          setFetching(false)
        }
        setFetching(false)
      } else if (token && action === 'challenge-vote') {
        setOpen(false)
        setComplete(false)
      } else if (token && action === 'vote-challenge') {
        setComplete(true)
        setFinishMessage('Thank You, Vote Completed')
        setVotes({ token: token, resourceId: resourceId })
      }
    }
  }

  const handleClickOpen = (e) => {
    e.stopPropagation()
    setOpen(true)
  }

  const handleClose = (e) => {
    e.stopPropagation()
    setOpen(false)
    if (contestUrl) {
      console.log('history', history)
      history.push(`${contestUrl}`)
    }
  }

  const handleUpload = (files) => {
    setFilearray([...files])
  }

  useEffect(() => {
    if (finishMessage) {
      window.setTimeout(() => {
        setFinishMessage('')
      }, 5000)
    }
  }, [finishMessage])

  useEffect(() => {
    if (createError) {
      setFetching(false)
    }
  }, [createError])

  const classes = useStyles()
  console.log(action)
  return (
    <div>
      {!auth && !actionResponse && (
        <Button
          variant={variant}
          size="small"
          color={color}
          onClick={handleClickOpen}
          style={{ textTransform: 'lowercase' }}
        >
          {buttonText}
        </Button>
      )}
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{ textAlign: 'center', padding: 0 }}>
          {complete ? (
            <div>
              <Alert severity="success">{finishMessage}</Alert>
            </div>
          ) : (
            headerMessage
          )}
        </DialogTitle>

        <DialogContent className={classes.modalWidth} style={{ minHeight: 300, height: 'auto' }}>
          {complete ? (
            <WelcomeIntro contestUrl={contestUrl} />
          ) : (
            <div>
              {contestImageUpload ? (
                <div style={{ textAlign: 'center' }}>
                  <div>
                    Sorry! {firstName}, {contestImageUpload}
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => history.push('/image/upload')}
                    >
                      Add Photo
                    </Button>
                  </div>
                </div>
              ) : (
                <div style={{ textAlign: 'center' }}>
                  {action === 'contest-vote' && (
                    <div>
                      <span>Voting on the app counts twice as much </span>
                      <DownloadApp />
                    </div>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        autoComplete="fname"
                        name="firstName"
                        variant="outlined"
                        required
                        fullWidth
                        id="firstName"
                        label="First Name"
                        size="small"
                        autoFocus
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        name="lastName"
                        autoComplete="lname"
                        size="small"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        size="small"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        size="small"
                        autoComplete="current-password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Grid>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="invisible"
                      sitekey="6LdC9DQbAAAAAPMMBeEDPvMd3NhUIiOTYFjzPH7Z"
                    />
                    <input
                      variant="outlined"
                      name="confirmPass"
                      label="Password"
                      type="hidden"
                      size="small"
                      autoComplete="current-password"
                      onChange={(e) => setConfirmEmail(e.target.value)}
                    />
                    {action === 'contest' && <ContestUpload handleUpload={handleUpload} />}
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={handleSubmit}
                  >
                    {action === 'contest-vote' && buttonActionText}
                    {action === 'challenge-vote' && 'Register & Vote'}
                    {action === 'contest' && 'enter contest'}
                    {action === 'vote-challenge' && 'Vote for Challenge'}
                  </Button>

                  {fetching ? (
                    <div style={{ textAlign: 'center' }}>
                      <CircularProgress />
                    </div>
                  ) : null}

                  {createError ? (
                    <CustomizedSnackbars
                      messagetype="error"
                      message={createError}
                    ></CustomizedSnackbars>
                  ) : null}

                  <Grid container>
                    <Grid item>
                      <Link href="/signin" variant="body2">
                        Already have an account? Sign in
                      </Link>
                    </Grid>
                  </Grid>
                </div>
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    authError: state.auth.authError,
    auth: state.auth.auth,
    createError: state.auth.createError,
    props: ownProps,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createContest: (contestdata, token) => dispatch(createContest(contestdata, token)),
    voteContest: (postData, token) => dispatch(voteContest(postData, token)),
    createUserandResponse: (credentials) => dispatch(createUserandResponse(credentials)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUpDialogContest))
