import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import SortIcon from '@material-ui/icons/Sort'
import queryString from 'query-string'

const UserType = [
  'Model',
  'Designer',
  'Photographer',
  'Fashion',
  'Makeup Artist',
  'Dancer',
  'All Types',
]
const Levels = [
  'Beginner',
  'Amateur',
  'Almost Pro',
  'Professional',
  'Master',
  'Grand Master',
  'Selfie Queen',
  'Top Models',
  'Pending',
  'All Levels',
]

const Locations = [
  'Kenya',
  'Nairobi',
  'Mombasa',
  'Kisumu',
  'Nakuru',
  'Nigeria',
  'Abuja',
  'Lagos',
  'Ghana',
  'Accra',
  'World',
]
const Timeframes = ['Now', 'This Past Week', 'This Past Month', 'This Past Year', 'All Time']
function ContentFilter(props) {
  const [timeframe, setTimeframe] = useState('All Time')
  const [creativeType, setCreativeType] = useState('All Types')
  const [creativeLevel, setCreativeLevel] = useState('All Levels')
  const [location, setLocation] = useState('')
  const [showFilter, setShowFilter] = useState(false)

  useEffect(() => {
    const search = queryString.parse(props.history.location.search)
    if (search.timeframe) {
      setTimeframe(search.timeframe)
    }
    if (search.user_type) {
      setCreativeType(search.user_type)
    }
    if (search.rank) {
      setCreativeLevel(search.rank)
    }
    if (search.rank) {
      setLocation(search.location)
    }
    if (Locations.includes(props.history.location.pathname.split('/')[3])) {
      setLocation(props.history.location.pathname.split('/')[3])
    }
  })

  const handleChangeTimeline = (event) => {
    const search = queryString.parse(props.history.location.search)
    search.timeframe = event.target.value
    const searchString = queryString.stringify(search)

    setTimeframe(event.target.value)
    props.history.push({
      pathname: props.history.location.pathname,
      search: searchString,
    })
  }
  const handleChangeCrativeType = (event) => {
    const search = queryString.parse(props.history.location.search)

    search.user_type = event.target.value
    const searchString = queryString.stringify(search)

    setCreativeType(event.target.value)
    let paramPath = props.history.location.pathname
    if (props.page) {
      paramPath = '/browse/' + event.target.value
    }
    props.history.push({
      pathname: paramPath,
      search: searchString,
    })
  }
  const handleChangeCreativeLevel = (event) => {
    const search = queryString.parse(props.history.location.search)

    search.rank = event.target.value
    const searchString = queryString.stringify(search)

    setCreativeLevel(event.target.value)
    props.history.push({
      pathname: props.history.location.pathname,
      search: searchString,
    })
  }
  const handleChangeCountryLocation = (event) => {
    let paramPath = props.history.location.pathname
    paramPath = '/browse/loc/' + event.target.value
    setLocation(event.target.value)
    props.history.push({
      pathname: paramPath,
    })
  }

  return (
    <div style={{ minHeight: 50 }}>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<SortIcon />}
        onClick={() => setShowFilter((showFilter) => !showFilter)}
        style={{ float: 'right' }}
      >
        Filter
      </Button>

      {showFilter && (
        <Box padding={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={2} xl={2} md={2}>
              <FormControl variant="outlined" margin="dense" style={{ width: '100%' }}>
                <Box paddingBottom={1} htmlFor="creative-type">
                  Timeframe
                </Box>
                <Select
                  native
                  value={timeframe}
                  onChange={handleChangeTimeline}
                  inputProps={{
                    name: 'creativeType',
                    id: 'creative-type',
                  }}
                >
                  {Timeframes.map((ele, index) => {
                    return (
                      <option value={ele} key={index}>
                        {ele}
                      </option>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={2} xl={2} md={2}>
              <FormControl variant="outlined" margin="dense" style={{ width: '100%' }}>
                <Box paddingBottom={1}>Creative Type</Box>
                <Select
                  native
                  value={creativeType}
                  onChange={handleChangeCrativeType}
                  inputProps={{
                    name: 'creativeType',
                    id: 'creative-type',
                  }}
                >
                  {UserType.map((ele, index) => {
                    return (
                      <option value={ele} key={index}>
                        {ele}
                      </option>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={2} xl={2} md={2}>
              <FormControl variant="outlined" margin="dense" style={{ width: '100%' }}>
                <Box paddingBottom={1}>Creative Level</Box>
                <Select
                  native
                  value={creativeLevel}
                  onChange={handleChangeCreativeLevel}
                  inputProps={{
                    name: 'creativeType',
                    id: 'creative-type',
                  }}
                >
                  {Levels.map((ele, index) => {
                    return (
                      <option value={ele} key={index}>
                        {ele}
                      </option>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={2} xl={2} md={2}>
              <FormControl variant="outlined" margin="dense" style={{ width: '100%' }}>
                <Box paddingBottom={1}>Country/Location</Box>
                <Select
                  native
                  value={location}
                  onChange={handleChangeCountryLocation}
                  inputProps={{
                    name: 'countryLocation',
                    id: 'country-location',
                  }}
                >
                  <option aria-label="None" value="" />
                  {Locations.map((ele, index) => {
                    return (
                      <option value={ele} key={index}>
                        {ele}
                      </option>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  )
}

export default ContentFilter
