import { FETCH_USER, USER_PHOTOS, FETCH_USER_REQUEST } from '../actions/types'

const initialState = {
  user: {},
  avatar: null,
  photos: [],
  about: null,
  fetching: false,
  battlewon: [],
  followers: 0,
  following: 0,
  contests: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_USER:
      return {
        ...state,
        user: action.user,
        avatar: action.avatar,
        about: action.about,
        fetching: false,
        battlewon: action.battlewon,
        followers: action.followers,
        following: action.following,
        contests: action.contests,
      }
    case USER_PHOTOS:
      return {
        ...state,
        photos: action.photos,
        fetching: false,
      }
    case FETCH_USER_REQUEST:
      return {
        ...state,
        fetching: true,
      }

    default:
      return state
  }
}
