import React, { useEffect, useState } from 'react'
import { getFeaturedContest } from '../../store/actions/contestActions'
import { connect } from 'react-redux'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import PageHeading from '../display/page_heading'
import FeaturedContestCard from './featured_contest_card'

const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    margin: 'auto',
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxHeight: '50px',
    padding: 5,
  },
}))

function FeaturedContest({ getFeaturedContest, token, auth, history }) {
  const [featuredContest, setFeaturedContest] = useState([])
  const [isFetching, setIsFetching] = useState(true)

  const classes = useStyle()

  useEffect(() => {
    const handleFetchFeaturedContest = async () => {
      const res = await getFeaturedContest()
      setFeaturedContest(res.data.featuredContests)
      setIsFetching(false)
    }

    handleFetchFeaturedContest()
  }, [getFeaturedContest, token, setFeaturedContest])

  useEffect(() => {
    if (featuredContest.length && isFetching) {
      setIsFetching(false)
    }
  }, [featuredContest.length, isFetching])

  return (
    <Box>
      <Paper
        className={classes.root}
        style={{
          boxShadow: 'none',
          background: '#FDFCFC',
        }}
        elevation={1}
      >
        {isFetching ? (
          <Box style={{ textAlign: 'center' }}>
            <CircularProgress size={20} />
          </Box>
        ) : (
          <Box style={{ marginBottom: 0 }}>
            <Box style={{ paddingTop: 20 }}>
              <PageHeading title="running contests" divider={false} />
            </Box>
            <Box
              flexDirection={'row'}
              display={'flex'}
              justifyContent={'space-between'}
              style={{ overflow: 'scroll', height: 295 }}
            >
              {featuredContest
                ? featuredContest.map((contest, index) => {
                    return (
                      <Box key={index} flex={1.5}>
                        <FeaturedContestCard
                          contest={contest}
                          auth={auth}
                          classes={classes}
                          featured={true}
                          history={history}
                        />
                      </Box>
                    )
                  })
                : null}
            </Box>
          </Box>
        )}
      </Paper>
    </Box>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFeaturedContest: (token) => dispatch(getFeaturedContest(token)),
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
  token: state.auth.token,
})

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedContest)
