import { POST_MESSAGE, GET_MESSAGE } from '../actions/types'

const initialState = {
  messages: [],
  fetching: false,
  seaminfo: null,
  seamfigure: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case POST_MESSAGE:
      return {
        ...state,
      }

    case GET_MESSAGE:
      return {
        ...state,
        messages: action.payload.message,
        seaminfo: action.payload.seam,
        seamfigure: action.payload.figure,
      }
    default:
      return state
  }
}
