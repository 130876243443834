import React, { useEffect } from 'react'
import { fetchPortfolio, fetchPortfolioMore } from '../../store/actions/portfolioActions'
import { connect } from 'react-redux'
import ImageCard from '../portfolio/image_cards'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import ExpandMore from '@material-ui/icons/ExpandMore'
import BrowseMenu from '../layout/top_browse_menu'
import { Mixpanel } from '../../Mixpanel'
import Masonry from 'react-masonry-css'
import './Grid.css'
import Hidden from '@material-ui/core/Hidden'
import ExpandRefresh from '@material-ui/icons/Refresh'
import IconButton from '@material-ui/core/IconButton'

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1,
}
function Tags({ portfolio, fetchPortfolio, tags, token, fetchPortfolioMore }) {
  useEffect(() => {
    const page = 1
    if (portfolio.length) {
      fetchPortfolio(null, null, null, tags, null, page, token)
    }
    document.title = 'Seensta | ' + tags

    Mixpanel.track('Tags Pageview')
  }, [fetchPortfolio, portfolio, tags, token])

  const handleMore = () => {
    //Calculate Page
    const page = Math.round(portfolio.length / 20 + 1)
    //fetch more for the data.
    fetchPortfolioMore(null, null, null, tags, null, page, token)

    Mixpanel.track('Tags View More Clicked')
  }

  //render the view

  if (portfolio.length > 0) {
    return (
      <div style={{ paddingBottom: '130px', maxWidth: 1380, margin: '0 auto' }}>
        <BrowseMenu />

        <div>
          <Hidden only="sm">
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {portfolio.map((card, index) => {
                return (
                  <div key={index}>
                    <ImageCard
                      header={card.photoPath ? 'yes' : 'no'}
                      {...card}
                      auth={this.props.auth}
                      nowuser={this.props.user.username}
                    />
                  </div>
                )
              })}
            </Masonry>

            <div style={{ textAlign: 'center' }}>
              {this.props.fetching ? (
                <CircularProgress />
              ) : (
                <div>
                  <IconButton
                    variant="outlined"
                    size="small"
                    color="secondary"
                    onClick={handleMore}
                  >
                    <ExpandRefresh /> <br />
                  </IconButton>
                </div>
              )}
            </div>
          </Hidden>
          <Hidden only={['lg', 'xl']}>
            {portfolio.map((card, index) => {
              return (
                <div key={index}>
                  <ImageCard
                    header={card.photoPath ? 'yes' : 'no'}
                    {...card}
                    auth={this.props.auth}
                    nowuser={this.props.user.username}
                  />
                </div>
              )
            })}
            <div style={{ textAlign: 'center' }}>
              {this.props.fetching ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  onClick={handleMore}
                  style={{ width: '80%' }}
                >
                  <ExpandMore />
                  Load More
                </Button>
              )}
            </div>
          </Hidden>
        </div>
      </div>
    )
  }

  return (
    <div style={{ paddingTop: '100px', paddingBottom: '100px', textAlign: 'center' }}>
      <CircularProgress />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPortfolio: (username, rank, usertype, tags, hot, page, token) => {
      dispatch(fetchPortfolio(username, rank, usertype, tags, hot, page, token))
    },
    fetchPortfolioMore: (username, rank, usertype, tags, hot, page, token) => {
      dispatch(fetchPortfolioMore(username, rank, usertype, tags, hot, page, token))
    },
  }
}

const mapStateToProps = (state, ownProps) => ({
  portfolio: state.portfolio.tags,
  auth: state.auth.auth,
  tags: ownProps.match.params.tag,
  fetching: state.portfolio.fetching,
  user: state.auth.user,
  token: state.auth.token,
})

export default connect(mapStateToProps, mapDispatchToProps)(Tags)
