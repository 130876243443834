import React, { useEffect, useState } from 'react'
import {
  fetchPortfolioFinder,
  fetchPortfolioFinderMore,
} from '../../store/actions/portfolioActions'
import { connect } from 'react-redux'
import ImageCard from '../portfolio/image_cards'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandRefresh from '@material-ui/icons/Refresh'
import IconButton from '@material-ui/core/IconButton'
import { Mixpanel } from '../../Mixpanel'
import './Grid.css'
import Hidden from '@material-ui/core/Hidden'
import SideBar from '../../components/layout/side_bar'
import EmptyState from '../appmessages/empty_state.'
import PageHeading from '../display/page_heading'
import Divider from '@material-ui/core/Divider'
import queryString from 'query-string'
import ContentFilter from '../display/content_filter'
import ExploreSearch from '../layout/explore_search'
function BrowseLocation({
  fetchPortfolio,
  fetchPortfolioMore,
  portfolio,
  auth,
  fetching,
  user,
  featuredusers,
  match,
  location,
  token,
  history,
}) {
  const [searchTerm, setSearchTerm] = useState()
  const [searchType, setSearchType] = useState()

  useEffect(() => {
    const values = queryString.parse(location.search)

    const searchTermInUri = match.params.term ? match.params.term : location.state.term
    const currentSearchType = match.params ? 'location' : location.state.searchtype
    fetchPortfolio({
      filter: queryString.stringify(values),
      page: 1,
      token: token,
      searchType: currentSearchType ? currentSearchType : null,
      searchTerm: match.params.term ? match.params.term : location.state.term,
    })
    setSearchTerm(searchTermInUri)
    setSearchType(currentSearchType)
    document.title = 'Seensta | Browse ' + currentSearchType
    Mixpanel.track('Browse ' + searchTermInUri + ' Pageview')
  }, [fetchPortfolio, location.search, location.state, match.params.term, token])

  const handleMore = () => {
    //Calculate Page
    var numberofitems = portfolio.length
    var page = Math.round(numberofitems / 20 + 1)
    const values = queryString.parse(location.search)

    fetchPortfolioMore({
      filter: queryString.stringify(values),
      page: page,
      token: token,
      searchType: searchType ? searchType : null,
      searchTerm: searchTerm ? searchTerm : null,
    })
    Mixpanel.track('Press Load More Expore Screen', {
      search_type: searchType,
      search_term: searchTerm,
    })
  }

  if (!fetching && !portfolio.length) {
    return (
      <div className="desktop-width-100">
        <div className="desktop-grid-container">
          <div className="desktop-grid-containerflex">
            <Hidden only={['xs', 'sm']}>
              <SideBar featuredusers={featuredusers} />
            </Hidden>
          </div>
          <div className="desktop-grid-container-card">
            <ContentFilter history={history} />
            <EmptyState
              message="Looks like we've ran out of content"
              title={`${searchTerm} Content`}
            />
          </div>
        </div>
      </div>
    )
  } else if (portfolio.length) {
    return (
      <div>
        <Divider />
        <div className="desktop-width-100">
          <div>
            <Hidden only={['xs', 'sm']}>
              <div className="desktop-grid-container">
                <div className="desktop-grid-containerflex">
                  <SideBar featuredusers={featuredusers} heading="Designers" />
                </div>
                <div className="desktop-grid-container-card">
                  <PageHeading title={`${searchTerm} Creatives`} message="browse" />
                  <ContentFilter history={history} />

                  {portfolio &&
                    portfolio.map((card, index) => {
                      return (
                        <div
                          key={index}
                          className={window.innerWidth <= 760 ? null : 'card-grid-container'}
                        >
                          <ImageCard
                            header={card.photoPath === undefined}
                            {...card}
                            auth={auth}
                            nowuser={user.username}
                            key={index}
                          />
                        </div>
                      )
                    })}
                </div>
              </div>
              <div style={{ textAlign: 'center' }}>
                {fetching ? (
                  <CircularProgress />
                ) : (
                  <div>
                    <IconButton
                      variant="outlined"
                      size="small"
                      color="secondary"
                      onClick={handleMore}
                    >
                      <ExpandRefresh /> <br />
                    </IconButton>
                  </div>
                )}
              </div>
            </Hidden>
            <Hidden only={['lg', 'xl', 'md']}>
              <div style={{ marginTop: 10 }}>
                <ExploreSearch showPanel={true} history={history} />

                {portfolio &&
                  portfolio.map((card, index) => {
                    return (
                      <div
                        key={index}
                        className={window.innerWidth <= 760 ? null : 'card-grid-container'}
                      >
                        <ImageCard
                          header={card.photoPath === undefined}
                          {...card}
                          auth={auth}
                          nowuser={user.username}
                          key={index}
                        />
                      </div>
                    )
                  })}
              </div>

              <div style={{ textAlign: 'center' }}>
                {fetching ? (
                  <CircularProgress />
                ) : (
                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    onClick={handleMore}
                    style={{ width: '80%' }}
                  >
                    <ExpandMore />
                    Load More
                  </Button>
                )}
              </div>
            </Hidden>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="desktop-width-100">
      <div className="desktop-grid-container-card">
        <div
          style={{
            paddingTop: '100px',
            paddingBottom: '100px',
            textAlign: 'center',
          }}
        >
          <CircularProgress />
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPortfolio: (queryData) => {
      dispatch(fetchPortfolioFinder(queryData))
    },
    fetchPortfolioMore: (queryData) => {
      dispatch(fetchPortfolioFinderMore(queryData))
    },
  }
}

const mapStateToProps = (state, ownProps) => ({
  portfolio: state.portfolio.finderfolio,
  auth: state.auth.auth,
  fetching: state.portfolio.fetching,
  user: state.auth.user,
  featuredusers: state.portfolio.featuredusers,
  token: state.auth.token,
  props: ownProps,
})

export default connect(mapStateToProps, mapDispatchToProps)(BrowseLocation)
