import {
  FETCH_PORTFOLIO_SEARCH,
  FETCH_PORTFOLIO_FINDER,
  FETCH_PORTFOLIO_FINDER_MORE,
  FETCH_PORTFOLIO_MORE_FINDER_REQUEST,
  FETCH_PORTFOLIO_SINGLE_ERROR,
  FETCH_PORTFOLIO_SINGLE_REQUEST,
  FETCH_PORTFOLIO_SINGLE,
  FETCH_PORTFOLIO,
  FETCH_PORTFOLIO_REQUEST,
  FETCH_PORTFOLIO_MORE,
  FETCH_PORTFOLIO_ERROR,
  FETCH_PORTFOLIO_MORE_ERROR,
  FETCH_PORTFOLIO_MORE_REQUEST,
  DELETE_PHOTO,
} from './types'
import Api from '../../Api'

const basedev = process.env.REACT_APP_API_URL
const headers = {}
export const fetchPortfolio = (queryData) => async (dispatch) => {
  let endpoint
  headers.Accept = 'application/json'
  queryData.resultType = queryData.resultType ? queryData.resultType : 'regular'

  if (queryData.term === 'Models') {
    queryData.term = 'model'
  }
  switch (queryData.resultType) {
    case 'usertype':
      endpoint = `${basedev}folio/browse/${queryData.term}/${queryData.page}/20?${queryData.query}`
      break
    case 'username':
      endpoint = `${basedev}folio/${queryData.term}/${queryData.page}/20`
      break
    case 'whatshot':
      endpoint = `${basedev}whatshot/${queryData.page}/20`
      break
    case 'new':
      endpoint = `${basedev}new/${queryData.page}/20`
      break
    case 'tags':
      endpoint = `${basedev}folio/tags/${queryData.term}/${queryData.page}/20`
      break
    default:
      endpoint = `${basedev}folio/${queryData.page}/20?${queryData.query}`
  }

  if (queryData.resultType === 'username') {
    try {
      const res = await Api.get(endpoint, { headers: headers })
      dispatch({
        type: FETCH_PORTFOLIO,
        payload: res.data,
        fetchby: queryData.resultType,
      })

      return res.data
    } catch (error) {
      dispatch({
        type: FETCH_PORTFOLIO_ERROR,
        payload: error.response,
      })
    }
  } else {
    dispatch({
      type: FETCH_PORTFOLIO_REQUEST,
    })
    Api.get(endpoint, { headers: headers })
      .then((res) => {
        dispatch({
          type: FETCH_PORTFOLIO,
          payload: res.data,
          fetchby: queryData.resultType,
        })
      })
      .catch((error) => {
        dispatch({
          type: FETCH_PORTFOLIO_ERROR,
          payload: error.response,
        })
      })
  }
}

export const fetchPortfolioFinder = (queryData) => (dispatch) => {
  let endpoint
  let fetchtag = 'searchterm'
  headers.Authorization = `Bearer ${queryData.token}`
  headers.Accept = 'application/json'

  endpoint = `${basedev}finder/${queryData.searchType}/${queryData.searchTerm}/${queryData.page}/20?${queryData.filter}`
  dispatch({
    type: FETCH_PORTFOLIO_REQUEST,
  })
  Api.get(endpoint, { headers: headers })
    .then((res) => {
      dispatch({
        type: FETCH_PORTFOLIO_FINDER,
        payload: res.data,
        fetchby: fetchtag,
      })
    })
    .catch(() => {
      dispatch({
        type: FETCH_PORTFOLIO_ERROR,
        payload: null,
      })
    })
}

export const fetchPortfolioFinderMore = (queryData) => (dispatch) => {
  const endpoint = `${basedev}finder/${queryData.searchType}/${queryData.searchTerm}/${queryData.page}/20?${queryData.filter}`
  const fetchtag = 'searchterm'
  dispatch({
    type: FETCH_PORTFOLIO_MORE_FINDER_REQUEST,
  })
  Api.get(endpoint)
    .then((res) => {
      dispatch({
        type: FETCH_PORTFOLIO_FINDER_MORE,
        payload: res.data,
        fetchby: fetchtag,
      })
    })
    .catch((error) => {
      dispatch({
        type: FETCH_PORTFOLIO_ERROR,
        payload: null,
      })
      console.log(error)
    })
}

export const fetchPortfolioMore = (queryData) => async (dispatch) => {
  let endpoint
  headers.Accept = 'application/json'
  headers.Authorization = `Bearer ${queryData.token}`

  if (queryData.term === 'Models') {
    queryData.term = 'model'
  }
  switch (queryData.resultType) {
    case 'usertype':
      endpoint = `${basedev}folio/browse/${queryData.term}/${queryData.page}/20?${queryData.query}`
      break
    case 'username':
      endpoint = `${basedev}folio/${queryData.term}/${queryData.page}/20`
      break
    case 'whatshot':
      endpoint = `${basedev}whatshot/${queryData.page}/20`
      break
    case 'new':
      endpoint = `${basedev}new/${queryData.page}/20`
      break
    case 'tags':
      endpoint = `${basedev}folio/tags/${queryData.term}/${queryData.page}/20`
      break
    default:
      endpoint = `${basedev}folio/${queryData.page}/20?${queryData.query}`
  }
  try {
    dispatch({
      type: FETCH_PORTFOLIO_MORE_REQUEST,
    })
    const res = await Api.get(endpoint, { headers: headers })
    dispatch({
      type: FETCH_PORTFOLIO_MORE,
      payload: res.data,
      fetchby: queryData.resultType,
    })
    return res
  } catch (error) {
    dispatch({
      type: FETCH_PORTFOLIO_MORE_ERROR,
      payload: null,
    })
    return error
  }
}

export const deleteFolio = (itemID, itemkey, token) => (dispatch) => {
  let endpoint
  endpoint = basedev + 'folio/' + itemID

  var headers = {
    Authorization: 'Bearer ' + token,
  }
  Api.delete(endpoint, { headers: headers })
    .then((res) => {
      dispatch({
        type: DELETE_PHOTO,
        payload: res.status,
        key: itemkey,
      })
    })
    .catch((error) => {
      return error
    })
}

export const fetchPortfolioSingle = (queryData) => async (dispatch) => {
  let endpoint
  let fetchtag

  endpoint = `${basedev}folio/${queryData.id}`

  headers.Authorization = `Bearer ${queryData.token}`
  dispatch({
    type: FETCH_PORTFOLIO_SINGLE_REQUEST,
  })
  try {
    const res = await Api.get(endpoint, { headers: headers })
    dispatch({
      type: FETCH_PORTFOLIO_SINGLE,
      payload: res.data,
      fetchby: fetchtag,
    })
    return res
  } catch (error) {
    dispatch({
      type: FETCH_PORTFOLIO_SINGLE_ERROR,
      payload: null,
    })

    return error
  }
}

export const fetchPortfolioSearch = (queryData) => (dispatch) => {
  const endpoint = `${basedev}findfolio/${queryData.category}/user/none/${queryData.page}/20`
  headers.Accept = 'application/json'
  headers.Authorization = `Bearer ${queryData.token}`
  dispatch({
    type: FETCH_PORTFOLIO_REQUEST,
  })
  Api.get(endpoint, { headers: headers })
    .then((res) => {
      dispatch({
        type: FETCH_PORTFOLIO_SEARCH,
        payload: res.data,
        finder: 'user',
      })
    })
    .catch((error) => {
      dispatch({
        type: FETCH_PORTFOLIO_ERROR,
        payload: null,
      })
      return error
    })
}
