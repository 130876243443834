import React, { useState } from 'react'
import { connect } from 'react-redux'
import { fetchPortfolioSearch } from '../../store/actions/portfolioActions'
import { Mixpanel } from '../../Mixpanel'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'
import Avatar from '@material-ui/core/Avatar'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import { fade, makeStyles } from '@material-ui/core/styles'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'

import Popover from '@material-ui/core/Popover'
import { withRouter } from 'react-router-dom'
import { awsImage } from '../../Helpers'

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade('#e4e4e4', 0.75),
    '&:hover': {
      backgroundColor: fade('#e4e4e4', 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#000',
  },
  inputRoot: {
    color: '#000',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: 650,
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 450,
    },
  },
}))

function SearchScreen({ fetchPortfolioSearch, userlist, token, history }) {
  const [anchorEl, setAnchorEl] = useState('')

  const handleClose = () => {
    setAnchorEl(null)
  }

  const _handleQueryChange = (searchText) => {
    if (searchText.target.value.length >= 4) {
      setAnchorEl(searchText.currentTarget)
      fetchPortfolioSearch({ category: searchText.target.value, page: 1, token: token })
      Mixpanel.track('Searched Term', { term: searchText.target.value })
    }
  }
  const classes = useStyles()
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          onChange={(query) => {
            _handleQueryChange(query)
          }}
          inputProps={{ 'aria-label': 'search' }}
        />
      </div>

      {userlist.length > 0 ? (
        <Popover
          id={id}
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          style={{ maxWidth: 650, width: '100%' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <List style={{ width: 650 }}>
            {userlist &&
              userlist.map((user, index) => {
                const profilepictureURL = awsImage('profilepictures', user.photoPath, 50, 'contain')
                return (
                  <ListItem
                    button
                    onClick={() => {
                      history.push(`/${user.username}`)
                    }}
                    key={index}
                    alignItems="flex-start"
                  >
                    <ListItemAvatar>
                      <Avatar alt="User" src={profilepictureURL} />
                    </ListItemAvatar>
                    <ListItemText primary={user.firstName + ' ' + user.lastName} />
                  </ListItem>
                )
              })}
          </List>
        </Popover>
      ) : null}
    </div>
  )
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchPortfolioSearch: (queryData) => {
      dispatch(fetchPortfolioSearch(queryData))
    },
  }
}

const mapStateToProps = (state) => ({
  userlist: state.portfolio.searchuser,
  auth: state.auth.auth,
  fetching: state.portfolio.fetching,
  user: state.auth.user,
  featuredusers: state.portfolio.featuredusers,
  token: state.auth.token,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchScreen))
