import React, { useState, useEffect } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { connect } from 'react-redux'
import { getGig } from '../../store/actions/gigActions'
import EmptyState from '../../components/appmessages/empty_state.'
import PageHeading from '../../components/display/page_heading'
import GigDisplayMin from './atoms/gig_display_min'
import Hidden from '@material-ui/core/Hidden'
import { withRouter } from 'react-router-dom'

function GigList({ getGig, token, history }) {
  const [postedGigs, setPostedGigs] = useState([])
  const [fetching, setFetching] = useState(true)

  useEffect(() => {
    document.title = 'Seensta | Apply for Available Gigs'
    getGig(token)
      .then((res) => {
        setPostedGigs([...res.data.gig])
        setFetching(false)
      })
      .catch(() => {})
  }, [getGig, token])

  return (
    <div>
      <Hidden only={['sm', 'xs']}>
        <PageHeading
          title="Available Gigs"
          message="Add to your portfolio through TFP or earn income"
        />
      </Hidden>

      <div
        style={{
          maxWidth: 600,
          width: '100%',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {postedGigs.length ? (
          postedGigs.map((gig, index) => {
            return <GigDisplayMin gig={gig} history={history} key={index} />
          })
        ) : (
          <div style={{ textAlign: 'center' }}>
            {fetching ? (
              <CircularProgress />
            ) : (
              <EmptyState
                title="No Gigs Available"
                message="Sorry we don't have anymore gigs available. Please check back soon!"
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
const mapStateToProps = (state, ownProps) => {
  return {
    token: state.auth.token,
    props: ownProps,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getGig: (token) => dispatch(getGig(token)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GigList))
