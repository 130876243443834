import React from 'react'
import AccountIcon from '@material-ui/icons/SupervisorAccount'
import Button from '@material-ui/core/Button'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'

function VotesCountDisplay({ votes, verb, type, classes }) {
  const RenderIcon = () => {
    switch (type) {
      case 'contest':
        return null
      case 'like':
        return <ThumbUpIcon fontSize="small" color="primary" />
      default:
        return <AccountIcon />
    }
  }

  return (
    <Button size="small" variant="text" className={classes.voteCount} startIcon={<RenderIcon />}>
      <span className={classes.voteCountTitle}>
        {votes} {verb}
      </span>
    </Button>
  )
}

export default VotesCountDisplay
