import React, { useReducer, useState } from 'react'
import { connect } from 'react-redux'
import FormControl from '@material-ui/core/FormControl'
import { createUserOnFly } from '../../store/actions/authActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { Typography } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { Mixpanel } from '../../Mixpanel'

function changeInputValue(state, newState) {
  return {
    ...state,
    ...newState,
  }
}
function ShowErrors(props) {
  return (
    <div>
      {props.errors &&
        props.errors.map((error, index) => {
          return (
            <div style={{ textAlign: 'center' }} key={index}>
              {error.email[0]}
            </div>
          )
        })}
    </div>
  )
}
function Signup({ props, createUser }) {
  const [userInput, setUserInput] = useReducer(changeInputValue, {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
  })
  const [errors, setErrors] = useState('')

  function handleChange(e) {
    const { name, value } = e.target
    setUserInput({ [name]: value })
  }
  function create() {
    const user = {
      firstname: userInput.firstName,
      lastname: userInput.lastName,
      email: userInput.email,
      password: userInput.password,
      phone: userInput.phoneNumber,
    }
    createUser(user)
      .then((res) => {
        Mixpanel.track('Sign Up Monthly Wear')
        props.completeQuestionire(res.data.access_token).then(() => {
          Mixpanel.track('Style Guide Completed')

          props.history.push('/style/plan')
        })
      })
      .catch((err) => {
        Mixpanel.track('Error Monthly Wear')
        setErrors([JSON.parse(err.response.data.errors)])
      })
  }
  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <Typography variant="h5">We got it.</Typography>
        <Typography variant="subtitle1">We get your style, let&apos;s set you up. </Typography>
      </div>
      <ShowErrors errors={errors} />
      <form style={{ maxWidth: 320, margin: '0 auto', width: '100%' }}>
        <FormControl margin="normal" required fullWidth>
          <TextField
            id="firstname"
            name="firstName"
            label="First Name"
            value={userInput.firstName}
            variant="outlined"
            onChange={(event) => handleChange(event)}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <TextField
            id="lastname"
            name="lastName"
            label="Last Name"
            value={userInput.lastName}
            variant="outlined"
            onChange={(event) => handleChange(event)}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <TextField
            id="email"
            name="email"
            autoComplete="email"
            label="email"
            value={userInput.email}
            variant="outlined"
            onChange={(event) => handleChange(event)}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <TextField
            id="phone"
            name="phoneNumber"
            label="phone"
            value={userInput.phoneNumber}
            variant="outlined"
            onChange={(event) => handleChange(event)}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <TextField
            id="password"
            name="password"
            label="password"
            value={userInput.password}
            type="password"
            variant="outlined"
            onChange={(event) => handleChange(event)}
          />
        </FormControl>
        <div className="iteratorButton">
          <Button variant="outlined" onClick={() => create()} size="large">
            Continue
          </Button>
        </div>
      </form>
    </div>
  )
}
const mapStateToProps = (state, ownProps) => {
  return {
    props: ownProps,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    createUser: (credentials) => dispatch(createUserOnFly(credentials)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup))
