import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { withRouter } from 'react-router-dom'
import { fetchContest } from '../../store/actions/contestActions'
import PageHeading from '../display/page_heading'
import ListOfContests from './list_of_contests'
import { Mixpanel } from '../../Mixpanel'
import EmptyState from '../appmessages/empty_state.'
import CircularProgress from '@material-ui/core/CircularProgress'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    margin: 'auto',
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxHeight: '50px',
    padding: 5,
  },
}))
function ContestsContainer({ contest: contests, fetchContest, auth, history }) {
  const [fetching, setFetching] = useState(false)
  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  useEffect(() => {
    Mixpanel.track('Contests View')

    const handleFetchContest = async () => {
      setFetching(true)
      const getContest = await fetchContest(null)
      if (getContest) {
        setFetching(false)
      }
    }

    if (contests.length === 0) {
      handleFetchContest()
    }
  }, [fetchContest, contests])

  const classes = useStyle()
  return (
    <div style={{ marginTop: matches ? 0 : 72 }}>
      <PageHeading title="contests" message="compete in contests and win prizes in cash and kind" />
      {contests.length ? (
        contests.map((contest, index) => {
          return (
            <ListOfContests
              contest={contest}
              classes={classes}
              history={history}
              auth={auth}
              index={index}
              key={index}
            />
          )
        })
      ) : (
        <div style={{ textAlign: 'center' }}>
          {fetching ? (
            <CircularProgress />
          ) : (
            <EmptyState message="There are no current contests" title="No Contests Available" />
          )}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    contest: state.contest.items,
    fetching: state.contest.fetching,
    auth: state.auth.auth,
  }
}

const mapDispatchToState = (dispatch) => {
  return {
    fetchContest: (fetchtype) => dispatch(fetchContest(fetchtype)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToState)(ContestsContainer))
