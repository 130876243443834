import { FETCH_QUESTION, ADD_ANSWER, ANSWER_OPTIONS } from '../actions/types'
const initialState = {
  questions: [],
  fetching: false,
  answers: [],
}
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_QUESTION:
      return {
        ...state,
        questions: [...action.payload.questions],
        fetching: false,
      }
    case ADD_ANSWER:
      return {
        ...state,
        fetching: false,
      }
    case ANSWER_OPTIONS:
      let question = state.answers.find((ele) => ele.question_id === action.payload.question_id)
      let answersIndex = -1
      if (question) {
        if (action.payload.option_type === 'radio') {
          question.answers = action.payload.answers
          question.answerlabel = action.payload.answerlabel
          return { ...state }
        }
        if (action.payload.option_type === 'textarea' || action.payload.option_type === 'input') {
          question.answers = action.payload.answers
          question.answerlabel = action.payload.answerlabel
          return { ...state }
        }
        answersIndex = question.answers.findIndex((ele) => ele === +action.payload.answers[0])
        if (answersIndex !== -1) {
          question.answers.splice(answersIndex, 1)
          question.answerlabel.splice(answersIndex, 1)

          return { ...state }
        }
        question.answerlabel.push(...action.payload.answerlabel)
        question.answers.push(...action.payload.answers)
        return { ...state }
      }

      state.answers.push(action.payload)

      return {
        ...state,
      }
    default:
      return state
  }
}
