import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createUpVote } from '../../store/actions/voteActions'
import Button from '@material-ui/core/Button'

function UpDownVote({ upvotes, photoid, upvote, token, size }) {
  const [upVote, setUpvote] = useState(upvotes)

  useEffect(() => {
    if (!upvotes) {
      setUpvote(0)
      setUpvote(0)
    }
  }, [upvotes])

  const handleVote = () => {
    const postdata = {
      photoid: photoid,
    }

    upvote(postdata, token)
    setUpvote(upvotes + 1)
  }

  return (
    <div style={{ display: 'inline' }}>
      <Button
        onClick={handleVote}
        aria-label="Clap for picture"
        size="small"
        style={{ marginLeft: 5 }}
      >
        <img src="/black_accept_2.png" width={size ? size : 18} alt="" />{' '}
        <span style={{ color: '#9c9c9c', fontSize: 14, paddingLeft: 10 }}>{upVote} </span>
      </Button>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    upvote: (votes, token) => dispatch(createUpVote(votes, token)),
  }
}

const mapStateToProps = (state) => {
  return {
    votes: null,
    voted: state.vote.voted,
    token: state.auth.token,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpDownVote)
