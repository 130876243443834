import React, { useState, useEffect, useRef } from 'react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Alert from '@material-ui/lab/Alert'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { createUserandResponse, loginUserSocial } from '../../store/actions/authActions'
import { Mixpanel } from '../../Mixpanel'
import CustomizedSnackbars from '../appmessages/customized_snackbars'
import ContestUpload from '../upload/contest_upload'
import { handleImage } from '../../Helpers'
import { createContest, voteContest } from '../../store/actions/contestActions'
import Typography from '@material-ui/core/Typography/Typography'
import ReCAPTCHA from 'react-google-recaptcha'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))
function ContestApply({
  createUserandResponse,
  createError,
  match,
  createContest,
  setVotes,
  votes,
  voteContest,
  history,
  loginUserSocial,
  userToken,
}) {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [filearray, setFilearray] = useState([])
  const [fetching, setFetching] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [contestImageUpload, setContestImageUpload] = useState(null)
  const [finishMessage, setFinishMessage] = useState()
  const [confirmEmail, setConfirmEmail] = useState(null)
  const [resourceId, setResourceId] = useState(null)
  const [action, setAction] = useState(null)
  const [registrationComplete, setRegistrationComplete] = useState(false)
  useEffect(() => {
    if (match.path.includes('contest')) {
      Mixpanel.track('Contest Apply PageView')
      setResourceId(match.params.contestId)
      setAction('contest')
    }
  }, [match])
  let actionResponse
  const recaptchaRef = useRef(0)

  const responseFacebook = async (response) => {
    if (response.email) {
      const name = response.name.split(' ')
      setFetching(true)
      const socialUserData = {
        firstName: name[1],
        lastName: name[0],
        email: response.email,
        access_token: response.accessToken,
        service: 'facebook',
        expires_in: response.expiresIn,
        user_id: response.id,
      }

      if (response.picture.data) {
        socialUserData.avatar = response.picture.data.url
      }

      const createdUser = await loginUserSocial(socialUserData)
      if (createdUser && createdUser.status === 200) {
        Mixpanel.track('Contest Entry Registration Facebook')

        setRegistrationComplete(true)
      }
      setFetching(false)
    }
  }

  const handleSubmit = async (e) => {
    if (confirmEmail && !registrationComplete) {
      return
    }
    if (registrationComplete) {
      setFetching(true)

      const imageUploadResponse = await handleImage(filearray, 'folio', userToken)
      if (imageUploadResponse) {
        if (action === 'contest') {
          const contestPostData = {
            contest_id: resourceId,
            image: imageUploadResponse.data
              ? imageUploadResponse.data.filename
              : imageUploadResponse.image,
          }
          actionResponse = await createContest(contestPostData, userToken)
          if (actionResponse.status === 200) {
            window.fbq('track', 'Lead')
            Mixpanel.track('Contest Entry')
            setIsComplete(true)
            setFetching(false)

            history.push(`/contests/entry/${actionResponse.data.contest_id}`, { status: 'new' })
            return
          }
        }
      }
      setFetching(false)
    }
    const captchaToken = await recaptchaRef.current.executeAsync()
    e.preventDefault()

    if (captchaToken) {
      const user = {
        firstname: firstName,
        lastname: lastName,
        email: email,
        password: password,
        token: captchaToken,
      }
      setFetching(true)
      if (!filearray.length && action === 'contest') {
        setContestImageUpload('Please upload a photo for entry into this contest.')
        Mixpanel.track('Contest No Photo')
        setFetching(false)

        return
      }

      const token = await createUserandResponse(user)
      if (!token) {
        setFetching(false)
        return
      }

      if (token && action === 'contest') {
        window.fbq('track', 'CompleteRegistration')
        Mixpanel.track('Contest Entry Registration')
        const imageUploadResponse = await handleImage(filearray, 'folio', token)
        if (imageUploadResponse) {
          if (action === 'contest') {
            const contestPostData = {
              contest_id: resourceId,
              image: imageUploadResponse.data
                ? imageUploadResponse.data.filename
                : imageUploadResponse.image,
            }
            actionResponse = await createContest(contestPostData, token)
            if (actionResponse.status === 200) {
              window.fbq('track', 'Lead')
              Mixpanel.track('Contest Entry')
              setIsComplete(true)
              history.push(`/contests/entry/${actionResponse.data.contest_id}`, { status: 'new' })
            }
          }
        }
      } else if (token && action === 'contest-vote') {
        Mixpanel.track('Contest Vote Registration')
        const postData = {
          contest_id: resourceId,
        }
        actionResponse = await voteContest(postData, token)
        if (actionResponse.status === 200) {
          setVotes(votes + 1)
          Mixpanel.track('Contest Vote Casted')
          setFinishMessage('Thank You, Vote Completed')
          setIsComplete(true)
        }
      } else if (token && action === 'challenge-vote') {
        setIsComplete(false)
      } else if (token && action === 'vote-challenge') {
        setIsComplete(true)
        setFinishMessage('Thank You, Vote Completed')
        setVotes({ token: token, resourceId: resourceId })
      }
    }
  }

  const handleUpload = (files) => {
    setFilearray([...files])
  }

  useEffect(() => {
    if (finishMessage) {
      window.setTimeout(() => {
        setFinishMessage('')
      }, 5000)
    }
  }, [finishMessage])

  const classes = useStyles()

  return (
    <div style={{ minHeight: 300, maxWidth: 500, margin: '0 auto', height: 'auto', padding: 10 }}>
      {(!isComplete || !registrationComplete) && (
        <div>
          <Typography variant="h6" style={{ fontWeight: 100, textAlign: 'center', padding: 5 }}>
            Enter the contest for a chance to win.
          </Typography>
          <hr />
        </div>
      )}

      <div>
        <div style={{ textAlign: 'center' }}>
          {!registrationComplete && (
            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
              autoLoad={false}
              fields="name,email,picture"
              callback={(e) => responseFacebook(e)}
              redirectUri={window.location.href}
              render={(renderProps) => (
                <Button
                  variant="contained"
                  style={{ color: '#fff', background: '#3b5998' }}
                  onClick={renderProps.onClick}
                >
                  {action === 'contest' ? 'Enter contest using Facebook' : 'Vote using Facebook'}
                </Button>
              )}
            />
          )}
          <form className={classes.form} noValidate>
            {!registrationComplete && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="fname"
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    value={firstName}
                    label="First Name"
                    size="small"
                    autoFocus
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    value={lastName}
                    label="Last Name"
                    autoComplete="lname"
                    size="small"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    value={email}
                    label="Email Address"
                    autoComplete="email"
                    size="small"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    size="small"
                    autoComplete="current-password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey="6LdC9DQbAAAAAPMMBeEDPvMd3NhUIiOTYFjzPH7Z"
                />
                <input
                  variant="outlined"
                  name="confirmPass"
                  label="Password"
                  type="hidden"
                  size="small"
                  autoComplete="current-password"
                  onChange={(e) => setConfirmEmail(e.target.value)}
                />
                {contestImageUpload && (
                  <Alert severity="info">
                    Sorry! {firstName}, {contestImageUpload}
                  </Alert>
                )}
              </Grid>
            )}

            {action === 'contest' && <ContestUpload handleUpload={handleUpload} />}

            {fetching ? (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress />
              </div>
            ) : (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit}
              >
                {action === 'challenge-vote' && 'Register & Vote'}
                {action === 'contest' && 'Enter Contest'}
                {action === 'vote-challenge' && 'Vote for Challenge'}
              </Button>
            )}

            {createError ? (
              <CustomizedSnackbars messagetype="error" message={createError}></CustomizedSnackbars>
            ) : null}

            {!registrationComplete && (
              <Grid container justify="flex-end">
                <Grid item>
                  <Link href="/signin" variant="body2">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    authError: state.auth.authError,
    auth: state.auth.auth,
    createError: state.auth.createError,
    props: ownProps,
    userToken: state.auth.token,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createContest: (contestdata, token) => dispatch(createContest(contestdata, token)),
    voteContest: (postData, token) => dispatch(voteContest(postData, token)),
    createUserandResponse: (credentials) => dispatch(createUserandResponse(credentials)),
    loginUserSocial: (credentials) => dispatch(loginUserSocial(credentials)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContestApply))
