import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { fetchChallenge, fetchChallengeMore } from '../../store/actions/challengeActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Mixpanel } from '../../Mixpanel'
import Hidden from '@material-ui/core/Hidden'
import ExpandRefresh from '@material-ui/icons/Refresh'
import IconButton from '@material-ui/core/IconButton'
import ChallengeCard from './challenge_card'
import PageHeading from '../display/page_heading'
import EmptyState from '../appmessages/empty_state.'
import Box from '@material-ui/core/Box'

function AllChallengesPage({ challenge, fetching, fetchChallenge, fetchChallengeMore }) {
  useEffect(() => {
    Mixpanel.track('Challenges Pageview')
    console.log(challenge)
    if (!challenge) {
      fetchChallenge(null)
    }
  }, [challenge, fetchChallenge])

  const handleMore = () => {
    //Calculate Page
    const numberofitems = challenge.length
    const page = Math.round(numberofitems / 20 + 1)

    fetchChallengeMore(null, page)
    Mixpanel.track('Challenges View More Clicked')
  }

  if (challenge && challenge.length) {
    const arrayOfChallengesCard = challenge.map((card, index) => {
      return <ChallengeCard key={index} {...card} />
    })
    return (
      <div>
        <div className="desktop-width-100">
          <Hidden only={['sm', 'xs']}>
            <div className="desktop-grid-container-card">
              <PageHeading title="challenges" message="Winners get a star on their profile" />
              {arrayOfChallengesCard.length !== 0 ? (
                arrayOfChallengesCard
              ) : (
                <EmptyState
                  title="No active challenges"
                  message="Sorry, there aren't any active challenges."
                />
              )}
            </div>
          </Hidden>

          <Hidden only={['xl', 'lg', 'md']}>
            <div className="desktop-grid-container">
              <div className="desktop-grid-container-card" style={{ paddingRight: 0 }}>
                <div>
                  <PageHeading title="challenges" message="Winners get a star on their profile" />
                </div>

                {arrayOfChallengesCard.length !== 0 ? (
                  <Box style={{ flexDirection: 'row' }}>{arrayOfChallengesCard}</Box>
                ) : (
                  <EmptyState
                    title="No active challenges"
                    message="Sorry, there aren't any active challenges."
                  />
                )}
              </div>
            </div>
          </Hidden>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            marginBottom: 50,
          }}
        >
          <div style={{ margin: '0 auto' }}>
            {fetching ? (
              <CircularProgress />
            ) : (
              <div>
                <IconButton variant="outlined" size="small" color="secondary" onClick={handleMore}>
                  <ExpandRefresh /> <br />
                </IconButton>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  if (!challenge) {
    return (
      <div style={{ textAlign: 'center' }}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <EmptyState
      title="No active challenges"
      message="Sorry, there aren't any active challenges. Be the first to challenge. If you win a challenge you get a star on your profile. "
    />
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchChallenge: (username) => dispatch(fetchChallenge(username)),
    fetchChallengeMore: (username, page) => dispatch(fetchChallengeMore(username, page)),
  }
}

const mapStateToProps = (state) => ({
  challenge: state.challenge.items,
  fetching: state.challenge.fetching,
})
export default connect(mapStateToProps, mapDispatchToProps)(AllChallengesPage)
