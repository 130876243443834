import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { connect } from 'react-redux'
import { Mixpanel } from '../../Mixpanel'
import { Elements } from 'react-stripe-elements'
import Divider from '@material-ui/core/Divider'
import { StripeProvider } from 'react-stripe-elements'
import InjectedCheckoutForm from '../payment/CheckoutFormSubscription'
import HeaderSimple from '../layout/header_simple'
import SubscriptionComplete from '../payment/SubscriptionComplete'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    boxShadow: 'none',
    backgroundColor: 'inherit',
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: -22,
  },
  submit: {
    marginTop: theme.spacing(3),
  },
}))

function SubscriptionPayment() {
  const [didComplete, setDidComplete] = useState(false)

  useEffect(() => {
    Mixpanel.track('Subscription Payment Page')
    if (window.Stripe) {
      window.Stripe(process.env.REACT_APP_STRIPE_KEY)
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        window.Stripe(process.env.REACT_APP_STRIPE_KEY)
      })
    }
  }, [])

  const classes = useStyles()

  return (
    <div>
      <div style={{ marginTop: 80 }}>
        <HeaderSimple />
      </div>
      <div style={{ maxWidth: 360, margin: '0 auto' }}>
        {!didComplete ? (
          <Paper className={classes.paper}>
            <Typography variant="h5">Payment Information</Typography>
            <Typography variant="subtitle1" style={{ textAlign: 'center', fontWeight: 200 }}>
              Learn, connect and be inspired. You are almost done. Discover Africa through its
              creatives.
            </Typography>
            <Divider style={{ width: '100%', marginBottom: 20 }} />
            <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
              <Elements>
                <InjectedCheckoutForm
                  callbackPaid={() => {
                    window.fbq('track', 'Subscribe')

                    setDidComplete(true)
                  }}
                />
              </Elements>
            </StripeProvider>
          </Paper>
        ) : (
          <div style={{ maxWidth: 360, margin: '0 auto', padding: 10 }}>
            <SubscriptionComplete />
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    seamPhoto: state.seam.seamble_image,
  }
}

export default connect(mapStateToProps, null)(SubscriptionPayment)
