import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import BackArrow from '@material-ui/icons/ArrowBack'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { SocialIcon } from 'react-social-icons'
import { FacebookShareButton, WhatsappShareButton } from 'react-share'
import UserPhotos from '../profile/UserPhotos'
import { createContest } from '../../store/actions/contestActions'
import { Mixpanel } from '../../Mixpanel'
import { awsImage } from '../../Helpers'

const useStyle = makeStyles(() => ({
  p2: {
    padding: 2,
    textTransform: 'lowercase',
  },
  dialongTitle: {
    textAlign: 'center',
    paddingTop: 0,
    paddingBottom: 0,
  },
  pullLeft: {
    float: 'left',
  },
  contentContainer: {
    maxWidth: 650,
    width: '100%',
    margin: '0 auto',
  },
  imageWrapper: {
    height: '100%',
    textAlign: 'center',
  },
  contestImage: {
    maxWidth: 300,
    margin: '0 auto',
    objectFit: 'cover',
  },
  blackFont: {
    color: 'black',
  },
  socialIconContainer: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
  },
  socialIconButtons: {
    display: 'inline-block',
    width: 25,
    height: 25,
    padding: 15,
  },
  hostUrl: {
    display: 'block',
    width: '100%',
    marginTop: 20,
  },
  clickPhotoToUse: {
    color: '#000',
    textTransform: 'uppercase',
  },
}))

function ContestDialog({ createContest, contestentry, history, photo, contestid }) {
  const [open, setOpen] = useState(false)
  const [image, setImage] = useState()
  const [hasEntry, setHasEntry] = useState(false)

  const handleAction = (param) => {
    const contestPostData = {
      contest_id: param.contestid,
      image: param.image,
    }
    createContest(contestPostData)
    Mixpanel.track('Contest Entered')
    setHasEntry(true)
    setImage(param.image)
  }
  const handleClickOpen = (e) => {
    e.stopPropagation()

    setOpen(true)
    Mixpanel.track('Contest Clicked')
  }
  const handleClose = (e) => {
    e.stopPropagation()

    setOpen(false)
  }

  const hostUrl = contestentry
    ? window.location.protocol +
      '//' +
      window.location.host +
      '/contests/entry/' +
      contestentry.entry_id
    : null

  const classes = useStyle()
  return (
    <div>
      <Button
        onClick={handleClickOpen}
        aria-label="Enter a contest"
        variant="outlined"
        size="small"
        color="primary"
        className={classes.p2}
      >
        enter contest
      </Button>
      <Dialog
        maxWidth="lg"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.dialongTitle}>
          <IconButton onClick={handleClose} aria-label="Back" className={classes.pullLeft}>
            <BackArrow />
          </IconButton>
        </DialogTitle>
        <DialogContent
          className={classes.contentContainer}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          {hasEntry ? (
            <div>
              <div className={classes.imageWrapper}>
                <img
                  src={awsImage('folio', image, 150, 'contain')}
                  className={classes.contestImage}
                  alt="contest show"
                />
                <p className={classes.blackFont}>
                  Almost done. Share your entry to get more votes.
                  <br />
                  <span className={hostUrl}>{hostUrl}</span>
                </p>
                <div style={{ padding: 20 }}>
                  <WhatsappShareButton
                    url={hostUrl}
                    className={classes.socialIconButtons}
                    title={
                      " I'm inviting you to join and vote for me on Seensta. A Network for African Creators.  "
                    }
                  >
                    <SocialIcon network="whatsapp" />
                  </WhatsappShareButton>

                  <FacebookShareButton
                    url={hostUrl}
                    className={classes.socialIconButtons}
                    quote={'Vote for me on Seensta'}
                  >
                    <SocialIcon network="facebook" />
                  </FacebookShareButton>
                </div>
                <Button
                  onClick={() => {
                    history.push('/contests/entry/' + contestentry.entry_id)
                  }}
                  aria-label="View Entry"
                  variant="outlined"
                  size="small"
                  color="primary"
                  className={classes.p2}
                >
                  View Entry
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <small className={classes.clickPhotoToUse}>Click photo to be used.</small>
              <UserPhotos
                photo={photo}
                actiontype="contest"
                contestid={contestid}
                handleCall={handleAction}
              />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    photos: state.user.photos,
    challengeAdd: state.challenge.challengeAdd,
    fetching: state.contest.fetching,
    contestentry: state.contest.newentry,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createContest: (contestdata) => dispatch(createContest(contestdata)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContestDialog))
