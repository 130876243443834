import {
  FETCH_CHALLENGE,
  FETCH_CHALLENGE_SINGLE,
  CREATE_CHALLENGE,
  AFTER_ADD,
  FETCH_CHALLENGE_MORE,
  FETCH_CHALLENGE_REQUEST,
} from './types'
import Api from '../../Api'

const basedev = process.env.REACT_APP_API_URL
const headers = {
  Accept: 'application/json',
}

export const fetchChallenge = (username = null) => (dispatch) => {
  var endpoint
  if (username !== null) endpoint = basedev + 'challenges/' + username
  else endpoint = basedev + 'challenges/1/20'

  dispatch({
    type: FETCH_CHALLENGE_REQUEST,
  })

  Api.get(endpoint, { headers: headers }).then((res) => {
    dispatch({
      type: FETCH_CHALLENGE,
      payload: res.data,
    })
  })
}

export const fetchSingle = (challengeid = null) => (dispatch) => {
  const endpoint = basedev + 'challenge/' + challengeid

  dispatch({
    type: FETCH_CHALLENGE_REQUEST,
  })

  Api.get(endpoint, { headers: headers }).then((res) => {
    dispatch({
      type: FETCH_CHALLENGE_SINGLE,
      payload: res.data,
    })
  })
}

export const fetchChallengeMore = (username = null, page = null) => (dispatch) => {
  var endpoint
  if (username !== null) endpoint = basedev + 'challenges/' + username
  else endpoint = `${basedev}challenges/${page}/20`

  dispatch({
    type: FETCH_CHALLENGE_REQUEST,
  })

  Api.get(endpoint, { headers: headers }).then((res) => {
    dispatch({
      type: FETCH_CHALLENGE_MORE,
      payload: res.data,
    })
  })
}

export const createChallenge = (data, token) => async (dispatch) => {
  var endpoint = basedev + 'challenge'
  headers.Authorization = 'Bearer ' + token

  //create the challenge
  try {
    const res = await Api.post(endpoint, data, { headers: headers })
    dispatch({
      type: CREATE_CHALLENGE,
      payload: res.data,
    })
    return res
  } catch (error) {
    return error.message
  }
}

export const afterAdd = () => (dispatch) => {
  //create the challenge
  dispatch({
    type: AFTER_ADD,
    payload: '',
  })
}
