import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import { setPlan } from '../../store/actions/seamitActions'
import { withRouter } from 'react-router-dom'
import HeaderSimple from '../layout/header_simple'
import { Mixpanel } from '../../Mixpanel'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'

function PricingMonthly({ seamIt, history, header }) {
  useEffect(() => {
    Mixpanel.track('Pricing Page')
  }, [])

  const addPlan = (plan) => {
    const planData = {
      plan: plan,
    }
    Mixpanel.track('Selected Pricing', { plan })
    seamIt(planData)
    history.push('/subscribe/payment')
  }

  return (
    <div>
      {!header ? <HeaderSimple /> : null}
      <div
        style={{
          maxWidth: 1320,
          margin: '0 auto',
          display: 'block',
          paddingTop: 60,
        }}
      >
        <Typography variant="h5" style={{ textAlign: 'center', width: '100%' }}>
          Recommended Subscriptions
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ textAlign: 'center', width: '100%', fontWeight: 200 }}
        >
          Please select a plan and experience Africa daily
        </Typography>
        <div style={{ padding: '0 25px', display: 'flex', marginBottom: 100 }}>
          <Grid container spacing={3} style={{ maxWidth: 550, margin: '0 auto' }}>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Card onClick={() => addPlan('BasicMonthly')}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt="Hip African Clothes"
                    style={{ objectFit: 'cover', objectPosition: 'top' }}
                    height="350"
                    image="https://d1sb0aptwihzht.cloudfront.net/eyJidWNrZXQiOiJzZWVuc3RhLWNvbnRlbnQiLCJrZXkiOiJmb2xpby9HWUF0cjNfSU1HLTIwMTgwMjA1LVdBMDAxMi5qcGciLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjcwMCwiZml0IjoiY29udGFpbiJ9fX0="
                    title="Hip African Clothes"
                  />
                  <CardContent>
                    <span>
                      <Typography variant="h6" style={{ textAlign: 'center' }}>
                        $10/mo.
                      </Typography>
                      <Typography variant="body2" style={{ textAlign: 'center' }}>
                        Authentica, geniune African content delivered to you regularly.
                      </Typography>
                    </span>
                  </CardContent>
                  <div
                    style={{
                      display: 'block',
                      width: '100%',
                      alignItems: 'center',
                      textAlign: 'center',
                      paddingBottom: 10,
                    }}
                  >
                    <Button
                      size="large"
                      color="secondary"
                      variant="contained"
                      onClick={() => addPlan('BasicMonthly')}
                      style={{ color: 'white !important', backgroundColor: 'orange' }}
                    >
                      Select
                    </Button>
                  </div>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Card onClick={() => addPlan('BasicYearly')}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt="Hip African Clothes"
                    style={{ objectFit: 'cover', objectPosition: 'top' }}
                    height="350"
                    image="https://d1sb0aptwihzht.cloudfront.net/eyJidWNrZXQiOiJzZWVuc3RhLWNvbnRlbnQiLCJrZXkiOiJmb2xpby93UnVIMVdfNDBGQTNBRTItOTg4RS00NjgzLUE5Q0QtQjkyQzkyQTk0Q0MwLmpwZWciLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjM1MCwiZml0IjoiY29udGFpbiJ9fX0="
                    title="Hip African Clothes"
                  />
                  <CardContent>
                    <span>
                      <Typography variant="h6" style={{ textAlign: 'center' }}>
                        $106/year.
                      </Typography>
                      <Typography variant="body2" style={{ textAlign: 'center' }}>
                        Authentica, geniune African content delivered to you regularly.
                      </Typography>
                    </span>
                  </CardContent>
                  <div
                    style={{
                      display: 'block',
                      width: '100%',
                      alignItems: 'center',
                      textAlign: 'center',
                      paddingBottom: 10,
                    }}
                  >
                    <Button
                      size="large"
                      color="secondary"
                      variant="contained"
                      onClick={() => addPlan('BasicYearly')}
                      style={{ color: 'white !important', backgroundColor: 'orange' }}
                    >
                      Select
                    </Button>
                  </div>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    seamIt: (plan) => dispatch(setPlan(plan)),
  }
}
export default connect(null, mapDispatchToProps)(withRouter(PricingMonthly))
