import React, { useState, useEffect } from 'react'
import HeaderSimple from '../layout/header_simple'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import { Mixpanel } from '../../Mixpanel'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Api from '../../Api'

const USERCATEGORY = [
  { type: 'Model', label: 'A Model' },
  { type: 'Designer', label: 'A Designer' },
  { type: 'Makeup Artist', label: 'A Makeup Artist' },
  { type: 'Photographer', label: 'A Photographer' },
  { type: 'Artist', label: 'An Artist' },
  { type: 'Dancer', label: 'An Dancer' },
  { type: 'General', label: 'Other' },
]
function UserCategory({ onBoarding = true, showButton = true, about, token, history }) {
  const [selected, setSelected] = useState(about ? about.usertype : '')
  const [settings, setSettings] = useState(false)

  useEffect(() => {
    if (about) {
      setSettings(true)
      setSelected(about.usertype)
    }
  }, [settings, about])

  const handleAbout = (category) => {
    const usertype = {
      usertype: category,
    }
    const headers = {
      Authorization: 'Bearer ' + token,
    }
    Api.put(`about`, usertype, { headers: headers }).then((res) => {
      Mixpanel.track('Usertype selected', usertype)
      if (res && usertype.usertype === 'Fan') {
        window.location.href = '/home'
      }
    })
  }

  if (settings) {
    return (
      <Box marginTop={5} maxWidth={400} textAlign="center">
        <Paper>
          <Box padding={5}>
            <Typography variant="body1">Your creative is?</Typography>

            {USERCATEGORY.map((cat, index) => (
              <div key={index} style={{ display: 'block', padding: 5 }}>
                <Chip
                  variant="default"
                  style={{ width: 120 }}
                  key={index}
                  color={selected === cat.type ? 'primary' : 'default'}
                  label={cat.label}
                  onClick={() => {
                    setSelected(cat.type)
                    handleAbout(cat.type)
                  }}
                ></Chip>
              </div>
            ))}
          </Box>
        </Paper>
      </Box>
    )
  }

  return (
    <div style={{ marginTop: 60 }}>
      {onBoarding && <HeaderSimple />}
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Typography className="question">Which best describes you?</Typography>
        {USERCATEGORY.map((cat, index) => (
          <div key={index} style={{ display: 'block', padding: 5 }}>
            <Chip
              variant="default"
              style={{ width: 120 }}
              key={index}
              color={selected === cat.type ? 'primary' : 'default'}
              label={cat.label}
              onClick={() => {
                setSelected(cat.type)
                handleAbout(cat.type)
              }}
            ></Chip>
          </div>
        ))}
        {Boolean(showButton) !== false && (
          <div style={{ marginTop: 20 }}>
            <Button
              component="a"
              onClick={() => history.push('/onboard/country')}
              variant="contained"
              color="primary"
            >
              Continue
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    about: state.auth.about,
  }
}

export default withRouter(connect(mapStateToProps, null)(UserCategory))
