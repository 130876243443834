import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import { awsImage } from '../../../Helpers'
import Link from '@material-ui/core/Link'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles(() => ({
  avatar: {
    backgroundColor: '#52183E',
    width: 25,
    height: 25,
    marginRight: -13,
  },
  avatarTitle: {
    color: '#52183E',
    textDecoration: 'none',
    fontSize: 12,
  },
}))

function PhotoComments({ allComments, user, handleDeleteCommnet, history }) {
  const classes = useStyle()
  return (
    <div>
      {allComments
        ? allComments.map((comment, index) => (
            <ListItem key={index}>
              <ListItemAvatar
                style={{ minWidth: 35 }}
                onClick={() => history.push(`/${comment.username}`)}
              >
                <Avatar
                  alt={comment.firstName}
                  className={classes.avatar}
                  src={
                    comment.photoPath && comment.photoPath.includes('http')
                      ? `${comment.photoPath}`
                      : awsImage('profilepictures', comment.photoPath, 150, 'contain')
                  }
                />
              </ListItemAvatar>
              <ListItemText
                className={classes.avatarTitle}
                primary={comment.firstName}
                secondary={
                  <span>
                    {comment.message.includes('audio') ? (
                      <audio
                        controls
                        src={`https://seensta-audio-content.s3.amazonaws.com/audio-feedback/${comment.message}`}
                        style={{ height: 25 }}
                      />
                    ) : (
                      comment.message
                    )}
                    {user.username === comment.username && (
                      <span>
                        {' '}
                        <br />
                        <Link
                          onClick={() => {
                            handleDeleteCommnet(comment.message_id)
                          }}
                          variant="caption"
                          underline="always"
                        >
                          Delete
                        </Link>
                      </span>
                    )}
                  </span>
                }
              />
            </ListItem>
          ))
        : 'Be the first to comment'}
    </div>
  )
}

export default withRouter(PhotoComments)
