import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getUserLocation, updateAbout } from '../../store/actions/authActions'

function UserLocation({ getUserLocation, updateAbout, about, token }) {
  const [country, setCountry] = useState(null)

  useEffect(() => {
    if (window && about && !about.location) {
      window.navigator.geolocation.getCurrentPosition((position) => {
        const locationData = getUserLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        })
        if (locationData) {
          locationData.then((res) => {
            setCountry(res.results[0].formatted_address)
          })
        }
      })
    }
  }, [getUserLocation, about])

  useEffect(() => {
    if (country) {
      updateAbout({ location: country, token: token })
    }
  }, [country, updateAbout, token])

  return null
}

const mapStateToProps = (state) => ({
  about: state.auth.about,
  token: state.auth.token,
})

const mapDispatchToProps = (dispatch) => {
  return {
    getUserLocation: (locationData) => dispatch(getUserLocation(locationData)),
    updateAbout: (updateData) => dispatch(updateAbout(updateData)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserLocation)
