import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import Label from '../../components/display/label'
import { gigApplication } from '../../store/actions/gigActions'
import { connect } from 'react-redux'
import SignUpDialog from '../../components/auth/sign_up_dialog'

function ApplyForGig({ applyToGig, token, props, auth }) {
  const [coverLetter, setCoverLetter] = useState('')
  const [applied, setApplied] = useState(false)

  const handleChange = (evt) => {
    setCoverLetter(evt.target.value)
  }

  const handleSubmit = () => {
    if (!auth) {
      window.location.href = '/signup'
      return
    }
    const data = {
      coverLetter: coverLetter,
      resourceID: props.id,
    }
    applyToGig(data, token)
      .then(() => {
        setApplied(true)
      })
      .catch(() => {
        setApplied(false)
      })
  }

  if (!applied)
    return (
      <div style={{ width: '90%', padding: 20 }}>
        <Label label="Apply for this Gig" />
        <div style={{ paddingTop: 10 }}>
          <TextField
            placeholder={auth ? 'I am interested this gig ...' : 'Sign up to apply to this gig '}
            variant="outlined"
            name="cover_letter"
            required
            value={coverLetter}
            disabled={!auth}
            onChange={handleChange}
            fullWidth
            multiline
          />
        </div>
        <div style={{ textAlign: 'left', width: '100%', paddingTop: 20 }}>
          {auth ? (
            <Button variant="contained" size="small" color="primary" onClick={handleSubmit}>
              Send
            </Button>
          ) : (
            <SignUpDialog
              buttonText="Join to apply"
              variant="contained"
              color="primary"
              headerMessage="Please Join to apply."
            />
          )}
        </div>
      </div>
    )

  return (
    <div>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <div style={{ paddingTop: 10 }}>
          <Typography variant="h5">Thank you for applying.</Typography>
          <Typography variant="body1">
            We&apos;ve sent your profile to the poster. <br />
            Please make sure your profile is up to date with new creatives.
          </Typography>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state, ownProps) => {
  return {
    token: state.auth.token,
    props: ownProps,
    auth: state.auth.auth,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    applyToGig: (data, token) => dispatch(gigApplication(data, token)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ApplyForGig)
