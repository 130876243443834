import React from 'react'
import Moment from 'react-moment'

function FormatDateTime({ dateTime }) {
  return (
    <Moment fromNow ago>
      {dateTime}
    </Moment>
  )
}
export default FormatDateTime
