import React, { useState, useEffect } from 'react'
import { awsImage } from '../../Helpers'
import CheckCircleRounded from '@material-ui/icons/CheckCircleRounded'
function ShowImage({ question, image, option, folder, fit, width, addAnswer }) {
  const [selected, setSelected] = useState(false)
  const [bodyType, setBodyType] = useState(false)

  useEffect(() => {
    if (question.question.includes('body type')) {
      setBodyType(true)
    }
  }, [question.question])

  return (
    <div className="clothesImageContainer">
      {selected ? (
        <div className="checkMarkClothes">
          <CheckCircleRounded />
        </div>
      ) : null}

      <img
        onClick={() => {
          setSelected(!selected)
          addAnswer({
            question: question.question,
            answerlabel: [option.option],
            question_id: question.id,
            answers: [option.id],
            option_type: 'checkbox',
          })
        }}
        alt="visual "
        src={awsImage(folder, image, width, fit)}
        width={150}
        height={bodyType ? 190 : 150}
        className="itemsImage"
      />

      {bodyType ? (
        <span style={{ display: 'block', textAlign: 'center' }}>{option.option}</span>
      ) : null}
    </div>
  )
}
export default ShowImage
