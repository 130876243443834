import {
  FETCH_CHALLENGE,
  FETCH_CHALLENGE_SINGLE,
  CREATE_CHALLENGE,
  AFTER_ADD,
  FETCH_CHALLENGE_MORE,
  FETCH_CHALLENGE_REQUEST,
} from '../actions/types'

const initialState = {
  items: null,
  challenges: [],
  challengeAdd: null,
  fetching: false,
  single: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CHALLENGE:
      var challengeList = []
      if (action.payload['ischallenged'] !== undefined) {
        challengeList = [...action.payload['ischallenged']]
      }
      if (action.payload['ischallenging'] !== undefined) {
        challengeList = [...action.payload['ischallenging']]
      }

      if (challengeList.length > 0) {
        return {
          ...state,
          challenges: [...challengeList],
          fetching: false,
        }
      } else {
        return {
          ...state,
          items: action.payload.results,
          fetching: false,
        }
      }

    case CREATE_CHALLENGE:
      return {
        ...state,
        challengeAdd: action.payload.battlelink,
        fetching: false,
      }
    case FETCH_CHALLENGE_SINGLE:
      return {
        ...state,
        single: action.payload.battle,
        fetching: false,
      }
    case AFTER_ADD:
      return {
        ...state,
        challengeAdd: action.payload,
        fetching: false,
      }
    case FETCH_CHALLENGE_MORE:
      return {
        ...state,
        items: [...state.items, ...action.payload.results],

        fetching: false,
      }
    case FETCH_CHALLENGE_REQUEST:
      return {
        ...state,
        fetching: true,
      }

    default:
      return state
  }
}
