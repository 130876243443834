import { CREATE_VOTES, FETCH_VOTES, REQUEST_VOTE, CREATE_VOTES_ERROR } from './types'
import Api from '../../Api'

const basedev = process.env.REACT_APP_API_URL

//create votes
export const createVote = (data, token) => (dispatch) => {
  const endpoint = basedev + 'vote'

  var headers = {
    Authorization: 'Bearer ' + token,
  }

  Api.post(endpoint, data, { headers: headers })
    .then((res) => {
      dispatch({
        type: CREATE_VOTES,
        payload: res.data,
      })
    })
    .catch((error) => {
      dispatch({
        type: CREATE_VOTES_ERROR,
        payload: null,
      })
    })
}

//get votes
export const fetchVotes = () => (dispatch) => {
  var headers = {
    Accept: 'application/json',
  }
  const endpoint = basedev + 'vote'

  Api.get(endpoint, { headers: headers })
    .then((res) => {
      dispatch({
        type: FETCH_VOTES,
        payload: res.data,
      })
    })
    .catch({})
}

//upvote
export const createUpVote = (data, token) => (dispatch) => {
  const endpoint = basedev + 'upvote'
  var headers = {
    Authorization: 'Bearer ' + token,
  }
  dispatch({
    type: REQUEST_VOTE,
  })
  Api.post(endpoint, data, { headers: headers })
    .then((res) => {
      dispatch({
        type: CREATE_VOTES,
        payload: res.data,
      })
    })
    .catch((error) => {})
}
