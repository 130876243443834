import {
  SEAMABLE_CUSTOM_SIZE,
  SEAMABLE_FIGURE,
  GET_SEAMBLE,
  SEAMABLE_FIGURE_REMOVE,
  SEAMABLE_PAYMENT_TOKEN,
  SEAMABLE_IMAGE,
  CREATE_SEAMIT,
  CREATE_SEAMIT_ERROR,
  SEAMABLE_SIZE,
  SEAMABLE_SIZE_REMOVE,
  SEAMABLE_BUDGET,
  SEAMABLE_CLOTHESTYPE_REMOVE,
  SEAMABLE_CLOTHESTYPE,
  SEAMABLE_PLAN,
} from '../actions/types'

const initialState = {
  seamble_image: null,
  seamble_id: null,
  seamed: [],
  size: [],
  clothestype: [],
  seamble_gender: null,
  seamable_budget: null,
  card_token: null,
  figure: [],
  submitted: false,
  seamrequests: [],
  plan: 'monthly50',
  customSize: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SEAMABLE_PLAN:
      return {
        ...state,
        plan: action.payload.plan,
      }
    case SEAMABLE_IMAGE:
      if ('gender' in action.payload) {
        return {
          ...state,
          seamble_gender: action.payload.gender,
        }
      } else {
        return {
          ...state,
          seamble_image: action.payload.image,
          seamble_id: action.payload.resource_id,
        }
      }

    case CREATE_SEAMIT:
      return {
        ...state,
        seamed: action.payload.data,
        submited: true,
      }
    case SEAMABLE_PAYMENT_TOKEN:
      return {
        ...state,
        card_token: action.payload,
      }
    case SEAMABLE_BUDGET:
      return {
        ...state,
        seamable_budget: action.payload,
      }
    case SEAMABLE_CUSTOM_SIZE:
      return {
        ...state,
        customSize: action.payload,
      }
    case SEAMABLE_SIZE:
      return {
        ...state,
        size: [...state.size, action.payload],
      }
    case SEAMABLE_CLOTHESTYPE:
      return {
        ...state,
        clothestype: [...state.clothestype, action.payload],
      }
    case SEAMABLE_SIZE_REMOVE:
      for (var i = 0; i < state.size.length; i++) {
        if (
          action.payload.sizevalue === state.size[i].sizevalue &&
          action.payload.sizeclass === state.size[i].sizeclass
        ) {
          state.size.splice(i, 1)
        }
      }
      return {
        ...state,
      }
    case SEAMABLE_CLOTHESTYPE_REMOVE:
      for (let i = 0; i < state.clothestype.length; i++) {
        if (action.payload.clothestype === state.clothestype[i].clothestype) {
          state.clothestype.splice(i, 1)
        }
      }
      return {
        ...state,
      }
    case SEAMABLE_FIGURE:
      if (state.figure.length > 0) {
        for (let i = 0; i < state.figure.length; i++) {
          if (action.payload.figureposition === state.figure[i].figureposition) {
            state.figure.splice(i, 1)
            return {
              figure: [...state.figure, action.payload],
            }
          } else {
            return {
              ...state,
              figure: [...state.figure, action.payload],
            }
          }
        }
      } else {
        return {
          ...state,
          figure: [...state.figure, action.payload],
        }
      }
      break

    case SEAMABLE_FIGURE_REMOVE:
      for (let i = 0; i < state.figure.length; i++) {
        if (action.payload.figureposition === state.size[i].figureposition) {
          state.size.splice(i, 1)
        }
      }
      return {
        ...state,
      }
    case CREATE_SEAMIT_ERROR:
      return {
        ...state,
      }
    case GET_SEAMBLE:
      return {
        ...state,
        seamrequests: action.payload.results,
      }
    default:
      return state
  }
}
