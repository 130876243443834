import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share'
import Typography from '@material-ui/core/Typography'
import { SocialIcon } from 'react-social-icons'
import { Mixpanel } from '../../Mixpanel'
import { fetchSingle } from '../../store/actions/challengeActions'
import SignUpDialogContest from '../auth/sign_up_dialog_contest'
import ChallengeCard from './challenge_card'

function SingleChallengeCard({ singleChallenge }) {
  return <ChallengeCard isSingle={true} {...singleChallenge} />
}

function ChallengeEntry({ singlechallenge, challengeid, fetchSingle }) {
  useEffect(() => {
    Mixpanel.track('Challenge Pageview')
    document.title = 'Seensta | Challenge'
    fetchSingle(challengeid)
  }, [challengeid, fetchSingle])

  if (singlechallenge) {
    return (
      <div style={{ marginTop: 75, marginBottom: 50, maxWidth: 500, margin: '0 auto' }}>
        <div spacing={3}>
          <Typography variant="h4" style={{ textAlign: 'center' }}></Typography>
          <SingleChallengeCard singleChallenge={singlechallenge} />
        </div>
        <div style={{ padding: 10 }}>
          <div style={{ textAlign: 'center' }}>
            <div style={{ display: 'block', width: '100%' }}>
              <Typography variant="body1" style={{ textAlign: 'center' }}>
                Share this challenge
              </Typography>

              <div>
                <FacebookShareButton
                  url={window.location.href}
                  style={{ display: 'inline-block', width: 10, height: 10, padding: 15 }}
                  quote={'Who is best?'}
                >
                  <SocialIcon style={{ width: 20, height: 20 }} network="facebook" />
                </FacebookShareButton>
                <WhatsappShareButton
                  url={window.location.href}
                  style={{ display: 'inline-block', width: 10, height: 10, padding: 15 }}
                  quote={'Who is best?'}
                >
                  <SocialIcon style={{ width: 20, height: 20 }} network="whatsapp" />
                </WhatsappShareButton>

                <TwitterShareButton
                  url={window.location.href}
                  style={{ display: 'inline-block', width: 10, height: 10, padding: 15 }}
                  quote={'Who is best?'}
                >
                  <SocialIcon style={{ width: 20, height: 20 }} network="twitter" />
                </TwitterShareButton>
              </div>
            </div>
          </div>

          <div style={{ textAlign: 'center', marginTop: 50 }}>
            <SignUpDialogContest
              buttonText="Register To Vote"
              variant="outlined"
              color="primary"
              headerMessage="Please Join to vote for Challenge"
              resourceId={challengeid}
              action="challenge-vote"
            />
          </div>
        </div>
      </div>
    )
  }
  return (
    <div style={{ textAlign: 'center' }}>
      <CircularProgress />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSingle: (challengeid) => dispatch(fetchSingle(challengeid)),
  }
}

const mapStateToProps = (state, ownProps) => ({
  singlechallenge: state.challenge.single,
  fetching: state.challenge.fetching,
  challengeid: ownProps.match.params.entryId
    ? ownProps.match.params.entryId
    : ownProps.match.params.entryid,
  auth: state.auth.auth,
})

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeEntry)
