import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import './upload.css'
import Add from '@material-ui/icons/AddAPhotoSharp'
import Delete from '@material-ui/icons/Delete'
import { handleImage } from '../../Helpers'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

function UploadImage({ props, username }) {
  const [textvalue, setTextvalue] = useState('')
  const [filearray, setFilearray] = useState([])
  const [error, setError] = useState()
  const [spinner, setSpinner] = useState(false)

  const handleTextareaInput = (e) => {
    setTextvalue(e.target.value)
  }

  const removeItem = (index) => {
    setFilearray([...filearray.splice(1, index)])
  }

  const uploadPhoto = () => {
    if (filearray) {
      setSpinner(true)
      handleImage(filearray, 'folio', localStorage.getItem('asafo_token'), textvalue).then(() => {
        setSpinner(false)
        props.history.push(`/${username}`)
      })
    } else {
      setError('Please select a file')
    }
  }

  const DisplayImages = () => {
    const showimages = filearray.map((image, index) => {
      return (
        <div className="image" key={index}>
          <img
            src={`${window.URL.createObjectURL(image)}`}
            width={150}
            alt="new upload for seensta"
          />
          <Delete
            fontSize="small"
            onClick={() => {
              removeItem(index)
            }}
          />
        </div>
      )
    })
    return showimages
  }
  return (
    <div style={{ padding: 10 }}>
      <div
        style={{
          padding: 10,
          marginTop: 50,
          border: '2px dashed rgb(150 150 150)',
          borderRadius: 10,
          maxWidth: 500,
          margin: '0 auto',
        }}
      >
        <UploadInstructions />
        <div className="uploadContainer">
          {filearray.length !== 0 ? (
            <div className="imageContainer">
              <DisplayImages />
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <Button>
                <label htmlFor="contained-button-file">
                  <Add fontSize="large" />
                  <Typography variant="button" style={{ display: 'block' }}>
                    Click to Upload
                  </Typography>
                </label>
              </Button>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="contained-button-file"
                type="file"
                onChange={() => {
                  setFilearray([...document.getElementById('contained-button-file').files])
                }}
              />
            </div>
          )}

          {spinner ? (
            <div style={{ textAlign: 'center' }}>
              <CircularProgress />
            </div>
          ) : null}
        </div>
      </div>
      <div
        style={{
          maxWidth: 500,
          margin: '0 auto',
        }}
      >
        <div className="textareaContainer">
          <TextField
            id="outlined-full-width3"
            placeholder="image description ( optional )"
            multiline
            rows={2}
            margin="normal"
            value={textvalue}
            variant="filled"
            name="description"
            onChange={handleTextareaInput}
            className="textareaField"
          />
          {error && error}
        </div>
        <Button
          variant="contained"
          color="primary"
          disabled={!filearray.length}
          onClick={() => {
            uploadPhoto()
          }}
        >
          Post
        </Button>
      </div>
    </div>
  )
}
const mapStateToProps = (state, ownprops) => {
  return {
    props: ownprops,
    username: state.auth.user.username,
  }
}
export default connect(mapStateToProps, null)(withRouter(UploadImage))

function UploadInstructions() {
  return (
    <div className="uploadGuidelines">
      <ul style={{ textAlign: 'center' }}>
        <li style={{ fontWeight: 800 }}>Must have at least 50% ownership of work.</li>
        <li>
          {' '}
          <span style={{ color: 'rgb(119 36 90)', fontSize: 16 }}>browse</span> to choose a file
        </li>
        <li style={{ color: 'rgb(167 163 163)', fontSize: 12 }}>
          ( Violators will be banned from the network. )
        </li>
      </ul>
    </div>
  )
}
