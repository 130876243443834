import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/CheckBox'
import { deleteApplication, acceptApplicant } from '../../../store/actions/gigActions'
import { connect } from 'react-redux'
import { awsImage } from '../../../Helpers'
import { withRouter } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}))

function Applicants({ deleteApplication, acceptApplicant, token, props }) {
  const classes = useStyles()
  const [applicants, setApplicants] = useState(props.applicants)
  const [isAccepted, setIsAccepted] = useState(false)
  const handleRemove = (e, userID, index) => {
    const data = {
      resourceID: props.resource + '|' + userID,
    }
    deleteApplication(data, token)
      .then(() => {
        setApplicants([...props.applicants.splice(1, index)])
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleAccept = (e, userID) => {
    const data = {
      resourceID: props.resource,
      applicantID: userID,
    }
    setIsAccepted(true)
    acceptApplicant(data, token)
      .then(() => {
        setIsAccepted(true)
      })
      .catch(() => {
        setIsAccepted(false)
      })
  }
  return (
    <div>
      <Grid item xs={12} md={12} lg={12} key={props.resource}>
        <div className={classes.demo}>
          <List dense={true}>
            {applicants.map((applicant, index) => {
              return (
                <ListItem
                  button
                  key={index}
                  onClick={() => props.history.push(`/${applicant.user.username}`)}
                >
                  <ListItemAvatar>
                    {applicant.user.avatar ? (
                      <Avatar
                        alt={applicant.user.firstName + ' ' + applicant.user.lastName}
                        src={
                          applicant.user.avatar &&
                          awsImage(
                            'profilepictures',
                            applicant.user.avatar.photoPath,
                            150,
                            'contain'
                          )
                        }
                      />
                    ) : (
                      <Avatar alt={applicant.user.firstName + ' ' + applicant.user.lastName}>
                        {`${applicant.user.firstName[0]} ${applicant.user.lastName[0]}`}
                      </Avatar>
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${applicant.user.firstName} ${applicant.user.lastName}`}
                    secondary={applicant.cover}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={(e) => handleAccept(e, applicant.user_id, index)}
                    >
                      <CheckIcon
                        color={
                          applicant.applicant_status === 1 || isAccepted ? 'primary' : 'default'
                        }
                      />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={(e) => handleRemove(e, applicant.user_id, index)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })}
          </List>
        </div>
      </Grid>
    </div>
  )
}
const mapStateToProps = (state, ownProps) => {
  return {
    token: state.auth.token,
    props: ownProps,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    deleteApplication: (data, token) => dispatch(deleteApplication(data, token)),
    acceptApplicant: (data, token) => dispatch(acceptApplicant(data, token)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Applicants))
