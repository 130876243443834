import {CREATE_FOLLOW,GET_FOLLOW,FOLLOW_ERROR,REQUEST_FOLLOWING } from '../actions/types'

const initialState ={
    following:false,
    followError:null,
    created:false
}


export default function(state=initialState,action){

    switch(action.type){
        case CREATE_FOLLOW:
            return{
                ...state,
                created:true

            }
        case GET_FOLLOW:
                return{
                    ...state,
                    following:action.payload,
                    created:false
                }

        case FOLLOW_ERROR:
            return{
                ...state,
                created:false
            }            
        case REQUEST_FOLLOWING:
            return{
                ...state,
                following:false,
                created:false
            }                             
        default:
            return state

    }
}