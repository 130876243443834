import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import './upload.css'
import Add from '@material-ui/icons/AddAPhotoSharp'
import Delete from '@material-ui/icons/Delete'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

function UploadImage({ props }) {
  const [filearray, setFilearray] = useState([])
  function UploadButton() {
    return (
      <div style={{ textAlign: 'center' }}>
        <Button>
          <label htmlFor="contained-button-file">
            <Add fontSize="large" />
            <Typography variant="button" style={{ display: 'block' }}>
              Add Photo for Contest
            </Typography>
          </label>
        </Button>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="contained-button-file"
          type="file"
          onChange={() => {
            setFilearray([...document.getElementById('contained-button-file').files])
            props.handleUpload(document.getElementById('contained-button-file').files)
          }}
        />
      </div>
    )
  }

  function removeItem(index) {
    setFilearray([...filearray.splice(1, index)])
  }

  return (
    <div style={{ margin: '0 auto' }}>
      <UploadInstructions />
      <div className="uploadContainer">
        {filearray.length !== 0 ? (
          <div className="imageContainer">
            <DisplayImages />
          </div>
        ) : (
          <UploadButton />
        )}
      </div>
    </div>
  )

  function DisplayImages() {
    const showimages = filearray.map((image, index) => {
      return (
        <div className="image" key={index}>
          <img src={`${window.URL.createObjectURL(image)}`} width={150} alt="uploaded contest " />
          <Delete
            fontSize="small"
            onClick={() => {
              removeItem(index)
            }}
          />
        </div>
      )
    })
    return showimages
  }
  function UploadInstructions() {
    return (
      <div className="uploadGuidelines">
        <ul>
          <li>Upload image for contest.</li>
        </ul>
      </div>
    )
  }
}
const mapStateToProps = (state, ownprops) => {
  return {
    props: ownprops,
    username: state.auth.user.username,
  }
}
export default connect(mapStateToProps, null)(withRouter(UploadImage))
