import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import { createVote } from '../../store/actions/voteActions'
import { withRouter } from 'react-router-dom'
import { Mixpanel } from '../../Mixpanel'
import SignUpDialogContest from '../auth/sign_up_dialog_contest'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'

function Vote({ photoPath, battleId, auth, history, token, voteHandler, createVote, voteSnum }) {
  const [voted, setVoted] = useState(false)

  const handleVote = (params = {}) => {
    const postData = {
      photopath: photoPath,
      battleId: battleId,
    }

    if (auth || params.token) {
      createVote(postData, params.token ? params.token : token)

      setVoted(true)
      voteHandler()
    } else {
      history.push('/signup')
    }
    Mixpanel.track('Vote Clicked')
  }

  return (
    <div>
      {voted ? (
        <div style={{ textAlign: 'center' }}>
          <Button variant="outlined" color="primary" size="small" aria-label="Vote">
            <span style={{ color: '#52183E' }}>thanks!</span>
          </Button>
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          {auth && (
            <Button
              variant="outlined"
              color="primary"
              aria-label="Vote"
              onClick={handleVote}
              size="small"
              startIcon={<ThumbUpIcon />}
            >
              <span>vote</span>
            </Button>
          )}
          <div>
            <SignUpDialogContest
              setVotes={handleVote}
              votes={voteSnum}
              action="vote-challenge"
              buttonText={`Join to vote`}
              headerMessage={`Join to vote for this challenge}`}
              variant="contained"
              color="primary"
              buttonActionText={`Vote `}
              resourceId={battleId}
            />
          </div>
        </div>
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    createVote: (votes, token) => dispatch(createVote(votes, token)),
  }
}

const mapStateToProps = (state) => {
  return {
    votes: state.vote.voted,
    auth: state.auth.auth,
    token: state.auth.token,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Vote))
