import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { createChallenge, afterAdd } from '../../store/actions/challengeActions'
import { createContest } from '../../store/actions/contestActions'
import { userPhotos } from '../../store/actions/userActions'
import Button from '@material-ui/core/Button'
import { SocialIcon } from 'react-social-icons'
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share'
import { awsImage } from '../../Helpers'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(() => ({
  progressContainer: {
    display: 'flex',
    textAlign: 'center',
  },
  didCompleteContainer: {
    height: 350,
    textAlign: 'center',
    color: 'black',
  },
  shareButton: {
    display: 'inline-block',
    width: 25,
    height: 25,
    padding: 15,
  },
}))

function UserPhotos({
  userPhotos,
  actiontype,
  createChallenge,
  token,
  challengeAdd,
  afterAdd,
  photos,
  handleCall,
  contestid,
  photo,
  fetching,
}) {
  // const [images, setImages] = useState([])
  // const [didComplete, setDidComplete] = useState(false)
  // const [challenger, setChallenger] = useState('')
  // const [hostUrl, setHostUrl] = useState('')
  const classes = useStyles()
  useEffect(() => {
    userPhotos()
  }, [userPhotos])

  useEffect(() => {
    if (!challengeAdd) {
      afterAdd()
    }
  }, [afterAdd, challengeAdd])

  const handleChallenge = async (event) => {
    switch (actiontype) {
      case 'contest': {
        const postdata = {
          image: event.target.dataset.imageKey,
          contestid: contestid,
        }
        postdata.contestLink = `${window.location.protocol}//${window.location.host}/chellenge/${challengeAdd}`

        handleCall(postdata)
        break
      }
      default: {
        const postdata = {
          champPhoto: photo,
          challengerPhoto: event.target.dataset.imageKey,
        }
        handleCall(postdata)
      }
    }
  }

  if (challengeAdd) {
    return (
      <div className={classes.didCompleteContainer}>
        <p>
          Awesome! Your challenge has been created.
          <br /> Share this to get votes.
        </p>
        <h5>
          <div style={{ display: 'block', width: '100%' }}>
            <FacebookShareButton
              url={
                window.location.protocol +
                '//' +
                window.location.host +
                '/challenge/' +
                challengeAdd
              }
              quote={'Vote for me on Seensta'}
              className={classes.shareButton}
            >
              <SocialIcon network="facebook" />
            </FacebookShareButton>
            <WhatsappShareButton
              url={
                window.location.protocol +
                '//' +
                window.location.host +
                '/challenge/' +
                challengeAdd
              }
              className={classes.shareButton}
              title={'Vote for me on Seensta '}
            >
              <SocialIcon network="whatsapp" />
            </WhatsappShareButton>
            <TwitterShareButton
              url={
                window.location.protocol +
                '//' +
                window.location.host +
                '/challenge/' +
                challengeAdd
              }
              className={classes.shareButton}
              title={'Vote for me on Seensta '}
            >
              <SocialIcon network="twitter" />
            </TwitterShareButton>
          </div>
          <br />
          <br />
          {window.location.protocol + '//' + window.location.host + '/challenge/' + challengeAdd}
        </h5>
        <Button
          href={'/challenge/' + challengeAdd}
          aria-label="View Challenge"
          variant="outlined"
          size="small"
          color="primary"
          style={{ padding: '2px' }}
        >
          View Challenge
        </Button>
      </div>
    )
  } else if (photos.length !== 0) {
    const userphotos = photos.map((card) => {
      const imageAwsAvatar = awsImage('folio', card.imagePath, 150, 'contain')
      return (
        <div key={card.id}>
          <img
            onClick={handleChallenge}
            src={imageAwsAvatar}
            alt="From Seensta"
            data-image-key={card.imagePath}
            style={{ width: '100px', float: 'left', padding: 10 }}
          />
        </div>
      )
    })
    return <div>{userphotos}</div>
  }

  if (fetching) {
    return (
      <div className={classes.didCompleteContainer}>
        <CircularProgress />
      </div>
    )
  }

  if (!fetching && photos.length === 0) {
    return (
      <div className={classes.didCompleteContainer}>
        <Button
          onClick={() => window.location.assign('/image/upload')}
          aria-label="View Challenge"
          variant="outlined"
          size="small"
          color="primary"
          style={{ padding: '2px' }}
        >
          Add Content
        </Button>
      </div>
    )
  }

  return <div></div>
}

const mapStateToProps = (state) => {
  return {
    photos: state.user.photos,
    challengeAdd: state.challenge.challengeAdd,
    fetching: state.user.fetching,
    token: state.auth.token,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    createChallenge: (challenge, token) => dispatch(createChallenge(challenge, token)),
    userPhotos: () => dispatch(userPhotos()),
    afterAdd: () => dispatch(afterAdd()),
    createContest: (contestdata) => dispatch(createContest(contestdata)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserPhotos)
