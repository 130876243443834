import React, { useEffect } from 'react'
import { fetchPortfolio, fetchPortfolioMore } from '../../store/actions/portfolioActions'
import { connect } from 'react-redux'
import ImageCard from '../portfolio/image_cards'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { Mixpanel } from '../../Mixpanel'
import Masonry from 'react-masonry-css'
import './Grid.css'
import Hidden from '@material-ui/core/Hidden'
import ExpandRefresh from '@material-ui/icons/Refresh'
import IconButton from '@material-ui/core/IconButton'
import BrowseMenu from '../layout/top_browse_menu'

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1,
}
function Trending({ portfolio, trend, token, fetching, featuredusers, auth, user }) {
  useEffect(() => {
    const page = 1
    if (!portfolio.length) {
      fetchPortfolio(null, null, null, null, trend, page, token)
    }
    document.title = 'Seensta | Trending ' + trend

    Mixpanel.track('Trends Pageview')
  }, [portfolio, trend, token])

  const handleMore = () => {
    //Calculate Page
    const page = Math.round(portfolio.length / 20 + 1)
    fetchPortfolioMore(null, null, null, null, trend, page, token)
    Mixpanel.track('Trends View More Clicked')
  }

  //render the view

  const cards = portfolio.map((card, index) => {
    return (
      <div key={index}>
        <ImageCard
          header={card.photoPath ? 'yes' : 'no'}
          {...card}
          auth={auth}
          nowuser={user.username}
        />
      </div>
    )
  })

  if (portfolio.length) {
    return (
      <div style={{ paddingBottom: '130px', maxWidth: 1380, margin: '0 auto' }}>
        <BrowseMenu />

        <div>
          <Hidden only="sm">
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {cards}
            </Masonry>

            <div style={{ textAlign: 'center' }}>
              {fetching ? (
                <CircularProgress />
              ) : (
                <div>
                  <IconButton
                    variant="outlined"
                    size="small"
                    color="secondary"
                    onClick={handleMore}
                  >
                    <ExpandRefresh /> <br />
                  </IconButton>
                </div>
              )}
            </div>
          </Hidden>
          <Hidden only={['lg', 'xl']}>
            {cards}
            <div style={{ textAlign: 'center' }}>
              {fetching ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  onClick={handleMore}
                  style={{ width: '80%' }}
                >
                  <ExpandMore />
                  Load More
                </Button>
              )}
            </div>
          </Hidden>
        </div>
      </div>
    )
  } else {
    return (
      <div style={{ paddingTop: '100px', paddingBottom: '100px', textAlign: 'center' }}>
        <CircularProgress />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPortfolio: (username, rank, usertype, tags, hot, page, token) => {
      dispatch(fetchPortfolio(username, rank, usertype, tags, hot, page, token))
    },
    fetchPortfolioMore: (username, rank, usertype, tags, hot, page, token) => {
      dispatch(fetchPortfolioMore(username, rank, usertype, tags, hot, page, token))
    },
  }
}

const mapStateToProps = (state, ownProps) => ({
  portfolio: state.portfolio.trend,
  usertype: ownProps.match.params.usertype,
  cat: ownProps.match.params.cat,
  type: ownProps.match.params.type,
  username: ownProps.match.params.username,
  auth: state.auth.auth,
  tags: ownProps.match.params.tag,
  fetching: state.portfolio.fetching,
  trend: ownProps.match.params.trend,
  user: state.auth.user,
  featuredusers: state.portfolio.featuredusers,
  token: state.auth.token,
})

export default connect(mapStateToProps, mapDispatchToProps)(Trending)
