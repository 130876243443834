import React, { useEffect, useRef, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import MicIcon from '@material-ui/icons/Mic'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'

import { handleUploadAudio } from '../../Helpers'
import { connect } from 'react-redux'
import { Mixpanel } from '../../Mixpanel'
import LockIcon from '@material-ui/icons/Lock'
import { Divider } from '@material-ui/core'

function AudioRecorderContainer(props) {
  const { onClose, selectedValue, open } = props
  const [mediaRecorder, setMediaRecorder] = useState()
  const [isRecording, setIsRecording] = useState()
  const [chunks, setChunks] = useState([])
  const [audioUrl, setAudioUrl] = useState()
  const [recordingLimit, setRecordingLimit] = useState(10)
  const [currentBlob, setCurrentBlob] = useState()
  const [error, setError] = useState()

  const chunkRef = useRef(chunks)
  useEffect(() => {
    if (open) {
      const handleSuccess = function (stream) {
        setMediaRecorder(new MediaRecorder(stream))
        setError(null)
        Mixpanel.track('Audio Permission Allowed')
      }
      window.navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(handleSuccess)
        .catch((error) => {
          Mixpanel.track(error)
          setError('Please allow access to your microphone in order to add audio comment/feedback')
        })
    }
  }, [open])

  const timerRef = useRef()
  useEffect(() => {
    if (isRecording) {
      timerRef.current = setInterval(() => {
        setRecordingLimit(recordingLimit - 1)
      }, 1000)
    }
    return () => window.clearInterval(timerRef.current)
  }, [isRecording, recordingLimit])

  useEffect(() => {
    if (recordingLimit === 0) {
      mediaRecorder.stop()
      setIsRecording(false)
    }
  }, [mediaRecorder, recordingLimit])

  useEffect(() => {
    if (mediaRecorder) {
      mediaRecorder.addEventListener('dataavailable', (e) => {
        chunkRef.current = e.data
        setChunks(e.data)
      })
    }
  }, [mediaRecorder])

  useEffect(() => {
    if (mediaRecorder) {
      mediaRecorder.addEventListener('stop', () => {
        const blob = new Blob([chunkRef.current], { type: chunkRef.current.type })
        const audioURL = window.URL.createObjectURL(blob)
        setChunks([])
        setAudioUrl(audioURL)
        setCurrentBlob(blob)
      })
    }
  }, [chunks, mediaRecorder])

  const handleRecord = () => {
    setRecordingLimit(10)
    mediaRecorder.start()
    setIsRecording(true)
    setAudioUrl(null)
  }

  const handleStop = () => {
    mediaRecorder.stop()
    setIsRecording(false)
  }

  const handleClose = () => {
    onClose(selectedValue)
  }

  const handlePostAudio = async (value) => {
    const uploadedAudio = await handleUploadAudio(currentBlob, 'audio-feedback', props.token)
    props.handleAudioComment(uploadedAudio)
    if (uploadedAudio) {
      onClose(value)
    }
  }

  if (mediaRecorder || error) {
    return (
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title" align="center">
          Audio Comment Feedback
        </DialogTitle>
        {error ? (
          <DialogContent>
            <Typography variant="body2" align="center">
              {error}
            </Typography>
            <Divider />
            <Typography variant="body2" align="center">
              To Allow for audio permission click the <LockIcon fontSize="small" /> icon in the
              address bar. Click permission then click microphone. Click allow.
            </Typography>
          </DialogContent>
        ) : (
          <Box>
            <Typography variant="caption" align="center">
              Provide audio feedback about this peice of work.
            </Typography>
            <DialogContent>
              <Box style={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                <Button variant="contained" onClick={isRecording ? handleStop : handleRecord}>
                  <span style={{ color: isRecording ? 'red' : 'black' }}>
                    {' '}
                    {isRecording ? 'Stop Recording' : 'Start Recording'}
                  </span>
                  <MicIcon style={{ color: isRecording ? 'red' : 'black', fontSize: 30 }} />
                </Button>

                <span style={{ padding: 10 }}>{recordingLimit} seconds remaining.</span>
              </Box>
              {audioUrl && <audio controls src={audioUrl} style={{ height: 25 }}></audio>}
              {audioUrl && (
                <Box justifyContent="center" style={{ display: 'flex' }}>
                  <Button
                    variant="contained"
                    align="center"
                    color="primary"
                    onClick={handlePostAudio}
                  >
                    Post Audio Comment
                  </Button>
                </Box>
              )}
            </DialogContent>
          </Box>
        )}
      </Dialog>
    )
  }

  return null
}

function AudioFeedback({ token, handleAudioComment }) {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <IconButton variant="contained" style={{ padding: 4 }} onClick={handleClickOpen}>
        <MicIcon />
      </IconButton>
      <AudioRecorderContainer
        token={token}
        selectedValue={'okay'}
        open={open}
        onClose={handleClose}
        handleAudioComment={handleAudioComment}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  }
}

export default connect(mapStateToProps, null)(AudioFeedback)
