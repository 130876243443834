import React from 'react'
import Header from './header'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import SideBar from './side_bar'
import Hidden from '@material-ui/core/Hidden'
import '../home/Grid.css'
import Contests from '../contest/contests_container'
import ContestEntry from '../contest/contest_entry'
import LeaderBoard from '../contest/leader_board'
import ContestSingleView from '../contest/contest_single_view'
import ContestApply from '../contest/contest_apply'
import CreateContest from '../contest/create_contest'

function ContestWrapper({ featuredusers, match }) {
  return (
    <div style={{ marginTop: 50, color: '#000' }}>
      <div className="desktop-width-100">
        <Header />
        <Hidden only={['sm', 'xs']}>
          <div className="desktop-grid-container">
            <div className="desktop-grid-containerflex">
              <SideBar featuredusers={featuredusers} heading="Designers" />
            </div>
            <div className="desktop-grid-container-card">
              <Route path={`${match.url}`} exact component={Contests} />
              <Route path={`${match.url}/entry/:entryid`} exact component={ContestEntry} />
              <Route path={`${match.url}/leaderboard/:contestId`} exact component={LeaderBoard} />
              <Route path={`${match.url}/details/:contestId`} exact component={ContestSingleView} />
              <Route path={`${match.url}/:contestId/apply`} exact component={ContestApply} />
              <Route path={`${match.url}/create`} exact component={CreateContest} />
            </div>
          </div>
        </Hidden>

        <Hidden only={['lg', 'xl', 'md']}>
          <div>
            <Route path={`${match.url}`} exact component={Contests} />
            <Route path={`${match.url}/entry/:entryid`} exact component={ContestEntry} />
            <Route path={`${match.url}/leaderboard/:contestId`} exact component={LeaderBoard} />
            <Route path={`${match.url}/details/:contestId`} exact component={ContestSingleView} />
            <Route path={`${match.url}/:contestId/apply`} exact component={ContestApply} />
            <Route path={`${match.url}/create`} exact component={CreateContest} />
          </div>
        </Hidden>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    portfolio: state.portfolio.folio,
    auth: state.auth.auth,
    fetching: state.portfolio.fetching,
    user: state.auth.user,
    featuredusers: state.portfolio.featuredusers,
    token: state.auth.token,
  }
}
export default connect(mapStateToProps)(ContestWrapper)
