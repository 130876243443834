import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Moment from 'react-moment'
import FeaturedLabel from '../../../components/display/featured_label'
import Label from '../../../components/display/label'
import { Button, Grid } from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    marginTop: 10,
  },
  label: {
    marginTop: 10,
    display: 'flex',
    fontWeight: 700,
  },
}))
function GigDisplayMin({ gig, history, featured, title, auth }) {
  const classes = useStyles()

  return (
    <Paper
      className={classes.root}
      style={{
        padding: 10,
        background: '#ffffff',
        border: '1px solid #F0EFEF',
        boxShadow: 'none',
      }}
      elevation={1}
      onClick={() =>
        history
          ? history.push('/gigs/details/' + gig.gig_id, { gig })
          : (window.location.href = '/gigs/details/' + gig.gig_id)
      }
    >
      {featured && <FeaturedLabel text="Featured Gig" width={130} />}

      <Grid container direction="row" alignItems="center">
        <Grid item xs={8}>
          <Label label="title" fontWeight={200} />
          <Typography className={classes.heading} variant="h5" style={{ fontSize: 14 }}>
            {gig.gig_title}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          {auth ? (
            <div>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  console.log('clicked show all')
                }}
                style={{ textTransform: 'lowercase' }}
              >
                apply for gig
              </Button>
            </div>
          ) : (
            <div>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  console.log('clicked show register')
                }}
                style={{ textTransform: 'lowercase' }}
              >
                apply for gig
              </Button>
            </div>
          )}
        </Grid>
      </Grid>

      {!title && (
        <Box>
          <Label label="gig description" fontWeight={200} />

          <Typography component="p">{gig.gig_description}</Typography>
        </Box>
      )}
      <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box>
          <Label label="start date" fontWeight={200} />
          <Typography component="p">
            <Moment format="D MMM YYYY">{gig.gig_start_date}</Moment>
          </Typography>
        </Box>
        <Box>
          <Label label="end date" fontWeight={200} />
          <Typography component="p">
            <Moment format="D MMM YYYY">{gig.gig_end_date}</Moment>
          </Typography>
        </Box>
      </Box>
    </Paper>
  )
}

export default GigDisplayMin
