export const Countries = [
  { label: 'Algeria' },
  { label: 'Angola' },
  { label: 'Benin' },
  { label: 'Botswana' },
  { label: 'Burkina Faso' },
  { label: 'Burundi' },
  { label: 'Cabo Verde' },
  { label: 'Cameroon' },
  { label: 'Central African Republic' },
  { label: 'Chad' },
  { label: 'Comoros' },
  { label: 'Democratic Republic of the Congo' },
  { label: 'Republic of the Congo' },
  { label: "Cote d'Ivoire" },
  { label: 'Djibouti' },
  { label: 'Egypt' },
  { label: 'Equatorial Guinea' },
  { label: 'Eritrea' },
  { label: 'Eswatini' },
  { label: 'Ethiopia' },
  { label: 'Gabon' },
  { label: 'Gambia' },
  { label: 'Ghana' },
  { label: 'Guinea' },
  { label: 'Guinea-Bissau' },
  { label: 'Kenya' },
  { label: 'Lesotho' },
  { label: 'Liberia' },
  { label: 'Libya' },
  { label: 'Madagascar' },
  { label: 'Malawi' },
  { label: 'Mali' },
  { label: 'Mauritania' },
  { label: 'Mauritius' },
  { label: 'Morocco' },
  { label: 'Mozambique' },
  { label: 'Namibia' },
  { label: 'Niger' },
  { label: 'Nigeria' },
  { label: 'Rwanda' },
  { label: 'Sao Tome and Principe' },
  { label: 'Senegal' },
  { label: 'Seychelles' },
  { label: 'Sierra Leone' },
  { label: 'Somalia' },
  { label: 'South Africa' },
  { label: 'South Sudan' },
  { label: 'Sudan' },
  { label: 'Tanzania' },
  { label: 'Togo' },
  { label: 'Tunisia' },
  { label: 'Uganda' },
  { label: 'Zambia' },
  { label: 'Zimbabwe' },
  { label: 'United States' },
]
