import React from 'react'
import '../Welcome.css'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Hidden from '@material-ui/core/Hidden'
import { awsImage } from '../../../Helpers'

function TellStory() {
  const hipImage = awsImage('media', 'girls2photo-web.jpg', 500, 'cover')
  const authenticImage = awsImage('folio', 'phWzCZ_IMG_20180317_193040_741.jpg', 500, 'cover')
  const uniqueImage = awsImage('media', 'traditionclothes-web.jpg', 500, 'contain')
  const traditionalImage = awsImage('profilepictures', 'ijRsBo_AA008.jpg', 500, 'contain')

  return (
    <div style={{ color: '#000' }}>
      <Hidden only={['xl', 'lg']}>
        <div style={{ padding: 10, background: '#fff' }}>
          <div style={{ maxWidth: 1170, margin: '0 auto', width: '100%' }}>
            <div className="howitworks-title" style={{ textTransform: 'capitalize' }}>
              <span style={{ fontSize: 32, paddingBottom: 20, color: '#76235a' }}>Embracing</span>{' '}
              <span style={{ fontSize: 31, fontWeight: 100, lineHeight: 1 }}>
                our rich history &amp; diverse contributions
              </span>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} lg={6}>
                <Card>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt="Hip African Clothes"
                      style={{ objectFit: 'cover', objectPosition: 'top' }}
                      height="150"
                      image={hipImage}
                      title="Hip African Clothes"
                    />
                    <CardContent>
                      <span>
                        {' '}
                        <Typography
                          variant="h3"
                          style={{ textAlign: 'center', fontSize: 24, color: '#000' }}
                        >
                          On Trend
                        </Typography>
                      </span>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={6} sm={6} lg={6}>
                <Card>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt="Authentic African Clothes"
                      style={{ objectFit: 'cover', objectPosition: 'top' }}
                      height="150"
                      image={authenticImage}
                      title="Authentic African Clothes"
                    />
                    <CardContent>
                      <span>
                        {' '}
                        <Typography
                          variant="h3"
                          style={{ textAlign: 'center', fontSize: 24, color: '#000' }}
                        >
                          Authentic
                        </Typography>
                      </span>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} lg={6}>
                <Card>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt="Unique and traditional"
                      style={{ objectFit: 'cover', objectPosition: 'top' }}
                      height="150"
                      image={traditionalImage}
                      title="Unique and traditional"
                    />
                    <CardContent>
                      <span>
                        {' '}
                        <Typography
                          variant="h3"
                          style={{ textAlign: 'center', fontSize: 24, color: '#000' }}
                        >
                          Unique
                        </Typography>
                      </span>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={6} sm={6} lg={6}>
                <Card>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt="Bold and Strong"
                      style={{ objectFit: 'cover', objectPosition: 'top' }}
                      height="150"
                      image={uniqueImage}
                      title="Bold and Strong"
                    />
                    <CardContent>
                      <span>
                        {' '}
                        <Typography
                          variant="h3"
                          style={{ textAlign: 'center', fontSize: 24, color: '#000' }}
                        >
                          Bold
                        </Typography>
                      </span>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>
      </Hidden>

      <Hidden only={['sm', 'xs']}>
        <div style={{ padding: 50, background: '#fff', color: '#000' }}>
          <div style={{ maxWidth: 950, margin: '0 auto', width: '100%' }}>
            <div className="howitworks-title" style={{ textTransform: 'capitalize' }}>
              <span style={{ fontSize: 32, paddingBottom: 20 }}>Embracing</span>{' '}
              <span style={{ fontSize: 32, fontWeight: 100, lineHeight: 1 }}>
                our rich history & diverse contributions
              </span>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} lg={6}>
                <Card>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt="Hip African Clothes"
                      style={{ objectFit: 'cover', objectPosition: 'top' }}
                      height="350"
                      image={hipImage}
                      title="Hip African Clothes"
                    />
                    <CardContent>
                      <span>
                        {' '}
                        <Typography variant="h3" style={{ textAlign: 'center', color: '#000' }}>
                          On Trend
                        </Typography>
                      </span>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={6} sm={6} lg={6}>
                <Card>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt="Authentic African Clothes"
                      style={{ objectFit: 'cover', objectPosition: 'top' }}
                      height="350"
                      image={authenticImage}
                      title="Authentic African Clothes"
                    />
                    <CardContent>
                      <span>
                        {' '}
                        <Typography variant="h3" style={{ textAlign: 'center', color: '#000' }}>
                          Authentic
                        </Typography>
                      </span>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} lg={6}>
                <Card>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt="Unique and traditional"
                      style={{ objectFit: 'cover', objectPosition: 'top' }}
                      height="350"
                      image={traditionalImage}
                      title="Unique and traditional"
                    />
                    <CardContent>
                      <span>
                        {' '}
                        <Typography variant="h3" style={{ textAlign: 'center', color: '#000' }}>
                          Unique
                        </Typography>
                      </span>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={6} sm={6} lg={6}>
                <Card>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt="Bold and Strong"
                      style={{ objectFit: 'cover', objectPosition: 'top' }}
                      height="350"
                      image={uniqueImage}
                      title="Bold and Strong"
                    />
                    <CardContent>
                      <span>
                        {' '}
                        <Typography variant="h3" style={{ textAlign: 'center', color: '#000' }}>
                          Bold
                        </Typography>
                      </span>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>
      </Hidden>
    </div>
  )
}

export default TellStory
