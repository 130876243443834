import React, { useEffect, useState, useReducer } from 'react'
import HeaderSimple from '../layout/header_simple'
import { Countries } from './countries'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import { Mixpanel } from '../../Mixpanel'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Api from '../../Api'

function Country({ showButton = true, history, token }) {
  const [country, setCountry] = useState()
  const [bio, setBio] = useState('')

  function reducer(state, action) {
    switch (action.type) {
      case 'unMount':
        submitBioLocation()
        break
      default:
        throw new Error()
    }
  }

  const [addBioInfo, dispatch] = useReducer(reducer, null)

  useEffect(() => {
    return () => {
      dispatch({ type: 'unMount' })
    }
  }, [])

  const submitBioLocation = () => {
    const aboutinfo = {
      bio: bio,
      location: country,
    }
    const headers = {
      Authorization: 'Bearer ' + token,
    }
    Api.put(`about`, aboutinfo, { headers: headers }).then((res) => {
      if (res) {
        Mixpanel.track('Usertype Selected')
        if (showButton) {
          history.push('/home')
        }
      }
    })
  }

  function showCountry() {
    return (
      <div style={{ width: '100%' }}>
        <Select
          native
          id="select-country"
          onChange={(e) => {
            setCountry(e.target.value)
          }}
          inputProps={{
            name: 'location',
            id: 'age-native-simple',
          }}
        >
          {Countries.map((country, index) => {
            return (
              <option key={index} value={country.label}>
                {country.label}
              </option>
            )
          })}
        </Select>
      </div>
    )
  }

  return (
    <div style={{ maxWidth: 800, margin: '0 auto', marginTop: 60 }}>
      <HeaderSimple />
      <Typography className="question" align="center">
        Location and Bio
      </Typography>
      <div style={{ textAlign: 'center', marginTop: 20 }}>
        {showCountry()}
        <div style={{ padding: 10 }}>
          <TextField
            label="Bio"
            placeholder="Tell us your story."
            multiline
            rowsMax="8"
            margin="normal"
            helperText="Tell us a little about you."
            variant="outlined"
            name="bio"
            fullWidth
            onChange={(e) => {
              setBio(e.target.value)
            }}
          />
        </div>
        {showButton !== false && (
          <div style={{ marginTop: 20 }}>
            <Button onClick={() => submitBioLocation()} variant="contained" color="primary">
              Continue
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  }
}

export default withRouter(connect(mapStateToProps, null)(Country))
