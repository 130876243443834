import React from 'react'
import Rollbar from 'rollbar'
import EmptyState from './empty_state.'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
    this.state = {
      rollbar: new Rollbar({
        accessToken: `03d5e962141e48ce92f1af217196841f`,
        captureUncaught: true,
        captureUnhandledRejections: true,
      }),
    }

    console.log(this.state)
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service

    this.state.rollbar.info(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <EmptyState message="Sorry, we ran into a hippo" title="Something went wrong" />
    }

    return this.props.children
  }
}

export default ErrorBoundary
