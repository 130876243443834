import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import FaqExpand from './faq_expand'
import HeaderSimple from '../layout/header_simple'
import Typography from '@material-ui/core/Typography'
import Footer from './sections/footer_section'
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: 70,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

const Questions = [
  {
    question: 'What is Seensta',
    answer: `Seensta is a platform for African Designers and Artisans. We have over 30 thousand creators in our network who we source from. Our goal is to bring Africa to the world.`,
  },
  {
    question: 'Is Seensta available for everybody',
    answer: `Everybody can use Seensta, however, we source our products directly from the African continent. In the future, we will add the African diaspora creators to our network.`,
  },
  {
    question: 'How much is Seensta?',
    answer: `Seensta costs $50 a month or every other month. You keep all the clothes we send you. You don’t have to buy them to keep them.`,
  },
  {
    question: 'What items should I expect to receive?',
    answer: `Your monthly shipment will include an article of clothing and occasionally accessories, jewelry or art from the African continent. In addition to that you will also receive instruction if the particular clothes require special care.`,
  },
  {
    question: 'Can I return items I don’t like?',
    answer: `Yes. You can return items you don’t like, however, we prefer you keep them. We will make the adjustment on our part. We would hate for you to go through the hassle of returning items to us.`,
  },
  {
    question: 'When will my card be charged?',
    answer: `You're charged at the time you make your reservation or confirm your order for your membership.`,
  },
  {
    question: 'What type of clothing do you carry?',
    answer: `We carry clothing for women for every occasion and stage in her life. Our clothing ranges from size 00 - plus size. We carry clothes for kids and men as well.`,
  },
]
function FAQ() {
  const classes = useStyles()
  return (
    <div>
      <HeaderSimple />
      <div className={classes.root}>
        <Typography variant="h6" style={{ width: '100%', textAlign: 'center' }}>
          Frequently Asked Questions
        </Typography>
        {Questions.map((question, index) => {
          return (
            <FaqExpand
              question={question.question}
              id={index}
              answer={question.answer}
              key={index}
            />
          )
        })}
      </div>
      <Footer />
    </div>
  )
}
export default FAQ
