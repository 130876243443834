import { FETCH_COMMENT, CREATE_COMMENT, COMMENT_REQUEST } from './types'
import Api from '../../Api'

const basedev = process.env.REACT_APP_API_URL

const headers = {}

export const fetchComments = (commentData) => (dispatch) => {
  const endpoint = `${basedev}comments/${commentData.id}`
  headers.Authorization = `Bearer ${commentData.token}`
  dispatch({
    type: COMMENT_REQUEST,
  })

  Api.get(endpoint, { headers: headers }).then((res) => {
    dispatch({
      type: FETCH_COMMENT,
      payload: res.data,
    })
  })
}

export const deleteComments = (commentData) => async (dispatch) => {
  const endpoint = `${basedev}comments/${commentData.id}`

  headers.Authorization = `Bearer ${commentData.token}`

  dispatch({
    type: COMMENT_REQUEST,
  })

  const res = await Api.delete(endpoint, { headers: headers })

  return res
}

export const createComment = (commentData) => async (dispatch) => {
  var endpoint = basedev + 'comments'
  headers.Authorization = 'Bearer ' + commentData.token
  //create the challenge
  const res = await Api.post(endpoint, commentData.data, { headers: headers })
  dispatch({
    type: CREATE_COMMENT,
    payload: res.data,
  })
  return res
}
