import React from 'react'
import Typography from '@material-ui/core/Typography'
import { SocialIcon } from 'react-social-icons'
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share'

function ShareContentSocial({ shareUrl, titleContent, headingTitle }) {
  return (
    <div style={{ display: 'block', width: '100%' }}>
      <div
        style={{
          width: 350,
          margin: ' 0 auto',
          textAlign: 'center',
          padding: 20,
        }}
      >
        <Typography variant="h6" style={{ textAlign: 'center' }}>
          {headingTitle}
        </Typography>

        <FacebookShareButton
          url={shareUrl}
          style={{ display: 'inline', width: 10, height: 10, padding: 5 }}
          quote={titleContent}
        >
          <SocialIcon style={{ width: 20, height: 20 }} network="facebook" />
        </FacebookShareButton>
        <WhatsappShareButton
          url={shareUrl}
          style={{ display: 'inline', width: 10, height: 10, padding: 5 }}
          title={titleContent}
        >
          <SocialIcon style={{ width: 20, height: 20 }} network="whatsapp" />
        </WhatsappShareButton>

        <TwitterShareButton
          url={shareUrl}
          style={{ display: 'inline', width: 10, height: 10, padding: 5 }}
          title={titleContent}
        >
          <SocialIcon style={{ width: 20, height: 20 }} network="twitter" />
        </TwitterShareButton>
      </div>
    </div>
  )
}

export default ShareContentSocial
