import { FETCH_USER, USER_PHOTOS, FETCH_USER_REQUEST } from './types'
import Api from '../../Api'
const basedev = process.env.REACT_APP_API_URL

const endpointuser = basedev + 'user/portfolio'

export const fetchUser = (username = null) => (dispatch) => {
  const headers = {
    Authorization: 'Bearer ' + window.localStorage.getItem('asafo_token'),
  }

  if (username !== null) {
    var endpoint = basedev + 'user/' + username
  }
  dispatch({
    type: FETCH_USER_REQUEST,
  })
  Api.get(endpoint, { headers: headers }).then((res) => {
    dispatch({
      type: FETCH_USER,
      user: res.data.user,
      avatar: res.data.avatar,
      about: res.data.about,
      battlewon: res.data.badges,
      followers: res.data.follower,
      following: res.data.following,
      contests: res.data.contests,
    })
  })
}

//get the user photos

export const userPhotos = () => (dispatch) => {
  const headers = {
    Authorization: 'Bearer ' + window.localStorage.getItem('asafo_token'),
  }
  dispatch({
    type: FETCH_USER_REQUEST,
  })

  Api.get(endpointuser, { headers: headers }).then((res) => {
    dispatch({
      type: USER_PHOTOS,
      photos: res.data.results,
    })
  })
}
