import { encode as btoa } from 'base-64'
import uuid from 'uuid'
import Api from './Api'
const baseURL = process.env.REACT_APP_API_URL
export async function preSignedUrl(fileName, fileInfo, token, folder) {
  let endpoint = `${baseURL}add/postsign`
  const data = {
    filetype: fileInfo.type,
    filename: fileName,
    folder: folder,
  }
  const responseAPI = Api.post(endpoint, data, {
    headers: { Authorization: 'Bearer ' + token },
  })
  return responseAPI
}

export async function postAWS(fileInfo, data, thefilename, folder) {
  const postURL = data.formAttribution.action
  let filename = `${folder}/${thefilename}`
  let formData = new FormData()
  formData.append('Content-Type', contentType(fileInfo.type))
  formData.append('success_action_status', `201`)
  formData.append('key', filename)
  Object.keys(data.formData).forEach((key) => {
    if (key !== 'key') formData.append(key, data.formData[key])
  })
  formData.append('file', fileInfo.file)
  const config = {
    method: data.formAttribution.method,
    headers: new Headers({
      Accept: 'application/xml',
    }),
    body: formData,
  }
  return fetch(postURL, config)
}

export async function addPhoto(filename, token, caption, folder) {
  let endpoint = `${baseURL}add/content`
  const data = {
    caption: caption,
    filename: filename,
    folder: folder,
  }
  const apiResponse = Api.post(endpoint, data, {
    headers: { Authorization: 'Bearer ' + token },
  })
  return apiResponse
}

export async function handleImage(file, folder, token, caption = '') {
  let presignResponse
  let addResponse
  if (file.length) {
    const filetype = file[0].type
    const type = filetype.split('/')
    const fileInfo = {
      name: file[0].name,
      type: type[1],
      file: file[0],
      fulltype: file[0].type,
    }
    const fileName = `seensta-${uuid.v4()}`
    presignResponse = await preSignedUrl(fileName, fileInfo, token, folder)
    if (presignResponse.data.formData) {
      const awsResponse = await postAWS(
        fileInfo,
        presignResponse.data,
        fileName + `.${type[1]}`,
        folder
      )
      if (awsResponse.status === 201) {
        let newFileName = fileName + `.${type[1]}`
        addResponse = await addPhoto(newFileName, token, caption, folder)

        return addResponse
      }
    }
  } else {
    const imageUrl = awsImage('media', 'sankofa.jpeg', 150, 'cover')
    return { uploading: false, image: imageUrl }
  }
}

export async function handleUploadAudio(blob, folder, token) {
  let presignResponse
  const fileName = `seensta-audio-comment-${uuid.v4()}`
  const file = new File([blob], fileName, {
    lastModifiedDate: new Date(),
    type: blob.type.split(';')[0],
  })
  if (file.size) {
    const filetype = blob.type
    const type = filetype.split('/')
    const fileInfo = {
      name: file.name,
      type: type[1].split(';')[0],
      file: file,
      fulltype: file.type,
    }
    presignResponse = await preSignedUrl(fileName, fileInfo, token, folder)
    if (presignResponse.data.formData) {
      const awsResponse = await postAWS(
        fileInfo,
        presignResponse.data,
        fileName + `.${fileInfo.type}`,
        folder
      )
      if (awsResponse.status === 201) {
        let newFileName = fileName + `.${fileInfo.type}`

        return newFileName
      }
    }
  } else {
    return { uploading: false }
  }
}

export function awsImage(folder, key, width, fit) {
  if (key === null) {
    if (folder === 'profilepictures') {
      folder = 'media'
      key = 'sankofa.jpeg'
    }
  }
  const imageRequest = JSON.stringify({
    bucket: 'seensta-content',
    key: `${folder}/${key}`,
    edits: {
      resize: {
        width,
        fit,
      },
    },
  })
  const imageUrl = `https://d1sb0aptwihzht.cloudfront.net/${btoa(imageRequest)}`
  return imageUrl
}

function contentType(type) {
  switch (type) {
    case 'png':
      return 'image/png'
    case 'jpg':
      return 'image/jpeg'
    case 'gif':
      return 'image/gif'
    case 'jpeg':
      return 'image/jpeg'
    case 'webp':
      return 'image/webp'
    case 'mpeg':
      return 'video/mpeg'
    case 'webm':
      return 'video/webm'
    case 'mov':
      return 'video/quicktime'
    case '3gp':
      return 'video/3gpp'
    case 'wmv':
      return 'video/x-ms-wmv'
    default:
      return null
  }
}

export function userType(type) {
  switch (type) {
    case 'modelUser':
      return 'Model'
    case 'photographer':
      return 'Photographer'
    case 'Designer':
      return 'Designer'
    case 'universe':
      return 'Fan'
    case 'Model':
      return 'Model'
    case 'Artist':
      return 'Artist'
    case 'Dancer':
      return 'Dancer'
    case 'Fashion':
      return 'Fashion'
    case 'Makeup Artist':
      return 'MUA'
    case 'Photographer':
      return 'Photographer'
    case 'model':
      return 'Model'
    default:
      return 'Fan'
  }
}
