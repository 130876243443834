// CardSection.js
import React from 'react'
import { CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements'

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
        padding,
      },
      invalid: {
        color: '#9e2146',
      },
    },
  }
}

function CardSection() {
  const handleBlur = () => {}
  const handleChange = () => {}

  const handleFocus = () => {}
  const handleReady = () => {}

  return (
    <div>
      <label>
        Card number
        <CardNumberElement
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onReady={handleReady}
          {...createOptions(12)}
        />
      </label>
      <label>
        Expiration date
        <CardExpiryElement
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onReady={handleReady}
          {...createOptions(12)}
        />
      </label>
      <label>
        CVC
        <CardCVCElement
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onReady={handleReady}
          {...createOptions(12)}
        />
      </label>
    </div>
  )
}

export default CardSection
