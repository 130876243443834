import React, { useState } from 'react'
import Header from './header'
import { Route } from 'react-router-dom'
import SideBar from './side_bar'
import Hidden from '@material-ui/core/Hidden'
import ShowGig from '../../Views/gigs/show'
import MyGigs from '../../Views/gigs/dashboard/mygigs'
import MyApplications from '../../Views/gigs/dashboard/myapplications'
import CreateGig from '../../Views/gigs/create'
import GigList from '../../Views/gigs/index'
import { connect } from 'react-redux'
import SwipeableViews from 'react-swipeable-views'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import EditIcon from '@material-ui/icons/Edit'
import makeStyles from '@material-ui/core/styles/makeStyles'
import '../home/Grid.css'
import { purple } from '@material-ui/core/colors'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
    position: 'relative',
    minHeight: 200,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(8),
    right: theme.spacing(2),
  },
  fabPrimary: {
    color: theme.palette.common.white,
    backgroundColor: purple[500],
    '&:hover': {
      backgroundColor: purple[600],
    },
  },
}))

function TabPanel({ children }) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
}

function GigWapper({ featuredusers, match, history }) {
  const [isValue, setIsValue] = useState(0)

  const handleChange = (event, value) => {
    setIsValue(value)
  }

  const handleChangeIndex = (index) => {
    setIsValue(index)
  }

  const theme = useTheme()
  const classes = useStyles()

  return (
    <div style={{ marginTop: 50, color: '#000' }}>
      <div className="desktop-width-100">
        <Header />
        <Hidden only={['sm', 'xs']}>
          <div className="desktop-grid-container">
            <div className="desktop-grid-containerflex">
              <SideBar featuredusers={featuredusers} heading="Designers" />
            </div>
            <div className="desktop-grid-container-card">
              <Route path={`${match.url}`} exact component={GigList} />
              <Route path={`${match.url}/details/:gigID`} exact component={ShowGig} />
              <Route path={`${match.url}/admin/self`} exact component={MyGigs} />
              <Route path={`${match.url}/admin/applications`} exact component={MyApplications} />
              <Route path={`${match.url}/create`} exact component={CreateGig} />
            </div>
          </div>
        </Hidden>

        <Hidden only={['lg', 'xl', 'md']}>
          <AppBar position="static" color="default">
            <Tabs
              value={isValue}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Available Gigs" />
              <Tab label="My Gigs" />
              <Tab label="Applied" />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={isValue}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={isValue} index={0}>
              <GigList history={history} />
            </TabPanel>
            <TabPanel value={isValue} index={1}>
              <MyGigs history={history} />
            </TabPanel>
            <TabPanel value={isValue} index={2}>
              <MyApplications history={history} />
            </TabPanel>
          </SwipeableViews>

          <div style={{ marginTop: 10 }}></div>
          <Fab
            aria-label={'Post a gig'}
            className={classes.fab}
            onClick={() => history.push(`${match.url}/create`)}
            color="primary"
          >
            <EditIcon />
          </Fab>
        </Hidden>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    portfolio: state.portfolio.folio,
    auth: state.auth.auth,
    fetching: state.portfolio.fetching,
    user: state.auth.user,
    featuredusers: state.portfolio.featuredusers,
    token: state.auth.token,
  }
}
export default connect(mapStateToProps)(GigWapper)
