import React, { useState, useEffect } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { awsImage, handleImage } from '../../Helpers'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'

function AvatarPhoto(props) {
  //constructor
  const [avatarPhoto, setAvatarPhoto] = useState('/female_avatar.png')
  const [spinner, setSpinner] = useState(false)
  useEffect(() => {
    if (props.avatar) {
      const imageAws = props.avatar.photoPath.includes('http')
        ? props.avatar.photoPath
        : props.avatar.photoPath
        ? awsImage('profilepictures', props.avatar.photoPath, 250, 'contain')
        : '/female_avatar.png'

      setAvatarPhoto(imageAws)
    }
  }, [avatarPhoto, props.avatar, setAvatarPhoto])

  const handleChange = async () => {
    setSpinner(true)
    const imageUploadResponse = await handleImage(
      document.getElementById('user-avatar').files,
      'profilepictures',
      localStorage.getItem('asafo_token'),
      null
    )
    if (imageUploadResponse.status === 201) {
      setSpinner(false)
      const imageAws = awsImage(
        'profilepictures',
        imageUploadResponse.data.filename,
        250,
        'contain'
      )
      setAvatarPhoto(imageAws)
    }
  }

  return (
    <div style={{ textAlign: 'center', marginTop: 90 }}>
      <label htmlFor="user-avatar">
        <img
          src={avatarPhoto}
          alt="Profile upload "
          style={{
            marginBottom: 10,
            height: 140,
            width: 150,
            border: '2px solid #bbb',
            padding: 10,
            cursor: 'pointer',
          }}
        />
        {avatarPhoto !== '/female_avatar.png' && (
          <div>
            {props.location && props.location.pathname.includes('profilepicture') && (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => props.history.push(`/home`)}
                >
                  Home
                </Button>
              </div>
            )}
          </div>
        )}
        <Typography variant="caption" style={{ width: 150, margin: '0 auto', display: 'block' }}>
          Click to upload profile photo
        </Typography>
      </label>
      <input
        accept="image/*"
        type="file"
        data-figure_type="avatar"
        id="user-avatar"
        onChange={handleChange}
        style={{ display: 'none' }}
      />

      {spinner ? (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      ) : null}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    avatar: state.auth.avatar,
  }
}

export default connect(mapStateToProps, null)(AvatarPhoto)
