import React, { useEffect } from 'react'
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom'
import SignIn from './components/auth/sign_in'
import SignUp from './components/auth/sign_up'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import createTheme from '@material-ui/core/styles/createTheme'
import withStyles from '@material-ui/core/styles/withStyles'
import { Provider } from 'react-redux'
import store from './store'
import { connect } from 'react-redux'
import PasswordReset from './components/auth/password_reset'
import Wrapper from './components/layout/wrapper'
import NewPassword from './components/auth/new_password'
import ReactGA from 'react-ga'
import Welcome from './components/welcome/welcome'
import AuthLoading from './components/auth/auth_loading'
import BrowsingContainer from './components/layout/browsing_container'
import FAQ from './components/welcome/faq'
import { refreshUser } from './store/actions/authActions'
import TypeOfUser from './components/onboarding/user_category'
import Country from './components/onboarding/country'
import GigWapper from './components/layout/gig_wrapper'
import ContestWrapper from './components/layout/contest_wrapper'
import ChallengesContainer from './components/layout/challenges_container'
import EmailVerification from './components/auth/email_verification'
import ShowGig from './Views/gigs/show'
import CreateGig from './Views/gigs/create'
import FacebookCallback from './components/social_components/facebook_callback'
import ErrorBoundary from './components/appmessages/error_boundary'
import LabelBottomNavigation from './components/layout/label_bottom_navigation'
import WelcomeAAConnect from './components/welcome/welcome_aa_connect'
import Questions from './components/questionswrapper/question'
import MonthlyClotheSignup from './components/welcome/MonthlyClotheSignup'
import StyleExplain from './components/welcome/StyleExplain'
import SubscriptionPayment from './components/seamrequest/SubscriptionPayment'
import PricingSectionMonthly from './components/welcome/PricingSectionMonthly'
import WelcomeContestCreators from './components/welcome/welcome_contest_creators'
import WelcomeGigCreators from './components/welcome/welcome_gig_creators'

const purpleTheme = createTheme({
  palette: {
    primary: {
      main: '#52183E',
      color: '#000',
      borderColor: '#52183E',
    },
    secondary: {
      main: '#93a303',
      color: '#fff',
    },
  },
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: '#FFFBFA',
      },
    },
  },
})
const styles = (theme) => ({
  narrowContainer: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
})

function App({ refreshUser }) {
  useEffect(() => {
    ReactGA.initialize('UA-44849758-1')
    ReactGA.pageview(window.location.pathname + window.location.search)

    if (localStorage.getItem('asafo_token')) refreshUser()
  }, [refreshUser])

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <MuiThemeProvider theme={purpleTheme}>
          <BrowserRouter>
            <div style={{ backgroundColor: '#FDFCFC', color: '#000' }}>
              <Switch>
                <Redirect from="/u/:user_username" to="/:user_username" />
                <Route path="/signup" component={SignUp} />
                <Route path="/signin" component={SignIn} />
                <Route path="/password/reset" exact component={PasswordReset} />
                <Route path="/password/new" component={NewPassword} />
                <Route path="/terms" component={Wrapper} />
                <Route path="/privacy" component={Wrapper} />
                <Route path="/fee-policy" component={Wrapper} />
                <Route path="/prohibited" component={Wrapper} />
                <Route path="/clothes" component={Wrapper} />
                <Route path="/request/complete" exact component={Wrapper} />
                <Route path="/contests" component={ContestWrapper} />
                <Route path="/creators" exact component={Welcome} />
                <Route path="/" exact component={Welcome} />
                <Route path="/discover/africa" exact component={WelcomeAAConnect} />
                <Route path="/home" exact component={BrowsingContainer} />
                <Route path="/challenges" component={ChallengesContainer} />
                <Route path="/settings" component={Wrapper} />
                <Route path="/profilepicture" exact component={Wrapper} />
                <Route path="/trending/:trend" exact component={Wrapper} />
                <Route path="/challenge/:entryid" exact component={Wrapper} />
                <Route path="/photo/:photo_id" exact component={Wrapper} />
                <Route path="/messages/:messageid" exact component={Wrapper} />
                <Route path="/seam/requests" exact component={Wrapper} />
                <Route path="/authloading" exact component={AuthLoading} />
                <Route path="/browse/loc/:term" exact component={BrowsingContainer} />
                <Route path="/browse/:usertype" exact component={BrowsingContainer} />
                <Route path="/browse/tags/:tag" exact component={BrowsingContainer} />
                <Route path="/faqs" exact component={FAQ} />
                <Route path="/image/upload" component={Wrapper} />
                <Route path="/onboard/user" component={TypeOfUser} />
                <Route path="/onboard/country" component={Country} />
                <Route path={`/gigs/details/:gigID`} component={ShowGig} />
                <Route path={`/gigs/create`} component={CreateGig} />
                <Route path="/gigs" component={GigWapper} />
                <Route path={`/fb/callback`} component={FacebookCallback} />
                <Route path="/verify/:verifyCode" component={EmailVerification} />
                <Route path="/explore/search" component={BrowsingContainer} />
                <Route path="/explore" component={BrowsingContainer} />
                <Route path="/connect/guide" exact component={Questions} />
                <Route path="/registration" exact component={MonthlyClotheSignup} />
                <Route path="/discover/start" exact component={StyleExplain} />
                <Route path="/subscribe/payment" component={SubscriptionPayment} />
                <Route path="/connect/plan" component={PricingSectionMonthly} />
                <Route path="/creative/contest" component={WelcomeContestCreators} />
                <Route path="/creative/gigs" component={WelcomeGigCreators} />
                <Route path="/gig/new" component={CreateGig} />

                <Route path="/:user_username" component={Wrapper} />
              </Switch>
              <LabelBottomNavigation />
            </div>
          </BrowserRouter>
        </MuiThemeProvider>
      </Provider>
    </ErrorBoundary>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    refreshUser: (data) => dispatch(refreshUser(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App))
