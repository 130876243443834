import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { verifyUser } from '../../store/actions/authActions'
import HeaderSimple from '../layout/header_simple'

function EmailVerification({ match, verifyUser }) {
  const [verfied, setVerfied] = useState(false)
  const [requesting, setRequesting] = useState(false)

  useEffect(() => {
    setRequesting(true)
    const getVerifyUser = async () => {
      const verification = await verifyUser({ verification_code: match.params.verifyCode })
      if (verification && verification.status === 201) {
        setRequesting(false)
        setVerfied(true)
      } else {
        setRequesting(false)
      }
    }
    getVerifyUser()
  }, [verifyUser])

  if (requesting) {
    return (
      <div>
        <HeaderSimple />

        <div style={{ textAlign: 'center', marginTop: 60 }}>
          <CircularProgress />
        </div>
      </div>
    )
  }
  return (
    <div>
      <HeaderSimple />
      <div className="desktop-width-100">
        <div style={{ marginTop: 60, textAlign: 'center' }}>
          {verfied ? (
            <div>
              <Typography variant="body1" gutterBottom>
                Awesome! Your account has been verfied.
              </Typography>
              <Button variant="contained" href="/signin" color="primary">
                Login
              </Button>
            </div>
          ) : (
            <div>
              <Typography variant="body1" gutterBottom>
                Sorry! We weren&apos;t able to verify your account. Please try again.
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    fetching: state.auth.fetching,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    verifyUser: (credentials) => dispatch(verifyUser(credentials)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification)
