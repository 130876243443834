import React from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import { withRouter } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0 2px 4px rgba(0,0,0,0.4)',
      transform: 'translateY(-5px)',
    },
  },
}))

function JoinSection({ download, history }) {
  const playstore = () => {
    window.location.href = 'https://play.google.com/store/apps/details?id=ananse.seensta'
  }

  const appstore = () => {
    window.location.href = 'https://apps.apple.com/us/app/seensta/id1476448223?ls=1'
  }
  const classes = useStyles()

  return (
    <div>
      <Hidden only={['xl', 'lg']}>
        <div style={{ display: 'block', padding: 25, background: '#f7f5f9' }}>
          <div style={{ maxWidth: 1170, margin: '0 auto', paddingBottom: 50, width: '100%' }}>
            <div className="howitworks-title">
              <span style={{ fontSize: 32, paddingBottom: 20, color: '#76235a' }}>
                Feeling unsure?{' '}
              </span>{' '}
              <span style={{ fontSize: 32, fontWeight: 100 }}>
                take a look at the creative network
              </span>
            </div>
            {/*<Typography variant="h5" style={{textAlign:'center'}}> Benefits &amp; Pricing</Typography>*/}
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} lg={6} xl={6}>
                <Paper
                  className={classes.paper}
                  onClick={() => {
                    history.push('/browse/Designer')
                  }}
                >
                  <Typography variant="h6" style={{ textAlign: 'center' }}>
                    {' '}
                    Designers{' '}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6} sm={6} lg={6} xl={6}>
                <Paper
                  className={classes.paper}
                  onClick={() => {
                    history.push('/browse/Fashion')
                  }}
                >
                  <Typography variant="h6" style={{ textAlign: 'center' }}>
                    Fashionates{' '}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>

            {download ? (
              <div style={{ textAlign: 'center', width: '100%', paddingTop: 20 }}>
                <Typography
                  variant="h4"
                  gutterBottom={true}
                  style={{
                    paddingTop: 20,
                    lineHeight: 1.4,
                    fontSize: 36,
                    color: '#454545',
                    fontWeight: 300,
                  }}
                >
                  Download The App
                </Typography>

                <img
                  src="./Get_it_on_Google_play.svg"
                  alt="bg"
                  style={{ width: 150 }}
                  onClick={playstore}
                />
                <img
                  src="./Download_on_the_App_Store_Badge.svg"
                  alt="bg"
                  style={{ width: 150 }}
                  onClick={appstore}
                />
              </div>
            ) : null}
          </div>
        </div>
      </Hidden>
      <Hidden only={['sm', 'xs', 'md']}>
        <div style={{ display: 'block', padding: 25, background: '#f7f5f9' }}>
          <div style={{ maxWidth: 1170, margin: '0 auto', paddingBottom: 50, width: '100%' }}>
            <div className="howitworks-title">
              <span style={{ fontSize: 32, paddingBottom: 20, color: '#76235a' }}>
                Feeling unsure?{' '}
              </span>{' '}
              <span style={{ fontSize: 32, fontWeight: 100 }}>
                take a look at the creative network
              </span>
            </div>
            {/*<Typography variant="h5" style={{textAlign:'center'}}> Benefits &amp; Pricing</Typography>*/}
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} lg={6} xl={6}>
                <Paper
                  className={classes.paper}
                  onClick={() => {
                    history.push('/browse/Designer')
                  }}
                >
                  <Typography variant="h4" style={{ textAlign: 'center' }}>
                    {' '}
                    Designers{' '}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6} sm={6} lg={6} xl={6}>
                <Paper
                  className={classes.paper}
                  onClick={() => {
                    history.push('/browse/Fashion')
                  }}
                >
                  <Typography variant="h4" style={{ textAlign: 'center' }}>
                    Fashionates{' '}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>

            {download ? (
              <div style={{ textAlign: 'center', width: '100%', paddingTop: 20 }}>
                <Typography
                  variant="h4"
                  gutterBottom={true}
                  style={{
                    paddingTop: 20,
                    lineHeight: 1.4,
                    fontSize: 36,
                    color: '#454545',
                    fontWeight: 300,
                  }}
                >
                  Download The App
                </Typography>
                <img
                  src="./Get_it_on_Google_play.svg"
                  alt="bg"
                  style={{ width: 150 }}
                  onClick={() =>
                    (window.history.href =
                      'https://play.google.com/store/apps/details?id=com.seensta.ananse')
                  }
                />
                <img
                  src="./Download_on_the_App_Store_Badge.svg"
                  alt="bg"
                  style={{ width: 150 }}
                  onClick={() =>
                    (window.history.href = 'https://apps.apple.com/us/app/seensta/id1476448223')
                  }
                />
              </div>
            ) : null}
          </div>
        </div>
      </Hidden>
    </div>
  )
}

export default withRouter(JoinSection)
