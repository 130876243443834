import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { createComment, fetchComments } from '../../store/actions/commentAction'
import { connect } from 'react-redux'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import FollowUser from './user_follow'
import FindFollowuser from './find_follow_user'
import { Button, DialogActions, makeStyles } from '@material-ui/core'
import Api from '../../Api'

const useStyles = makeStyles(() => ({
  dialogPaper: {
    maxHeight: 500,
    height: '100%',
  },
}))

function FollowAndFollowingDialogDesktop({ verb, username, followcount }) {
  const [open, setOpen] = useState(false)
  const [fetching, setFetching] = useState()
  const [userList, setUserList] = useState([])
  const classes = useStyles()
  useEffect(() => {}, [])

  const handleClickOpen = () => {
    setOpen(true)
    const basedev = process.env.REACT_APP_API_URL
    const endpoint = basedev + 'view/' + verb + '/' + username

    const headers = {
      Authorization: 'Bearer ' + localStorage.getItem('asafo_token'),
    }
    console.log(headers)
    setFetching(true)
    Api.get(endpoint, { headers: headers }).then((res) => {
      setUserList(res.data.data)
      setFetching(false)
    })
    //Mixpanel.track('View Follower');
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <div onClick={handleClickOpen} style={{ textAlign: 'center', height: 36 }}>
        <span style={{ color: '#52183E', fontSize: 16, display: 'block' }}>{followcount}</span>
        <span
          style={{
            display: 'block',
            textTransform: 'lowercase',
            fontSize: 11,
            color: '#504D4D',
            paddingTop: 5,
          }}
        >
          {verb}
        </span>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth={true}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle id="form-dialog-title">
          <span
            style={{
              fontSize: 18,
              fontWeight: 600,
              color: '#504D4D',
              display: 'block',
              textAlign: 'center',
            }}
          >
            {' '}
            {verb}
          </span>
          <span style={{ fontSize: 12, fontWeight: 600, color: '#504D4D', display: 'block' }}>
            {' '}
            {followcount} {verb}
          </span>
        </DialogTitle>
        <DialogContent style={{ paddingLeft: 0, paddingRight: 0, maxHeight: 500, height: '100%' }}>
          <List dense>
            {userList.length !== 0 &&
              userList.map((user, index) => {
                return (
                  <ListItem key={index} button>
                    <FindFollowuser user={user} handleclose={handleClose} />
                    <ListItemSecondaryAction>
                      <FollowUser user={user.username} />
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })}

            {userList.length === 0 && !fetching && (
              <div style={{ textAlign: 'center' }}>no {verb}</div>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    allcomments: state.comment.comments,
    user: state.auth.user,
    fetching: state.comment.fetching,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createComment: (commentdata) => dispatch(createComment(commentdata)),
    fetchComments: (resourceid) => dispatch(fetchComments(resourceid)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FollowAndFollowingDialogDesktop)
