import React, { useEffect, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { connect } from 'react-redux'
import { showGig } from '../../store/actions/gigActions'
import ApplyForGig from './apply'
import FormatDateTime from '../../components/display/format_date_time'
import Moment from 'react-moment'
import ShareContentSocial from '../../components/display/share_content_social'
import Hidden from '@material-ui/core/Hidden'
import Header from '../../components/layout/header'
import Label from '../../components/display/label'
import { Button, Grid } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    marginTop: 60,
  },
  label: {
    marginTop: 10,
    display: 'flex',
    fontWeight: 700,
  },
  container: {
    maxWidth: 600,
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  gigTitle: {
    fontSize: 20,
    letterSpacing: 0,
    color: '#504D4D',
    textTransform: 'lowercase',
    opacity: 1,
  },
  gigInfo: {
    fontSize: 16,
    letterSpacing: 0,
    color: '#504D4D',
    textTransform: 'lowercase',
    opacity: 1,
  },
}))
function ShowGig({ token, match, fetchSingleGig, auth, history }) {
  const [singleGig, setSingleGig] = useState()
  const [isApplying, setIsApplying] = useState()
  const [loading, setLoading] = useState()
  useEffect(() => {
    setLoading(true)
    fetchSingleGig(token, match.params.gigID).then((res) => {
      setLoading(false)
      setSingleGig(res.data.gig[0])
      document.title = res.data.gig[0].gig_title
    })
  }, [match.params.gigID, token, fetchSingleGig])

  const classes = useStyles()

  return (
    <div className={classes.container}>
      {!loading && singleGig ? (
        <div>
          <Hidden only={['lg', 'xl', 'md']}>
            <Header />
          </Hidden>
          <Paper
            className={classes.root}
            style={{
              padding: 10,
              background: '#ffffff',
              border: '1px solid #F0EFEF',
              boxShadow: 'none',
            }}
            elevation={1}
          >
            <Grid container direction="row" alignItems="center">
              <Grid item xs={8}>
                <Typography component="p" className={classes.gigInfo}>
                  <Label label="posted" fontWeight={200} />
                  <FormatDateTime dateTime={singleGig.created_at} />
                </Typography>
              </Grid>
              <Grid item xs={4}>
                {auth ? (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      setIsApplying(true)
                    }}
                    style={{ textTransform: 'lowercase' }}
                  >
                    apply for gig
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      history.push('/signup')
                    }}
                    style={{ textTransform: 'lowercase' }}
                  >
                    apply for gig
                  </Button>
                )}
              </Grid>
            </Grid>
            <Divider />

            <Label label="title" fontWeight={200} />
            <Typography className={classes.gigTitle}>{singleGig.gig_title}</Typography>
            <Label label="description" fontWeight={200} />
            <Typography component="p" className={classes.gigInfo}>
              {singleGig.gig_description}
            </Typography>

            <Grid container direction="row" alignItems="center">
              <Grid item xs={3}>
                <Label label="location" fontWeight={200} />
                <Typography component="p" className={classes.gigInfo}>
                  {singleGig.gig_location}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Label label="gig gender" fontWeight={200} />
                <Typography align="center" component="p" className={classes.gigInfo}>
                  {singleGig.gig_gender}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Label label="gig age" fontWeight={200} />
                <Typography component="p" className={classes.gigInfo}>
                  {singleGig.gig_age}
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction="row" alignItems="center">
              <Grid item xs={3}>
                <Label label="gig pay" fontWeight={200} />
                <Typography component="p" className={classes.gigInfo}>
                  {singleGig.gig_compensation}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Label label="start date" fontWeight={200} />
                <Typography component="p" className={classes.gigInfo}>
                  <Moment format="D MMM YYYY">{singleGig.gig_start_date}</Moment>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Label label="end date" fontWeight={200} />
                <Typography component="p" className={classes.gigInfo}>
                  <Moment format="D MMM YYYY">{singleGig.gig_end_date}</Moment>
                </Typography>
              </Grid>
            </Grid>

            {isApplying && <ApplyForGig id={singleGig.gig_id} />}
          </Paper>
          <ShareContentSocial
            headingTitle="Share this Gig"
            titleContent={`Seensta Gig: ${singleGig.gig_title} | Click link to apply `}
            shareUrl={window.location.href}
          />
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    auth: state.auth.auth,
  }
}

const mapDispatchToState = (dispatch) => {
  return {
    fetchSingleGig: (token, gigId) => dispatch(showGig(token, gigId)),
  }
}
export default connect(mapStateToProps, mapDispatchToState)(ShowGig)
