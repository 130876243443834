import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createFollow, fetchFollow } from '../../../store/actions/followAction'
import Button from '@material-ui/core/Button'
import FollowingIcon from '@material-ui/icons/Check'
import { Mixpanel } from '../../../Mixpanel'
import Hidden from '@material-ui/core/Hidden'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import Api from '../../../Api'

function IsFollowingOrFollow({ follow, current, username, token }) {
  const [isFollowing, setIsFollowing] = useState(null)

  useEffect(() => {
    const headers = {
      Authorization: 'Bearer ' + token,
    }

    const endpoint = 'follow/' + username
    Api.get(endpoint, { headers: headers }).then((res) => {
      if (res.status === 204) {
        setIsFollowing(false)
      } else {
        setIsFollowing(true)
      }
    })
  }, [isFollowing, setIsFollowing, token, username])

  const handleFollow = () => {
    follow(username, token)
    setIsFollowing((isFollowing) => !isFollowing)
    Mixpanel.track('Follow User Profile')
  }

  if (username !== current.username) {
    return (
      <div>
        {isFollowing ? (
          <div>
            <Hidden only={['lg', 'xl', 'md']}>
              <Button
                color="primary"
                variant="contained"
                size="small"
                aria-label="Add"
                onClick={handleFollow}
                startIcon={<FollowingIcon style={{ fontSize: 12 }} />}
                style={{ textTransform: 'lowercase' }}
              >
                followed
              </Button>
            </Hidden>
            <Hidden only={['sm', 'xs']}>
              <Button
                variant="contained"
                size="small"
                aria-label="Add"
                onClick={handleFollow}
                color="primary"
                startIcon={<FollowingIcon style={{ fontSize: 12 }} />}
                style={{ textTransform: 'lowercase' }}
              >
                followed
              </Button>
            </Hidden>
          </div>
        ) : (
          <div>
            <Hidden only={['lg', 'xl', 'md']}>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                aria-label="Add"
                onClick={handleFollow}
                style={{ textTransform: 'lowercase' }}
                startIcon={<PersonAddIcon />}
              >
                Follow
              </Button>
            </Hidden>
            <Hidden only={['sm', 'xs']}>
              <Button
                variant="contained"
                size="small"
                aria-label="Add"
                onClick={handleFollow}
                color="primary"
                startIcon={<FollowingIcon />}
                style={{ textTransform: 'lowercase' }}
              >
                followed
              </Button>
            </Hidden>
          </div>
        )}
      </div>
    )
  }
  return null
}

const mapStateToProps = (state, ownProps) => {
  return {
    username: ownProps.user,
    followcreated: state.follow.created,
    following: state.follow.following,
    token: state.auth.token,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    follow: (username, token) => dispatch(createFollow(username, token)),
    hasfollow: (username, token) => dispatch(fetchFollow(username, token)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(IsFollowingOrFollow)
