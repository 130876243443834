import React, { useState, useEffect } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { loginUser } from '../../store/actions/authActions'
import { Mixpanel } from '../../Mixpanel'
import CustomizedSnackbars from '../appmessages/customized_snackbars'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { loginUserSocial } from '../../store/actions/authActions'
import { withRouter } from 'react-router-dom'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage:
      'url(https://d1sb0aptwihzht.cloudfront.net/eyJidWNrZXQiOiJzZWVuc3RhLWNvbnRlbnQiLCJrZXkiOiJmb2xpby9jN08wcUlfMEQ0NjNENTYtNjE3Qy00QTVFLThDMDUtNzMyNDdEMDcyQUYyLmpwZyIsImVkaXRzIjp7InJlc2l6ZSI6eyJ3aWR0aCI6NzAwLCJmaXQiOiJjb250YWluIn19fQ==)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

function SignIn({ authError, auth, fetching, loginUser, props, history, loginUserSocial }) {
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()

  useEffect(() => {
    if (auth) {
      props.history.push('/home')
    }
    document.tilte = 'Seensta - Sign In'
    Mixpanel.track('Login Pageview')
  }, [auth, props.history])

  const responseFacebook = (response) => {
    if (response.email) {
      const name = response.name.split(' ')

      const socialUserData = {
        firstName: name[1],
        lastName: name[0],
        email: response.email,
        access_token: response.accessToken,
        service: 'facebook',
        expires_in: response.expiresIn,
        user_id: response.id,
      }

      if (response.picture.data) {
        socialUserData.avatar = response.picture.data.url
      }

      loginUserSocial(socialUserData).then((res) => {
        if (res) {
          Mixpanel.track('Login With Facebook')
          if (res.status === 'exists') {
            history.push('/home')
          } else {
            history.push('/onboard/user')
          }
        }
      })
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const user = {
      email: email,
      password: password,
      device_name: 'web',
    }

    loginUser(user)
    Mixpanel.track('Logged In')
  }
  const classes = useStyles()

  return (
    <Box>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Box p={2}>
              <img
                alt="App Logo"
                src="/Seensta_African_logo.png"
                className="avatar"
                style={{ width: 100 }}
              />
            </Box>
            <Box style={{ marginBottom: 15 }}>
              <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
                autoLoad={false}
                fields="name,email,picture"
                redirectUri={window.location.href}
                callback={(e) => responseFacebook(e)}
                render={(renderProps) => (
                  <Button
                    variant="contained"
                    style={{ color: '#fff', background: '#3b5998' }}
                    onClick={renderProps.onClick}
                  >
                    Login with Facebook
                  </Button>
                )}
              />
              <Divider />
            </Box>
            <Typography variant="caption">Or</Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(event) => setEmail(event.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(event) => setPassword(event.target.value)}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit}
              >
                Log In
              </Button>

              {fetching ? (
                <div style={{ textAlign: 'center' }}>
                  <CircularProgress />
                </div>
              ) : null}

              <Grid container>
                <Grid item xs>
                  {authError ? (
                    <CustomizedSnackbars
                      messagetype="error"
                      message={authError}
                    ></CustomizedSnackbars>
                  ) : null}

                  <Link onClick={() => props.history.push('password/reset')}>Forgot password?</Link>
                </Grid>
                <Grid item>
                  <Link
                    style={{ padding: 20, textDecoration: 'none' }}
                    onClick={() => props.history.push('/signup')}
                  >
                    {"Don't have an account? Join"}
                  </Link>
                </Grid>
              </Grid>
              <Box mt={5}></Box>
            </form>
          </div>
        </Grid>
      </Grid>
    </Box>
  )
}
const mapStateToProps = (state, ownProps) => {
  return {
    authError: state.auth.authError,
    auth: state.auth.auth,
    fetching: state.auth.fetching,
    props: ownProps,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (credentials) => dispatch(loginUser(credentials)),
    loginUserSocial: (credentials) => dispatch(loginUserSocial(credentials)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn))
