import React, { useEffect, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import { gigGetApplication, deleteApplication } from '../../../store/actions/gigActions'
import EmptyState from '../../../components/appmessages/empty_state.'
import PageHeading from '../../../components/display/page_heading'
import CircularProgress from '@material-ui/core/CircularProgress'
import Hidden from '@material-ui/core/Hidden'
import Grid from '@material-ui/core/Grid'
import Label from '../../../components/display/label'
import Moment from 'react-moment'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    marginTop: 10,
  },
  label: {
    marginTop: 10,
    display: 'flex',
  },
  gigTitle: {
    fontSize: 20,
    letterSpacing: 0,
    color: '#504D4D',
    textTransform: 'lowercase',
    opacity: 1,
  },
  gigInfo: {
    fontSize: 16,
    letterSpacing: 0,
    color: '#504D4D',
    textTransform: 'lowercase',
    opacity: 1,
  },
}))

function MyGigApplication({ deleteApplication, gigApplication, token, auth }) {
  const [applications, setApplications] = useState([])
  const [fetching, setFetching] = useState(true)

  useEffect(() => {
    gigApplication(token)
      .then((res) => {
        setApplications([...res.data.gigs])
        setFetching(false)
      })
      .catch(() => {})
  }, [gigApplication, token])

  const withdrawApplication = (gigID, index) => {
    const data = { resourceID: gigID }
    deleteApplication(data, token)
      .then(() => {
        setApplications([...applications.splice(1, index)])
      })
      .catch(() => {})
  }
  const classes = useStyles()

  return (
    <div>
      <Hidden only={['sm', 'xs']}>
        <PageHeading title="My Gig Applications" message="Gigs I've applied for" />
      </Hidden>

      <div
        style={{
          maxWidth: 600,
          width: '100%',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {applications.length ? (
          applications.map((gig, index) => {
            return (
              <Paper className={classes.root} key={index}>
                <Grid container direction="row" alignItems="center">
                  <Grid item xs={8}>
                    <Label label="title" fontWeight={200} />
                    <Typography className={classes.gigTitle}>{gig.gig.gig_title}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    {auth && (
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => withdrawApplication(gig.gig.gig_id, index)}
                        style={{ textTransform: 'lowercase' }}
                      >
                        withdraw application
                      </Button>
                    )}
                  </Grid>
                </Grid>

                <Label label="description" fontWeight={200} />
                <Typography component="p" className={classes.gigInfo}>
                  {gig.gig.gig_description}
                </Typography>

                <Grid container direction="row" alignItems="center">
                  <Grid item xs={3}>
                    <Label label="location" fontWeight={200} />
                    <Typography component="p" className={classes.gigInfo}>
                      {gig.gig.gig_location}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Label label="gig gender" fontWeight={200} />
                    <Typography align="center" component="p" className={classes.gigInfo}>
                      {gig.gig.gig_gender}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Label label="gig age" fontWeight={200} />
                    <Typography component="p" className={classes.gigInfo}>
                      {gig.gig.gig_age}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container direction="row" alignItems="center">
                  <Grid item xs={3}>
                    <Label label="gig pay" fontWeight={200} />
                    <Typography component="p" className={classes.gigInfo}>
                      {gig.gig.gig_compensation}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Label label="start date" fontWeight={200} />
                    <Typography component="p" className={classes.gigInfo}>
                      <Moment format="D MMM YYYY">{gig.gig.gig_start_date}</Moment>
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Label label="end date" fontWeight={200} />
                    <Typography component="p" className={classes.gigInfo}>
                      <Moment format="D MMM YYYY">{gig.gig.gig_end_date}</Moment>
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            )
          })
        ) : (
          <div style={{ textAlign: 'center' }}>
            {fetching ? (
              <CircularProgress />
            ) : (
              <EmptyState
                title="My Gig Applications"
                message="You haven't applied to any Gig yet."
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
const mapStateToProps = (state, ownProps) => {
  return {
    token: state.auth.token,
    props: ownProps,
    auth: state.auth.auth,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    deleteApplication: (data, token) => dispatch(deleteApplication(data, token)),
    gigApplication: (token) => dispatch(gigGetApplication(token)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MyGigApplication)
