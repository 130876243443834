import React from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import TrashIcon from '@material-ui/icons/Delete'
import { deleteFolio } from '../../store/actions/portfolioActions'
import { Mixpanel } from '../../Mixpanel'

function TrashItem({ trash, item, token, itemkey }) {
  const handleDelete = () => {
    trash(item, itemkey, token)
    Mixpanel.track('Photo Deleted')
  }

  return (
    <div>
      <Button
        onClick={handleDelete}
        aria-label="delete"
        size="small"
        style={{ padding: '2px', marginLeft: 10, color: '#ad9e9e' }}
      >
        <TrashIcon />
      </Button>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    trash: (item, key, token) => dispatch(deleteFolio(item, key, token)),
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    item: ownProps.item,
    itemkey: ownProps.itemkey,
    token: state.auth.token,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrashItem)
