import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Country from '../onboarding/country'
import UserCategory from '../onboarding/user_category'
import AvatarPhoto from '../profile/user_avatar'
import WelcomeModalIntro from '../welcome/app_intro/welcome_modal_intro'

const SLIDERSECTIONS = ['intro', 'userType', 'profilePicture', 'country', 'finish']

function WelcomeIntro({ contestUrl }) {
  const [sliderSection, setSliderSection] = useState('intro')
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0)

  const handleContinueButton = () => {
    const currentIndex = SLIDERSECTIONS.indexOf(sliderSection)
    setCurrentSectionIndex(currentIndex + 1)
    setSliderSection(SLIDERSECTIONS[currentIndex + 1])
  }
  const handleBackButton = () => {
    if (currentSectionIndex !== 0) {
      setSliderSection(SLIDERSECTIONS[currentSectionIndex - 1])
      setCurrentSectionIndex(currentSectionIndex - 1)
    }
  }

  const renderSlider = (sliderSection) => {
    switch (sliderSection) {
      case 'intro': {
        return (
          <div>
            <WelcomeModalIntro contestUrl={contestUrl} />
          </div>
        )
      }
      case 'userType': {
        return (
          <div>
            <UserCategory showButton={false} />
          </div>
        )
      }
      case 'profilePicture': {
        return (
          <div>
            <AvatarPhoto />
          </div>
        )
      }
      case 'country': {
        return (
          <div>
            <Country showButton={false} />
          </div>
        )
      }
      case 'finish': {
        return (
          <div>
            <Typography variant="h5" align="center">
              Great! All Done
            </Typography>

            <Typography variant="subtitle1" align="center">
              {contestUrl
                ? 'You can view your contest and share to get votes.'
                : 'You can now view your profile and browse African creatives.'}
            </Typography>
          </div>
        )
      }
      default: {
        return null
      }
    }
  }

  return (
    <div>
      {' '}
      {renderSlider(sliderSection)}
      {sliderSection === 'finish' ? (
        <div style={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              contestUrl
                ? window.location.replace(`${contestUrl}`)
                : window.location.replace('/home')
            }
            size="small"
          >
            {contestUrl ? 'Share and Get Votes' : 'Enter Seensta'}
          </Button>
        </div>
      ) : (
        <div style={{ textAlign: 'center', padding: 20 }}>
          <Button variant="text" color="primary" onClick={handleBackButton} size="small">
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={handleContinueButton} size="small">
            Continue
          </Button>
        </div>
      )}
    </div>
  )
}

export default WelcomeIntro
