import React, { useEffect, useState } from 'react'
import AboutUser from '../onboarding/about_info'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Userinfo from './user_info'
import { connect } from 'react-redux'
import { Mixpanel } from '../../Mixpanel'
import UserCategory from '../onboarding/user_category'
import AvatarPhoto from '../profile/user_avatar'
import SwipeableViews from 'react-swipeable-views'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useTheme } from '@material-ui/core/styles'
import { authLogout } from '../../store/actions/authActions'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'

function TabPanel({ children }) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
}

function AccountSettings({ logout, history }) {
  const [isValue, setIsValue] = useState(0)

  const handleChange = (event, value) => {
    setIsValue(value)
  }

  const handleChangeIndex = (index) => {
    setIsValue(index)
  }

  useEffect(() => {
    Mixpanel.track('Settings Pageview')
  }, [])

  const theme = useTheme()

  return (
    <div className="desktop-width-100">
      <AppBar position="static" color="default">
        <Tabs
          value={isValue}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Basic" />
          <Tab label="Bio" />
          <Tab label="category" />
          <Tab label="avatar" />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={isValue}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={isValue} index={0}>
          <Box>
            <Userinfo />
            <Box style={{ textAlign: 'center', paddingTop: 20 }}>
              <Button
                style={{ color: '#ccc', fontSize: 12 }}
                onClick={() => {
                  localStorage.removeItem('asafo_token')
                  logout()
                  history.push('/')
                }}
              >
                Logout
              </Button>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={isValue} index={1}>
          <AboutUser />
        </TabPanel>
        <TabPanel value={isValue} index={2}>
          <UserCategory onBoarding={false} />
        </TabPanel>
        <TabPanel value={isValue} index={2}>
          <AvatarPhoto />
        </TabPanel>
      </SwipeableViews>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    token: state.auth.token,
    props: ownProps,
    about: state.auth.about,
    avatar: state.auth.avatar,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(authLogout()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings)
