import React from 'react'
import Typography from '@material-ui/core/Typography'
import PublicIcon from '@material-ui/icons/LiveHelp'

function EmptyState({ title, message }) {
  return (
    <div style={{ textAlign: 'center' }}>
      <p style={{ textAlign: 'center' }}>
        <PublicIcon style={{ fontSize: 200, color: '#cccccc' }} />
      </p>
      <Typography variant="h5" align="center">
        {title}
      </Typography>

      <Typography variant="h6" align="center" style={{ color: '#cccccc' }}>
        {message}
      </Typography>
    </div>
  )
}

export default EmptyState
