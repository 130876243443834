import React, { useEffect, useState } from 'react'
import { postMessages, getMessages } from '../../store/actions/messengerActions'
import { connect } from 'react-redux'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Mixpanel } from '../../Mixpanel'
import './messages.css'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'

function Messages({ messageId, postMessage, user, figure, messages, seam }) {
  const [message, setMessage] = useState('')

  useEffect(() => {
    getMessages(messageId)
  }, [messageId])

  const handleSubmit = () => {
    const messagePostData = {
      message: message,
      seam_request_id: messageId,
    }
    postMessage(messagePostData)
    const addedcomment = {
      message: message,
      created_at: new Date(),
      firstName: user.firstName,
      lastName: user.lastName,
      username: user.username,
      photoPath: user.avatar.photoPath,
    }

    messages.push(addedcomment)

    Mixpanel.track('Commented')
  }

  const figures = figure.map((look, index) => {
    return (
      <span key={index} style={{ display: 'inline-block' }}>
        <img
          src={'https://images.seensta.com/figure/' + look.image}
          alt={'seam figure '}
          style={{ height: 50 }}
        />
      </span>
    )
  })

  if (seam != null) {
    return (
      <div>
        <Hidden only={['lg', 'xl', 'md']}>
          <div style={{ maxWidth: 1300, width: '100%', margin: '0 auto' }}>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <div style={{ padding: 5 }}>
                  <img
                    src={'https://images.seensta.com/folio/' + seam.seam_photo}
                    alt={'seam item'}
                    style={{ display: 'block', height: 50 }}
                  />
                </div>
                <Typography variant="subheading">
                  {' '}
                  Outfit to be made <br />{' '}
                  <Typography variant="caption"> Budget: ${seam.budget}</Typography>
                </Typography>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails style={{ display: 'inherit' }}>
                <Typography variant="subheading">Size</Typography>
                <Typography variant="caption">{seam.size}</Typography>
                <Typography variant="subheading">Gender</Typography>
                <Typography variant="caption">{seam.gender}</Typography>
                <Typography variant="subheading">Figure</Typography>
                <Typography>{figures}</Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <div>
              <div style={{ maxHeight: '75%' }}>
                <div style={{ maxHeight: 371, overflow: 'scroll' }}>
                  {messages
                    ? messages.map((msg, index) => {
                        return (
                          <ListItem key={index}>
                            <ListItemAvatar>
                              <Avatar
                                alt={msg.firstName}
                                src={
                                  'https://images.seensta.com/profilepictures/thumbs/' +
                                  msg.photoPath
                                }
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={msg.firstName}
                              secondary={<React.Fragment>{msg.message}</React.Fragment>}
                            />
                          </ListItem>
                        )
                      })
                    : 'No messages yet'}
                </div>
                <div
                  style={{ position: 'absolute', width: '86%', background: 'white', bottom: 48 }}
                >
                  <Divider />

                  <TextField
                    id="outlined-full-width"
                    label="message"
                    style={{ margin: 8, color: '#000' }}
                    placeholder="Yasss here!"
                    fullWidth
                    multiline
                    margin="normal"
                    variant="outlined"
                    name="message"
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <div style={{ position: 'absolute', right: 0, marginBottom: '10px', bottom: 60 }}>
                  <Button
                    size="small"
                    onClick={handleSubmit}
                    aria-label="Back"
                    style={{ float: 'left' }}
                  >
                    Send
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Hidden>

        <Hidden only={['xs', 'sm']}>
          <div
            style={{
              maxWidth: 1000,
              width: '100%',
              margin: '0 auto',
              marginTop: 100,
              maxHeight: 500,
              height: 500,
              border: '2px solid #ccc',
            }}
          >
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <div style={{ padding: 5 }}>
                  <img
                    src={'https://images.seensta.com/folio/' + seam.seam_photo}
                    alt={'seam item'}
                    style={{ display: 'block', height: 50 }}
                  />
                </div>
                <Typography variant="subheading">
                  {' '}
                  Outfit to be made <br />{' '}
                  <Typography variant="caption"> Budget: ${seam.budget}</Typography>
                </Typography>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails style={{ display: 'inherit' }}>
                <Typography variant="subheading">Size</Typography>
                <Typography variant="caption">{seam.size}</Typography>
                <Typography variant="subheading">Gender</Typography>
                <Typography variant="caption">{seam.gender}</Typography>
                <Typography variant="subheading">Figure</Typography>
                <Typography>{figures}</Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <div>
              <div style={{ maxHeight: '75%' }}>
                <div style={{ maxHeight: 371, overflow: 'scroll' }}>
                  {messages
                    ? messages.map((msg, index) => {
                        return (
                          <ListItem key={index}>
                            <ListItemAvatar>
                              <Avatar
                                alt={msg.firstName}
                                src={
                                  'https://images.seensta.com/profilepictures/thumbs/' +
                                  msg.photoPath
                                }
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={msg.firstName}
                              secondary={<React.Fragment>{msg.message}</React.Fragment>}
                            />
                          </ListItem>
                        )
                      })
                    : 'No messages yet'}
                </div>
                <div
                  style={{
                    position: 'absolute',
                    maxWidth: 1000,
                    width: '100%',
                    background: 'white',
                    top: 500,
                  }}
                >
                  <Divider />

                  <TextField
                    id="outlined-full-width"
                    label="message"
                    style={{ margin: 8, color: '#000', maxWidth: 950, width: '100%' }}
                    placeholder="Yasss here!"
                    fullWidth
                    multiline
                    margin="normal"
                    variant="outlined"
                    name="message"
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <div
                  style={{
                    position: 'absolute',
                    maxWidth: 1000,
                    right: '20%',
                    marginBottom: '10px',
                    top: 520,
                  }}
                >
                  <Button
                    size="small"
                    onClick={handleSubmit}
                    aria-label="Back"
                    style={{ float: 'left' }}
                  >
                    Send
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Hidden>
      </div>
    )
  } else {
    return <div>Loading</div>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    messages: state.messenger.messages,
    user: state.auth.user,
    messageId: ownProps.match.params.messageid,
    seam: state.messenger.seaminfo,
    figure: state.messenger.seamfigure,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    postMessage: (messagedata) => dispatch(postMessages(messagedata)),
    getMessages: (data) => dispatch(getMessages(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Messages)
