import React, { useEffect, useState, useRef } from 'react'
import HeaderSimple from '../layout/header_simple'
import { connect } from 'react-redux'
import { fetchQuestions, createAnswers } from '../../store/actions/questionActions'
import { setSeamable } from '../../store/actions/seamitActions'
import QuestionWithOptions from './questionoptions'
import Button from '@material-ui/core/Button'
import './questions.css'
import MonthlyClotheSignup from '../welcome/MonthlyClotheSignup'
import { Mixpanel } from '../../Mixpanel'
import { createUserOnFly } from '../../store/actions/authActions'
import LinearDeterminate from './questionprogress'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import ReCAPTCHA from 'react-google-recaptcha'

function Questions({ answers, getQuestions, seamit, props, createUser, createAnswers }) {
  const [questions, setQuestions] = useState([])
  const [section, setSection] = useState([])
  const [sectionName, setSectionName] = useState('africa')
  const [currentIndex, setCurrentIndex] = useState(0)
  const [signup, setSignup] = useState(false)
  const [isProcessing, setIsProcessing] = useState(null)
  const recaptchaRef = useRef(0)

  useEffect(() => {
    Mixpanel.track('Style Guide Page')
    getQuestions('back2africa').then((response) => {
      setQuestions(response.questions)
      getSection(response.questions, sectionName)
    })
  }, [getQuestions, sectionName])

  function nextSection() {
    if (sectionName === 'intro') {
      setSignup(true)
      return true
    }
    getSection(questions, 'africa')
  }

  function sectionClothes() {
    setSignup(true)
    setSectionName('africa')
    getSection(questions, 'africa')
  }

  const getUserInformation = () => {
    const nameAnswer = answers
      .filter((q) => q.question === 'What is your name?')
      .map((question) => question.answerlabel)
      .toString()
      .split(' ')

    const emailAnswer = answers
      .filter((q) => q.question === 'What is your email?')
      .map((question) => question.answerlabel)
      .toString()

    return {
      firstname: nameAnswer[0],
      lastname: nameAnswer.slice(1, nameAnswer.length).toString(),
      email: emailAnswer,
      password: Math.random().toString(36).substr(2, 5),
    }
  }

  async function create() {
    setIsProcessing(true)

    const userData = getUserInformation()

    const token = await recaptchaRef.current.executeAsync()
    if (token) {
      userData.token = token
    }

    const createdUser = await createUser(userData)
    if (createdUser) {
      Mixpanel.track('Discover Africa Monthly Subscription')
      const answersCreate = await createAnswers({
        data: answers,
        token: createdUser.data.data.access_token,
      })
      if (answersCreate) {
        Mixpanel.track('Discover Guide Completed')
        window.fbq('track', 'CompleteRegistration')
        setIsProcessing(false)
        props.history.push('/home')
      }
    } else {
      Mixpanel.track('Error Discover Monthly ')
    }
  }

  function onFinish() {
    create()
    Mixpanel.track('Show plans')
  }

  function onContinue() {
    if (questions[currentIndex].required) {
      const answerExists = checkAnswerExists(questions[currentIndex].id)
      if (answerExists) {
        if (questions[currentIndex].question === 'Are you male or female?') {
          answers.find((ele) => {
            if (ele.answerlabel.includes('Female')) {
              const filteredQuestions = questions.filter((ele) => {
                if (ele.sex === 'female' || ele.sex === null) {
                  return ele
                }
                return ele
              })
              seamit({ gender: 'female' })
              setQuestions(filteredQuestions)
              getSection(filteredQuestions, sectionName)
            } else if (ele.answerlabel.includes('Male')) {
              const filteredQuestions = questions.filter((ele) => {
                if (ele.sex === 'male' || ele.sex === null) {
                  return ele
                }
                return ele
              })
              seamit({ gender: 'male' })
              setQuestions(filteredQuestions)
              getSection(filteredQuestions, sectionName)
            } else {
              return ele
            }

            return ele
          })
        }
        setCurrentIndex(currentIndex + 1)
      }
    } else {
      checkAnswerExists(questions[currentIndex].id)
      setCurrentIndex(currentIndex + 1)
    }
  }

  function checkAnswerExists(question_id) {
    const found = answers.find((ele) => {
      if (ele.question_id === question_id) {
        if (ele.answers.length) {
          Mixpanel.track('Guide Question', { question: ele.question, answers: ele.answerlabel })
          return true
        }
        Mixpanel.track('Guide Question', { question: ele.question, answers: ele.answerlabel })
        return false
      }
      return false
    })
    return found
  }

  function getSection(questions, sectionName) {
    let introQuestions = questions.filter((ele) => ele.flow === sectionName)
    setSection(introQuestions)
  }

  return (
    <div>
      <HeaderSimple />
      <div className="questionsContainer">
        <LinearDeterminate questionStep={currentIndex + 1} questionLength={questions.length} />
        {currentIndex > 0 ? sectionBack(currentIndex, setCurrentIndex, setSignup, signup) : null}
        <div style={{ paddingTop: 20 }}>
          {signup ? (
            <div>
              <MonthlyClotheSignup completeQuestionire={sectionClothes} basic={true} />
            </div>
          ) : (
            <div>
              {' '}
              {section.length > 0 ? (
                <div>
                  {isProcessing && (
                    <Typography align="center">
                      <CircularProgress />
                    </Typography>
                  )}
                  <QuestionWithOptions question={section[currentIndex]} />
                </div>
              ) : (
                <div style={{ textAlign: 'center' }}> Loading Discover Guide... </div>
              )}
              <div className="iteratorButton">
                {showButtons(sectionName, currentIndex, section, nextSection, onContinue, onFinish)}
              </div>
            </div>
          )}
        </div>
      </div>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LdC9DQbAAAAAPMMBeEDPvMd3NhUIiOTYFjzPH7Z"
      />
    </div>
  )
}

function sectionBack(currentIndex, setCurrentIndex, setSignup, signup) {
  return (
    <div>
      <Button
        variant="text"
        size="small"
        onClick={() => {
          if (!signup) setCurrentIndex(currentIndex - 1)
          setSignup(false)
        }}
      >
        back
      </Button>
    </div>
  )
}

function showButtons(sectionName, currentIndex, section, nextSection, onContinue, onFinish) {
  if (sectionName === 'intro') {
    return (
      <div>
        {currentIndex === section.length - 1 ? (
          <Button variant="outlined" size="large" onClick={() => nextSection()}>
            continue
          </Button>
        ) : (
          <Button variant="outlined" onClick={() => onContinue()} size="large">
            Continue
          </Button>
        )}
      </div>
    )
  }

  return (
    <div>
      {currentIndex === section.length - 1 ? (
        <Button variant="outlined" size="large" onClick={() => onFinish()}>
          Continue
        </Button>
      ) : (
        <Button variant="outlined" onClick={() => onContinue()} size="large">
          Continue
        </Button>
      )}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    questions: state.questions.questions,
    answers: state.questions.answers,
    props: ownProps,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getQuestions: (qtype) => dispatch(fetchQuestions(qtype)),
    seamit: (photo) => dispatch(setSeamable(photo)),
    createUser: (credentials) => dispatch(createUserOnFly(credentials)),
    createAnswers: (data) => dispatch(createAnswers(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Questions)
