import React, { useEffect } from 'react'
import { fetchPortfolio, fetchPortfolioMore } from '../../store/actions/portfolioActions'
import { connect } from 'react-redux'
import Divider from '@material-ui/core/Divider'
import ExpandRefresh from '@material-ui/icons/Refresh'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'
import CircularProgress from '@material-ui/core/CircularProgress'
import queryString from 'query-string'
import { Mixpanel } from '../../Mixpanel'
import './Grid.css'
import SideBar from '../layout/side_bar'
import EmptyState from '../appmessages/empty_state.'
import PageHeading from '../display/page_heading'
import ContentFilter from '../display/content_filter'
import FeaturedGig from '../../Views/gigs/featured_gig'
import FeaturedContest from '../contest/featured_contest'
import NewImageGrid from './explore/image_grid'
import UserLocation from '../feedback/user_location'

function Home({
  fetchPortfolio,
  fetchPortfolioMore,
  portfolio,
  fetching,
  featuredusers,
  token,
  user,
  auth,
  props,
  getFeaturedGig,
}) {
  useEffect(() => {
    const values = queryString.parse(props.location.search)
    const query = queryString.stringify(values)
    let page = 1
    fetchPortfolio({ page: page, token: token, term: null, resultType: 'regular', query: query })

    document.title = 'Seensta | African Models, Photographers, Creatives, Talent Seeking, Etc'
    Mixpanel.track('Home Pageview')
  }, [fetchPortfolio, props.location.search, token, getFeaturedGig])

  const handleMore = () => {
    Mixpanel.track('Home View More Clicked')
    const values = queryString.parse(props.location.search)
    const query = queryString.stringify(values)

    let numberofitems = portfolio.length
    let page = Math.round(numberofitems / 20 + 1)
    fetchPortfolioMore({ page: page, token: token, resultType: 'regular', query: query })
  }

  if (!fetching && !portfolio.length) {
    return (
      <div className="desktop-width-100">
        <div className="desktop-grid-container">
          <div className="desktop-grid-containerflex">
            <Hidden only={['xs', 'sm']}>
              <SideBar featuredusers={featuredusers} heading="Designers" />
            </Hidden>
          </div>
          <div className="desktop-grid-container-card">
            <ContentFilter history={props.history} page={true} />
            <EmptyState message="Looks like we've ran out of content" title={`Home Content`} />
          </div>
        </div>
      </div>
    )
  } else if (portfolio.length) {
    return (
      <div>
        <Divider />
        <div className="desktop-width-100">
          <div>
            <Hidden only={['xs', 'sm']}>
              <div className="desktop-grid-container">
                <div className="desktop-grid-containerflex">
                  <SideBar featuredusers={featuredusers} heading="Designers" />
                </div>
                <div className="desktop-grid-container-card">
                  <PageHeading title="Frontpage Creatives" message="featured members " />

                  <ContentFilter history={props.history} page={true} />

                  {portfolio &&
                    portfolio.map((card, index) => {
                      return (
                        <div key={index} className={'card-grid-container'}>
                          <NewImageGrid auth={auth} nowuser={user.username} content={card} />
                        </div>
                      )
                    })}
                </div>
              </div>
              <div style={{ textAlign: 'center' }}>
                {fetching ? (
                  <CircularProgress />
                ) : (
                  <Button
                    variant="contained"
                    color="default"
                    onClick={handleMore}
                    startIcon={<ExpandRefresh />}
                    style={{ boxShadow: 'none', textTransform: 'lowercase', padding: 10 }}
                  >
                    Load More
                  </Button>
                )}
              </div>
            </Hidden>
            <Hidden only={['lg', 'xl', 'md']}>
              <UserLocation />
              <FeaturedGig history={props.history} />
              <FeaturedContest history={props.history} />
              <div style={{ marginTop: 10 }}>
                {portfolio &&
                  portfolio.map((card, index) => {
                    return (
                      <div key={index} className={'card-grid-container-explore'}>
                        <NewImageGrid auth={auth} nowuser={user.username} content={card} />
                      </div>
                    )
                  })}
              </div>

              <div style={{ textAlign: 'center', marginTop: 20 }}>
                {fetching ? (
                  <CircularProgress />
                ) : (
                  <Button
                    variant="contained"
                    color="default"
                    onClick={handleMore}
                    startIcon={<ExpandRefresh />}
                    style={{ boxShadow: 'none', textTransform: 'lowercase', padding: 10 }}
                  >
                    Load More
                  </Button>
                )}
              </div>
            </Hidden>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="desktop-width-100">
      <div
        style={{
          paddingTop: '100px',
          paddingBottom: '100px',
          textAlign: 'center',
        }}
      >
        <CircularProgress />
      </div>
    </div>
  )
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchPortfolio: (queryData) => {
      dispatch(fetchPortfolio(queryData))
    },
    fetchPortfolioMore: (queryData) => {
      dispatch(fetchPortfolioMore(queryData))
    },
  }
}

const mapStateToProps = (state, ownProps) => ({
  portfolio: state.portfolio.folio,
  auth: state.auth.auth,
  fetching: state.portfolio.fetching,
  user: state.auth.user,
  featuredusers: state.portfolio.featuredusers,
  token: state.auth.token,
  isSubscribed: state.auth.isSubscribed,
  props: ownProps,
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
