import { CREATE_FOLLOW, GET_FOLLOW, FOLLOW_ERROR, REQUEST_FOLLOWING } from './types'

import Api from '../../Api'

const basedev = process.env.REACT_APP_API_URL
const headers = {}
//create votes
export const createFollow = (data, token) => (dispatch) => {
  const endpoint = basedev + 'follow'
  let postdata = {
    username: data,
  }
  headers.Authorization = `Bearer ${token}`

  Api.post(endpoint, postdata, { headers: headers })
    .then((res) => {
      dispatch({
        type: CREATE_FOLLOW,
        payload: res.data,
      })
    })
    .catch(() => {
      dispatch({
        type: FOLLOW_ERROR,
        payload: null,
      })
    })
}

//get votes
export const fetchFollow = (username, token) => (dispatch) => {
  headers.Authorization = `Bearer ${token}`

  const endpoint = basedev + 'follow/' + username
  dispatch({
    type: REQUEST_FOLLOWING,
    payload: null,
  })
  Api.get(endpoint, { headers: headers })
    .then((res) => {
      dispatch({
        type: GET_FOLLOW,
        payload: res.data,
      })
    })
    .catch({})
}
