import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import { withRouter } from 'react-router-dom'
import { awsImage } from '../../Helpers'

function FindFollowuser({ handleclose, history, user }) {
  const handleusers = () => {
    handleclose()
    history.push(`/${user.username}`)
  }

  let imageAws = '/female_avatar.png'
  if (user.photoPath) {
    imageAws = user.photoPath.includes('http')
      ? user.photoPath
      : user.photoPath
      ? awsImage('profilepictures', user.photoPath, 50, 'contain')
      : '/female_avatar.png'
  }

  return (
    <span
      onClick={handleusers}
      style={{ textDecoration: 'none', padding: 0, display: 'inherit', cursor: 'pointer' }}
    >
      <ListItemAvatar>
        <Avatar alt={user.firstName} src={imageAws} style={{ width: 25, height: 25 }} />
      </ListItemAvatar>
      <ListItemText
        style={{ color: '#52183E', fontSize: 14, fontWeight: 600 }}
        primary={user.firstName + ' ' + user.lastName}
      />
    </span>
  )
}

export default withRouter(FindFollowuser)
