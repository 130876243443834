import React, { useEffect, useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Send from '@material-ui/icons/Send'
import LazyLoad from 'react-lazyload'
import { connect } from 'react-redux'
import { awsImage } from '../../../Helpers'
import { Mixpanel } from '../../../Mixpanel'
import { createComment, fetchComments, deleteComments } from '../../../store/actions/commentAction'
import { fetchPortfolioSingle } from '../../../store/actions/portfolioActions'
import UpDownVote from '../../vote/up_down_vote'
import PhotoComments from './photo_comments'
import UserHeader from './user_header'
import Button from '@material-ui/core/Button'
import AudioFeedback from '../../feedback/audio_feedback'
import { Box, Grid, makeStyles } from '@material-ui/core'
import IsFollowingOrFollow from '../../profile/components/is_following_or_follow'
import ChallengeDialog from '../../portfolio/challenge'

const useStyle = makeStyles(() => ({
  avatar: {
    backgroundColor: '#52183E',
    width: 42,
    height: 42,
    marginRight: -13,
  },
  avatarTitle: {
    color: '#52183E',
    textDecoration: 'none',
    fontSize: 14,
  },
}))

function SingleImage({
  fetchComments,
  match,
  createComment,
  photoid,
  token,
  avatar,
  allComments,
  location,
  auth,
  user,
  deleteComments,
  fetchSingle,
  current,
}) {
  const [comment, setComment] = useState('')
  const [currentComments, setCurrentComments] = useState(allComments)
  const [imageAws, setImageAws] = useState('')
  const [singlePhotoData, setSinglePhotoData] = useState([])
  const [isCreatingComment, setIsCreatingComment] = useState(false)
  const classes = useStyle()
  useEffect(() => {
    const handleFetchSinglePhoto = async () => {
      const responseData = await fetchSingle({ id: match.params.photo_id, token: token })
      setSinglePhotoData(responseData.data.results[0])
      setImageAws(awsImage('folio', responseData.data.results[0].imagePath, 700, 'contain'))
    }

    if (!location.state) {
      handleFetchSinglePhoto()
    }
  }, [fetchSingle, location.state, match.params.photo_id, token])

  useEffect(() => {
    fetchComments({ id: photoid, token: token })
    document.title = 'Seensta | Photo View'
  }, [fetchComments, photoid, token])

  useEffect(() => {
    setCurrentComments(allComments)
  }, [allComments])

  useEffect(() => {
    document.title = `Seensta  ${
      location.state ? location.state.firstname : singlePhotoData.firstname
    }  ${location.state ? location.state.lastname : singlePhotoData.firstname}  Photo`
    const awsImageFetch = awsImage(
      'folio',
      location.state ? location.state.photo : singlePhotoData.imagePath,
      700,
      'contain'
    )
    setImageAws(awsImageFetch)
  }, [location, singlePhotoData.firstname, singlePhotoData.imagePath])

  const handleSubmit = async () => {
    if (!comment) {
      return
    }

    setIsCreatingComment(true)
    const data = {
      comment: comment,
      what_id: match.params.photo_id,
    }

    const createdMessage = await createComment({ data: data, token: token })

    if (createdMessage.status === 202) {
      const addedComment = {
        message: comment,
        created_at: new Date(),
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.username,
        knownas: '',
        photoPath: avatar ? avatar.photoPath : null,
        message_id: createdMessage.data.message_id,
      }

      setCurrentComments([...currentComments, addedComment])
      setComment('')
      setIsCreatingComment(false)
      Mixpanel.track('Commented')
    }
  }

  const handleAudioComment = async (fileName) => {
    if (!fileName) {
      return
    }

    setIsCreatingComment(true)
    const data = {
      comment: fileName,
      what_id: match.params.photo_id,
    }

    const createdMessage = await createComment({ data: data, token: token })

    if (createdMessage.status === 202) {
      Mixpanel.track('Audio Comment')

      const addedComment = {
        message: fileName,
        created_at: new Date(),
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.username,
        knownas: '',
        photoPath: avatar ? avatar.photoPath : null,
        message_id: createdMessage.data.message_id,
      }

      setCurrentComments([...currentComments, addedComment])
      setComment('')
      setIsCreatingComment(false)
      Mixpanel.track('Audio Feedback Comment')
    }
  }

  const handleDeleteCommnet = async (id) => {
    const deletedResponse = await deleteComments({ id: id, token: token })

    if (deletedResponse.status === 200) {
      const filteredComments = currentComments.filter((comment) => comment.message_id !== id)
      setCurrentComments(filteredComments)
    }
  }

  if (!imageAws) {
    return (
      <div style={{ textAlign: 'center' }}>
        <CircularProgress />
      </div>
    )
  }
  return (
    <div style={{ paddingTop: 10 }}>
      <Hidden only={['lg', 'xl']}>
        <div style={{ marginTop: 10, color: '#000', padding: 20 }}>
          <Card style={{ boxShadow: 'none', padding: 10, border: '1px solid #F0EFEF' }}>
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={6}>
                <Grid container justifyContent="center" spacing={1}>
                  <UserHeader
                    photoPath={
                      location.state ? location.state.photoPath : singlePhotoData.photoPath
                    }
                    userName={location.state ? location.state.username : singlePhotoData.username}
                    firstName={
                      location.state ? location.state.firstname : singlePhotoData.firstname
                    }
                    lastName={location.state ? location.state.lastname : singlePhotoData.lastname}
                    classes={classes}
                    fullwidth={false}
                  >
                    <span
                      style={{
                        color: '#504D4D',
                        textTransform: 'lowercase',
                        textAlign: 'left',
                        fontSize: 12,
                      }}
                    >
                      {location.state.usertype}
                    </span>
                  </UserHeader>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="center"
                  spacing={1}
                  direction="column"
                  alignItems="flex-end"
                >
                  <IsFollowingOrFollow
                    user={location.state ? location.state.username : singlePhotoData.username}
                    current={current}
                  />
                </Grid>
              </Grid>
            </Grid>

            <LazyLoad offset={100} height="100%" once={true}>
              <img
                src={imageAws}
                width="100%"
                alt="single seensta "
                style={{ borderRadius: 6, marginTop: 20 }}
              />
            </LazyLoad>
            <CardContent style={{ padding: 0 }}>
              {auth ? (
                <CardActions disableSpacing style={{ padding: 0 }}>
                  <Box
                    display="flex"
                    justifyContent="space-evenly"
                    style={{ paddingTop: 20, width: '100%' }}
                  >
                    <UpDownVote
                      photoid={match.params.photo_id}
                      upvotes={
                        location.state ? location.state.totalvotes : singlePhotoData.totalvotes
                      }
                    />
                    <AudioFeedback handleAudioComment={handleAudioComment} />
                    <ChallengeDialog
                      photo={location.state ? location.state.photo : singlePhotoData.imagePath}
                    />
                  </Box>
                </CardActions>
              ) : (
                <UpDownVote
                  photoid={location.state ? location.state.id : match.params.photo_id}
                  upvotes={location.state ? location.state.totalvotes : singlePhotoData.totalvotes}
                />
              )}

              <div style={{ color: '#000', padding: 10 }}>
                <span style={{ color: '#504D4D', fontSize: 14, padding: 10 }}>comments</span>
                {!currentComments.length && (
                  <span
                    style={{ display: 'block', color: '#504D4D', fontSize: 12, paddingLeft: 10 }}
                  >
                    no comments made
                  </span>
                )}
                <PhotoComments
                  allComments={currentComments}
                  user={user}
                  handleDeleteCommnet={handleDeleteCommnet}
                />

                <div style={{ color: '#000' }}>
                  <Divider />

                  <div style={{ width: '100%', color: 'black' }}>
                    <UserHeader
                      photoPath={current.avatar && current.avatar.photoPath}
                      userName={current.user && current.user.username}
                      firstName={current.user && current.user.firstName}
                      lastName={current.user && current.user.lastName}
                      fullwidth={false}
                    ></UserHeader>
                    <TextField
                      id="outlined-full-width"
                      label="comment"
                      placeholder="add you comment"
                      fullWidth
                      multiline
                      margin="normal"
                      variant="outlined"
                      value={comment}
                      name="comment"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </div>
                  <div
                    style={{
                      right: 0,
                      position: 'relative',
                      display: 'inline-flex',
                      color: 'black',
                      paddingBottom: 100,
                    }}
                  >
                    <Button
                      endIcon={<Send> Send </Send>}
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      disabled={isCreatingComment}
                    >
                      Send
                    </Button>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </Hidden>

      <Hidden only={['xs', 'sm']}>
        <div
          style={{
            color: '#000',
            maxWidth: 800,
            margin: '0 auto',
            marginTop: 80,
          }}
        >
          <Card style={{ boxShadow: 'none' }}>
            <UserHeader
              photoPath={location.state ? location.state.photoPath : singlePhotoData.photoPath}
              userName={location.state ? location.state.username : singlePhotoData.username}
              firstName={location.state ? location.state.firstname : singlePhotoData.firstname}
              lastName={location.state ? location.state.lastname : singlePhotoData.lastname}
              classes={classes}
            />
            <LazyLoad offset={100} height="100%" once={true}>
              <img src={imageAws} width="100%" alt="single seensta " style={{ borderRadius: 6 }} />
            </LazyLoad>
            <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
              {auth ? (
                <CardActions disableSpacing style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <UpDownVote
                    photoid={match.params.photo_id}
                    upvotes={
                      location.state ? location.state.totalvotes : singlePhotoData.totalvotes
                    }
                  />{' '}
                </CardActions>
              ) : (
                <UpDownVote
                  photoid={location.state ? location.state.id : match.params.photo_id}
                  upvotes={location.state ? location.state.totalvotes : singlePhotoData.totalvotes}
                />
              )}

              <div style={{ color: '#000', padding: 10 }}>
                <PhotoComments
                  allComments={allComments}
                  user={user}
                  handleDeleteCommnet={handleDeleteCommnet}
                />

                <div style={{ color: '#000' }}>
                  <Divider />

                  <div style={{ width: '70%', color: 'black' }}>
                    <TextField
                      id="outlined-full-width"
                      label="comment"
                      placeholder="add you comment"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      name="comment"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ margin: 8 }}
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </div>
                  <div
                    style={{
                      right: 0,
                      marginBottom: '10px',
                      float: 'right',
                      marginTop: '-53px',
                      position: 'relative',
                      display: 'inline-flex',
                      color: 'black',
                      paddingBottom: 100,
                    }}
                  >
                    <IconButton onClick={handleSubmit} aria-label="Back" style={{ float: 'left' }}>
                      <Send />
                    </IconButton>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </Hidden>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    item: state.portfolio.singleitem,
    photoid: ownProps.match.params.photo_id,
    allComments: state.comment.comments,
    user: state.auth.user,
    fetching: state.comment.fetching,
    auth: state.auth.auth,
    token: state.auth.token,
    avatar: state.auth.avatar,
    current: state.auth,
  }
}

const mapDispatchTopProps = (dispatch) => {
  return {
    fetchSingle: (queryData) => dispatch(fetchPortfolioSingle(queryData)),
    createComment: (commentData) => dispatch(createComment(commentData)),
    fetchComments: (commentData) => dispatch(fetchComments(commentData)),
    deleteComments: (commentData) => dispatch(deleteComments(commentData)),
  }
}

export default connect(mapStateToProps, mapDispatchTopProps)(SingleImage)
