import { ANSWER_OPTIONS } from './types'
import Api from '../../Api'

const basedev = process.env.REACT_APP_API_URL
//const basedev = 'http://nsiza.api.test/api/'

export const fetchQuestions = (qtype = null) => async () => {
  var endpoint
  endpoint = basedev + 'questions/' + qtype
  const res = await Api.get(endpoint)
  return res.data
}

export const createAnswers = (queryData) => async () => {
  var endpoint = basedev + 'question/answer'
  const headers = {
    Authorization: 'Bearer ' + queryData.token,
  }
  const res = await Api.post(endpoint, queryData.data, { headers: headers })
  return res
}

export const addAnswer = (data) => (dispatch) => {
  dispatch({
    type: ANSWER_OPTIONS,
    payload: data,
  })
}
