import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import './Welcome.css'
import { Mixpanel } from '../../Mixpanel'
import HowItWorks from './sections/sections_aa_connect/how_it_works'
import Footer from './sections/footer_section'
import { Redirect } from 'react-router-dom'
import Hidden from '@material-ui/core/Hidden'
import Tellstory from './sections/sections_aa_connect/tell_story'
import JoinSection from './sections/sections_aa_connect/join_section'
import Grid from '@material-ui/core/Grid'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

const styles = (theme) => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  background: 'inherit',
  color: 'black',
})

function WelcomeContestCreators({ auth, history }) {
  useEffect(() => {
    document.title = 'Seensta | African creatives network'
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.src =
        'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v5.0&appId=795460493801677&autoLogAppEvents=1'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
    Mixpanel.track('Welcome Pageview')
  }, [])

  const handleLogo = () => {
    window.location.href = '/'
  }

  if (auth) {
    return <Redirect to="home" />
  }

  return (
    <div>
      <AppBar position="static" style={{ background: '#fff', boxShadow: 'none' }}>
        <Toolbar className="welcome-toolbar">
          <img
            alt="App Logo"
            src="/Seensta_African_logo.png"
            className="welcome-logo-width"
            onClick={handleLogo}
          />

          <div className="signup-button-group">
            <Button
              variant="text"
              size="small"
              color="secondary"
              className="signup-button-group-color"
              component="a"
              onClick={() => history.push('/signup')}
            >
              Join
            </Button>

            <Button
              variant="text"
              size="small"
              color="secondary"
              className="signup-button-group-color"
              component="a"
              onClick={() => history.push('/signin')}
            >
              Login
            </Button>
          </div>
        </Toolbar>
      </AppBar>

      <CssBaseline />
      <Hidden only={['sm', 'xs']}>
        <div className="white-background">
          <div className="welcome-hero-container">
            <Grid container spacing={0} style={{ height: 700 }}>
              <Grid item xs={12} sm={6} style={{ padding: '20px' }}>
                <div className="welcome-hero">
                  <Typography
                    variant="h4"
                    style={{ textAlign: 'center' }}
                    gutterBottom={true}
                    className="welcome-hero-typography"
                  >
                    Reach new customers through contest marketing.
                  </Typography>
                  <div
                    style={{
                      display: 'block',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <Button
                      size="large"
                      color="primary"
                      variant="contained"
                      href="/connect/guide"
                      style={{ color: 'white !important' }}
                    >
                      Post a Contest
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
                <img src="../discover_africa.png" alt="bg" className="welcome-phone-width" />
              </Grid>
            </Grid>
          </div>
        </div>
      </Hidden>
      <Hidden only={['xl', 'lg']}>
        <div className="white-background">
          <div className="welcome-hero-container">
            <Grid container spacing={0} style={{ height: 630 }}>
              <Grid item xs={12} sm={6} style={{ padding: '20px' }}>
                <div style={{ top: '30%', maxWidth: 600 }}>
                  <Typography
                    variant="h4"
                    gutterBottom={true}
                    className="welcome-hero-typography"
                    style={{ textAlign: 'center' }}
                  >
                    Reach new customers through contest marketing.
                  </Typography>

                  <div
                    style={{
                      display: 'block',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <Button
                      size="large"
                      color="primary"
                      variant="contained"
                      onClick={() => history.push('/connect/guide')}
                      style={{ color: 'white !important' }}
                    >
                      Post a Contest
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
                <img src="../discover_africa.png" alt="bg" className="welcome-phone-width" />
              </Grid>
            </Grid>
          </div>
        </div>
      </Hidden>

      <HowItWorks history={history} />
      <Tellstory />

      <JoinSection />

      <Footer />
    </div>
  )
}

const mapStateToProps = (state) => ({
  portfolio: state.portfolio.items,
  auth: state.auth.auth,
  fetching: state.portfolio.fetching,
})

export default connect(mapStateToProps)(withStyles(styles)(WelcomeContestCreators))
