import React from 'react'

function DownloadApp() {
  const playstore = () => {
    window.location.href = 'https://play.google.com/store/apps/details?id=ananse.seensta'
  }

  const appstore = () => {
    window.location.href = 'https://apps.apple.com/us/app/seensta/id1476448223?ls=1'
  }

  return (
    <div>
      <img
        src="../..//Get_it_on_Google_play.svg"
        alt="bg"
        className="welcome-appstore-image"
        onClick={playstore}
      />
      <img
        src="../../Download_on_the_App_Store_Badge.svg"
        alt="bg"
        className="welcome-appstore-image"
        onClick={appstore}
      />
      <div style={{ display: 'block', color: '#000' }}>Or</div>
      <div
        style={{
          display: 'block',
          width: '100%',
          alignItems: 'center',
        }}
      ></div>
    </div>
  )
}

export default DownloadApp
