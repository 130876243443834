import {
  CREATE_CONTEST_ENTRY,
  CREATE_ENTRY_VOTE,
  CONTEST_VOTE_ERROR,
  FETCH_CONTEST,
  ENTER_CONTEST,
  FETCH_CONTEST_MORE,
  FETCH_CONTEST_REQUEST,
  FETCH_CONTEST_ENTRY,
} from '../actions/types'

const initialState = {
  items: [],
  contest: null,
  contestAdd: '',
  fetching: false,
  entry: [],
  newentry: [],
  voted: false,
  votedError: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CONTEST:
      return {
        ...state,
        items: action.payload.contest,
        fetching: false,
      }
    case ENTER_CONTEST:
      return {
        ...state,
        contest: action.payload.contest,
        fetching: false,
      }

    case FETCH_CONTEST_MORE:
      return {
        ...state,
        // items:[...state.items,...action.payload.results],

        fetching: false,
      }
    case FETCH_CONTEST_ENTRY:
      return {
        ...state,
        entry: action.payload,
        // items:[...state.items,...action.payload.results],

        fetching: false,
      }
    case FETCH_CONTEST_REQUEST:
      return {
        ...state,
        fetching: true,
      }
    case CREATE_ENTRY_VOTE:
      return {
        ...state,
        fetching: true,
        voted: true,
      }
    case CONTEST_VOTE_ERROR:
      return {
        ...state,
        fetching: false,
        voted: false,
      }
    case CREATE_CONTEST_ENTRY:
      return {
        ...state,
        newentry: action.payload.contest_data,
        fetching: false,
      }
    default:
      return state
  }
}
