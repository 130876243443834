import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fetchContest } from '../../store/actions/contestActions'
import { Mixpanel } from '../../Mixpanel'
import ContestCard from './contest_card'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    margin: 'auto',
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxHeight: '50px',
    padding: 5,
  },
}))

function ContestSingleView({ contest, auth, fetchContest, match, history }) {
  const [singleContest, setSingleContest] = useState(contest)
  const classes = useStyle()

  useEffect(() => {
    fetchContest(match.params.contestId)
  }, [fetchContest, match.params.contestId])

  useEffect(() => {
    setSingleContest(contest)
  }, [contest])

  if (singleContest) {
    Mixpanel.track('Single Contest View', { singleContest })
    document.title = singleContest.title
    return (
      <ContestCard
        featured={false}
        history={history}
        contest={singleContest}
        auth={auth}
        classes={classes}
      />
    )
  }

  return null
}

const mapStateToProps = (state) => {
  return {
    contest: state.contest.contest,
    fetching: state.contest.fetching,
    auth: state.auth.auth,
  }
}

const mapDispatchToState = (dispatch) => {
  return {
    fetchContest: (fetchtype) => dispatch(fetchContest(fetchtype)),
  }
}
export default connect(mapStateToProps, mapDispatchToState)(ContestSingleView)
