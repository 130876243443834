import React, { useEffect, useState } from 'react'
import { fetchPortfolio, fetchPortfolioMore } from '../../store/actions/portfolioActions'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { Mixpanel } from '../../Mixpanel'
import './Grid.css'
import Hidden from '@material-ui/core/Hidden'
import ExpandRefresh from '@material-ui/icons/Refresh'
import SideBar from '../layout/side_bar'
import Divider from '@material-ui/core/Divider'
import EmptyState from '../appmessages/empty_state.'
import PageHeading from '../display/page_heading'
import queryString from 'query-string'
import ContentFilter from '../display/content_filter'
import { withRouter } from 'react-router-dom'
import ExploreSearch from '../layout/explore_search'
import NewImageGrid from './explore/image_grid'

function UserCategoryBrowser({
  fetchPortfolio,
  fetchPortfolioMore,
  portfolio,
  token,
  usertype,
  auth,
  fetching,
  featuredusers,
  props,
  user,
}) {
  const [searchQuery, setSearchQuery] = useState()
  useEffect(() => {
    const values = queryString.parse(props.location.search + '&platform=web')
    const query = queryString.stringify(values)
    let page = 1
    fetchPortfolio({
      page: page,
      toke: token,
      resultType: 'usertype',
      term: props.match.params.usertype,
      query: query,
    })
    setSearchQuery(query)
    document.title = 'Seensta | Browse ' + props.match.params.usertype
    Mixpanel.track('Browse ' + props.match.params.usertype + ' Pageview')
  }, [props.match.params.usertype, props.location.search])

  const handleMore = () => {
    //Calculate Page
    var numberofitems = portfolio.length
    var page = Math.round(numberofitems / 20 + 1)
    if (usertype !== undefined) {
      fetchPortfolioMore({
        page: page,
        token: token,
        resultType: 'usertype',
        term: usertype,
        query: searchQuery,
      })
    }

    Mixpanel.track('Usertype View More Clicked')
  }

  if (!fetching && !portfolio.length) {
    return (
      <div className="desktop-width-100">
        <div>
          <div className="desktop-grid-containerflex">
            <Hidden only={['xs', 'sm']}>
              <SideBar featuredusers={featuredusers} heading="Designers" />
            </Hidden>
          </div>
          <div className="desktop-grid-container-card">
            <EmptyState
              message="Looks like we've ran out of content"
              title={`${usertype} Content`}
            />
          </div>
        </div>
      </div>
    )
  } else if (portfolio.length) {
    return (
      <div>
        <Divider />
        <div className="desktop-width-100">
          <div>
            <Hidden only={['xs', 'sm']}>
              <div className="desktop-grid-container">
                <div className="desktop-grid-containerflex">
                  <SideBar featuredusers={featuredusers} heading="Designers" />
                </div>
                <div className="desktop-grid-container-card">
                  <PageHeading title={`${usertype} Creatives`} message="browse" />
                  <ContentFilter history={props.history} page={true} />
                  {portfolio &&
                    portfolio.map((card, index) => {
                      const content = { ...card.portfolio[0] }
                      content.user = {
                        firstName: card.firstName,
                        lastName: card.lastName,
                        username: card.username,
                        about: card.about,
                        avatar: card.avatar,
                      }
                      return (
                        <div key={index} className={'card-grid-container'}>
                          <NewImageGrid auth={auth} nowuser={user.username} content={content} />
                        </div>
                      )
                    })}
                </div>
              </div>
              <div style={{ textAlign: 'center' }}>
                {fetching ? (
                  <CircularProgress />
                ) : (
                  <Button
                    variant="contained"
                    color="default"
                    onClick={handleMore}
                    startIcon={<ExpandRefresh />}
                    style={{ boxShadow: 'none', textTransform: 'lowercase', padding: 10 }}
                  >
                    Load More
                  </Button>
                )}
              </div>
            </Hidden>
            <Hidden only={['lg', 'xl', 'md']}>
              <div style={{ marginTop: 20 }}>
                <PageHeading title="explore" />

                <ExploreSearch showPanel={true} history={props.history} />
                <Divider />
                {portfolio &&
                  portfolio.map((card, index) => {
                    const content = { ...card.portfolio[0] }
                    content.user = {
                      firstName: card.firstName,
                      lastName: card.lastName,
                      username: card.username,
                      about: card.about,
                      avatar: card.avatar,
                    }
                    return (
                      <div key={index} className="card-grid-container-explore">
                        <NewImageGrid auth={auth} nowuser={user.username} content={content} />
                      </div>
                    )
                  })}
              </div>

              <div style={{ textAlign: 'center' }}>
                {fetching ? (
                  <CircularProgress />
                ) : (
                  <div style={{ padding: 20 }}>
                    <Button
                      variant="contained"
                      color="default"
                      onClick={handleMore}
                      startIcon={<ExpandMore />}
                      style={{ boxShadow: 'none', textTransform: 'lowercase', padding: 10 }}
                    >
                      Load More
                    </Button>
                  </div>
                )}
              </div>
            </Hidden>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="desktop-width-100">
      <div className="desktop-grid-container-card">
        <div
          style={{
            paddingTop: '100px',
            paddingBottom: '100px',
            textAlign: 'center',
          }}
        >
          <CircularProgress />
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPortfolio: (queryData) => {
      dispatch(fetchPortfolio(queryData))
    },
    fetchPortfolioMore: (queryData) => {
      dispatch(fetchPortfolioMore(queryData))
    },
  }
}

const mapStateToProps = (state, ownProps) => ({
  portfolio: state.portfolio.usertype,
  usertype: ownProps.match.params.usertype,
  cat: ownProps.match.params.cat,
  type: ownProps.match.params.type,
  username: ownProps.match.params.username,
  auth: state.auth.auth,
  tags: ownProps.match.params.tag,
  fetching: state.portfolio.fetching,
  trend: ownProps.match.params.trend,
  user: state.auth.user,
  featuredusers: state.portfolio.featuredusers,
  token: state.auth.token,
  props: ownProps,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserCategoryBrowser))
