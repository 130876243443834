import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Box from '@material-ui/core/Box/Box'

const useStyles = makeStyles(() => ({
  label: {
    marginTop: 10,
    display: 'flex',
    color: '#76235a',
    padding: 5,
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: 14,
    width: 130,
    textDecoration: 'underline',
    textDecorationStyle: 'double',
  },
}))

function FeaturedLabel(props) {
  const classes = useStyles(props)
  return (
    <Box className={classes.label} style={{ width: props.width }}>
      {props.text}
    </Box>
  )
}

export default FeaturedLabel
