import React, { useState } from 'react'
import { connect } from 'react-redux'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { resendVerificationCode } from '../../store/actions/authActions'
import Alert from '@material-ui/lab/Alert'

function VerificationBanner({ confirmed, token, resendVerificationCode, auth }) {
  const [didResend, setDidResend] = useState(false)

  const handleResendVerificationCode = async () => {
    const resendResponse = await resendVerificationCode(token)
    if (resendResponse.status === 200) {
      setDidResend(true)
      return
    }

    setDidResend(false)
  }
  if (confirmed || !auth) return null

  return (
    <Box style={{ marginTop: 50 }}>
      <Alert severity="error" style={{ textAlign: 'center', padding: 0 }}>
        {didResend ? (
          'Resent! Please check your email to verify your account'
        ) : (
          <div>
            Please check your email to verify your account.
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={handleResendVerificationCode}
            >
              Resend Verification Code
            </Button>
          </div>
        )}
      </Alert>
    </Box>
  )
}
const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    auth: state.auth.auth,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resendVerificationCode: (token) => dispatch(resendVerificationCode(token)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerificationBanner)
