import React, { useState } from 'react'
import { injectStripe } from 'react-stripe-elements'
import CardSection from './CardSection'
import './card.css'
import { setCardToken } from '../../store/actions/seamitActions'
import { createSubscription } from '../../store/actions/monthlySubscription'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Mixpanel } from '../../Mixpanel'

function CheckoutForm({ stripe, seamdata, createSubscription, callbackPaid, setToken, apiToken }) {
  const [fetching, setFetching] = useState(false)

  const handleSubmit = (ev) => {
    ev.preventDefault()
    stripe
      .createToken({})
      .then(({ token }) => {
        setToken(token.id)
        setFetching(true)
        const stripeData = {
          token: token.id,
          plan: seamdata.plan,
        }
        createSubscription(stripeData, apiToken)
          .then(() => {
            Mixpanel.track('Subscription Complete')
            window.fbq('track', 'AddPaymentInfo')
            callbackPaid()
          })
          .catch(() => {
            Mixpanel.track('Subscription Failed')
            setFetching(false)
          })
      })
      .catch(() => {
        setFetching(false)
        Mixpanel.track('Subscription Invalid Card')
      })
  }

  return (
    <div>
      <form onSubmit={handleSubmit} style={{ width: 320, background: 'inherit' }}>
        {fetching ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        ) : null}
        <CardSection />
        <button className="submit-button">Submit </button>
      </form>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    seamdata: state.seam,
    apiToken: state.auth.token,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setToken: (token) => dispatch(setCardToken(token)),
    createSubscription: (data, token) => dispatch(createSubscription(data, token)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectStripe(CheckoutForm)))
