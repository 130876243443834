import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

function Terms({ history }) {
  useEffect(() => {
    document.title = 'Seensta | Terms of Use'
  }, [])

  return (
    <div style={{ color: '#000', maxWidth: 800, margin: '0 auto' }}>
      <div style={{ textAlign: 'center', padding: 10 }}>
        <img
          alt="App Logo"
          src="/Seensta_African_logo.png"
          style={{ width: '80px' }}
          onClick={() => history.push('/home')}
        />
      </div>
      <h5>Date of Last Revision: April 28, 2019</h5>
      <br />
      <br />
      <p>
        Seensta ("Seensta," "we," "us," and/or "our") provides its services (described below) to you
        through its website and or App ( SPA ) and other software made available by Seensta and its
        website located at https://www.seensta.com (collectively, the "Service(s)"), subject to the
        following Terms of Service (as amended from time to time, these "Terms"). We reserve the
        right, at our sole discretion, to change or modify portions of these Terms at any time
        without further notice. You should periodically visit this page to review the current Terms
        so you are aware of any revision to which you are bound. If we do this, we will post the
        changes to these Terms on this page and will indicate at the top of this page the date these
        terms were last revised. We may also notify you, either through the Services user interface,
        in an email notification or through other reasonable means. Any such changes will become
        effective for existing users no earlier than ten (10) days after they are posted, except
        that changes addressing new functions of the Services or changes made for legal reasons will
        be effective immediately. Your continued use of the Service after the date any such changes
        become effective constitutes your acceptance of the new Terms. If you do not agree to abide
        by these or any future Terms, do not use or access (or continue to use or access) the
        Service.
        <br />
        <br />
      </p>
      <h5>
        PLEASE READ THESE TERMS CAREFULLY AS THEY CONTAIN AN AGREEMENT TO ARBITRATE AND OTHER
        IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS. THE AGREEMENT
        TO ARBITRATE REQUIRES (WITH LIMITED EXCEPTION) THAT YOU SUBMIT CLAIMS YOU HAVE AGAINST US TO
        BINDING AND FINAL ARBITRATION, AND FURTHER (1) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS
        AGAINST COMPANY ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR
        REPRESENTATIVE ACTION OR PROCEEDING, AND (2) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF
        (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS. PLEASE
        INDICATE YOUR ACCEPTANCE TO THESE TERMS IN ACCORDANCE WITH THE INSTRUCTIONS PROVIDED BY
        SEENSTA. INDICATING ACCEPTANCE ESTABLISHES A BINDING AND EXECUTED WRITTEN AGREEMENT BETWEEN
        YOU AND SEENSTA. IF YOU DO NOT ACCEPT THESE TERMS, YOU CANNOT USE THE SERVICE.
      </h5>
      <br />
      <br />
      <p>
        The Terms include, and incorporate by reference, the following policies: the Seensta Privacy
        Policy, the Fee Policy, and the Prohibited Items Policy.
        <br />
        <br />
        <h5>Access and Use of the Service</h5>
        <br />
        <br />
        <h6>Services Description:</h6>{' '}
        <p>
          The Service is a technology platform that provides an online social marketplace in which
          consumers and individuals can list their services and sell their fashion items ("Items"),
          and buyers can browse and purchase Items to be made. Users who purchase Items through the
          Service are "Buyers," and users who list and sell Items through the Service are
          "Sellers/Makers." A user may be both a Buyer and Seller/Maker. All purchases are made
          directly (and any contract for purchSeensta is not a traditional auctioneer, nor is it a
          Seller/Maker or carrier. The Service includes assistance and shipping, but not buying or
          selling. We may also help facilitate the resolution of disputes between our Buyers and
          Sellers/Makers, but, Seensta has no control over and does not guarantee (a) the existence,
          quality, safety, authenticity, or legality of Items advertised on the Service; (b) the
          truth or accuracy of Sellers' contents or listings on the Service; (c) the ability of
          Sellers to sell Items through the Service and ship Items within required shipping windows;
          (d) the ability of Buyers to pay for Items purchased through the Service; or (e) that a
          Buyer or Seller will actually complete a transaction, effectuate trouble-free delivery and
          shipping, or return an Item through the Service.
          <br />
          <br />
        </p>
        <h6>Third Party Services:</h6>{' '}
        <p>
          You may register for the Service using third party services (e.g., Facebook Connect) and
          otherwise enable various third party services to be directly integrated into your Seensta
          experience. By directly integrating these services into the Service, we make your online
          experiences richer and more personalized. To take advantage of these features, we may ask
          you to register for or log into such services on the websites of their respective
          providers. By enabling third party services within the Service, you are allowing us to
          pass your log-in information to these service providers for this purpose. For more
          information about the implications of activating these third party services and Seensta's
          use, storage and disclosure of information related to you and your use of such services
          within the Service (including your friend lists and the like), please see our Privacy
          Policy at{' '}
          <a href="http://www.Seensta.com/#/privacy" title="Privacy Policy">
            http://www.Seensta.com/#/privacy
          </a>
          . However, please remember that the manner in which third party services use, store and
          disclose your information is governed solely by the policies of such third parties, and
          Seensta shall have no liability or responsibility for the privacy practices or other
          actions of any third party site or service that may be enabled within the Service.
          <br />
          <br />
          In addition, Seensta is not responsible for the accuracy, availability or reliability of
          any information, content, goods, data, opinions, advice or statements made available in
          connection with third party services. As such, Seensta is not liable for any damage or
          loss caused or alleged to be caused by or in connection with use of or reliance on any
          such third party service. Seensta enables these features merely as a convenience and the
          inclusion of such features does not imply an endorsement or recommendation.
          <br />
          <br />
        </p>
        <h6>Your Registration Obligations:</h6>{' '}
        <p>
          You may also be permitted to register with the Service directly. In any case, if you
          choose to register for the Service (whether directly or through a third party service),
          you agree to provide and maintain true, accurate, current and complete information about
          yourself. Registration data and certain other information about you are governed by our
          Privacy Policy. The Service is available only to individuals who can form legally binding
          contracts under applicable law. Without limiting the foregoing, the Service is not
          available to minors (people under the age of majority in their state or province of
          residence) or to temporarily or permanently suspended members of the Service. If you do
          not qualify, please do not use the Service. Additionally, Seensta reserves the right to
          refuse access to, or use of the Service to anyone, and may terminate any account, at
          anytime, in its sole discretion. You are only authorized to create and use one account for
          the Service and are prohibited from using alter egos or other disguised identities when
          using the Service. <br />
          <br />
        </p>
        <h6>Member Account, Password and Security:</h6>{' '}
        <p>
          You are responsible for maintaining the confidentiality of your password and account, if
          any, and are fully responsible for any and all activities that occur under your password
          or account. You agree to (a) immediately notify Seensta of any unauthorized use of your
          password or account or any other breach of security, and (b) ensure that you exit from
          your account at the end of each session when accessing the Service. Seensta will not be
          liable for any loss or damage arising from your failure to comply with this Section.{' '}
          <br />
          <br />
        </p>
        <h6>Modifications to Service:</h6>{' '}
        <p>
          Seensta reserves the right to modify or discontinue, temporarily or permanently, the
          Service (or any part thereof) with or without notice. You agree that Seensta shall not be
          liable to you or to any third party for any modification, suspension or discontinuance of
          the Service. <br />
          <br />
        </p>
        <h6>General Practices Regarding Use and Storage:</h6>{' '}
        <p>
          You acknowledge that Seensta may establish general practices and limits concerning use of
          the Service. You agree that Seensta has no responsibility or liability for the deletion or
          failure to store any data or other content maintained or transmitted by the Service. You
          acknowledge that Seensta reserves the right to terminate accounts that are inactive for an
          extended period of time. You further acknowledge that Seensta reserves the right to change
          these general practices and limits at any time, in its sole discretion, with or without
          notice. <br />
          <br />
        </p>
        <h6>Mobile Services:</h6>{' '}
        <p>
          When you access the Service through a mobile device, your wireless service carrier's
          standard charges, data rates and other fees may apply. In addition, downloading,
          installing, or using certain mobile services may be prohibited or restricted by your
          carrier, and not all mobile services may work with all carriers or devices. You
          acknowledge and agree that your use of the Service must be in accordance with the usage
          rules established by your mobile device platform or service provider. <br />
          <br />
        </p>
        <h6>Friend Referral Program:</h6>{' '}
        <p>
          Seensta may from time to time offer credits to existing users that refer new users to the
          Service using the invite code provided by Seensta to such existing users. Seensta may also
          make certain credits available to such new users using that invite code. The amount and
          requirements to earn such credits will be determined, and may change, from time to time in
          Seensta's sole discretion and such program may be terminated by Seensta at any time, in
          each case with or without notice to you. Furthermore, Seensta reserves the right to limit
          the number and/or amount of credits that may be earned with respect to any existing user
          with or without notice to you. To be eligible for a credit (whether granted to an existing
          user or new user), the new user must be an individual that has never registered for the
          Service and must be using a device (not the web) for sign-up and the device being used
          must not be associated with an existing Seensta account. You agree that any credit
          granted: (1) cannot be traded for cash or any other service; (2) may not be sold or
          transferred in any manner, or made available to the general public (whether posted to a
          public forum or otherwise), unless expressly permitted by Seensta; (3) cannot be acquired
          via public distribution where you are a contributor but not the primary content owner
          (e.g. coupon websites); (4) may expire prior to your use; (5) may be voided or deducted by
          Seensta at any time for any reason without liability to Seensta, if Seensta believes in
          its sole discretion that such credits resulted from fraud or other misuse of the Referral
          program or the Service. If your account has been suspended (see "Termination" below), you
          will not be able to use such credits during the duration of your account suspension.{' '}
          <br />
          <br />
        </p>
        <h5>Conditions of Use</h5>
        <h6>User Conduct:</h6>{' '}
        <p>
          You are solely responsible for all descriptions, pictures of items, listings, information,
          data, text, software, music, sound, graphics, video, messages or other materials
          ("content") that you upload, post, publish or display (hereinafter, "post") or otherwise
          transmit via the Service, and for all items that you sell or purchase via the Service. The
          following are examples of the kind of items, content and/or use that is illegal or
          prohibited by Seensta. Seensta reserves the right to investigate and take appropriate
          legal action against anyone who, in Seensta's sole discretion, violates this provision,
          including without limitation, removing the offending items or content from the Service,
          suspending or terminating the account of such violators and reporting you to the law
          enforcement authorities. You agree to not use the Service to: <br />
          sell, post or otherwise transmit any item or content that (i) is unlawful, harmful,
          threatening, abusive, harassing, tortious, excessively violent, defamatory, vulgar,
          obscene, pornographic, libelous, invasive of another‘s privacy, hateful racially,
          ethnically or otherwise objectionable; (ii) you do not have a right to sell or transmit
          under any law or under contractual or fiduciary relationships; (iii) poses or creates a
          privacy or security risk to any person; (iv) infringes any intellectual property or other
          proprietary rights of any party; (v) constitutes unsolicited or unauthorized advertising,
          promotional materials, commercial activities and/or sales, "junk mail," "spam," "chain
          letters," "pyramid schemes," "contests," "sweepstakes," or any other form of solicitation;
          (vi) contains software viruses or any other computer code, files or programs designed to
          interrupt, destroy or limit the functionality of any computer software or hardware or
          telecommunications equipment; or (vii) in the sole judgment of Seensta, is objectionable
          or which restricts or inhibits any other person from using or enjoying the Service, or
          which may expose Seensta or its users to any harm or liability of any type; impersonate
          any person or entity, or falsely state or otherwise misrepresent your affiliation with a
          person or entity; solicit personal information from anyone under the age of 18; harvest or
          collect email addresses or other contact information of other users from the Service or
          the Site by electronic or other means for the purposes of sending unsolicited emails or
          other unsolicited communications; advertise or offer to sell or buy any goods or services
          other than those items intended to be sold and purchased through the Service; interfere
          with or disrupt the Service or servers or networks connected to the Service, or disobey
          any requirements, procedures, policies or regulations of networks connected to the
          Service; or violate any applicable local, state, national or international law, or any
          regulations having the force of law; engage in any activities that violate the Telephone
          Consumer Protection Act, 47 U.S.C. § 227 et seq. and its regulations at 47 C.F.R. §
          64.1200; the Do-Not-Call Implementation Act, 15 U.S.C. § 6101 et seq.; or any similar
          anti-spam, data protection, or privacy legislation in any jurisdiction; further or promote
          any criminal activity or enterprise or provide instructional information about illegal
          activities; or obtain or attempt to access or otherwise obtain any materials or
          information through any means not intentionally made available or provided for through the
          Service.
        </p>
        <h6>Special Notice for International Use; Export Controls:</h6>{' '}
        <p>
          Software available in connection with the Service and the transmission of applicable data,
          if any, is subject to United States ("U.S.") export controls. No Software may be
          downloaded from the Service or otherwise exported or re-exported in violation of U.S.
          export laws. Downloading or using the Software is at your sole risk. Recognizing the
          global nature of the Internet, you agree to comply with all local rules and laws regarding
          your use of the Service, including as it concerns online conduct and acceptable content.
          <br />
          <br />
        </p>
        <h5>Purchase and Sale Transactions</h5>
        <br />
        <br />
        <h6>Fees:</h6>{' '}
        <p>
          Registering for the Service is free; however, Seensta charges certain fees for various
          transactions effected through the Service, as set forth in the Fee Policy. Unless
          otherwise stated, all fees are quoted in U.S. dollars. <br />
          <br />
        </p>
        <h6>Taxes; Shipping Costs; Buyer Responsibility:</h6>{' '}
        <p>
          Items purchased by and shipped to Buyers in locations in the U.S. may be subject to
          applicable state or local sales or use tax, privilege tax or similar transaction-based
          taxes ("State Tax"). Items purchased by and shipped to Buyers in locations outside the
          U.S. may also be subject to applicable taxes, including value added taxes (collectively
          with State Tax, "Taxes"). The amount of Taxes is based on a number of factors, including
          but not limited to the type of Items purchased, the Buyers/Makers designated delivery
          address, and/or the location of the Seller.
          <br />
          <br />
          Buyers/Makers are responsible for paying the applicable purchase price for a purchased
          Item to the Seller, as well as any shipping costs (which are detailed in the Fee Policy)
          and paying applicable Taxes and/or duties associated with the purchase and sale of any
          Items through the Service. For Buyers in the U.S., the rate of Tax applied to purchases of
          taxable Items is a combined rate based on the state and local rates of the address where
          the Items are delivered to or shipped from.
          <br />
          <br />
          Buyers may use certain Seensta promotions in a Seller’s/Maker’s account, towards the
          purchase of Items. Depending on the type of promotion or credit used, it may reduce the
          amount of Taxes that apply to a Buyer’s order. The application of the promotion or credit
          will be reflected at the time of checkout and on the receipt of purchase.
          <br />
          <br />
          Tax and shipping costs are not included in the listed price for any Items listed by
          Sellers/Maker or the budget you set through the Service but will be displayed to Buyers
          before confirmation of any purchase.
          <br />
          <br />{' '}
        </p>
        <h6>Marketplace Collection; Taxes; Commissions; Seller Responsibility:</h6>{' '}
        <p>
          Seensta may collect Taxes from Buyers on behalf of Sellers/Makers where we may legally do
          so. This includes those states that have enacted legislation that requires marketplaces,
          like Seensta, to collect Taxes on behalf of sellers. It also includes those states that
          have agreed to allow Seensta to collect Taxes on behalf of Sellers. In these states, the
          amount of Taxes collected will be submitted by Seensta to the appropriate taxing authority
          on behalf of Sellers/Makers based on their State or Country. If Seensta is unable to
          submit Taxes on behalf of Sellers/Makers, Seensta will provide Sellers/Makers with the
          amount of Taxes collected. Sellers/Makers are responsible for submitting such Taxes to the
          appropriate taxing authority.
          <br />
          <br />
          Sellers/Makers are responsible for paying Seensta’s commission as well as all taxes
          imposed or measured by Seller’s net income, net profits, income, profits, and/or revenues
          associated with the sale of any Items through the Service.
          <br />
          <br />
        </p>
        <h6>Payments:</h6>{' '}
        <p>
          Buyers may pay for Items using credit cards, PayPal or other payment methods. If you
          submit your payment information through the Service, then you authorize Seensta to store
          that payment information and charge your payment method for any Item you purchase with
          your permission.
          <br />
          <br />
        </p>
        <h6>Fee Modifications:</h6>{' '}
        <p>
          We reserve the right to change or discontinue, temporarily or permanently, some or all of
          the fees for the Service. Any change will be effective upon posting of a revised.
          <br />
          <br />
        </p>
        <h6>Seller Fee Policy:</h6>
        <p>
          Our fees are simple and straightforward: Listing is always free on Seensta When you make a
          sale, we deduct a fee from your listing price. For sales under $15, the fee is a single
          flat rate of $2.95. You keep the rest. For sales of $15 or more, the fee is 20% of the
          listing price and you keep 80%. Here's what you get:
          <br />
          <br />
          we handle international shipping for you free credit card processing free customer support
          and Posh Protect buyer protection for your buyers
          <br />
          <br />
        </p>
        <h6>
          Marketplace Risk: Seensta is not affiliated with or endorsed by any designer,
          manufacturer, retailer or brand of the Items made available through the Service:
        </h6>
        <p>
          Seensta is a marketplace and technology platform and is not involved in any transaction
          between Buyers and Sellers/Makers, does not obtain title to any purchased Items and does
          not act as a Buyer, Seller (or affiliate for a Buyer or Seller) or broker with respect to
          any transactions. There are risks that you assume when dealing with other users (including
          those who may be acting under false pretenses) and all of these risks are borne by you,
          and not Seensta. We encourage you to use the various functionalities of the Service (e.g.,
          our Item commenting system, and our direct messaging platform) to help evaluate the user
          with whom you are dealing.
          <br />
          <br />
          Seensta does not control the behavior of users of the Service or the information or User
          Content (defined below) provided by other users. As a result, Seensta does not guarantee
          or endorse the authenticity, quality, safety, or legality of any Items offered or sold,
          the truth or accuracy of any listings, or the ability of Sellers to sell Items or of
          Buyers to buy Items. We cannot assure that all transactions will be completed.
          Additionally, Seensta does not guarantee the ability or intent of users to fulfill their
          obligations in any transactions. Seensta reserves the right to delay the completion of any
          transaction for a reasonable period of time for purposes of fraud detection and otherwise
          protecting Seensta and its users from illegal or wrongful activities or other violations
          of these Terms.
          <br />
          <br />
        </p>
        <h5>
          FOR EVERY TRANSACTION THAT IS COMMENCED THROUGH THE SERVICE, YOU MUST MAKE YOUR OWN
          INDEPENDENT DETERMINATION REGARDING THE STATEMENTS, ITEM DESCRIPTIONS OR THE
          SELLER’S/MAKER’S REPRESENTATIONS AND THE BUYER’S ABILITY TO PAY FOR OR DELIVER THE ITEM(S)
          OFFERED. PLEASE USE CAUTION, COMMON SENSE, AND PRACTICE SAFE TRADING WHEN DEALING WITH
          OTHER USERS OR OTHERWISE USING THE SERVICE.
        </h5>{' '}
        <br />
        <br />
        <h6>Prohibited Items:</h6>{' '}
        <p>
          Seensta prohibits the listing or sale of any Item that is illegal to sell under any
          applicable law, statute, ordinance, or regulation, including, without limitation, Items
          listed in our Prohibited Items Policy .<br />
          <br />
        </p>
        <h6>Sellers:</h6>{' '}
        <p>
          You must have the right to sell the Items that you make available for sale through the
          Service. You must describe your Item (including the original price) and all terms of sale
          in your listing truthfully, accurately and completely.
          <br />
          <br />
        </p>
        <h6>Buyers:</h6>{' '}
        <p>
          You are responsible for reading the full Item listing before making an offer. When you
          make an offer and your offer is accepted by the Seller, you have a contract with the
          Seller.
          <br />
          <br />
        </p>
        <h6>Purchases:</h6>{' '}
        <p>
          Buyers may offer to purchase Items from Sellers. Once an offer is accepted by Seller, the
          sale transaction is binding on both Seller and Buyer, and no returns, refunds,
          cancellations or retractions are allowed, except as permitted by these Terms. Once the
          offer is accepted by Seller, Buyer must pay the purchase price and shipping costs for the
          Item through the Service. After doing so, Buyer’s obligations are complete, unless Buyer
          requests a permitted return. After Buyer pays those amounts, Seensta will provide Seller
          with a prepaid shipping label ("Label"), sent to the email address associated with
          Seller’s account. Once the Label is sent to Seller, Seensta’s obligations for that sale
          are complete. Notwithstanding the foregoing, a Buyer may cancel an order for an Item
          within three hours of purchasing that Item.
          <br />
          <br />
        </p>
        <p>
          Seller must ship the Item to the Buyer using the Label (and corresponding shipping method)
          within 3 days after receipt of the Label. If the Seller does not ship the Item within that
          time period, then we will notify Seller and give Seller a set amount of time to ship the
          Item before we cancel the order automatically. Seller transfers title to the Item upon
          shipment.
          <br />
          <br />
          When using a Label provided by Seensta: (i) Seller may only use the Label to ship the
          applicable Item; (ii) Labels may not be transferred or sold to a third party; (iii) Seller
          is fully responsible for the contents of any parcels shipped using the Label; and (iv)
          Seller must comply with the terms and conditions and any other policies or rules imposed
          by the carrier used to ship the Items. As a Buyer, if an Item shipped with a Label is
          lost, damaged, or arrives later than expected, or is the wrong Item, we have no obligation
          to you; however, please report the issue to us through the Service within three days after
          delivery. Seensta reserves the right to discontinue providing Labels to any or all users
          at any time and for any reason.
          <br />
          <br />
          Once the Buyer confirms it has received and accepted the Item (either by affirmatively
          indicating acceptance through the Service or by failing to notify Seensta of any issues
          with the Item within three days of delivery of the Item, as determined by the tracking
          information on the Label), then Seensta will credit Seller’s account with an amount equal
          to the purchase price received from the Buyer (less any applicable Tax), minus Seensta’s
          commission, as set forth in the Fee Policy. Funds credited to Seller’s account may be
          redeemed by the Seller through a permitted third party payment provider (e.g., by ACH from
          Seensta's chosen financial institution) or used by Seller to purchase items listed by
          other Sellers through the Service.
          <br />
          <br />
        </p>
        <h6>Returns:</h6>{' '}
        <p>
          Seensta may allow you to return an Item in limited circumstances. If the Item you receive
          is not as described on the Service, then you may request a return by reporting the issue
          through the Service or by emailing team@Seensta.com, in each case within three days after
          delivery (as determined by the tracking information on the Label) of the Item.
          <br />
          <br />
        </p>
        <h5>Intellectual Property Rights</h5>
        <br />
        <br />
        <h6>Service Content, Software and Trademarks:</h6>{' '}
        <p>
          You acknowledge and agree that the Service may contain images and descriptions of fashion
          items and other content (collectively, "Service Content") that is protected by copyright,
          patent, trademark, trade secret or other proprietary rights and laws. Except as expressly
          authorized by Seensta, you agree not to modify, copy, frame, scrape, rent, lease, loan,
          sell, distribute or create derivative works based on the Service or the Service Content,
          in whole or in part, except that the foregoing does not apply to your own User Content (as
          defined below) that you legally upload to the Service. In connection with your use of the
          Service you shall not engage in or use any data mining, robots, scraping or similar data
          gathering or extraction methods. Any use of the Service or the Service Content other than
          as specifically authorized herein is strictly prohibited. The technology underlying the
          Service (including the Software distributed in connection therewith) is the property of
          Seensta, our affiliates and our partners. You agree not to copy, modify, create a
          derivative work of, reverse engineer, reverse assemble or otherwise attempt to discover
          any source code, sell, assign, sublicense, or otherwise transfer any right in the Service
          (including the Software). Any rights not expressly granted herein are reserved by Seensta.
          <br />
          <br />
          The Seensta name and logos are trademarks and service marks of Seensta (collectively the
          "Seensta Trademarks"). Other company, product, and service names and logos used and
          displayed via the Service may be trademarks or service marks of their respective owners
          who may or may not endorse or be affiliated with or connected to Seensta. Nothing in these
          Terms or the Service should be construed as granting, by implication, estoppel, or
          otherwise, any license or right to use any of Seensta Trademarks displayed on the Service,
          without our prior written permission in each instance. All goodwill generated from the use
          of Seensta Trademarks will inure to our exclusive benefit.
          <br />
          <br />
        </p>
        <h6>Apple-enabled Software Applications:</h6>{' '}
        <p>
          Seensta offers Software applications that are intended to be operated in connection with
          products made commercially available by Apple Inc. ("Apple"). With respect to Software
          that is made available for your use in connection with an Apple-branded product (such
          Software, "Apple-Enabled Software"), in addition to the other terms and conditions set
          forth in these Terms, the following terms and conditions apply:
          <br />
          <br />
          Seensta and you acknowledge that these Terms are concluded between Seensta and you only,
          and not with Apple, and that as between Seensta and Apple, Seensta, not Apple, is solely
          responsible for the Apple-Enabled Software and the content thereof. You may not use the
          Apple-Enabled Software in any manner that is in violation of or inconsistent with the
          Usage Rules set forth for Apple-Enabled Software in, or otherwise be in conflict with, the
          App Store Terms. Your license to use the Apple-Enabled Software is limited to a
          non-transferable license to use the Apple-Enabled Software on an iPhone OS Product that
          you own or control, as permitted by the Usage Rules set forth in the App Store Terms.
          Apple has no obligation whatsoever to provide any maintenance or support services with
          respect to the Apple-Enabled Software. Apple is not responsible for any product
          warranties, whether express or implied by law. In the event of any failure of the
          Apple-Enabled Software to conform to any applicable warranty, you may notify Apple, and
          Apple will refund the purchase price for the Apple-Enabled Software to you, if any; and,
          to the maximum extent permitted by applicable law, Apple will have no other warranty
          obligation whatsoever with respect to the Apple-Enabled Software, or any other claims,
          losses, liabilities, damages, costs or expenses attributable to any failure to conform to
          any warranty, which will be Seensta's sole responsibility, to the extent it cannot be
          disclaimed under applicable law. Seensta and you acknowledge that Seensta, not Apple, is
          responsible for addressing any claims of you or any third party relating to the
          Apple-Enabled Software or your possession and/or use of that Apple-Enabled Software,
          including, but not limited to: (i) product liability claims; (ii) any claim that the
          Apple-Enabled Software fails to conform to any applicable legal or regulatory requirement;
          and (iii) claims arising under consumer protection or similar legislation. In the event of
          any third party claim that the Apple-Enabled Software or the end-user's possession and use
          of that Apple-Enabled Software infringes that third party's intellectual property rights,
          as between Seensta and Apple, Seensta, not Apple, will be solely responsible for the
          investigation, defense, settlement and discharge of any such intellectual property
          infringement claim. You represent and warrant that (i) you are not located in a country
          that is subject to a U.S. Government embargo, or that has been designated by the U.S.
          Government as a "terrorist supporting" country; and (ii) you are not listed on any U.S.
          Government list of prohibited or restricted parties. If you have any questions, complaints
          or claims with respect to the Apple-Enabled Software, they should be directed to Seensta
          by e-mail to team@Seensta.com.
          <br />
          <br />
          Seensta and you acknowledge and agree that Apple, and Apple's subsidiaries, are third
          party beneficiaries of these Terms with respect to the Apple-Enable Software, and that,
          upon your acceptance of the terms and conditions of these Terms, Apple will have the right
          (and will be deemed to have accepted the right) to enforce these Terms against you with
          respect to the Apple-Enable Software as a third party beneficiary thereof. <br />
          <br />
        </p>
        <h6>Seensta Credits:</h6>{' '}
        <p>
          Seensta Credits or Seensta Credits are not redeemable for cash or cash equivalents, are
          non-transferable and may not be applied toward prior purchases. If your account has been
          suspended (see "Termination" below), you will not be able to use Seensta Credits during
          the duration of your account suspension. Other restrictions may apply. We reserve the
          right to rescind at any time any credits that you may receive a result of referral or
          other promotional programs, subject to reasonable notice to you. <br />
          <br />
        </p>
        <h6>Electronic Communications:</h6>{' '}
        <p>
          When you use the Service or send e-mails to us, you are communicating with us
          electronically. We will communicate with you by e-mail or providing notices via the
          Service. You agree that all agreements, notices, disclosures and other communications that
          we provide to you electronically satisfy any legal requirement that such communications be
          in writing. Any such emails may include marketing and promotional content. <br />
          <br />
        </p>
        <h6>Third Party Material:</h6>{' '}
        <p>
          Under no circumstances will Seensta be liable in any way for any items or content posted
          by third parties or at the direction of users, including, but not limited to, for any
          errors or omissions in any content, or for any loss or damage of any kind incurred as a
          result of the use of any items or content posted, sold, purchased or otherwise transmitted
          via the Service. You acknowledge that Seensta does not pre-screen items or content, but
          that Seensta and its designees shall have the right (but not the obligation) in their sole
          discretion to refuse or remove any items or content that is available via the Service.
          Without limiting the foregoing, Seensta and its designees shall have the right to remove
          any item or content that violates these Terms or is deemed by Seensta, in its sole
          discretion, to be otherwise objectionable. You agree that you must evaluate, and bear all
          risks associated with, the use of any items or content, including any reliance on the
          safety, condition, accuracy, completeness, or usefulness of such items or content. <br />
          <br />
        </p>
        <h6>User Content Posted on the Site:</h6>{' '}
        <p>
          You are solely responsible for the content you post or transmit on or through the Service
          (collectively, "User Content"). You will not post any content that you did not create or
          that you do not own all right, title and interest in and to, including, without
          limitation, all copyright and rights of publicity contained therein. By posting or
          otherwise transmitting any User Content you hereby grant and will grant to Seensta and its
          affiliated companies a nonexclusive, worldwide, royalty free, fully paid up, transferable,
          sublicenseable, perpetual, irrevocable license to copy, display, transmit, perform,
          distribute, store, modify, make derivative works of and otherwise use in any manner your
          User Content in connection with the operation of the Service or any other products or
          services of Seensta, or the promotion, advertising or marketing thereof, in any form,
          medium or technology now known or later developed (including without limitation publishing
          your User Content on the Internet or on Third Party Services such as Facebook, sharing it
          with blogs, etc., and allowing other users to share listings that include your User
          Content). <br />
          <br />
          You acknowledge and agree that any questions, comments, suggestions, ideas, feedback or
          other information about the Site or the Service ("Submissions"), provided by you to
          Seensta are non-confidential and Seensta shall be entitled to the unrestricted use and
          dissemination of these Submissions for any purpose, commercial or otherwise, without
          acknowledgment or compensation to you. <br />
          <br />
          You acknowledge and agree that Seensta may preserve content and may also disclose content
          if required to do so by law or in the good faith belief that such preservation or
          disclosure is reasonably necessary to: (a) comply with legal process, applicable laws or
          government requests; (b) enforce these Terms; (c) respond to claims that any content
          violates the rights of third parties; or (d) protect the rights, property, or personal
          safety of Seensta, its users and the public. You understand that the technical processing
          and transmission of the Service, including your content, may involve (a) transmissions
          over various networks; and (b) changes to conform and adapt to technical requirements of
          connecting networks or devices. <br />
          <br />
        </p>
        <h6>Infringement Policy:</h6>{' '}
        <p>
          Seensta respects the intellectual property of others, and we ask our users to do the same.
          Seensta will promptly process and investigate notices of alleged infringement and will
          take appropriate actions under the Digital Millennium Copyright Act and other applicable
          intellectual property laws with respect to any alleged or actual infringement. If you
          believe that your work has been copied in a way that constitutes copyright infringement,
          or that your intellectual property rights have been otherwise violated, please provide our
          Copyright Agent with the following information: <br />
          <br />
          an electronic or physical signature of the person authorized to act on behalf of the owner
          of the copyright or other intellectual property interest; a description of the copyrighted
          work or other intellectual property that you claim has been infringed; a description of
          where the material that you claim is infringing is located on the Service, with enough
          detail that we may find it on the Service; your address, telephone number, and email
          address; a statement by you that you have a good faith belief that the disputed use is not
          authorized by the copyright or intellectual property owner, its agent, or the law; a
          statement by you, made under penalty of perjury, that the above information in your Notice
          is accurate and that you are the copyright or intellectual property owner or authorized to
          act on the copyright or intellectual property owner‘s behalf.
          <br />
          <br />
          If you believe that your User Content that was removed (or to which access was disabled)
          is not infringing, or that you have the authorization from the copyright owner, the
          copyright owner's agent, or pursuant to the law, to post and use the content in your User
          Content, you may send a written counter-notice containing the following information to the
          Copyright Agent: <br />
          <br />
          your physical or electronic signature; identification of the content that has been removed
          or to which access has been disabled and the location at which the content appeared before
          it was removed or disabled; a statement that you have a good faith belief that the content
          was removed or disabled as a result of mistake or a misidentification of the content; and
          your name, address, telephone number, and email address, a statement that you consent to
          the jurisdiction of the federal court located within Northern District of California and a
          statement that you will accept service of process from the person who provided
          notification of the alleged infringement.
          <br />
          <br />
          If a counter-notice is received by the Copyright Agent, Seensta will send a copy of the
          counter-notice to the original complaining party informing that person that it may replace
          the removed content or cease disabling it in 10 business days. Unless the copyright owner
          files an action seeking a court order against the content provider, member or user, the
          removed content may be replaced, or access to it restored, in 10 to 14 business days or
          more after receipt of the counter-notice, at our sole discretion. <br />
          <br />
          The above information should be sent to Seensta's Copyright Agent for Notice of claims of
          copyright or other intellectual property infringement by email to the following address:
          team@seensta.com (Subject line: "DMCA Takedown Request"). You may also contact us by mail
          at: <br />
          <br />
        </p>
        <p>
          Seensta. Attn: Copyright Agent 2017 Kiwi Walkway San Jose, CA 95113 <br />
          <br />
        </p>
        <h5>Third Party Websites</h5>
        <br />
        <br />
        <p>
          The Service may provide, or third parties may provide, links or other access to other
          sites and resources on the Internet. Seensta has no control over such sites and resources
          and Seensta is not responsible for and does not endorse such sites and resources. You
          further acknowledge and agree that Seensta shall not be responsible or liable, directly or
          indirectly, for any damage or loss caused or alleged to be caused by or in connection with
          use of or reliance on any content, events, goods or services available on or through any
          such site or resource. Any dealings you have with third parties found while using the
          Service are between you and the third party, and you agree that Seensta is not liable for
          any loss or claim that you may have against any such third party. <br />
          <br />
        </p>
        <h5>Indemnity and Release</h5>
        <br />
        <br />
        <p>
          You agree to release, indemnify and hold Seensta and its affiliates and their officers,
          employees, directors and agents (collectively, "Indemnitees") harmless from any and all
          losses, damages, expenses, including reasonable attorneys' fees, rights, claims, actions
          of any kind and injury (including death) arising out of or relating to your use of the
          Service (including any sale or purchase of any items through the Service), any User
          Content, your connection to the Service, your violation of these Terms or your violation
          of any rights of another. Notwithstanding the foregoing, you will have no obligation to
          indemnify or hold harmless any Indemnitee from or against any liability, losses, damages
          or expenses incurred as a result of any action or inaction of such Indemnitee. If you are
          a California resident, you waive California Civil Code Section 1542, which says: "A
          general release does not extend to claims which the creditor does not know or suspect to
          exist in his favor at the time of executing the release, which if known by him must have
          materially affected his settlement with the debtor." If you are a resident of another
          jurisdiction, you waive any comparable statute or doctrine. <br />
          <br />
        </p>
        <h5>Disclaimer of Warranties</h5>
        <br />
        <br />
        <p>
          YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN "AS IS" AND
          "AS AVAILABLE" BASIS. SEENSTA EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
          EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. <br />
          <br />
          SEENSTA MAKES NO WARRANTY THAT (I) YOU WILL BE ABLE TO SELL OR PURCHASE ANY ITEMS THROUGH
          THE SERVICE OR THAT THE SERVICE WILL OTHERWISE MEET YOUR REQUIREMENTS, (II) THE SERVICE
          WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (III) THE RESULTS THAT MAY BE
          OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE, OR (IV) THE QUALITY OF
          ANY GOODS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH
          THE SERVICE WILL MEET YOUR EXPECTATIONS. <br />
          <br />
        </p>
        <h5>Limitation of Liability</h5>
        <br />
        <br />
        <p>
          YOU EXPRESSLY UNDERSTAND AND AGREE THAT SEENSTA SHALL NOT BE LIABLE FOR ANY INDIRECT,
          INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS
          INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF GOODWILL, USE, DATA OR OTHER INTANGIBLE
          LOSSES (EVEN IF Seensta HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), WHETHER
          BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, RESULTING FROM: (I)
          THE USE OR THE INABILITY TO USE THE SERVICE (INCLUDING ANY INJURY OR OTHER BODILY HARM
          THAT MAY RESULT FROM YOUR USE OF THE SERVICE); (II) THE COST OF PROCUREMENT OF SUBSTITUTE
          GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR
          OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICE;
          (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (IV) STATEMENTS
          OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR (V) ANY OTHER MATTER RELATING TO THE
          SERVICE. IN NO EVENT SHALL Seensta'S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR
          CAUSES OF ACTION EXCEED THE AMOUNT OF COMMISSIONS THAT YOU HAVE PAID TO Seensta AS A
          SELLER IN THE LAST SIX (6) MONTHS, OR, IF GREATER, ONE HUNDRED DOLLARS ($100). <br />
          <br />
          SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF CERTAIN WARRANTIES OR THE
          LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY,
          SOME OF THE ABOVE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU OR BE ENFORCEABLE WITH
          RESPECT TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SERVICE OR WITH THESE
          TERMS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE SERVICE.
          <br />
          <br />
          IF YOU ARE A USER FROM NEW JERSEY, THE FOREGOING SECTIONS TITLED "DISCLAIMER OF
          WARRANTIES" AND "LIMITATION OF LIABILITY" ARE INTENDED TO BE ONLY AS BROAD AS IS PERMITTED
          UNDER THE LAWS OF THE STATE OF NEW JERSEY. IF ANY PORTION OF THESE SECTIONS IS HELD TO BE
          INVALID UNDER THE LAWS OF THE STATE OF NEW JERSEY, THE INVALIDITY OF SUCH PORTION SHALL
          NOT AFFECT THE VALIDITY OF THE REMAINING PORTIONS OF THE APPLICABLE SECTIONS. <br />
          <br />
        </p>
        <h5>
          Dispute Resolution By Binding Arbitration: PLEASE READ THIS SECTION CAREFULLY AS IT
          AFFECTS YOUR RIGHTS.
        </h5>
        <br />
        <br />
        <p>
          a. Agreement to Arbitrate This Dispute Resolution by Binding Arbitration section is
          referred to in these Terms as the "Arbitration Agreement." You agree that any and all
          disputes or claims that have arisen or may arise between you and Seensta, whether arising
          out of or relating to these Terms of Service (including any alleged breach thereof), the
          Services, any advertising, any aspect of the relationship or transactions between us,
          shall be resolved exclusively through final and binding arbitration, rather than a court,
          in accordance with the terms of this Arbitration Agreement, except that you may assert
          individual claims in small claims court, if your claims qualify. Further, this Arbitration
          Agreement does not preclude you from bringing issues to the attention of federal, state,
          or local agencies, and such agencies can, if the law allows, seek relief against us on
          your behalf. You agree that, by entering into these Terms, you and Seensta are each
          waiving the right to a trial by jury or to participate in a class action. Your rights will
          be determined by a neutral arbitrator, not a judge or jury. The Federal Arbitration Act
          governs the interpretation and enforcement of this Arbitration Agreement. b. Prohibition
          of Class and Representative Actions and Non-Individualized Relief YOU AND COMPANY AGREE
          THAT EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS
          A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING.
          UNLESS BOTH YOU AND COMPANY AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN
          MORE THAN ONE PERSON'S OR PARTY'S CLAIMS AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A
          CONSOLIDATED, REPRESENTATIVE, OR CLASS PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF
          (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL
          PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY
          THAT PARTY'S INDIVIDUAL CLAIM(S). c. Pre-Arbitration Dispute Resolution Seensta is always
          interested in resolving disputes amicably and efficiently, and most customer concerns can
          be resolved quickly and to the customer's satisfaction by emailing customer support at
          team@seensta.com. If such efforts prove unsuccessful, a party who intends to seek
          arbitration must first send to the other, by certified mail, a written Notice of Dispute
          ("Notice"). The Notice to Seensta should be sent to 2077 Kiwi Walkway, San Jose, CA 95113
          ("Notice Address"). The Notice must (i) describe the nature and basis of the claim or
          dispute and (ii) set forth the specific relief sought. If Seensta and you do not resolve
          the claim within sixty (60) calendar days after the Notice is received, you or Seensta may
          commence an arbitration proceeding. During the arbitration, the amount of any settlement
          offer made by Seensta or you shall not be disclosed to the arbitrator until after the
          arbitrator determines the amount, if any, to which you or Seensta is entitled. d.
          Arbitration Procedures Arbitration will be conducted by a neutral arbitrator in accordance
          with the American Arbitration Association's ("AAA") rules and procedures, including the
          AAA's Supplementary Procedures for Consumer-Related Disputes (collectively, the "AAA
          Rules"), as modified by this Arbitration Agreement. For information on the AAA, please
          visit its website, http://www.adr.org. Information about the AAA Rules and fees for
          consumer disputes can be found at the AAA's consumer arbitration page,
          http://www.adr.org/consumer_arbitration. If there is any inconsistency between any term of
          the AAA Rules and any term of this Arbitration Agreement, the applicable terms of this
          Arbitration Agreement will control unless the arbitrator determines that the application
          of the inconsistent Arbitration Agreement terms would not result in a fundamentally fair
          arbitration. The arbitrator must also follow the provisions of these Terms of Service as a
          court would. All issues are for the arbitrator to decide, including, but not limited to,
          issues relating to the scope, enforceability, and arbitrability of this Arbitration
          Agreement. Although arbitration proceedings are usually simpler and more streamlined than
          trials and other judicial proceedings, the arbitrator can award the same damages and
          relief on an individual basis that a court can award to an individual under the Terms of
          Service and applicable law. Decisions by the arbitrator are enforceable in court and may
          be overturned by a court only for very limited reasons. Unless Seensta and you agree
          otherwise, any arbitration hearings will take place in a reasonably convenient location
          for both parties with due consideration of their ability to travel and other pertinent
          circumstances. If the parties are unable to agree on a location, the determination shall
          be made by AAA. If your claim is for $10,000 or less, Seensta agrees that you may choose
          whether the arbitration will be conducted solely on the basis of documents submitted to
          the arbitrator, through a telephonic hearing, or by an in-person hearing as established by
          the AAA Rules. If your claim exceeds $10,000, the right to a hearing will be determined by
          the AAA Rules. Regardless of the manner in which the arbitration is conducted, the
          arbitrator shall issue a reasoned written decision sufficient to explain the essential
          findings and conclusions on which the award is based. e. Costs of Arbitration Payment of
          all filing, administration, and arbitrator fees (collectively, the "Arbitration Fees")
          will be governed by the AAA Rules, unless otherwise provided in this Arbitration
          Agreement. If the value of the relief sought is $75,000 or less, at your request, Seensta
          will pay all Arbitration Fees. If the value of relief sought is more than $75,000 and you
          are able to demonstrate to the arbitrator that you are economically unable to pay your
          portion of the Arbitration Fees or if the arbitrator otherwise determines for any reason
          that you should not be required to pay your portion of the Arbitration Fees, Seensta will
          pay your portion of such fees. In addition, if you demonstrate to the arbitrator that the
          costs of arbitration will be prohibitive as compared to the costs of litigation, Seensta
          will pay as much of the Arbitration Fees as the arbitrator deems necessary to prevent the
          arbitration from being cost-prohibitive. Any payment of attorneys' fees will be governed
          by the AAA Rules. f. Confidentiality All aspects of the arbitration proceeding, and any
          ruling, decision, or award by the arbitrator, will be strictly confidential for the
          benefit of all parties. g. Severability If a court or the arbitrator decides that any term
          or provision of this Arbitration Agreement (other than the subsection (b) titled
          "Prohibition of Class and Representative Actions and Non-Individualized Relief" above) is
          invalid or unenforceable, the parties agree to replace such term or provision with a term
          or provision that is valid and enforceable and that comes closest to expressing the
          intention of the invalid or unenforceable term or provision, and this Arbitration
          Agreement shall be enforceable as so modified. If a court or the arbitrator decides that
          any of the provisions of subsection (b) above titled "Prohibition of Class and
          Representative Actions and Non-Individualized Relief" are invalid or unenforceable, then
          the entirety of this Arbitration Agreement shall be null and void. The remainder of the
          Terms of Service will continue to apply. h. Future Changes to Arbitration Agreement
          Notwithstanding any provision in these Terms of Service to the contrary, Seensta agrees
          that if it makes any future change to this Arbitration Agreement (other than a change to
          the Notice Address) while you are a user of the Services, you may reject any such change
          by sending Seensta written notice within thirty (30) calendar days of the change to the
          Notice Address provided above. By rejecting any future change, you are agreeing that you
          will arbitrate any dispute between us in accordance with the language of this Arbitration
          Agreement as of the date you first accepted these Terms of Service (or accepted any
          subsequent changes to these Terms of Service).
          <br />
          <br />
        </p>
        <h5>Termination</h5>
        <br />
        <p>
          You agree that Seensta, in its sole discretion, may suspend or terminate your account (or
          any part thereof) or use of the Service and remove and discard any items or content within
          the Service, for any reason, including, without limitation, for lack of use or if Seensta
          believes that you have violated or acted inconsistently with the letter or spirit of these
          Terms. Any suspected fraudulent, abusive or illegal activity that may be grounds for
          termination of your use of Service, may be referred to appropriate law enforcement
          authorities. Seensta may also in its sole discretion and at any time discontinue providing
          the Service, or any part thereof, with or without notice. You agree that any termination
          of your access to the Service under any provision of these Terms may be effected without
          prior notice, and acknowledge and agree that Seensta may immediately deactivate or delete
          your account and all related information and files in your account and/or bar any further
          access to such files or the Service. Further, you agree that Seensta shall not be liable
          to you or any third-party for any termination of your access to the Service. <br />
          <br />
        </p>
        <h5>User Disputes</h5>
        <br />
        <br />
        <p>
          You agree that you are solely responsible for your interactions (including any purchase
          and sale transactions) with any other user in connection with the Service and Seensta will
          have no liability or responsibility with respect thereto. Seensta reserves the right, but
          has no obligation, to become involved in any way with disputes between you and any other
          user of the Service. <br />
          <br />
        </p>
        <h5>General</h5>
        <br />
        <br />
        <p>
          These Terms constitute the entire agreement between you and Seensta and govern your use of
          the Service, superseding any prior agreements between you and Seensta with respect to the
          Service. You also may be subject to additional terms and conditions that may apply when
          you use affiliate or third-party services, third-party content or third-party software.
          These Terms of Service shall be governed by the laws of the State of California without
          regard to its conflict of law provisions. With respect to any disputes or claims not
          subject to arbitration, as set forth above, you and Seensta agree to submit to the
          personal and exclusive jurisdiction of the state and federal courts located within San
          Francisco County, California. The failure of Seensta to exercise or enforce any right or
          provision of these Terms shall not constitute a waiver of such right or provision. If any
          provision of these Terms of Service is found by a court of competent jurisdiction to be
          invalid, the parties nevertheless agree that the court should endeavor to give effect to
          the parties' intentions as reflected in the provision, and the other provisions of these
          Terms of Service remain in full force and effect. You agree that regardless of any statute
          or law to the contrary, any claim or cause of action arising out of or related to use of
          the Service or these Terms of Service must be filed within one (1) year after such claim
          or cause of action arose or be forever barred. A printed version of this agreement and of
          any notice given in electronic form shall be admissible in judicial or administrative
          proceedings based upon or relating to this agreement to the same extent and subject to the
          same conditions as other business documents and records originally generated and
          maintained in printed form. The section titles in these Terms of Service are for
          convenience only and have no legal or contractual effect. Notices to you may be made via
          either email or regular mail. The Service may also provide notices to you of changes to
          these Terms of Service or other matters by displaying notices or links to notices
          generally on the Service. <br />
          <br />
        </p>
        <h5>Your Privacy</h5>
        <br />
        <br />
        <p>
          At Seensta, we respect the privacy of our users. For details please see our Privacy
          Policy. By using the Service, you consent to our collection and use of personal data as
          outlined therein. <br />
          <br />
        </p>
        <h5>Notice for California Users</h5>
        <br />
        <br />
        <p>
          Under California Civil Code Section 1789.3, users of the Service from California are
          entitled to the following specific consumer rights notice: The Complaint Assistance Unit
          of the Division of Consumer Services of the California Department of Consumer Affairs may
          be contacted in writing at 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834, or
          by telephone at (916) 445-1254 or (800) 952-5210. <br />
          <br />
        </p>
        <h5>Questions? Concerns? Suggestions?</h5> <br />
        <br />
        <p>
          Please contact us at team@Seensta.com. to report any violations of these Terms of Service
          or to pose any questions regarding these Terms of Service or the Service.
          <br />
          <br />
        </p>
      </p>
    </div>
  )
}

export default withRouter(Terms)
