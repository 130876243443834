import React from 'react'
import Header from './header'
import { Route, Switch } from 'react-router-dom'
import UploadImage from '../upload/upload'
import AllChallenges from '../challenge/all_challenges_page'
import AccountSettings from '../settings/account_settings'
import UserCategory from '../home/user_category'
import AvatarPhoto from '../profile/user_avatar'
import Tags from '../home/tags'
import Welcome from '../welcome/welcome'
import PasswordReset from '../auth/password_reset'
import SingleImage from '../portfolio/singleimage/single_image'
import messages from '../messenger/messages'
import MessageThreads from '../messenger/message_threads'
import Trending from '../home/trending'
import Entry from '../contest/contest_entry'
import ProfileContainer from '../profile/components/profile_container'
import Home from '../home/Home'
import { connect } from 'react-redux'
import ChallengeEntry from '../challenge/challenge_entry'
import FeePolicy from '../welcome/legal/fee_policy'
import Prohibited from '../welcome/legal/prohibited'
import Terms from '../welcome/legal/terms'
import Privacy from '../welcome/legal/privacy'
import Footer from '../welcome/sections/footer_section'
import Contests from '../contest/contests_container'

function Wrapper({ auth }) {
  return (
    <div style={{ marginTop: 50, color: '#000' }}>
      <Header />
      <Switch>
        <Route path="/fee-policy" component={FeePolicy} />
        <Route path="/prohibited" component={Prohibited} />
        <Route path="/terms" component={Terms} />
        <Route path="/privacy" component={Privacy} />
        <Route exact path="/image/upload" component={UploadImage} />
        <Route path="/" exact component={Welcome} />
        <Route path="/challenges" component={AllChallenges} />
        <Route path="/settings" component={AccountSettings} />
        <Route path="/browse/:usertype" exact component={UserCategory} />
        <Route path="/profilepicture" exact component={AvatarPhoto} />
        <Route path="/browse/tags/:tag" exact component={Tags} />
        <Route path="/trending/:trend" exact component={Trending} />
        <Route path="/password/reset" exact component={PasswordReset} />
        <Route path="/entry/:entryid" exact component={Entry} />
        <Route path="/photo/:photo_id" exact component={SingleImage} />
        <Route path="/welcome" exact component={Welcome} />
        <Route path="/contests" exact component={Contests} />
        <Route path="/messages/:messageid" exact component={messages} />
        <Route path="/seam/requests" exact component={MessageThreads} />
        <Route path="/:user_username" component={ProfileContainer} />
        <Route path="/home" exact component={Home} />
        <Route path="/challenge/:entryid" exact component={ChallengeEntry} />
      </Switch>
      {!auth && <Footer />}
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    confirmed: state.auth.confirmed,
  }
}
export default connect(mapStateToProps)(Wrapper)
