import React from 'react'
import Header from './header'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import SideBar from './side_bar'
import Hidden from '@material-ui/core/Hidden'
import AllChallengesPage from '../challenge/all_challenges_page'
import ChallengeEntry from '../challenge/challenge_entry'
import '../home/Grid.css'

function ChallengeContainer({ featuredusers, match }) {
  return (
    <div style={{ marginTop: 50, color: '#000' }}>
      <div className="desktop-width-100">
        <Header />
        <Hidden only={['sm', 'xs']}>
          <div className="desktop-grid-container">
            <div className="desktop-grid-containerflex">
              <SideBar featuredusers={featuredusers} heading="Designers" />
            </div>
            <div className="desktop-grid-container-card">
              <Route path={`${match.url}`} exact component={AllChallengesPage} />
              <Route path={`${match.url}/:entryId`} exact component={ChallengeEntry} />
            </div>
          </div>
        </Hidden>

        <Hidden only={['lg', 'xl', 'md']}>
          <div style={{ marginTop: 10 }}>
            <Route path={`${match.url}`} exact component={AllChallengesPage} />
            <Route path={`${match.url}/:entryId`} exact component={ChallengeEntry} />
          </div>
        </Hidden>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    featuredusers: state.portfolio.featuredusers,
  }
}
export default connect(mapStateToProps)(ChallengeContainer)
