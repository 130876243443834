import React, { useState, useEffect } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Moment from 'react-moment'
import { connect } from 'react-redux'
import { getUserGig, deleteGig } from '../../../store/actions/gigActions'
import FormatDateTime from '../../../components/display/format_date_time'
import EmptyState from '../../../components/appmessages/empty_state.'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Grid, IconButton } from '@material-ui/core'
import Label from '../../../components/display/label'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import ApplicantsDialog from '../atoms/applicants_dialog'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    marginTop: 10,
  },
  label: {
    marginTop: 10,
    display: 'flex',
  },
  gigTitle: {
    fontSize: 20,
    letterSpacing: 0,
    color: '#504D4D',
    textTransform: 'lowercase',
    opacity: 1,
  },
  gigInfo: {
    fontSize: 16,
    letterSpacing: 0,
    color: '#504D4D',
    textTransform: 'lowercase',
    opacity: 1,
  },
}))

function MyGigs({ getUserGig, deleteGig, token, props, auth }) {
  const classes = useStyles()
  const [postedGigs, setPostedGigs] = useState([])
  const [fetching, setFetching] = useState(true)

  useEffect(() => {
    getUserGig(token)
      .then((res) => {
        setPostedGigs([...res.gig])
        setFetching(false)
      })
      .catch(() => {})
  }, [getUserGig, token, setFetching])

  const handleDelete = (gigID, index) => {
    const data = { resourceID: gigID }
    deleteGig(data, token)
      .then(() => {
        const newPostedGigs = postedGigs.splice(1, index)
        setPostedGigs([...newPostedGigs])
        setFetching(false)
      })
      .catch(() => {})
  }

  return (
    <div
      style={{
        maxWidth: 600,
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {postedGigs.length ? (
        postedGigs.map((gig, index) => {
          return (
            <Paper className={classes.root} key={index}>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={4}>
                  <Typography component="p" className={classes.gigInfo}>
                    <Label label="posted" fontWeight={200} />
                    <FormatDateTime dateTime={gig.created_at} />
                  </Typography>
                </Grid>
                <Grid item xs={8} style={{ textAlign: 'right' }}>
                  {auth && <ApplicantsDialog resource={gig.gig_id} applicants={gig.applicants} />}
                </Grid>
              </Grid>
              <Divider />

              <Label label="title" fontWeight={200} />
              <Typography className={classes.gigTitle}>{gig.gig_title}</Typography>
              <Label label="description" fontWeight={200} />
              <Typography component="p" className={classes.gigInfo}>
                {gig.gig_description}
              </Typography>

              <Grid container direction="row" alignItems="center">
                <Grid item xs={3}>
                  <Label label="location" fontWeight={200} />
                  <Typography component="p" className={classes.gigInfo}>
                    {gig.gig_location}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Label label="gig gender" fontWeight={200} />
                  <Typography align="center" component="p" className={classes.gigInfo}>
                    {gig.gig_gender}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Label label="gig age" fontWeight={200} />
                  <Typography component="p" className={classes.gigInfo}>
                    {gig.gig_age}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container direction="row" alignItems="center">
                <Grid item xs={4}>
                  <Label label="gig pay" fontWeight={200} />
                  <Typography component="p" className={classes.gigInfo}>
                    {gig.gig_compensation}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Label label="start date" fontWeight={200} />
                  <Typography component="p" className={classes.gigInfo}>
                    <Moment format="D MMM YYYY">{gig.gig_start_date}</Moment>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Label label="end date" fontWeight={200} />
                  <Typography component="p" className={classes.gigInfo}>
                    <Moment format="D MMM YYYY">{gig.gig_end_date}</Moment>
                  </Typography>
                </Grid>
              </Grid>

              <IconButton
                aria-label="dedit"
                onClick={() => props.history.push('/gigs/create', { gig })}
              >
                <EditIcon />
              </IconButton>

              <IconButton aria-label="dedit" onClick={() => handleDelete(gig.gig_id, index)}>
                <DeleteIcon />
              </IconButton>
            </Paper>
          )
        })
      ) : (
        <div style={{ textAlign: 'center' }}>
          {fetching ? (
            <CircularProgress />
          ) : (
            <EmptyState title="My Posted Gigs" message="You haven't posted any Gig yet." />
          )}
        </div>
      )}
    </div>
  )
}
const mapStateToProps = (state, ownProps) => {
  return {
    token: state.auth.token,
    props: ownProps,
    auth: state.auth.auth,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getUserGig: (token) => dispatch(getUserGig(token)),
    deleteGig: (data, token) => dispatch(deleteGig(data, token)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MyGigs)
