import React from 'react'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import '../Profile.css'
import UploadProfilePictureOnProfile from './upload_profile_picture_on_profile'
import Button from '@material-ui/core/Button'
import IsFollowingOrFollow from './is_following_or_follow'
import { withRouter } from 'react-router-dom'
import Hidden from '@material-ui/core/Hidden'
import { awsImage } from '../../../Helpers'
import FollowAndFollowingDialogDesktop from '../../follow/follow_and_following_dialog_desktop'
import Link from '@material-ui/core/Link'
import { Box, Grid } from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import PersonIcon from '@material-ui/icons/Person'

function ProfileCoverInfo({ user, avatar, current, about, badge, followers, following, history }) {
  const handleSettings = () => {
    history.push('/settings')
  }

  if (about) {
    var usertype
    switch (about.usertype) {
      case 'modelUser':
        usertype = 'Model'
        break
      case 'photographer':
        usertype = 'Photographer'
        break
      case 'Designer':
        usertype = 'Designer'
        break
      case 'universe':
        usertype = 'Fan'
        break
      case 'Model':
        usertype = 'Model'
        break
      case 'Artist':
        usertype = 'Artist'
        break
      case 'Dancer':
        usertype = 'Dancer'
        break
      case 'Fashion':
        usertype = 'Fashion'
        break
      case 'Makeup Artist':
        usertype = 'MUA'
        break
      case 'Photographer':
        usertype = 'Photographer'
        break
      case 'model':
        usertype = 'Model'
        break
      default:
        usertype = 'Fan'
    }
  }

  return (
    <div>
      <Hidden only={['lg', 'xl', 'md']}>
        <Box
          width={344}
          border={1}
          margin="0 auto"
          borderColor="#F0EFEF"
          top={30}
          position="relative"
        >
          <Grid
            container
            xs={12}
            direction="row"
            alignItems="center"
            style={{ justifyContent: 'space-between' }}
          >
            <Grid xs={3} item>
              <DisplayAvatar
                user={user}
                current={current}
                avatar={avatar && avatar.photoPath}
                history={history}
              />
            </Grid>
            <Grid xs={4} item>
              {user.username === current.username ? (
                <Button
                  variant="outlined"
                  size="small"
                  style={{ borderColor: '#52183E', fontWeight: 100, textTransform: 'lowercase' }}
                  onClick={handleSettings}
                  color="primary"
                >
                  Edit Profile
                </Button>
              ) : (
                <IsFollowingOrFollow user={user.username} current={current} />
              )}
            </Grid>
          </Grid>
          <Box>
            <Typography
              align="left"
              style={{
                color: '#52183E',
                fontSize: 16,
                fontWeight: 600,
                paddingLeft: 10,
                textTransform: 'lowercase',
              }}
            >
              {user.firstname} {user.lastname}
              {badge.length
                ? badge.map((won, index) => {
                    return (
                      <span key={index}>
                        <img src="/challenge_winner.png" width={16} alt="Won Challenges" />
                      </span>
                    )
                  })
                : null}
            </Typography>
            <Typography align="left" style={{ color: '#504D4D', fontSize: 12, paddingLeft: 10 }}>
              @{user.username}
            </Typography>
          </Box>

          <Grid container xs={12} direction="row" alignItems="center" style={{ height: 35 }}>
            <Grid xs={4} item style={{ textAlign: 'left' }}>
              <Typography style={{ color: '#504D4D' }} noWrap>
                {about && (
                  <Link
                    onClick={() => history.push(`/browse/loc/${about.location}`)}
                    style={{
                      color: '#504D4D',
                      fontSize: 14,
                      fontWeight: 600,
                      paddingLeft: 10,
                      display: 'flex',
                    }}
                  >
                    <LocationOnIcon style={{ fontSize: 20 }} /> {about.location}
                  </Link>
                )}
              </Typography>
            </Grid>

            <Grid xs={5} item style={{ textAlign: 'left' }}>
              <Link
                onClick={() => history.push(`/browse/${usertype}`)}
                style={{ color: '#504D4D', fontSize: 14, fontWeight: 600, display: 'flex' }}
              >
                <PersonIcon style={{ fontSize: 20 }} /> {usertype}{' '}
              </Link>
            </Grid>
          </Grid>

          <span
            style={{
              fontSize: 12,
              color: '#504D4D',
              textAlign: 'left',
              display: 'block',
              padding: 5,
            }}
          >
            {about.bio}
          </span>

          <Grid
            container
            xs={12}
            direction="row"
            alignItems="center"
            style={{ height: 36, marginTop: 30, marginBottom: 20 }}
          >
            <Grid xs={2} item>
              <FollowAndFollowingDialogDesktop
                verb="followers"
                followcount={followers}
                username={user.username}
              />
            </Grid>
            <Grid xs={2} item>
              <FollowAndFollowingDialogDesktop
                verb="following"
                followcount={following}
                username={user.username}
              />
            </Grid>
          </Grid>
        </Box>
      </Hidden>
    </div>
  )
}

function DisplayAvatar({ avatar, user, current, history }) {
  if (avatar) {
    const imageAws = avatar.includes('http')
      ? avatar
      : avatar
      ? awsImage('profilepictures', avatar, 200, 'contain')
      : '/female_avatar.png'

    return (
      <Avatar
        alt={user.firstname}
        style={{ border: '4px solid white' }}
        className="avatar-card"
        src={imageAws}
        onClick={() => {
          if (user.username === current.username) {
            history.push('/settings')
          }
        }}
        variant="circle"
      />
    )
  }

  return (
    <div>
      {user.username === current.username ? (
        <UploadProfilePictureOnProfile user={user.username} place="profile" />
      ) : null}
      <Avatar
        alt="User Avatar"
        className="avatar-card"
        onClick={() => {
          if (user.username === current.username) {
            history.push('/settings')
          }
        }}
        src="/female_avatar.png"
        variant="rounded"
      />
    </div>
  )
}
export default withRouter(ProfileCoverInfo)
