import React, { useEffect, useState } from 'react'
import ProfileCoverInfo from './profile_cover_info'
import PropTypes from 'prop-types'
import Portfolio from '../../portfolio/portfolio'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import SwipeableViews from 'react-swipeable-views'
import '../Profile.css'
import { Mixpanel } from '../../../Mixpanel'
import { fetchUser } from '../../../store/actions/userActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import { connect } from 'react-redux'
import Hidden from '@material-ui/core/Hidden'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import FollowAndFollowingDialogDesktop from '../../follow/follow_and_following_dialog_desktop'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import IconButton from '@material-ui/core/IconButton'
import { awsImage } from '../../../Helpers'
import IsFollowingOrFollow from './is_following_or_follow'
import { withRouter } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import ContestEntryProfile from '../../contest/contest_entry_profile'
import WelcomeIntro from '../../appmessages/welcome_intro'
import { Box } from '@material-ui/core'

function TabContainer({ children, dir }) {
  return <Box dir={dir}>{children}</Box>
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
}

function ProfileContainer({
  fetchUser,
  about,
  avatar,
  user,
  auth,
  battlewon,
  followers,
  following,
  current,
  match,
  props,
  contests,
  fetching,
}) {
  const theme = useTheme()

  const [isValue, setIsValue] = useState(0)
  useEffect(() => {
    Mixpanel.track('Profile Pageview')

    fetchUser(match.params.user_username)
  }, [fetchUser, match.params.user_username])

  const handleChange = (event, value) => {
    setIsValue(value)
  }

  const handleChangeIndex = (index) => {
    setIsValue(index)
    Mixpanel.track(index + ' Pageview')
  }

  const handleSettings = () => {
    props.history.push('/settings')
  }

  let usertype
  if (about) {
    switch (about.usertype) {
      case 'modelUser':
        usertype = 'Model'
        break
      case 'photographer':
        usertype = 'Photographer'
        break
      case 'Designer':
        usertype = 'Designer'
        break
      case 'universe':
        usertype = 'Fan'
        break
      case 'Model':
        usertype = 'Model'
        break
      case 'Artist':
        usertype = 'Artist'
        break
      case 'Dancer':
        usertype = 'Dancer'
        break
      case 'Fashion':
        usertype = 'Fashion'
        break
      case 'Makeup Artist':
        usertype = 'MUA'
        break
      case 'Photographer':
        usertype = 'Photographer'
        break
      default:
        usertype = undefined
    }
  }

  let imageAws = '/female_avatar.png'
  if (avatar) {
    imageAws = avatar.photoPath.includes('http')
      ? avatar.photoPath
      : avatar
      ? awsImage('profilepictures', avatar.photoPath, 350, 'contain')
      : '/female_avatar.png'
  }

  if (Object.keys(user).length && !fetching) {
    document.title = `Seensta | ${user.firstname} ${user.lastname} Profile `
    if (!about || (!about.location && current.username === user.username)) {
      return <WelcomeIntro />
    }
    return (
      <div>
        <Hidden only={['lg', 'xl', 'md']}>
          <div className="card-container" style={{ background: '#fff' }}>
            <ProfileCoverInfo
              user={user}
              auth={auth}
              avatar={avatar}
              current={current}
              about={about}
              badge={battlewon}
              followers={followers}
              following={following}
            />

            <AppBar
              position="relative"
              style={{
                background: 'inherit',
                top: 47,
                boxShadow:
                  '0px 2px 0px -1px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 1px 0px rgb(0 0 0 / 12%)',
              }}
            >
              <Tabs
                value={isValue}
                onChange={handleChange}
                indicatorColor="primary"
                dir={null}
                variant="fullWidth"
                style={{
                  //borderTop:'1px solid #a8a5ca',
                  background: 'inherit',
                  color: '#000',
                  justifyContent: 'center',
                  textTransform: 'lowercase',
                }}
              >
                <Tab label="posts" />
                {user.username === current.username && <Tab label="contests" />}
              </Tabs>
            </AppBar>
          </div>
          <div style={{ height: 'auto' }}>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={isValue}
              onChangeIndex={handleChangeIndex}
            >
              <TabContainer dir="profile">
                <Portfolio username={match.params.user_username} mobile={true} />
              </TabContainer>

              {user.username === current.username && (
                <TabContainer dir="contest">
                  <div style={{ marginTop: 60 }}>
                    {contests &&
                      contests.map((contest, index) => (
                        <ContestEntryProfile contestEntry={contest} key={index} />
                      ))}
                  </div>
                </TabContainer>
              )}
            </SwipeableViews>
          </div>
        </Hidden>

        <Hidden only={['sm', 'xs']}>
          <div>
            <Card className="profile-card-user">
              <div>
                {usertype && <Chip label={usertype} className="usertype-label" />}
                <IconButton
                  variant="contained"
                  size="small"
                  color="secondary"
                  aria-label="Add"
                  href="/settings/avatar"
                  className="usertype-avatarupload"
                >
                  {user.username === current.username ? <PhotoCamera /> : null}
                </IconButton>
                <CardMedia
                  className="profile-image"
                  image={imageAws}
                  title="User"
                  style={{ backgroundPosition: 'top' }}
                />
              </div>
              <div style={{ width: '100%', textAlign: 'center', overFlowY: 'scroll' }}>
                <CardContent style={{ textAlign: 'center' }}>
                  <Typography variant="h5">
                    {user.firstname} {user.lastname}
                  </Typography>
                  <IsFollowingOrFollow user={user.username} current={current} />
                  <Typography variant="overline" color="textSecondary">
                    {user.username} | {about && about.location}
                  </Typography>

                  <div>
                    {user.username === current.username ? (
                      <div>
                        <Button
                          variant="outlined"
                          size="small"
                          color="default"
                          onClick={handleSettings}
                        >
                          Edit Profile
                        </Button>
                      </div>
                    ) : null}
                  </div>

                  <div className="flex-grid-follow-container padding50">
                    <div className="col padding20">
                      <FollowAndFollowingDialogDesktop
                        verb="Followers"
                        followcount={followers}
                        username={user.username}
                      />
                    </div>

                    <div className="col padding20">
                      <FollowAndFollowingDialogDesktop
                        verb="Following"
                        followcount={following}
                        username={user.username}
                      />
                    </div>
                  </div>

                  <Typography variant="body1">{about && about.bio}</Typography>
                </CardContent>
              </div>
            </Card>
            <h2 className="work-line">
              <span>Work</span>
            </h2>

            <div style={{ maxWidth: 1300, margin: '0 auto' }}>
              <Portfolio username={match.params.user_username} />
            </div>
          </div>
        </Hidden>
      </div>
    )
  } else {
    return (
      <div style={{ paddingTop: '100px', paddingBottom: '100px', textAlign: 'center' }}>
        <CircularProgress />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  if (state.auth.username === ownProps.match.params.user_username) {
    return {
      user: state.auth.user,
      about: state.auth.About,
      avatar: state.auth.avatar,
      battlewon: state.user.battlewon,
      props: ownProps,
    }
  } else {
    return {
      about: state.user.about,
      avatar: state.user.avatar,
      user: state.user.user,
      auth: state.auth.auth,
      fetching: state.user.fetching,
      battlewon: state.user.battlewon,
      followers: state.user.followers,
      following: state.user.following,
      current: state.auth.user,
      contests: state.user.contests,
      props: ownProps,
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: (username) => dispatch(fetchUser(username)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileContainer))

// export default withRouter(
//   connect(
//     mapStateToProps,
//     mapDispatchToProps
//   )(withStyles(styles, { withTheme: true })(ProfileContainer))
// )
