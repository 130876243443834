import React, { useEffect, useState } from 'react'
import { getFeaturedGig } from '../../store/actions/gigActions'
import { connect } from 'react-redux'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import GigFeaturedLists from './gig_featured_lists'
import CircularProgress from '@material-ui/core/CircularProgress'
import FeaturedLabel from '../../components/display/featured_label'
import { Divider } from '@material-ui/core'

function FeaturedGigs({ getFeaturedGig, token, history }) {
  const [featuredGig, setFeaturedGig] = useState([])
  const [isFetching, setIsFetching] = useState(true)

  useEffect(() => {
    setIsFetching(true)
    const handleFetchFeaturedGig = async () => {
      const featuredGig = await getFeaturedGig(token)
      setFeaturedGig(featuredGig.data.featuredGigs)
      setIsFetching(false)
    }
    handleFetchFeaturedGig()
  }, [getFeaturedGig, token, setFeaturedGig])

  useEffect(() => {
    if (featuredGig.length && isFetching) {
      setIsFetching(false)
    }
  }, [featuredGig.length, isFetching])

  if (featuredGig.length === 0) {
    return null
  }

  return (
    <Box style={{ padding: 10 }}>
      <Paper style={{ boxShadow: 'none' }}>
        {isFetching ? (
          <Box style={{ textAlign: 'center' }}>
            <CircularProgress size={20} />
          </Box>
        ) : (
          <Box>
            <FeaturedLabel text="Featured Gigs" width={130} />
            {featuredGig
              ? featuredGig.map((gig, index) => {
                  return (
                    <Box key={index}>
                      <GigFeaturedLists gig={gig} history={history} />
                      {index !== featuredGig.length - 1 && <Divider />}
                    </Box>
                  )
                })
              : null}{' '}
          </Box>
        )}
      </Paper>
    </Box>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFeaturedGig: (token) => dispatch(getFeaturedGig(token)),
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
  token: state.auth.token,
})

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedGigs)
