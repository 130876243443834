import React, { useReducer, useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { postGig, updateGig } from '../../store/actions/gigActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import { connect } from 'react-redux'
import uuid from 'uuid'
import PageHeading from '../../components/display/page_heading'
import Header from '../../components/layout/header'
import { Grid } from '@material-ui/core'
import SignUpDialog from '../../components/auth/sign_up_dialog'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    boxShadow: 'none',
    background: 'inherit',
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    flexBasis: 200,
  },
}))

const GigType = [
  { value: 'All Productions', label: 'All Productions' },
  { value: 'Theater', label: 'Theater' },
  { value: 'Actors', label: 'Actors' },
  { value: 'TV/Video', label: 'TV/Video' },
  { value: 'Commercials', label: 'Commercials' },
  { value: 'Models', label: 'Models' },
  { value: 'Photographers', label: 'Photographers' },
  { value: 'Designers', label: 'Designers' },
  { value: 'Makeup Artist', label: 'Makeup Artist' },
  { value: 'Performing Art', label: 'Performing Art' },
  { value: 'Dancers', label: 'Dancers' },
]

const GigGender = [
  { value: 'female', label: 'Female' },
  { value: 'male', label: 'Male' },
  { value: 'none-binary', label: 'none-binary' },
]

function CreateGig({ postGig, updateGig, token, props, location, auth }) {
  const [progress, setProgress] = useState(false)
  const [posted, setPosted] = useState(false)
  const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }), {
    gigTitle: location.state ? location.state.gig.gig_title : '',
    gigCategory: location.state ? location.state.gig.gig_category : '',
    gigLocation: location.state ? location.state.gig.gig_location : '',
    gigGender: location.state ? location.state.gig.gig_gender : '',
    gigAge: location.state ? location.state.gig.gig_age : '',
    gigPay: location.state ? location.state.gig.gig_compensation : '',
    gigDescription: location.state ? location.state.gig.gig_description : '',
    gigStartDate: location.state ? location.state.gig.gig_start_date : '',
    gigEndDate: location.state ? location.state.gig.gig_end_date : '',
  })
  const [signUp, setSignUp] = useState(false)
  const [hasOpen, setHasOpen] = useState(false)
  const [timeForPrint, setTimeForPrint] = useState(false)
  const handleChange = (event) => {
    const name = event.target.name
    const newValue = event.target.value
    setUserInput({ [name]: newValue })
  }

  const handleFormSubmit = (dataAction) => {
    setProgress(true)
    const data = { ...userInput }
    if (dataAction.completeType === 'create') {
      data.gig_id = `gig-${uuid.v4()}`
      postGig(data, token)
        .then(() => {
          if (!signUp) {
            setProgress(false)
            setPosted(true)
          }
        })
        .catch(() => {
          if (!signUp) {
            setProgress(false)
            setPosted(false)
          }
        })
      return
    }

    data.resourceID = props.location.state.gig.gig_id
    updateGig(data, token)
      .then(() => {
        if (!signUp) {
          setProgress(false)
          setPosted(true)
        }
      })
      .catch(() => {
        if (!signUp) {
          setProgress(false)
          setPosted(false)
        }
      })
  }

  const classes = useStyles()
  const completeType = props.location.state ? 'update' : 'create'

  if (!posted) {
    return (
      <div>
        <Header />
        <PageHeading title="Post a Gig" message="Request a creative for your next project." />
        {signUp ? (
          <SignUpDialog
            buttonText="Post a Gig"
            variant="contained"
            color="primary"
            headerMessage="Please Join to post a gig."
            handleFormSubmit={handleFormSubmit}
            hasOpen={hasOpen}
          />
        ) : (
          <div style={{ marginTop: 10 }}>
            <Paper
              elevation={0}
              style={{ maxWidth: 600, width: '100%', margin: '0 auto', background: 'inherit' }}
            >
              <Typography variant="h6" style={{ textAlign: 'center' }}>
                {props.location.state ? 'Update Gig' : 'Post a Gig'}
              </Typography>
              <Divider />
              <div className={classes.root}>
                <form
                  style={{ padding: 20 }}
                  onSubmit={(e) => {
                    if (!auth) {
                      setHasOpen(true)
                      setSignUp(true)
                    } else {
                      e.preventDefault()
                      handleFormSubmit({ completeType: completeType })
                    }
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        variant="filled"
                        label="gig title"
                        name="gigTitle"
                        onChange={handleChange}
                        value={userInput.gigTitle}
                        required
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        name="gigLocation"
                        value={userInput.gigLocation}
                        required
                        label="location"
                        onChange={handleChange}
                        variant="filled"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        id="type-of-gig"
                        name="gigCategory"
                        select
                        label="Category"
                        value={userInput.gigCategory}
                        onChange={handleChange}
                        SelectProps={{
                          native: true,
                        }}
                        variant="filled"
                        fullWidth
                      >
                        {GigType.map((ele, index) => {
                          return (
                            <option value={ele.value} key={index}>
                              {ele.label}
                            </option>
                          )
                        })}
                      </TextField>
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        id="gig-age"
                        name="gigAge"
                        value={userInput.gigAge}
                        required
                        onChange={handleChange}
                        variant="filled"
                        label="applicant age"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="type-of-gig"
                        aria-label="gender"
                        name="gigGender"
                        select
                        value={userInput.gigGender}
                        onChange={handleChange}
                        label="gender"
                        variant="filled"
                        SelectProps={{
                          native: true,
                        }}
                        fullWidth
                      >
                        {GigGender.map((ele, index) => {
                          return (
                            <option value={ele.value} key={index}>
                              {ele.label}
                            </option>
                          )
                        })}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={timeForPrint}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setUserInput({ gigPay: 'TFP' })
                              } else {
                                setUserInput({ gigPay: '' })
                              }

                              setTimeForPrint(!timeForPrint)
                            }}
                            name="timeForPrint"
                            color="primary"
                          />
                        }
                        label={
                          <Typography variant="body1">
                            Time for Picture/Work{' '}
                            <Typography variant="caption">
                              Agrees to provide the talent with a license to use the creative work.
                              The payment is that each person gets to use the work created together
                              in their own catelog.
                            </Typography>
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="payout"
                        variant="filled"
                        name="gigPay"
                        label="payout"
                        required
                        value={userInput.gigPay}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="start-date"
                        variant="filled"
                        type="date"
                        label="start date"
                        value={userInput.gigStartDate}
                        required
                        name="gigStartDate"
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="end date"
                        variant="filled"
                        type="date"
                        name="gigEndDate"
                        label="end date"
                        required
                        value={userInput.gigEndDate}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="description"
                        variant="filled"
                        name="gigDescription"
                        label="details"
                        required
                        value={userInput.gigDescription}
                        onChange={handleChange}
                        fullWidth
                        multiline
                      />
                    </Grid>
                    <div style={{ textAlign: 'center', width: '100%', padding: 10 }}>
                      <div style={{ display: 'block', textAlign: 'center' }}>
                        {progress ? <CircularProgress /> : null}
                      </div>
                      <Button variant="contained" size="large" color="primary" type="submit">
                        {props.location.state ? 'Update Gig' : 'Post Gig'}
                      </Button>
                    </div>
                  </Grid>
                </form>
              </div>
            </Paper>
          </div>
        )}
      </div>
    )
  } else {
    return (
      <div style={{ marginTop: 60 }}>
        <Header />

        <Paper
          elevation={0}
          style={{ maxWidth: 600, width: '100%', margin: '0 auto', minHeight: 200 }}
        >
          <Typography variant="h6" style={{ textAlign: 'center', display: 'block', padding: 10 }}>
            Thank you. Your collabration has been sent.
          </Typography>
          <Typography
            variant="caption"
            style={{ textAlign: 'center', display: 'block', width: '100%' }}
          >
            We will reach-out to you before your collabration request goes live.
          </Typography>
          <Divider />
          <div style={{ textAlign: 'center', width: '100%', marginTop: 20 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                props.history.push('/gigs/admin/self')
              }}
            >
              View Posted Gig
            </Button>

            <Button
              variant="text"
              color="primary"
              onClick={() => {
                setPosted(false)
                setUserInput({})
              }}
            >
              Post Another
            </Button>
          </div>
        </Paper>
      </div>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    token: state.auth.token,
    props: ownProps,
    auth: state.auth.auth,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    postGig: (data, token) => dispatch(postGig(data, token)),
    updateGig: (data, token) => dispatch(updateGig(data, token)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateGig)
