import React, { useEffect } from 'react'

function Prohibited() {
  useEffect(() => {
    document.title = 'Seensta | Prohibited Items'
  }, [])

  return (
    <div style={{ maxWidth: 800, color: '#000', margin: '0 auto', marginTop: 100 }}>
      <h3>Prohibited Items</h3>
      <h5>
        Seensta strictly prohibits the sale of illegal items, including but not limited to replicas
        or fakes, products derived from threatened or extinct species*, anything requiring a
        prescription, misrepresented African arts and crafts, and stolen goods. Members who buy,
        make, or otherwise attempt to display such illegal items risk immediate and permanent
        suspension from Seensta. For example, the use of the words "inspired by" followed by a brand
        (for example "inspired by Gucci") is prohibited on our platform.
      </h5>
      <h5>
        Seensta also prohibits the making and selling of items that cannot be shipped using Priority
        Mail per USPS regulations, including nail polish, perfumes and aerosols.
      </h5>

      <h2>
        Selling or making items that endanger African Wildlife or the African Environment is
        prohibited. No exceptions.
      </h2>
    </div>
  )
}

export default Prohibited
