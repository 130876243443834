import React, { useState } from 'react'
import queryString from 'query-string'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import Button from '@material-ui/core/Button'
import { loginUserSocial } from '../../store/actions/authActions'
import { Mixpanel } from '../../Mixpanel'
import { fetchEntry, voteContest } from '../../store/actions/contestActions'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Header from '../layout/header'
import Alert from '@material-ui/lab/Alert'
import WelcomeIntro from '../appmessages/welcome_intro'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

import SignUpForm from '../auth/sign_up_form'
function FacebookCallback({ loginUserSocial, voteContest }) {
  const queryParams = queryString.parse(window.location.search)
  const resourceId = queryString.parse(queryParams.state)
  const [fetching, setFetching] = useState(true)
  const [didVote, setDidVote] = useState(false)

  const responseFacebook = async (response) => {
    if (response.email) {
      const name = response.name.split(' ')
      setFetching(true)

      const socialUserData = {
        firstName: name[1],
        lastName: name[0],
        email: response.email,
        access_token: response.accessToken,
        service: 'facebook',
        expires_in: response.expiresIn,
        user_id: response.id,
      }

      if (response.picture.data) {
        socialUserData.avatar = response.picture.data.url
      }

      const createdUser = await loginUserSocial(socialUserData)

      if (createdUser) {
        if (createdUser && createdUser.status === 'created') {
          Mixpanel.track('Contest Vote Registration Facebook')
          const postData = {
            contest_id: resourceId.contestId,
          }
          const actionResponse = await voteContest(postData, createdUser.data.access_token)
          setDidVote(true)
          if (actionResponse.status === 212) {
            return
          }
          Mixpanel.track('Contest Voted Facebook')
        } else if (createdUser.status === 'exists') {
          Mixpanel.track('Contest Vote Login Facebook')
          const postData = {
            contest_id: resourceId.contestId,
          }
          const actionResponse = await voteContest(postData, createdUser.access.access_token)
          setDidVote(true)
          if (actionResponse.status === 212) {
            return
          }
          Mixpanel.track('Contest Voted Facebook')
        }
      }

      setFetching(false)
    }
  }

  if (!fetching && !didVote) {
    return (
      <Box>
        <Header />
        <div style={{ textAlign: 'center', marginTop: 60 }}></div>
        <Alert security="info">
          <b>Sorry! </b>We could not complete your vote. Please login or join to vote.
        </Alert>
        <Box style={{ padding: 10 }}>
          <SignUpForm showSocial={true} />
        </Box>
      </Box>
    )
  }
  if (didVote) {
    return (
      <div>
        <Header />
        <div style={{ textAlign: 'center', marginTop: 60 }}></div>
        <Alert security="success">
          <b>Voting Complete! </b>Thank you for voting.
        </Alert>
        <WelcomeIntro />
      </div>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
        autoLoad={false}
        fields="name,email,picture"
        callback={(e) => responseFacebook(e)}
        redirectUri={window.location.href}
        render={(renderProps) => (
          <Button
            style={{ color: '#fff', background: 'inherit', boxShadow: 'none' }}
            variant="contained"
            onClick={renderProps.onClick}
          ></Button>
        )}
      />
      <CircularProgress />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    entry: state.contest.entry,
    entryid: ownProps.match.params.entryid,
    voted: state.contest.voted,
    auth: state.auth.auth,
    props: ownProps,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntry: (contest_id) => dispatch(fetchEntry(contest_id)),
    voteContest: (contest_id) => dispatch(voteContest(contest_id)),
    loginUserSocial: (credentials) => dispatch(loginUserSocial(credentials)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FacebookCallback))
