import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import Select from '@material-ui/core/Select'
import './about.css'
import { Countries } from './countries'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Api from '../../Api'

const styles = (theme) => ({
  root: {
    maxWidth: 500,
    width: '100%',
    marginTop: theme.spacing(1),
    margin: '0 auto',
    boxShadow: 'none',
  },
  container: {
    position: 'relative',
  },
  divider: {
    height: theme.spacing(1),
  },
  textareaBox: {
    width: '100%',
  },
})

function AboutInfo({ token, user, about, classes }) {
  const [bio, setBio] = useState(about ? about.bio : '')
  const [submitted, setSubmitted] = useState(false)
  const [country, setCountry] = useState(about ? about.location : '')

  const handleSubmit = () => {
    const aboutinfo = {
      bio: bio,
      location: country,
    }
    const headers = {
      Authorization: 'Bearer ' + token,
    }
    Api.put(`about`, aboutinfo, { headers: headers }).then((res) => {
      if (res) setSubmitted(true)
    })
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitted(false)
    }, 3000)

    return () => {
      clearTimeout(timeoutId)
    }
  })

  const countryselects = () => {
    return (
      <div>
        <Select
          native
          onChange={(e) => {
            setCountry(e.target.value)
          }}
          inputProps={{
            name: 'location',
            id: 'age-native-simple',
          }}
          defaultValue={about ? about.location : ''}
        >
          {Countries.map((part, index) => {
            if (Object.keys(user).length === 0 && user.constructor === Object) {
              return (
                <option key={index} value={part.label}>
                  {part.label}
                </option>
              )
            } else {
              if (about && part.label === about.location) {
                return (
                  <option key={index} value={part.label}>
                    {part.label}
                  </option>
                )
              } else {
                return (
                  <option key={index} value={part.label}>
                    {part.label}
                  </option>
                )
              }
            }
          })}
        </Select>
      </div>
    )
  }

  return (
    <Box maxWidth={400} marginTop={5}>
      <Paper>
        <Box padding={5}>
          {countryselects()}
          <div style={{ marginBottom: 100 }}>
            <TextField
              id="outlined-full-width3"
              placeholder="Tell us your story."
              multiline
              rowsMax="8"
              className={`${classes.textareaBox}`}
              margin="normal"
              defaultValue={about ? about.bio : ''}
              variant="outlined"
              name="bio"
              onChange={(e) => setBio(e.target.value)}
              style={{ maxWidth: '500px !important' }}
            />
            <Button
              fullWidth
              variant="contained"
              color={submitted ? 'secondary' : 'primary'}
              onClick={handleSubmit}
              className={classes.submit}
              disabled={submitted}
            >
              {submitted ? 'Updated' : 'Save'}
            </Button>
          </div>
        </Box>
      </Paper>
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    token: state.auth.token,
    about: state.auth.about,
  }
}
export default connect(mapStateToProps, null)(withStyles(styles)(AboutInfo))
