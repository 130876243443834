import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import SignUpForm from './sign_up_form'

export default function SignUpDialog(props) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = (e) => {
    e.stopPropagation()
    setOpen(true)
  }

  const handleClose = (e) => {
    e.stopPropagation()

    setOpen(false)
  }

  return (
    <div>
      {!props.hasOpen && (
        <Button variant={props.variant} color={props.color} onClick={handleClickOpen}>
          {props.buttonText}
        </Button>
      )}
      <Dialog
        open={open || props.hasOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
          {props.headerMessage}
        </DialogTitle>

        <DialogContent>
          <SignUpForm handleFormSubmit={props.handleFormSubmit} from="gigs" />
        </DialogContent>
      </Dialog>
    </div>
  )
}
