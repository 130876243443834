import React, { useEffect, useState } from 'react'
import NewImageGrid from './image_grid'
import { connect } from 'react-redux'
import { fetchPortfolio, fetchPortfolioMore } from '../../../store/actions/portfolioActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import Divider from '@material-ui/core/Divider'
import '../Grid.css'
import ExploreSearch from '../../layout/explore_search'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import queryString from 'query-string'
import PageHeading from '../../display/page_heading'
import ExpandRefresh from '@material-ui/icons/Refresh'

function Explore({
  fetching,
  user,
  token,
  fetchPortfolio,
  trend,
  newContent,
  auth,
  fetchPortfolioMore,
  history,
}) {
  const [tabValue, setTabValue] = useState()
  const [isFetchingMore, setIsFetchingMore] = useState(fetching)
  useEffect(() => {
    if (window.location.search) {
      const search = queryString.parse(history.location.search)
      setTabValue(search.tab)
    }
  }, [history.location.search, setTabValue])

  useEffect(() => {
    if (!window.location.search) {
      history.replace({
        pathname: history.location.pathname,
        search: queryString.stringify({ tab: 'popular' }),
      })
    }
  }, [history])

  useEffect(() => {
    const handleFetchPortfolio = async (type) => {
      fetchPortfolio({
        page: 1,
        token: token,
        term: null,
        resultType: type,
        query: '',
      })
    }
    switch (tabValue) {
      case 'popular':
        handleFetchPortfolio('whatshot')
        break
      case 'new':
        handleFetchPortfolio('new')
        break
      default:
        return
    }
  }, [fetchPortfolio, history.location.search, tabValue, token])

  const handleChange = async (event, newValue) => {
    setTabValue(newValue)
    history.replace({
      pathname: history.location.pathname,
      search: queryString.stringify({ tab: newValue }),
    })
  }

  const handleMore = async () => {
    //Calculate Page
    setIsFetchingMore(true)

    switch (tabValue) {
      case 'popular': {
        const numberofitems = trend.length + 1
        const page = Math.round(numberofitems / 20 + 1)
        await fetchPortfolioMore({
          page: page,
          token: token,
          resultType: 'whatshot',
          query: '',
        })

        setIsFetchingMore(false)
        break
      }
      case 'new': {
        const numberofitems = newContent.length + 1
        const page = Math.round(numberofitems / 20 + 1)
        await fetchPortfolioMore({
          page: page,
          token: token,
          resultType: 'new',
          query: '',
        })
        setIsFetchingMore(false)
        break
      }
      default:
        return null
    }
  }

  //render the view

  const Cards = () => {
    switch (tabValue) {
      case 'popular': {
        return trend
          .filter((card) => card.user)
          .map((card, index) => {
            return (
              <Box key={index} className={'card-grid-container-explore'}>
                <Box
                  style={{
                    paddingTop: 3,
                    paddingBottom: 2,
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}
                >
                  <NewImageGrid content={card} auth={auth} nowuser={user.username} />
                </Box>
              </Box>
            )
          })
      }
      case 'new': {
        return newContent
          .filter((card) => card.user)
          .map((card, index) => {
            return (
              <Box key={index} className={'card-grid-container-explore'}>
                <Box
                  style={{
                    paddingTop: 3,
                    paddingBottom: 2,
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}
                >
                  <NewImageGrid content={card} auth={auth} nowuser={user.username} />
                </Box>
              </Box>
            )
          })
      }
      default:
        return null
    }
  }

  return (
    <Box>
      <Hidden only={['xs', 'sm']}>
        <Box style={{ flexGrow: 1 }}>
          <Box style={{ flexDirection: 'row', width: '100%', flexWrap: 'wrap' }}>
            <Cards />
          </Box>
          <div style={{ textAlign: 'center' }}>
            {isFetchingMore ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                color="default"
                onClick={handleMore}
                startIcon={<ExpandRefresh />}
                style={{ boxShadow: 'none', textTransform: 'lowercase', padding: 10 }}
              >
                Load More
              </Button>
            )}
          </div>
        </Box>
      </Hidden>

      <Hidden only={['lg', 'xl', 'md']}>
        <Box style={{ flexGrow: 1, paddingTop: 20 }}>
          <PageHeading title="explore" />

          <ExploreSearch showPanel={true} />
          <Divider />

          {tabValue && (
            <Tabs
              style={{ justifyContent: 'center' }}
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              variant="fullWidth"
            >
              <Tab label="Popular" value="popular" style={{ textTransform: 'lowercase' }} />
              <Tab label="New" value="new" style={{ textTransform: 'lowercase' }} />
            </Tabs>
          )}

          <Box style={{ flexDirection: 'row', width: '100%', flexWrap: 'wrap' }}>
            {fetching ? (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress />{' '}
              </div>
            ) : (
              <Cards />
            )}
          </Box>
          <div style={{ textAlign: 'center' }}>
            {isFetchingMore ? (
              <CircularProgress />
            ) : (
              <div>
                {(newContent.length + 1) / 20 >= 1 && tabValue === 'new' && (
                  <div style={{ padding: 20 }}>
                    <Button
                      variant="contained"
                      color="default"
                      onClick={handleMore}
                      startIcon={<ExpandRefresh />}
                      style={{ boxShadow: 'none', textTransform: 'lowercase', padding: 10 }}
                    >
                      Load More
                    </Button>
                  </div>
                )}

                {tabValue === 'popular' && (trend.length + 1) / 20 >= 1 && (
                  <div style={{ padding: 20 }}>
                    <Button
                      variant="text"
                      size="small"
                      color="secondary"
                      onClick={handleMore}
                      style={{ width: '80%' }}
                    >
                      <ExpandMore />
                      Load More
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
          <div style={{ marginBottom: 80 }}></div>
        </Box>
      </Hidden>
    </Box>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPortfolio: (queryData) => {
      dispatch(fetchPortfolio(queryData))
    },
    fetchPortfolioMore: (queryData) => {
      dispatch(fetchPortfolioMore(queryData))
    },
  }
}

const mapStateToProps = (state) => ({
  trend: state.portfolio.trend,
  newContent: state.portfolio.newContent,
  auth: state.auth.auth,
  fetching: state.portfolio.fetching,
  user: state.auth.user,
  featuredusers: state.portfolio.featuredusers,
})

export default connect(mapStateToProps, mapDispatchToProps)(Explore)
