import React from 'react'
import { Typography, Button } from '@material-ui/core'
import { Mixpanel } from '../../Mixpanel'
import HeaderSimple from '../layout/header_simple'
import { awsImage } from '../../Helpers'

function StyleExplain(props) {
  return (
    <div>
      <HeaderSimple />
      <div style={{ marginTop: 64, textAlign: 'center' }}>
        <Typography variant="h4" style={{ fontWeight: 300 }}>
          Discover Africa
        </Typography>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <img
            src={awsImage('media', 'introphoto.png', 250, 'contain')}
            alt="intro"
            style={{ width: 250, height: 250 }}
          />
        </div>
        <Typography variant="h6" style={{ fontWeight: 200 }}>
          Let&apos;s figure out your African preferences. This will help us better curate the right
          content about Africa for you.
        </Typography>

        <div>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              Mixpanel.track('Begin Clicked')
              props.history.push('/connect/guide')
            }}
          >
            Begin
          </Button>
        </div>
      </div>
    </div>
  )
}
export default StyleExplain
