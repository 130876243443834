import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import { connect } from 'react-redux'
import { updateUser } from '../../store/actions/authActions'
import TextField from '@material-ui/core/TextField'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
}))

function UserInfo({ updateUser, token, updateError, user }) {
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [userName, setUserName] = useState()
  const [userPhone, setUserPhone] = useState()
  const [submitted, setSubmitted] = useState(false)
  const [hasMessage, setHasMessage] = useState()

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitted(false)
    }, 3000)

    return () => {
      clearTimeout(timeoutId)
    }
  })
  const handleUpdateSubmit = async (e) => {
    e.preventDefault()

    const userData = {
      firstname: firstName ? firstName : user.firstName,
      lastname: lastName ? lastName : user.lastName,
      username: userName ? userName : user.username,
      phone: userPhone ? userPhone : user.phone,
    }
    if (userData.firstname && userData.lastname && userData.username) {
      const updatedUser = await updateUser({ userData: userData, token: token })
      if (updatedUser.data.status.includes('exists')) {
        setHasMessage('The username has been taken.')
      }
      if (updatedUser) {
        setSubmitted(true)
      }
    }
  }

  const classes = useStyles()

  const emptyObject = !Object.keys(user).length

  if (!emptyObject) {
    const additionalInfo = user.data

    return (
      <Box marginTop={5} maxWidth={400}>
        <Paper>
          <Box p={5}>
            <form name="registration" id="register" className={classes.form}>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  required
                  id="firstname"
                  label="First Name"
                  defaultValue={user.firstName}
                  margin="normal"
                  onChange={(event) => setFirstName(event.target.value)}
                  name="first_name"
                />
              </FormControl>

              <FormControl margin="normal" required fullWidth>
                <TextField
                  required
                  id="lastname"
                  label="Last Name"
                  defaultValue={user.lastName}
                  margin="normal"
                  onChange={(event) => setLastName(event.target.value)}
                  name="last_name"
                />
              </FormControl>

              <FormControl margin="normal" required fullWidth>
                <TextField
                  required
                  id="username"
                  label="Username"
                  defaultValue={user.username}
                  margin="normal"
                  onChange={(event) => setUserName(event.target.value)}
                  name="username"
                />
              </FormControl>

              <FormControl margin="normal" fullWidth>
                <TextField
                  required
                  id="phone"
                  label="phone"
                  defaultValue={additionalInfo && additionalInfo.contact_info.phone}
                  margin="normal"
                  onChange={(event) => setUserPhone(event.target.value)}
                  name="phone"
                />
                <Typography variant="caption" style={{ color: 'red' }}>
                  {hasMessage}
                </Typography>
              </FormControl>

              <Button
                fullWidth
                variant="contained"
                color={submitted ? 'secondary' : 'primary'}
                onClick={handleUpdateSubmit}
                className={classes.submit}
                disabled={submitted}
              >
                {submitted ? 'Updated' : 'Save'}
              </Button>
            </form>
            {updateError && (
              <div style={{ textAlign: 'center', color: 'red', fontWeight: 100 }}>
                Sorry! something went wrong
              </div>
            )}
          </Box>
        </Paper>
      </Box>
    )
  } else {
    return <div>Loading</div>
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    updateError: state.auth.updateError,
    token: state.auth.token,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (data) => dispatch(updateUser(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo)
