import React, { useState, useEffect, useRef } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { createUser, loginUserSocial } from '../../store/actions/authActions'
import { Mixpanel } from '../../Mixpanel'
import CustomizedSnackbars from '../appmessages/customized_snackbars'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import ReCAPTCHA from 'react-google-recaptcha'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage:
      'url(https://d1sb0aptwihzht.cloudfront.net/eyJidWNrZXQiOiJzZWVuc3RhLWNvbnRlbnQiLCJrZXkiOiJmb2xpby9jN08wcUlfMEQ0NjNENTYtNjE3Qy00QTVFLThDMDUtNzMyNDdEMDcyQUYyLmpwZyIsImVkaXRzIjp7InJlc2l6ZSI6eyJ3aWR0aCI6NzAwLCJmaXQiOiJjb250YWluIn19fQ==)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))
function SignUpForm({
  auth,
  createUser,
  createError,
  fetching,
  history,
  loginUserSocial,
  showSocial,
  handleFormSubmit,
  from,
}) {
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()

  useEffect(() => {
    if (auth) {
      if (from === 'gigs') {
        handleFormSubmit({ completeType: 'create' })
      }

      history.push('/onboard/user')
    }
    document.title = 'Seensta - Join'
    Mixpanel.track('Sign Up Pageview')
  }, [auth, from, handleFormSubmit, history])

  const responseFacebook = (response) => {
    if (response.email) {
      const name = response.name.split(' ')

      const socialUserData = {
        firstName: name[1],
        lastName: name[0],
        email: response.email,
        access_token: response.accessToken,
        service: 'facebook',
        expires_in: response.expiresIn,
        user_id: response.id,
      }

      if (response.picture.data) {
        socialUserData.avatar = response.picture.data.url
      }

      loginUserSocial(socialUserData).then((res) => {
        if (res) {
          Mixpanel.track('Login With Facebook')
          if (res.status === 'exists') {
            history.push('/home')
          } else {
            history.push('/onboard/user')
          }
        }
      })
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const token = await recaptchaRef.current.executeAsync()
    if (token) {
      const user = {
        firstname: firstName,
        lastname: lastName,
        email: email,
        password: password,
        token: token,
      }
      createUser(user)

      window.fbq('track', 'COMPLETE_REGISTRATION')

      Mixpanel.track('Sign Up Complete')
    }
  }

  const classes = useStyles()
  const recaptchaRef = useRef(0)

  return (
    <div style={{ textAlign: 'center' }}>
      {!showSocial && (
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
          autoLoad={false}
          fields="name,email,picture"
          redirectUri={window.location.href}
          callback={(e) => responseFacebook(e)}
          render={(renderProps) => (
            <Button
              variant="contained"
              style={{ color: '#fff', background: '#3b5998' }}
              onClick={renderProps.onClick}
            >
              Register using Facebook
            </Button>
          )}
        />
      )}
      <Typography component="h1" variant="h5">
        Register
      </Typography>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="fname"
              name="firstName"
              variant="outlined"
              defaultValue={firstName}
              required
              fullWidth
              id="firstName"
              label="First Name"
              autoFocus
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="lname"
              onChange={(e) => setLastName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
        </Grid>
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey="6LdC9DQbAAAAAPMMBeEDPvMd3NhUIiOTYFjzPH7Z"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
        >
          Join
        </Button>

        {fetching ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        ) : null}

        {createError ? (
          <CustomizedSnackbars messagetype="error" message={createError}></CustomizedSnackbars>
        ) : null}

        <Grid container justify="flex-end">
          <Grid item>
            <Link onClick={() => history.push('/signin')} variant="body2">
              Already have an account? Sign in
            </Link>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    authError: state.auth.authError,
    auth: state.auth.auth,
    fetching: state.auth.fetching,
    createError: state.auth.createError,
    props: ownProps,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createUser: (credentials) => dispatch(createUser(credentials)),
    loginUserSocial: (credentials) => dispatch(loginUserSocial(credentials)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUpForm))
