import React from 'react'
import { awsImage } from '../../../Helpers'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import UserHeader from '../../portfolio/singleimage/user_header'
import { makeStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import Bump from '../../vote/up_down_vote'
import ChallengeDialog from '../../portfolio/challenge'
import TrashItem from '../../features/trash_item'
import ModeCommentOutlinedIcon from '@material-ui/icons/ModeCommentOutlined'
const useStyles = makeStyles((theme) => ({
  '@media (min-width: 600px)': {
    card: {
      maxWidth: 400,
      marginBottom: 20,
      margin: '0 auto',
      transition: '1s',
      borderRadius: 5,
      '&:hover': {
        cursor: 'pointer',
        boxShadow: '0 2px 4px rgba(0,0,0,0.4)',
        transform: 'translateY(-5px)',
      },
    },
  },
  '@media (min-width: 768px)': {
    card: {
      maxWidth: 330,
      marginBottom: 15,
      margin: '0 auto',
      transition: '1s',
      borderRadius: 5,
      '&:hover': {
        cursor: 'pointer',
        boxShadow: '0 2px 4px rgba(0,0,0,0.4)',
        transform: 'translateY(-5px)',
      },
    },
  },
  media: {
    height: 300,
  },
  actions: {
    display: 'flex',
    width: '100%',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: '#9c27b0',
    width: 42,
    height: 42,
    marginRight: -13,
  },
  avatarTitle: {
    color: '#52183E',
    textDecoration: 'none',
    fontSize: 14,
  },
  cardHeader: {
    paddingTop: 2,
    paddingBottom: 3,
    paddingLeft: 2,
    paddingRight: 0,
  },
}))

function NewImageGrid({ content, history, nowuser = null, user = null, itemKey }) {
  const image = awsImage('folio', content.imagePath, 380, 'cover')
  const classes = useStyles()
  const showImage = () => {
    if (user && Object.keys(user).length) {
      history.push('/photo/' + content.id, {
        photo: content.imagePath,
        photoid: content.id,
        totalvotes: content.totalvotes,
        usertype: user.user.about ? user.about.usertype : null,
        username: user.user.username,
        photoPath: user.avatar ? user.avatar.photoPath : '',
        firstname: user.user.firstname,
        lastname: user.user.lastname,
      })
    } else {
      history.push('/photo/' + content.id, {
        photo: content.imagePath,
        photoid: content.id,
        totalvotes: content.totalvotes,
        usertype: content.user.about ? content.user.about.usertype : null,
        username: content.user.username,
        photoPath: content.user.avatar ? content.user.avatar.photoPath : '',
        firstname: content.user.firstName,
        lastname: content.user.lastName,
      })
    }
  }

  return (
    <Box
      style={{
        width: 360,
        margin: '0 auto',
        border: '1px solid rgb(235, 235, 235)',
        borderRadius: 25,
      }}
    >
      <Paper
        style={{
          //backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0), rgba(118, 34, 89,0.4))',
          backgroundImage: `url(${image})`,
          height: 207,
          width: 360,
          position: 'relative',
          margin: '0 auto',
          backgroundSize: 'cover',
          backgroundAttachment: 'scroll',
          backgroundRepeat: 'no-repeat',
          borderRadius: '25px 25px 0px 0px',
          opacity: 1,
          boxShadow: 'none',
          backgroundPosition: 'center',
        }}
        onClick={showImage}
      >
        <div>
          <div style={{ height: 340, maxWidth: 380 }}>
            {content.user && (
              <div
                style={{
                  position: 'absolute',
                  bottom: 20,
                  left: 20,
                }}
              >
                {content.user.about && content.user.about.location && (
                  <span
                    style={{
                      padding: 10,
                      background: '#FDFCFC',
                      borderRadius: 17,
                      color: '#504D4D',
                      width: 89,
                      height: 34,
                      textTransform: 'lowercase',
                      fontSize: 14,
                      borderColor: '1px solid #E4DBE1',
                    }}
                  >
                    {'#'}
                    {content.user.about.location}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      </Paper>
      <Box
        style={{
          opacity: 1,
        }}
        padding={2}
      >
        {content.user && (
          <UserHeader
            photoPath={content.user.avatar && content.user.avatar.photoPath}
            userName={content.user.username}
            firstName={content.user.firstName}
            lastName={content.user.lastName}
            classes={classes}
            fullwidth={false}
          >
            <span
              style={{
                color: '#504D4D',
                textTransform: 'lowercase',
                textAlign: 'left',
                fontSize: 12,
              }}
            >
              {content.user.about && content.user.about.usertype}
            </span>
          </UserHeader>
        )}
        <Box display="flex" justifyContent="space-evenly" style={{ paddingTop: 20 }}>
          {user && user.user.username === nowuser ? (
            <TrashItem item={content.id} itemkey={itemKey} />
          ) : null}

          <Bump photoid={content.id} upvotes={content.totalvotes} size={13} />
          <Button onClick={showImage} style={{ padding: 0, marginLeft: 10 }}>
            <ModeCommentOutlinedIcon style={{ color: '#707070' }} />
          </Button>
          <ChallengeDialog photo={content.imagePath} />
        </Box>
      </Box>
    </Box>
  )
}

export default withRouter(NewImageGrid)
