export const FETCH_PORTFOLIO = 'FETCH_PORTFOLIO'
export const NEW_PORTFOLIO = 'NEW_PORTFOLIO'
export const FETCH_USER = 'FETCH_USER'
export const CREATE_USER = 'CREATE_USER'
export const LOGIN_USER = 'LOGIN_USER'
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const CREATE_CHALLENGE = 'CREATE_CHALLENGE'
export const FETCH_CHALLENGE = 'FETCH_CHALLENGE'
export const CREATE_SNATCH = 'CREATE_SNATCH'
export const FETCH_SNATCH = 'FETCH_SNATCH'
export const CREATE_VOTES = 'CREATE_VOTES'
export const FETCH_VOTES = 'FETCH_VOTES'
export const USER_PHOTOS = 'USER_PHOTOS'
export const REFRESH_USER = 'REFRESH_USER'
export const FETCH_PORTFOLIO_MORE = 'FETCH_PORTFOLIO_MORE'
export const AFTER_ADD = 'AFTER_ADD'
export const FETCH_CHALLENGE_MORE = 'FETCH_CHALLENGE_MORE'
export const UPLOAD_PHOTO = 'UPLOAD_PHOTO'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_ERROR = 'UPDATE_ERROR'
export const UPLOAD_AVATAR = 'UPLOAD_AVATAR'
export const FETCH_PORTFOLIO_MORE_ERROR = 'FETCH_PORTFOLIO_MORE_ERROR'
export const FETCH_PORTFOLIO_ERROR = 'FETCH_PORTFOLIO_ERROR'
export const FETCH_PORTFOLIO_REQUEST = 'FETCH_PORTFOLIO_REQUEST'
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST'
export const FETCH_CHALLENGE_REQUEST = 'FETCH_CHALLENGE_REQUEST'
export const UPLOAD_REQUEST = 'UPLOAD_REQUEST'
export const REQUEST_VOTE = 'REQUEST_VOTE'
export const FETCH_PORTFOLIO_MORE_REQUEST = 'FETCH_PORTFOLIO_MORE_REQUEST'
export const AUTH_REQUEST = 'AUTH_REQUEST'
export const CREATE_REQUEST = 'CREATE_REQUEST'
export const PASSWORD_RESET = 'PASSWORD_RESET'
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR'
export const PASSWORD_POST = 'PASSWORD_POST'
export const PASSWORD_POST_ERROR = 'PASSWORD_POST_ERROR'
export const FETCH_CONTEST = 'FETCH_CONTEST'
export const ENTER_CONTEST = 'ENTER_CONTEST'
export const FETCH_CONTEST_MORE = 'FETCH_CONTEST_REQUEST'
export const FETCH_CONTEST_REQUEST = 'FETCH_CONTEST_REQUEST'
export const CREATE_CONTEST_ENTRY = 'CREATE_CONTEST_ENTRY'
export const FETCH_CONTEST_ENTRY = 'FETCH_CONTEST_ENTRY'
export const CREATE_ENTRY_VOTE = 'CREATE_ENTRY_VOTE'
export const VOTE_ENTRY_REQUEST = 'VOTE_ENTRY_REQUEST'
export const FETCH_COMMENT = 'FETCH_COMMENT'
export const CREATE_COMMENT = 'CREATE_COMMENT'
export const COMMENT_REQUEST = 'COMMENT_REQUEST'
export const FETCH_CHALLENGE_SINGLE = 'FETCH_CHALLENGE_SINGLE'
export const CONTEST_VOTE_ERROR = 'CONTEST_VOTE_ERROR'
export const CREATE_VOTES_ERROR = 'CREATE_VOTES_ERROR'
export const CREATE_FOLLOW = 'CREATE_FOLLOW'
export const GET_FOLLOW = 'GET_FOLLOW'
export const FOLLOW_ERROR = 'FOLLOW_ERROR'
export const REQUEST_FOLLOWING = 'REQUEST_FOLLOWING'
export const DELETE_PHOTO = 'DELETE_PHOTO'
export const FETCH_PORTFOLIO_SINGLE_REQUEST = 'FETCH_PORTFOLIO_SINGLE_REQUEST'
export const FETCH_PORTFOLIO_SINGLE = 'FETCH_PORTFOLIO_SINGLE'
export const FETCH_PORTFOLIO_SINGLE_ERROR = 'FETCH_PORTFOLIO_SINGLE_ERROR'
export const SEAMABLE_IMAGE = 'SEAMABLE_IMAGE'
export const CREATE_SEAMIT = 'CREATE_SEAMIT'
export const CREATE_SEAMIT_ERROR = 'CREATE_SEAMIT_ERROR'
export const SEAMABLE_SIZE = 'SEAMABLE_SIZE'
export const SEAMABLE_SIZE_REMOVE = 'SEAMABLE_SIZE_REMOVE'
export const SEAMABLE_BUDGET = 'SEAMABLE_BUDGET'
export const SEAMABLE_PAYMENT_TOKEN = 'SEAMABLE_PAYMENT_TOKEN'
export const SEAMABLE_FIGURE = 'SEAMABLE_FIGURE'
export const SEAMABLE_FIGURE_REMOVE = 'SEAMABLE_FIGURE_REMOVE'
export const POST_MESSAGE = 'POST_MESSAGE'
export const GET_MESSAGE = 'GET_MESSAGE'
export const GET_SEAMBLE = 'GET_SEAMBLE'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const STORE_RESET_CODE = 'STORE_RESET_CODE'
export const SEAMABLE_CLOTHESTYPE = 'SEAMABLE_CLOTHESTYPE'
export const SEAMABLE_CLOTHESTYPE_REMOVE = 'SEAMABLE_CLOTHESTYPE_REMOVE'
export const SEAM_TOKEN_REQUEST = 'SEAM_TOKEN_REQUEST'
export const SEAMABLE_PLAN = 'SEAMABLE_PLAN'

export const FETCH_PORTFOLIO_FINDER = 'FETCH_PORTFOLIO_FINDER'
export const FETCH_PORTFOLIO_FINDER_MORE = 'FETCH_PORTFOLIO_FINDER_MORE'
export const FETCH_PORTFOLIO_MORE_FINDER_REQUEST = 'FETCH_PORTFOLIO_MORE_FINDER_REQUEST'
export const FETCH_PORTFOLIO_SEARCH = 'FETCH_PORTFOLIO_SEARCH'
export const SEAMABLE_CUSTOM_SIZE = 'SEAMABLE_CUSTOM_SIZE'
export const FETCH_QUESTION = 'FETCH_QUESTION'
export const ADD_ANSWER = 'ADD_ANSWER'
export const ANSWER_OPTIONS = 'ANSWER_OPTIONS'
export const POST_GIG = 'POST_GIG'
export const VERIFY_REQUEST = 'VERIFY_REQUEST'
export const VERIFY_USER = 'VERIFY_USER'
export const UPDATE_ABOUT = 'UPDATE_ABOUT'
