import React, { useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import Drawer from '@material-ui/core/Drawer'
import Avatar from '@material-ui/core/Avatar'
import { connect } from 'react-redux'
import { refreshUser } from '../../store/actions/authActions'
import { withRouter, Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import BackIcon from '@material-ui/icons/ArrowBack'
import GavelIcon from '@material-ui/icons/Gavel'
import AddPhotoIcon from '@material-ui/icons/AddAPhoto'
import UserIcon from '@material-ui/icons/Person'
import Hidden from '@material-ui/core/Hidden'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { awsImage } from '../../Helpers'
import SearchBar from './search_bar'
import SideBar from './side_bar'
import makeStyles from '@material-ui/core/styles/makeStyles'
import VerificationBanner from '../auth/verification_banner'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'fixed',
    zIndex: 9,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    color: '#000',
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,

    marginRight: theme.spacing(1),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(3),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  avatar: {
    width: 25,
    height: 25,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}))

function MainHeader({ avatar, history, user, featuredusers, auth, confirmed }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const handleMenuClose = () => {
    setAnchorEl(null)
    setMobileMoreAnchorEl(null)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const toggleDrawer = (side, open) => () => {
    setState({ ...state, [side]: open })
  }

  const handleProfilePath = () => {
    if (!avatar) history.push('/profilepicture')
    else history.push(`/${user.username}`)
  }
  const handleSettings = () => {
    history.push('/settings')
  }
  const handleLogin = () => {
    history.push('/signin')
  }
  const handleLogo = () => {
    history.push('/')
  }

  const handleBack = () => {
    history.goBack()
  }
  const classes = useStyles()

  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
  const token = localStorage.getItem('asafo_token')

  let imageAws = '/female_avatar.png'
  if (avatar) {
    imageAws = avatar.photoPath.includes('http')
      ? avatar.photoPath
      : avatar.photoPath
      ? awsImage('profilepictures', avatar.photoPath, 350, 'contain')
      : '/female_avatar.png'
  }

  return (
    <div>
      <div className={classes.root}>
        <Hidden only={['sm', 'xs']}>
          <Drawer open={state.left} onClose={toggleDrawer('left', false)} anchor="left">
            <div className={classes.drawerHeader}>
              <img
                alt="App Logo"
                src="/Seensta_African_logo.png"
                style={{ width: '80px', left: 0, position: 'absolute', padding: 10 }}
                onClick={handleLogo}
              />
              <IconButton onClick={toggleDrawer('left', false)}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <SideBar featuredusers={featuredusers} heading="Designers" />
          </Drawer>
        </Hidden>
        <AppBar position="fixed" style={{ background: '#fff' }}>
          <Hidden only={['sm', 'xs']}>
            <Toolbar style={{ minHeight: 45, maxWidth: 1380, width: 1380, margin: '0 auto' }}>
              <img
                alt="App Logo"
                src="/Seensta_African_logo.png"
                style={{ width: '110px' }}
                onClick={handleLogo}
              />
              <div style={{ maxWidth: 650, width: '100%', justifyContent: 'center' }}>
                <SearchBar />
              </div>
              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                {auth ? (
                  <div>
                    <IconButton component={Link} to="/challenges" aria-label="New">
                      <GavelIcon />
                    </IconButton>
                    <IconButton component={Link} to="/image/upload" aria-label="Photo">
                      <AddPhotoIcon />
                    </IconButton>

                    <IconButton
                      aria-owns={isMenuOpen ? 'material-appbar' : null}
                      aria-haspopup="true"
                      onClick={handleProfilePath}
                      color="inherit"
                    >
                      <Avatar
                        alt={user.username}
                        component={Link}
                        to={`/${user.username}`}
                        src={imageAws}
                        className={classes.avatar}
                      />
                    </IconButton>
                  </div>
                ) : (
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{ color: '#fff' }}
                    className={classes.button}
                    onClick={handleLogin}
                  >
                    Login
                  </Button>
                )}
              </div>
              <div className={classes.sectionMobile}>
                {auth ? (
                  <IconButton
                    aria-owns={isMenuOpen ? 'material-appbar' : null}
                    aria-haspopup="true"
                    onClick={handleProfilePath}
                    color="inherit"
                    style={{ padding: 0 }}
                  >
                    <Avatar
                      alt={user.username}
                      component={Link}
                      to={`/${user.username}`}
                      src={imageAws}
                      className={classes.avatar}
                    />
                  </IconButton>
                ) : (
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    style={{ color: '#fff203' }}
                    className={classes.button}
                    onClick={handleLogin}
                  >
                    Login
                  </Button>
                )}
              </div>
            </Toolbar>
          </Hidden>

          <Hidden only={['xl', 'lg', 'md']}>
            <Toolbar style={{ minHeight: 45 }}>
              {history.location.pathname === '/signin' ||
              history.location.pathname === '/signup' ||
              history.location.pathname === '/' ||
              history.location.pathname === '/about' ||
              history.location.pathname === '/home' ? null : (
                <IconButton
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={handleBack}
                  style={{ marginRight: 0 }}
                >
                  <BackIcon />
                </IconButton>
              )}

              <img
                alt="App Logo"
                src="/Seensta_African_logo.png"
                style={{ width: '100px' }}
                onClick={handleLogo}
              />

              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                {auth ? (
                  <div>
                    <IconButton component={Link} to="/image/upload" aria-label="Photo">
                      <AddPhotoIcon />
                    </IconButton>
                    <IconButton component={Link} to={`/${user.username}`} aria-label="Delete">
                      <UserIcon />
                    </IconButton>

                    <IconButton
                      aria-owns={isMenuOpen ? 'material-appbar' : null}
                      aria-haspopup="true"
                      onClick={handleProfilePath}
                      color="inherit"
                    >
                      <Avatar
                        component={Link}
                        to={`/${user.username}`}
                        alt={user.username}
                        src={imageAws}
                        className={classes.avatar}
                      />
                    </IconButton>
                  </div>
                ) : (
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    style={{ color: '#fff' }}
                    className={classes.button}
                    onClick={handleLogin}
                  >
                    Login
                  </Button>
                )}
              </div>
              <div className={classes.sectionMobile}>
                {auth ? (
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ textTransform: 'lowercase' }}
                      onClick={() => history.push('/image/upload')}
                    >
                      create post
                    </Button>
                    <div style={{ padding: 5, position: 'relative', left: 10 }}>
                      <Avatar
                        component={Link}
                        to={`/${user.username}`}
                        alt={user.username}
                        src={imageAws}
                        className={classes.avatar}
                      />
                    </div>
                  </div>
                ) : (
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    style={{ color: '#fff203' }}
                    className={classes.button}
                    onClick={handleLogin}
                  >
                    Login
                  </Button>
                )}
              </div>
            </Toolbar>
          </Hidden>
        </AppBar>

        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          {token && <MenuItem onClick={handleSettings}>Settings</MenuItem>}
        </Menu>

        <Menu
          anchorEl={mobileMoreAnchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isMobileMenuOpen}
          onClose={handleMobileMenuClose}
        >
          {token && <MenuItem onClick={handleSettings}>Settings</MenuItem>}
        </Menu>
      </div>
      <VerificationBanner confirmed={confirmed} />
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  avatar: state.auth.avatar,
  auth: state.auth.auth,
  featuredusers: state.portfolio.featuredusers,
  confirmed: state.auth.confirmed,
})
const mapDispatchToProps = (dispatch) => {
  return {
    refreshUser: () => dispatch(refreshUser()),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainHeader))
