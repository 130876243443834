import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

function Footer({ handleLogo }) {
  return (
    <div className="footer">
      <div className="footer-content">
        <Grid container spacing={3}>
          <Grid item xs={6} sm={3}>
            <img
              alt="App Logo"
              src="/Seensta_African_logo.png"
              style={{ width: '100px' }}
              onClick={handleLogo}
            />
            <br />
            Seensta connects African models, photographers and designers.
            <div style={{ display: 'block' }}>
              <img
                src="/Get_it_on_Google_play.svg"
                alt="bg"
                style={{ width: 150, cursor: 'pointer' }}
                onClick={() =>
                  (window.location.href =
                    'https://play.google.com/store/apps/details?id=com.seensta.ananse')
                }
              />
              <img
                src="/Download_on_the_App_Store_Badge.svg"
                alt="bg"
                style={{ width: 150, cursor: 'pointer' }}
                onClick={() =>
                  (window.location.href = 'https://apps.apple.com/us/app/seensta/id1476448223')
                }
              />
            </div>
            <Button
              href="/terms"
              aria-label=" Africa Seensta Instagram"
              style={{ display: 'block', fontWeight: 100 }}
            >
              Terms
            </Button>
            <Button
              href="/privacy"
              aria-label=" Africa Seensta Instagram"
              style={{ display: 'block', fontWeight: 100 }}
            >
              Privacy
            </Button>
            <span style={{ display: 'block', fontWeight: 100, paddingTop: 50 }}>
              2020 &copy; Seensta
            </span>
          </Grid>

          <Grid item xs={6} sm={3}>
            <Typography variant="h6" style={{ textAlign: 'left', fontWeight: 100 }}>
              {' '}
              Discover{' '}
            </Typography>
            <Button
              href="/browse/Designer"
              aria-label="Seensta African Fashion Designers "
              style={{ display: 'block', fontWeight: 100 }}
            >
              Designers
            </Button>
            <Button
              href="/browse/Fashion"
              aria-label="Seensta African Fashion "
              style={{ display: 'block', fontWeight: 100 }}
            >
              Fashion
            </Button>
            <Button
              href="/browse/Models"
              aria-label="Seensta African Models "
              style={{ display: 'block', fontWeight: 100 }}
            >
              Models
            </Button>

            <Button
              href="/browse/Makeup%20Artist"
              aria-label="Seensta African Makup Artist "
              style={{ display: 'block', fontWeight: 100 }}
            >
              MUA
            </Button>

            <Button
              href="/browse/Photographer"
              aria-label="Seensta African Photographer "
              style={{ display: 'block', fontWeight: 100 }}
            >
              Photographers
            </Button>

            <Button
              href="/browse/Dancer"
              aria-label="Seensta African Dancer "
              style={{ display: 'block', fontWeight: 100 }}
            >
              Dancers
            </Button>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Button
              href="/faqs"
              aria-label="Frequently Asked Questions"
              style={{ display: 'block', fontWeight: 100 }}
            >
              FAQs
            </Button>

            <Button href="mailto:team@nsiza.com" style={{ display: 'block', fontWeight: 100 }}>
              Contact Us
            </Button>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography variant="h6" style={{ textAlign: 'left', fontWeight: 100 }}>
              {' '}
              Follow Us{' '}
            </Typography>
            <Button
              href="https://instagram.com/tryseensta"
              aria-label=" Africa Seensta Instagram"
              style={{ display: 'block', fontWeight: 100 }}
            >
              Instagram
            </Button>
            <Button
              href="https://facebook.com/seensta"
              aria-label=" Africa Seensta Facebook"
              style={{ display: 'block', fontWeight: 100 }}
            >
              Facebook
            </Button>

            <Button
              href="https://twitter.com/tryseensta"
              aria-label=" Africa Seensta Twitter"
              style={{ display: 'block', fontWeight: 100 }}
            >
              Twitter
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Footer
