import React from 'react'

function InstallApp({ isFor }) {
  return (
    <div style={{ padding: 10 }}>
      {isFor ? <div>Install the app and get more votes.</div> : null}
      <div style={{ display: 'block', textAlign: 'center' }}>
        <img
          src="/Get_it_on_Google_play.svg"
          alt="bg"
          style={{ width: 150, cursor: 'pointer' }}
          onClick={() =>
            (window.location.href =
              'https://play.google.com/store/apps/details?id=com.seensta.ananse')
          }
        />
        <img
          src="/Download_on_the_App_Store_Badge.svg"
          alt="bg"
          style={{ width: 150, cursor: 'pointer' }}
          onClick={() =>
            (window.location.href = 'https://apps.apple.com/us/app/seensta/id1476448223')
          }
        />
      </div>
    </div>
  )
}
export default InstallApp
