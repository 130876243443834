import React from 'react'
import { Link } from 'react-router-dom'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: 'inherit',
    marginTop: 5,
    marginBottom: 10,
    maxWidth: 500,
    margin: '0 auto',
  },
  chip: {
    marginTop: 6,
    cursor: 'pointer',
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
}))
const browseData = [
  { title: 'Models', img: '/modelmenubg.jpg' },
  { title: 'Designer', img: '/designermenubg.jpg' },
  { title: 'Fashion', img: '/fashionmenubg.jpg' },
  { title: 'Makeup Artist', img: '/makeupartistbg.JPG' },
  { title: 'Photographer', img: '/photographermenubg.jpg' },
  { title: 'Dancer', img: '/dancermenu.jpg' },
]
function TopBrowseMenu() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {browseData.map((tile, index) => (
        <Chip
          key={index}
          label={tile.title}
          component={Link}
          to={'/browse/' + tile.title}
          className={classes.chip}
          variant="outlined"
          clickable
        />
      ))}
    </div>
  )
}

export default TopBrowseMenu
