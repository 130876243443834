import React, { useEffect, useState } from 'react'
import NewImageGrid from '../home/explore/image_grid'
import { connect } from 'react-redux'
import { fetchPortfolio } from '../../store/actions/portfolioActions'
import red from '@material-ui/core/colors/red'
import { withStyles } from '@material-ui/core/styles'
import '../home/Grid.css'

const styles = (theme) => ({
  card: {
    maxWidth: 400,
    marginBottom: 30,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
})

function Portfolio({
  username,
  portfolio,
  fetchPortfolio,
  token,
  user,
  auth,
  currentUser,
  mobile = false,
}) {
  const [currentPortfolio, setCurrentPortfolio] = useState([...portfolio])

  useEffect(() => {
    if (username) {
      fetchPortfolio({ page: 1, token: token, resultType: 'username', term: username, query: null })
    }
  }, [username, fetchPortfolio, token])

  useEffect(() => {
    setCurrentPortfolio(portfolio)
  }, [portfolio])

  return (
    <div
      className="desktop-grid-container-card"
      style={{ display: 'inline-block', width: '100%', marginBottom: 150 }}
    >
      {currentPortfolio.length &&
        currentPortfolio.map((card, index) => {
          return (
            <div
              key={index}
              className={mobile ? 'card-grid-container' : ''}
              style={{ top: 50, position: 'relative' }}
            >
              <NewImageGrid
                content={card}
                auth={auth}
                nowuser={currentUser.username}
                user={user}
                itemKey={index}
              />
            </div>
          )
        })}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPortfolio: (queryData) => dispatch(fetchPortfolio(queryData)),
  }
}

const mapStateToProps = (state) => ({
  portfolio: state.portfolio.userportfolio,
  auth: state.auth.auth,
  currentUser: state.auth.user,
  user: state.user,
  token: state.auth.token,
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Portfolio))
