import React, { useEffect } from 'react'

function Privacy() {
  useEffect(() => {
    document.title = 'Seensta | Privacy Policy'
  }, [])

  return (
    <div style={{ color: '#000', maxWidth: 800, margin: '0 auto' }}>
      <div style={{ textAlign: 'center', padding: 10 }}>
        <img alt="App Logo" src="/Seensta_African_logo.png" style={{ width: '80px' }} />
      </div>
      <h5>Our Policy:</h5>
      <br />
      <p>
        Welcome to the service provided by Seensta. ("Seensta", "we", "us" and/or "our"). This
        privacy policy has been created to provide information about our company, our website and
        the services we provide (including through our mobile applications) (collectively, the
        "Services") to our users ("you" and/or "your"). This Privacy Policy sets forth Seensta's
        policy with respect to information, including personally identifiable data ("Personal
        Data"), that is collected from users of the Services. <br />
        <br />
      </p>
      <h5>Information We Collect:</h5>
      <br />
      <p>
        When you interact with us through the Services, we may collect Personal Data and other
        information from you, as further described below:
        <br />
        <br />
      </p>
      <h6>Personal Data That You Provide Through the Services:</h6>
      <br />
      <p>
        We collect Personal Data from you when you voluntarily provide such information, such as
        when you register for access to our Services and/or third party services (for example,
        username and email address provided by services such as Facebook Connect), provide us with
        credit card and other payment information, use certain Services including withdrawal of
        funds, contact us with inquiries or respond to one of our surveys. Wherever Seensta collects
        Personal Data we make an effort to provide a link to this Privacy Policy. <br />
        <br />
      </p>
      <h6>
        By voluntarily providing us with Personal Data, you are consenting to our use of it in
        accordance with this Privacy Policy. If you provide Personal Data, you acknowledge and agree
        that such Personal Data may be transferred from your current location to the offices and
        servers of Seensta and the authorized third parties referred to herein located in the United
        States.
      </h6>
      <br />
      <h6>Non-Identifiable or Aggregated Data: </h6>
      <p>
        When you interact with Seensta through the Services, we receive and store certain personally
        non-identifiable information. Such information, which is collected passively using various
        technologies, cannot presently be used to specifically identify you. Seensta may store such
        information itself or such information may be included in databases owned and maintained by
        Seensta affiliates, agents or service providers. The Services may use such information and
        pool it with other information to track, for example, the number of transactions that are
        effected through the Service, the number of messages you send and receive, the total number
        of users of our Services, the domain names of our visitors' Internet service providers, and
        how our users use and interact with the Services. Also, in an ongoing effort to better
        understand and serve the users of the Services, Seensta may conduct research on its customer
        demographics, interests and behavior based on the Personal Data and other information
        provided to us. This research may be compiled and analyzed on an aggregate basis. Seensta
        may share this non-identifiable and aggregate data with its affiliates, agents and business
        partners, but this type of non-identifiable and aggregate information does not identify you
        personally. Seensta may also disclose aggregated user statistics in order to describe our
        services to current and prospective business partners, and to other third parties for other
        lawful purposes. <br />
        <br />
      </p>
      <p>
        In operating the Services (and in particular in making our website available), we may also
        use a technology called "cookies." A cookie is a piece of information that the computer that
        hosts our Services gives to your browser when you access the Services. Our cookies help
        provide additional functionality to the Services and help us analyze Services usage more
        accurately. For instance, our Services may set a cookie on your browser that allows you to
        access the Services without needing to remember and then enter a password more than once
        during a visit to the Services. In all cases in which we use cookies, we will not collect
        Personal Data except with your permission. On most web browsers, you will find a "help"
        section on the toolbar. Please refer to this section for information on how to receive
        notification when you are receiving a new cookie and how to turn cookies off. We recommend
        that you leave cookies turned on because they allow you to take advantage of some of the
        Services features. <br />
        <br />
      </p>
      <p>
        We may also employ the use of anonymous identifiers, which are random strings of characters
        that are used for the same purposes as cookies on platforms, including certain mobile
        devices, where cookie technology is not available. We may use Web beacons or similar
        technology on the Services. Web beacons are small, invisible graphic images that may be used
        on the Service or in emails relating to the Service to collect certain personally
        non-identifiable information and monitor user activity on the Services, such as to count
        visits, understand usage effectiveness and to tell if an email has been opened and acted
        upon.
        <br />
        <br />
        We may collect device-specific information (such as your hardware model, operating system
        version, unique device identifiers, and mobile network information including phone number).
        We may associate your device identifiers or phone number with your Seensta account.
        <br />
        <br />
      </p>
      <h6>Do Not Track Signals: </h6>
      <p>
        We may (and we may allow third party service providers to) use cookies or similar
        technologies to collect information about your browsing activities over time and across
        different websites following your use of the Service. Our Service currently does not respond
        to "Do Not Track" (DNT) signals and operates as described in this Privacy Policy whether or
        not a DNT signal is received. If we do so in the future, we will describe how we do so in
        this Privacy Policy.
        <br />
        <br />
      </p>
      <h6>Our Use of Your Personal Data and Other Information:</h6>
      <br />
      <p>
        Seensta uses the Personal Data you provide in a manner that is consistent with this Privacy
        Policy. If you provide Personal Data for a certain reason, we may use the Personal Data in
        connection with the reason for which it was provided. For instance, if you contact us by
        email or other messaging means, we will use the Personal Data you provide to answer your
        question or resolve your problem, or if you confirm a transaction using the Services, we
        will use the financial data you provide to consummate the transaction. Also, if you provide
        Personal Data in order to obtain access to the Services, we will use your Personal Data to
        provide you with access to such services and to monitor your use of such services. Seensta
        and its subsidiaries and affiliates (the "Related Companies") may also use your Personal
        Data and other personally non-identifiable information collected through the Services to
        help us customize the Services, improve the content and functionality of the Services,
        better understand our users, prevent fraud and improve the Services. Seensta and its Related
        Companies may use this information to communicate with you regarding the Services or to tell
        you about services we believe will be of interest to you. If we do so, any marketing
        communication we send you will contain instructions permitting you to "opt-out" of receiving
        future marketing communications. In addition, if at any time you wish not to receive any
        future marketing communications or you wish to have your name deleted from our mailing
        lists, please contact us as indicated below. Note however that as user of the Service you
        cannot opt-out of some administrative communications that are reasonably necessary to the
        Service, such as billing or service notifications.
        <br />
        <br />
      </p>
      <p>
        Please also note that to the extent you include any Personal Data or other information in a
        listing or any other content that you upload to the Service, we have the right to publish
        that listing and other content (including without limitation any Personal Data or other
        information including in the listing) in connection with the operation of the Service or any
        other products or services of Seensta, or the promotion, advertising or marketing thereof
        (including publishing that listing and other content on the Internet or on Third Party
        Services such as Facebook, sharing it with blogs, etc.). In addition, users have the ability
        as part of the Service to share your listings with others.
        <br />
        <br />
      </p>
      <p>
        If Seensta intends on using any Personal Data in any manner that is not consistent with this
        Privacy Policy, you will be informed of such anticipated use prior to or at the time at
        which the Personal Data is collected. <br />
        <br />
      </p>
      <h6>Our Disclosure of Your Personal Data and Other Information:</h6>
      <br />
      <p>
        Seensta is not in the business of selling your information. We consider this information to
        be a vital part of our relationship with you. There are, however, certain circumstances in
        which we may share your Personal Data with certain third parties without further notice to
        you, as set forth below:
        <br />
        <br />
      </p>
      <h6>Business Transfers:</h6>{' '}
      <p>
        As we develop our business, we might sell or buy businesses or assets. In the event of a
        corporate sale, merger, reorganization, dissolution or similar event, Personal Data may be
        part of the transferred assets.
        <br />
        <br />
      </p>
      <h6>Related Companies:</h6>{' '}
      <p>
        We may also share your Personal Data with our Related Companies for purposes consistent with
        this Privacy Policy.
        <br />
        <br />
      </p>
      <h6>Agents, Service Providers, Consultants and Related Third Parties:</h6>
      <p>
        {' '}
        Seensta, like many businesses, sometimes hires other companies to perform certain
        business-related functions. Examples of such functions include maintaining databases,
        identity verification, fraud monitoring, processing credit card payments etc. When we employ
        another company to perform a function of this nature, we only provide them with the
        information that they need to perform their specific function (for example, providing our
        credit card payment provider with your credit card information).
        <br />
        <br />
      </p>
      <h6>Legal Requirements:</h6>
      <p>
        {' '}
        Seensta may disclose your Personal Data if required to do so by law or in the good faith
        belief that such action is necessary to (i) comply with a legal obligation, (ii) protect and
        defend the rights or property of Seensta, (iii) act in urgent circumstances to protect the
        personal safety of users of the Services or the public, or (iv) protect against legal
        liability.
        <br />
        <br />
      </p>
      <h6>Enforcement:</h6>
      <br />
      <p>
        Seensta may disclose your Personal Data to enforce or apply this Privacy Policy, the Seensta
        Terms of Service, or our other policies posted on the Site from time to time or agreements
        to which you are a party. <br />
        <br />
      </p>
      <h6>Your Choices:</h6>
      <br />
      <p>
        If you choose not to provide any Personal Data, you may not be able to use certain Services.
        In addition, you may request that we delete your account, and any information associated
        with your account, but please note that we may be required (by law or otherwise) to retain
        this information and not delete it (or to keep this information for a certain time, in which
        case we will comply with your deletion request only after we have fulfilled such
        requirements). When we delete your information, it will be deleted from the active database
        but may remain in our archives. <br />
        <br />
      </p>
      <h6>Exclusions:</h6>
      <br />
      <p>
        This Privacy Policy does not apply to any Personal Data collected by Seensta other than
        Personal Data collected through the Services. This Privacy Policy shall not apply to any
        unsolicited information you provide to Seensta through the Services or through any other
        means. This includes, but is not limited to, information posted to any public areas of the
        Services, such as forums (collectively, "Public Areas"), any ideas for new products or
        modifications to existing products and other unsolicited submissions (collectively,
        "Unsolicited Information"). All Unsolicited Information shall be deemed to be
        non-confidential and Seensta shall be free to reproduce, use, disclose, distribute and
        exploit such Unsolicited Information without limitation or attribution. <br />
        <br />
      </p>
      <h6>Children:</h6>
      <br />
      <p>
        Seensta does not knowingly collect Personal Data from children under the age of 13. If you
        are under the age of 13, please do not submit any Personal Data through the Services. We
        encourage parents and legal guardians to monitor their children's Internet usage and to help
        enforce our Privacy Policy by instructing their children never to provide Personal Data
        through the Services without their permission. If you have reason to believe that a child
        under the age of 13 has provided Personal Data to Seensta through the Services, please
        contact us, and we will endeavor to delete that information from our databases.
        <br />
        <br />
      </p>
      <h6>Links to Other Web Sites:</h6>
      <br />
      <p>
        This Privacy Policy applies only to the Services. The Services may contain links to other
        web sites not operated or controlled by Seensta (the "Third Party Sites"). The policies and
        procedures we described here do not apply to the Third Party Sites. The links from the
        Services do not imply that Seensta endorses or has reviewed the Third Party Sites. We
        suggest contacting those sites directly for information on their privacy policies.
        <br />
        <br />
      </p>
      <h6>Integrating Third Party Services within the Services:</h6>
      <br />
      <p>
        One of the special features of the Service is that it allows you to enable various online
        third party services, such as Facebook and other social media and networking services,
        payment service providers etc. ("Third Party Services"), to be directly integrated into your
        Seensta experience. To take advantage of this feature, we will ask you to provide us your
        username and password for the relevant Third Party Services. By enabling such Third Party
        Services, you are allowing us to pass your log-in information to these service providers for
        this purpose. When you add a Third Party Service account to the Service, we will collect
        your login information and other relevant information necessary to enable the Service to
        access that Third Party Service and your data contained within that Third Party Service. As
        part of such integration, such Third Party Services will provide us with access to certain
        information that you have provided to such Third Party Services, and we will use, store and
        disclose such information in accordance with this Privacy Policy. However, please remember
        that the manner in which Third Party Services use, store and disclose your information is
        governed solely by the policies of such third parties, and Seensta shall have no liability
        or responsibility for the privacy practices or other actions of any Third Party Service that
        may be enabled within the Service.
        <br />
        <br />
      </p>
      <h6>Security:</h6>
      <br />
      <p>
        Seensta takes reasonable steps to protect the Personal Data provided via the Services from
        loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However, no
        Internet, email or other electronic transmission is ever fully secure or error free, so you
        should take special care in deciding what information you send to us in this way.
        <br />
        <br />
      </p>
      <h6>Other Terms and Conditions:</h6>
      <br />
      <p>
        Your access to and use of the Services is subject to the Seensta Terms of Service located at
        http://www.Seensta.com/terms.
        <br />
        <br />
      </p>
      <h6>Changes to Seensta's Privacy Policy:</h6>
      <br />
      <p>
        The Services and our business may change from time to time. As a result, at times it may be
        necessary for Seensta to make changes to this Privacy Policy. Seensta reserves the right to
        update or modify this Privacy Policy at any time and from time to time without prior notice.
        Please review this policy periodically, and especially before you provide any Personal Data.
        This Privacy Policy was last updated on the date indicated above. Your continued use of the
        Services after any changes or revisions to this Privacy Policy shall indicate your agreement
        with the terms of such revised Privacy Policy.
        <br />
        <br />
      </p>
      <h6>Access to Information; Contacting Seensta:</h6>
      <br />
      <p>
        To keep your Personal Data accurate, current, and complete, please contact us as specified
        below. We will take reasonable steps to update or correct Personal Data in our possession
        that you have previously submitted via the Services.
        <br />
        <br />
        Please also feel free to contact us if you have any questions about Seensta's Privacy Policy
        or the information practices of Seensta. <br />
        <br />
        You may contact us as follows: team@Seensta.com. <br />
        <br />
      </p>
    </div>
  )
}

export default Privacy
