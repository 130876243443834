import Api from '../../Api'

const basedev = process.env.REACT_APP_API_URL
//const basedev = 'http://nsiza.api.test/api/'

export const createPayment = (data) => async () => {
  let endpoint = basedev + 'subscribe/clothes'
  const res = await Api.post(endpoint, data)
  return res
}

export const createSubscription = (data, token) => async () => {
  var headers = {
    Accept: 'application/json',
    Authorization: 'Bearer ' + token,
  }

  let endpoint = basedev + 'subscribe'
  const res = await Api.post(endpoint, data, { headers })
  return res
}
