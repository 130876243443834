import { UPLOAD_PHOTO, UPLOAD_AVATAR, UPLOAD_REQUEST } from '../actions/types'

const initialState = {
  items: [],
  item: '',
  fetching: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case UPLOAD_PHOTO:
      return {
        ...state,
        items: [...state.items, action.payload.filename],
        fetching: false,
      }
    case UPLOAD_AVATAR:
      return {
        ...state,
        item: action.payload.filename,
        fetching: false,
      }
    case UPLOAD_REQUEST:
      return {
        ...state,
        fetching: true,
      }
    default:
      return state
  }
}
