import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))

export default function LinearDeterminate({ questionStep, questionLength }) {
  const classes = useStyles()
  const [completed, setCompleted] = useState(0)
  useEffect(() => {
    setCompleted((oldCompleted) => {
      if (oldCompleted === 100) {
        return 0
      }
      return questionStep * (100 / questionLength)
    })
  }, [questionStep, questionLength])

  return (
    <div className={classes.root}>
      <LinearProgress variant="determinate" value={completed} />
    </div>
  )
}
