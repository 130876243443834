import {
  FETCH_PORTFOLIO_SEARCH,
  FETCH_PORTFOLIO_FINDER,
  FETCH_PORTFOLIO_FINDER_MORE,
  FETCH_PORTFOLIO_SINGLE_REQUEST,
  FETCH_PORTFOLIO_SINGLE,
  FETCH_PORTFOLIO,
  FETCH_PORTFOLIO_MORE,
  FETCH_PORTFOLIO_ERROR,
  FETCH_PORTFOLIO_MORE_ERROR,
  FETCH_PORTFOLIO_REQUEST,
  FETCH_PORTFOLIO_MORE_REQUEST,
  DELETE_PHOTO,
} from '../actions/types'

const initialState = {
  tags: [],
  folio: [],
  usertype: [],
  userportfolio: [],
  trend: [],
  newContent: [],
  fetching: false,
  fetched: false,
  singleitem: [],
  featuredusers: [],
  featuredContent: [],
  finderfolio: [],
  searchuser: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PORTFOLIO:
      if (action.fetchby === 'tags') {
        return {
          ...state,
          tags: action.payload.results,
          fetching: false,
        }
      } else if (action.fetchby === 'username') {
        return {
          ...state,
          userportfolio: action.payload.results,
          fetching: false,
        }
      } else if (action.fetchby === 'whatshot') {
        return {
          ...state,
          trend: action.payload.results,
          fetching: false,
        }
      } else if (action.fetchby === 'new') {
        return {
          ...state,
          newContent: action.payload.results,
          fetching: false,
        }
      } else if (action.fetchby === 'usertype') {
        return {
          ...state,
          usertype: action.payload.results,
          fetching: false,
          featuredusers: action.payload.featured,
          featuredContent: action.payload.featuredHome,
        }
      } else if (action.fetchby === 'regular') {
        return {
          ...state,
          folio: action.payload.results,
          fetching: false,
          featuredusers: action.payload.featured,
          featuredContent: action.payload.featuredHome,
        }
      } else {
        return {
          ...state,
          folio: action.payload.results,
          fetching: false,
          featuredusers: action.payload.featured,
          featuredContent: action.payload.featuredHome,
        }
      }
    case FETCH_PORTFOLIO_FINDER:
      return {
        ...state,
        finderfolio: [...action.payload.results],
        fetching: false,
      }
    case FETCH_PORTFOLIO_FINDER_MORE:
      return {
        ...state,
        finderfolio: [...state.finderfolio, ...action.payload.results],
        fetching: false,
      }
    case FETCH_PORTFOLIO_MORE:
      if (action.fetchby === 'tags') {
        return {
          ...state,
          tags: [...state.tags, ...action.payload.results],
          fetching: false,
        }
      } else if (action.fetchby === 'username') {
        return {
          ...state,
          userportfolio: [...state.userportfolio, ...action.payload.results],
          fetching: false,
        }
      } else if (action.fetchby === 'whatshot') {
        return {
          ...state,
          trend: [...state.trend, ...action.payload.results],
        }
      } else if (action.fetchby === 'new') {
        return {
          ...state,
          newContent: [...state.newContent, ...action.payload.results],
        }
      } else if (action.fetchby === 'usertype') {
        return {
          ...state,
          usertype: [...state.usertype, ...action.payload.results],
          fetching: false,
        }
      } else if (action.fetchby === 'regular') {
        return {
          ...state,
          folio: [...state.folio, ...action.payload.results],
          fetching: false,
        }
      }
      break
    case FETCH_PORTFOLIO_ERROR:
      return {
        ...state,
        items: [...state.items],
        fetching: false,
      }
    case FETCH_PORTFOLIO_SINGLE_REQUEST:
      return {
        ...state,
        fetching: true,
      }
    case FETCH_PORTFOLIO_SINGLE:
      return {
        ...state,
        singleitem: [...action.payload.results],
        fetching: false,
      }
    case FETCH_PORTFOLIO_MORE_ERROR:
      return {
        ...state,
        items: [...state.items],
      }
    case FETCH_PORTFOLIO_REQUEST:
      return {
        ...state,
        items: [],
        fetching: true,
      }
    case FETCH_PORTFOLIO_MORE_REQUEST:
      return {
        ...state,
        items: [...state.items],
      }
    case DELETE_PHOTO:
      if (action.payload === 200) {
        state.userportfolio.splice(action.key, 1)

        return {
          ...state,
          userportfolio: [...state.userportfolio],
        }
      } else {
        return {
          ...state,
          fetching: true,
        }
      }
    case FETCH_PORTFOLIO_SEARCH:
      if (action.finder === 'user') {
        //console.log(action.payload.results)
        return {
          ...state,
          searchuser: [...action.payload.results],
          fetching: false,
        }
      } else {
        return {
          ...state,
          trend: [...state.trend, ...action.payload.results],
          fetching: false,
        }
      }

    default:
      return state
  }
}
