import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import CardMedia from '@material-ui/core/CardMedia'
import Trash from '../features/trash_item'
import Bump from '../vote/up_down_vote'
import { withRouter } from 'react-router-dom'
import Hidden from '@material-ui/core/Hidden'
import ChallengeDialog from './challenge'
import { awsImage } from '../../Helpers'
import Bespoke from '../features/be_spoke_tag'
import Skeleton from '@material-ui/lab/Skeleton'
import UserHeader from './singleimage/user_header'

const useStyles = makeStyles((theme) => ({
  '@media (min-width: 600px)': {
    card: {
      maxWidth: 400,
      marginBottom: 20,
      margin: '0 auto',
      transition: '1s',
      borderRadius: 5,
      '&:hover': {
        cursor: 'pointer',
        boxShadow: '0 2px 4px rgba(0,0,0,0.4)',
        transform: 'translateY(-5px)',
      },
    },
  },
  '@media (min-width: 768px)': {
    card: {
      maxWidth: 330,
      marginBottom: 20,
      margin: '0 auto',
      transition: '1s',
      borderRadius: 5,
      '&:hover': {
        cursor: 'pointer',
        boxShadow: '0 2px 4px rgba(0,0,0,0.4)',
        transform: 'translateY(-5px)',
      },
    },
  },
  media: {
    height: 300,
  },
  actions: {
    display: 'flex',
    width: '100%',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: '#9c27b0',
    width: 25,
    height: 25,
    marginRight: -13,
  },
  cardHeader: {
    paddingTop: 2,
    paddingBottom: 3,
    paddingLeft: 2,
    paddingRight: 0,
  },
}))

function ImageCard({
  imagePath,
  id,
  totalvotes,
  usertype,
  username,
  photoPath,
  firstname,
  lastname,
  location,
  itemkey,
  current,
  auth,
  history,
}) {
  const showImage = () => {
    history.push('/photo/' + id, {
      photo: imagePath,
      photoid: id,
      totalvotes: totalvotes,
      usertype: usertype,
      username: username,
      photoPath: photoPath,
      firstname: firstname,
      lastname: lastname,
      location: location,
    })
  }

  const classes = useStyles()
  const imageAws = awsImage('folio', imagePath, 450, 'contain')
  return (
    <div>
      <Hidden only={['lg', 'xl', 'md']}>
        <Card className={classes.card}>
          <UserHeader
            photoPath={photoPath}
            userName={username}
            firstName={firstname}
            lastName={lastname}
            classes={classes}
          />
          <CardMedia
            component={'img'}
            className={classes.media}
            image={imageAws}
            onClick={showImage}
          />
          <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}></CardContent>{' '}
          {auth ? (
            <CardActions
              className={classes.actions}
              disableSpacing
              style={{ paddingTop: 0, paddingBottom: 0 }}
            >
              {current && current === username ? <Trash item={id} itemkey={itemkey} /> : null}
              <Bump photoid={id} upvotes={totalvotes} size={13} />

              {usertype === 'model' || usertype === 'Model' || usertype === 'modelUser' ? (
                <ChallengeDialog photo={imagePath} />
              ) : null}
            </CardActions>
          ) : (
            <div>
              <Bump photoid={id} upvotes={totalvotes} size={13} />
              {/* <Seamit  photoid={this.props.id} image={this.props.imagePath} /> */}
            </div>
          )}
        </Card>
      </Hidden>

      <Hidden only={['sm', 'xs']}>
        <Card className={classes.card}>
          <UserHeader
            photoPath={photoPath}
            userName={username}
            firstName={firstname}
            lastName={lastname}
            classes={classes}
          />

          {imageAws ? (
            <CardMedia
              component={'img'}
              className={classes.media}
              image={imageAws}
              onClick={showImage}
            />
          ) : (
            <Skeleton animation="wave" height={290} width={450} className={classes.media} />
          )}
          <Bespoke label={usertype} />
          {auth ? (
            <CardActions className={classes.actions} disableSpacing style={{ paddingTop: 0 }}>
              {current && current === username ? <Trash item={id} itemkey={itemkey} /> : null}
              <Bump photoid={id} upvotes={totalvotes} size={13} />

              {usertype === 'model' || usertype === 'Model' || usertype === 'modelUser' ? (
                <ChallengeDialog photo={imagePath} />
              ) : null}
            </CardActions>
          ) : (
            <div style={{ flexDirection: 'column', margin: 10 }}>
              <div style={{ flex: 1, float: 'left' }}>
                <Bump photoid={id} upvotes={totalvotes} size={13} />
              </div>
            </div>
          )}
        </Card>
      </Hidden>
    </div>
  )
}

export default withRouter(ImageCard)
