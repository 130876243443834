/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { connect } from 'react-redux'
import { addAnswer } from '../../store/actions/questionActions'
import TextField from '@material-ui/core/TextField'
import ShowImage from './image'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}))

function QuestionWithOptions({ props, addAnswer }) {
  const classes = useStyles()
  const { options } = props.question
  const [currentId, setCurrentId] = useState(false)
  const [questionId, setQuestionId] = useState(props.question.id)

  switch (props.question.answer_option_type) {
    case 'checkbox':
      return (
        <div className={classes.root}>
          <Typography className="question">{props.question.question}</Typography>
          <Typography
            variant="caption"
            style={{ width: '100%', display: 'block', textAlign: 'center' }}
          >
            {props.question.subheading}
          </Typography>

          {options.map((option) => (
            <div
              key={option.id}
              style={{
                margin: option.image ? '0 auto' : null,
                display: option.image ? 'grid' : 'inherit',
                width: option.image === 'invertedtriangle.png' ? 200 : 'auto',
              }}
            >
              {option.image && (
                <ShowImage
                  folder="media"
                  width={option.image === 'rectangle.png' ? 55 : 100}
                  image={option.image}
                  fit="contain"
                />
              )}
              <ChipOption option={option} question={props.question} addAnswer={addAnswer} />
            </div>
          ))}
        </div>
      )
    case 'image':
      return (
        <div className={classes.root}>
          <Typography className="question">{props.question.question}</Typography>
          <Typography
            variant="caption"
            style={{ width: '100%', display: 'block', textAlign: 'center' }}
          >
            {props.question.subheading}
          </Typography>

          {options.map((option) => (
            <ShowImage
              option={option}
              question={props.question}
              addAnswer={addAnswer}
              key={option.id}
              folder="media"
              width={300}
              image={option.image}
              fit="contain"
            />
          ))}
        </div>
      )
    case 'radio':
      return (
        <div className={classes.root}>
          <Typography className="question">{props.question.question}</Typography>
          <Typography variant="caption">{props.question.subheading}</Typography>

          {options.map((option, index) => (
            <div
              key={index}
              style={{
                margin: option.image ? '0 auto' : null,
                display: option.image ? 'grid' : 'inherit',
                width: option.image === 'invertedtriangle.png' ? 200 : 'auto',
              }}
            >
              {option.image && (
                <ShowImage
                  folder="media"
                  width={option.image === 'rectangle.png' ? 55 : 70}
                  image={option.image}
                  fit="contain"
                />
              )}
              <Chip
                label={option.option}
                className="chip"
                onClick={() => {
                  setCurrentId(option.id)
                  setQuestionId(props.question.id)
                  addAnswer({
                    question: props.question.question,
                    answerlabel: [option.option],
                    question_id: props.question.id,
                    answers: [option.id],
                    option_type: props.question.answer_option_type,
                  })
                }}
                color={
                  option.id === currentId && questionId === props.question.id
                    ? 'primary'
                    : 'default'
                }
                key={props.question + option.id}
              />
            </div>
          ))}
        </div>
      )
    case 'input':
      return (
        <div className={classes.root}>
          <Typography className="question">{props.question.question}</Typography>
          <Typography
            variant="caption"
            style={{ width: '100%', display: 'block', textAlign: 'center' }}
          >
            {props.question.subheading}
          </Typography>

          <TextField
            id="outlined-basic"
            variant="outlined"
            key={props.question.id}
            className="textArea"
            label="Answer"
            onChange={(e) => {
              addAnswer({
                question: props.question.question,
                answerlabel: [e.target.value],
                question_id: props.question.id,
                answers: [e.target.value],
                option_type: props.question.answer_option_type,
              })
            }}
          />
        </div>
      )
    case 'textarea':
      return (
        <div className={classes.root}>
          <Typography className="question">{props.question.question}</Typography>
          <Typography
            variant="caption"
            style={{ width: '100%', display: 'block', textAlign: 'center' }}
          >
            {props.question.subheading}
          </Typography>

          <TextField
            id="standard-multiline-static"
            label="Answer"
            key={props.question.id}
            multiline
            rows="4"
            defaultValue=""
            variant="outlined"
            className="textArea"
            onChange={(e) => {
              addAnswer({
                question: props.question.question,
                question_id: props.question.id,
                answerlabel: [e.target.value],
                answers: [e.target.value],
                option_type: props.question.answer_option_type,
              })
            }}
          />
        </div>
      )
    default:
      return <div>Nothing Here</div>
  }
}

function ChipOption(props) {
  const [active, setActive] = useState(false)
  function handleClick() {
    setActive(!active)
    props.addAnswer({
      question: props.question.question,
      answerlabel: [props.option.option],
      question_id: props.question.id,
      answers: [props.option.id],
      option_type: props.question.answer_option_type,
    })
  }
  return (
    <Chip
      className="chip"
      label={props.option.option}
      onClick={() => handleClick()}
      color={active ? 'primary' : 'default'}
      size="medium"
    />
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    questions: state.questions.questions,
    props: ownProps,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer: (data) => dispatch(addAnswer(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(QuestionWithOptions)
