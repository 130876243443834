import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { fetchPortfolioSearch } from '../../store/actions/portfolioActions'
import { Mixpanel } from '../../Mixpanel'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'
import Avatar from '@material-ui/core/Avatar'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import { makeStyles } from '@material-ui/core/styles'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import CloseIcon from '@material-ui/icons/CloseRounded'
import { withRouter } from 'react-router-dom'
import { awsImage } from '../../Helpers'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { Chip } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,

    marginLeft: 0,
    marginTop: theme.spacing(3),
    width: 'auto',
    [theme.breakpoints.up('xs')]: {
      width: 350,
      height: 47,
      margin: '0 auto',
      background: '#FAFAFA 0% 0% no-repeat padding-box',
      border: '1px solid #F0EFEF',
      borderRadius: 10,
      opacity: 1,
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#000',
  },
  inputRoot: {
    color: '#000',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: 650,
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 450,
    },
    [theme.breakpoints.up('xs')]: {
      height: 30,
    },
    '&:focus': {
      boxShadow: 'none',
    },
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: 'inherit',
    marginTop: 5,
    marginBottom: 10,
    maxWidth: 500,
    margin: '0 auto',
  },
  chip: {
    marginTop: 6,
    cursor: 'pointer',
    background: '#EFEFEF',
  },

  MuiListSubheaderGutters: {
    paddingLeft: 16,
    paddingRight: 16,
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.875rem',
    boxSizing: 'border-box',
    listStyle: 'none',
    fontFamily: 'Roboto',
    fontWeight: 500,
  },
}))

const UserType = [
  'Model',
  'Designer',
  'Photographer',
  'Fashion',
  'Makeup Artist',
  'Dancer',
  'General',
]
// const Levels = [
//   'Beginner',
//   'Amateur',
//   'Almost Pro',
//   'Professional',
//   'Master',
//   'Grand Master',
//   'Selfie Queen',
//   'Top Models',
//   'Pending',
//   'All Levels',
// ]

// const Locations = [
//   'Kenya',
//   'Nairobi',
//   'Mombasa',
//   'Kisumu',
//   'Nakuru',
//   'Nigeria',
//   'Abuja',
//   'Lagos',
//   'Ghana',
//   'Accra',
//   'United States',
// ]

function ExploreSearch({ fetchPortfolioSearch, userlist, token, history, showPanel = false }) {
  const [searchResults, setSearchResults] = useState(userlist)
  const [searchTerm, setSearchTerm] = useState('')
  const [fetching, setFetching] = useState(false)
  const [showCurrentPanel, setShowCurrentPanel] = useState(showPanel)

  useEffect(() => {
    setSearchResults(userlist)
    setFetching(false)
  }, [userlist])

  useEffect(() => {
    if (searchTerm && searchTerm.length >= 4) {
      setFetching(true)
      const delayDebounceFn = setTimeout(() => {
        fetchPortfolioSearch({ category: searchTerm, page: 1, token: token })
        Mixpanel.track('Searched Term', { term: searchTerm })
      }, 3000)

      return () => clearTimeout(delayDebounceFn)
    }
    setFetching(false)
  }, [fetchPortfolioSearch, searchTerm, token])

  const clearSearch = () => {
    setSearchResults(null)
    setSearchTerm('')
  }
  const classes = useStyles()

  return (
    <div>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          value={searchTerm}
          onChange={(query) => {
            setSearchTerm(query.target.value)
          }}
          inputProps={{ 'aria-label': 'search' }}
          endAdornment={searchTerm ? <CloseIcon onClick={clearSearch} /> : null}
          onClick={() => setShowCurrentPanel(true)}
        />
      </div>

      <Box style={{ display: showCurrentPanel ? 'block' : 'none' }}>
        <div style={{ textAlign: 'right' }}>
          <Button onClick={() => setShowCurrentPanel(false)} size="small" style={{ fontSize: 10 }}>
            Close
          </Button>
        </div>

        {fetching ? (
          <Box style={{ textAlign: 'center' }}>
            <CircularProgress size={20} />
          </Box>
        ) : (
          <div>
            {searchResults && (
              <List>
                {searchResults &&
                  searchResults.map((user, index) => {
                    const profilepictureURL = user.photoPath.includes('http')
                      ? user.photoPath
                      : user.photoPath
                      ? awsImage('profilepictures', user.photoPath, 50, 'contain')
                      : '/female_avatar.png'
                    return (
                      <ListItem
                        button
                        onClick={() => {
                          history.push(`/${user.username}`)
                        }}
                        key={index}
                        alignItems="flex-start"
                      >
                        <ListItemAvatar>
                          <Avatar size="25" alt="User" src={profilepictureURL} />
                        </ListItemAvatar>
                        <ListItemText primary={user.firstName + ' ' + user.lastName} />
                      </ListItem>
                    )
                  })}
              </List>
            )}
          </div>
        )}

        <div className={classes.MuiListSubheaderGutters}>Creative Types</div>
        <Box className={classes.root}>
          {UserType &&
            UserType.map((creativeType, index) => {
              return (
                <Chip
                  key={index}
                  label={creativeType}
                  onClick={() => {
                    history.push(`/browse/${creativeType}`)
                  }}
                  className={classes.chip}
                  variant="default"
                  clickable
                  color={history.location.pathname.includes(creativeType) ? 'primary' : 'default'}
                />
              )
            })}
        </Box>

        {/* <div className={classes.MuiListSubheaderGutters}>Location</div>
        <Box className={classes.root}>
          {Locations &&
            Locations.map((loc, index) => {
              return (
                <Chip
                  key={index}
                  label={loc}
                  onClick={() => {
                    history.push(`/browse/loc/${loc}`)
                    setShowCurrentPanel(false)
                  }}
                  className={classes.chip}
                  variant="default"
                  clickable
                />
              )
            })}
        </Box> */}
      </Box>
    </div>
  )
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchPortfolioSearch: (queryData) => {
      dispatch(fetchPortfolioSearch(queryData))
    },
  }
}

const mapStateToProps = (state) => ({
  userlist: state.portfolio.searchuser,
  auth: state.auth.auth,
  fetching: state.portfolio.fetching,
  user: state.auth.user,
  featuredusers: state.portfolio.featuredusers,
  token: state.auth.token,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExploreSearch))
