import { combineReducers } from 'redux'
import portfolioReducer from './portfolioReducer'
import userReducer from './userReducer'
import authReducer from './authReducer'
import challengeReducer from './challengeReducer'
import snatchReducer from './snatchReducer'
import voteReducer from './voteReducer'
import uploadReducer from './uploadReducer'
import contestReducer from './contestReducer'
import commentReducer from './commentReducer'
import followReducer from './followReducer'
import seamitReducer from './seamitReducer'
import messengerReducer from './messengerReducer'
import questionReducer from './questionReducer'

export default combineReducers({
  portfolio: portfolioReducer,
  user: userReducer,
  auth: authReducer,
  challenge: challengeReducer,
  snatch: snatchReducer,
  vote: voteReducer,
  upload: uploadReducer,
  contest: contestReducer,
  comment: commentReducer,
  follow: followReducer,
  seam: seamitReducer,
  messenger: messengerReducer,
  questions: questionReducer,
})
