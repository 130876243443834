import React from 'react'
import './HowItWorks.css'
import Divider from '@material-ui/core/Divider'
import Hidden from '@material-ui/core/Hidden'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import { awsImage } from '../../../../Helpers'

function HowItWorksGigs({ history }) {
  const mainImage = awsImage('media', 'landing_how_it_works.png', 530, 'contain')
  const mainImageMobile = awsImage('media', 'landing_how_it_works_white.png', 530, 'contain')

  return (
    <div>
      <Hidden only={['sm', 'xs']}>
        <div style={{ background: '#f7f5f9' }}>
          <div style={{ width: 1170, margin: '0 auto' }}>
            <div className="howitworks-title">
              <span style={{ fontSize: 32, paddingBottom: 20, color: '#76235a' }}>How</span>{' '}
              <span style={{ fontSize: 32, fontWeight: 100 }}>it works?</span>
            </div>
            <Grid container spacing={0} style={{ height: 630 }}>
              <Grid item xs={12} sm={6} style={{ padding: '20px' }}>
                <div>
                  <img src={mainImage} alt="bg" />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} style={{ textAlign: 'left' }}>
                <div
                  className="howitworks-container"
                  style={{
                    width: 600,
                    margin: '0 auto',
                    display: 'block',
                    boxShadow: 'none',
                    background: 'inherit',
                  }}
                >
                  <div className="how-container">
                    <div className="howimage">
                      <svg width="75" xmlns="http://www.w3.org/2000/svg">
                        <image href="./public-black-48dp.svg" width="75" />
                      </svg>
                    </div>
                    <div className="how-description">
                      <div className="how-heading">Post a Gig</div>
                      <div className="how-caption">
                        <span>
                          Post a gig for arts, film, photography, modeling requests or a general
                          entertainment industry gig.
                        </span>
                      </div>
                    </div>
                    <Divider />
                  </div>
                  <div className="how-container">
                    <div className="howimage">
                      <svg width="75" xmlns="http://www.w3.org/2000/svg">
                        <image href="./connect_without_contact-black-48dp.svg" width="75" />
                      </svg>
                    </div>
                    <div className="how-description">
                      <div className="how-heading">Creatives Apply</div>
                      <div className="how-caption">
                        <span>
                          With thousands of creatives on our platform, you can quickly get creatives
                          to engage with.
                        </span>
                      </div>
                    </div>
                    <Divider />
                  </div>
                  <div className="how-container">
                    <div className="howimage">
                      <svg width="75" xmlns="http://www.w3.org/2000/svg">
                        <image href="./design_services-black-48dp.svg" width="75" />
                      </svg>
                    </div>
                    <div className="how-description">
                      <div className="how-heading">Collaborate</div>
                      <div className="how-caption">
                        <span>
                          We connect you with the creative of your choice so you can get back to
                          what's important: Creating.
                        </span>
                      </div>
                    </div>
                  </div>

                  <div style={{ textAlign: 'center', width: '100%', paddingTop: 20 }}>
                    <div
                      style={{
                        display: 'block',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                      }}
                    >
                      <Button
                        size="large"
                        color="primary"
                        variant="contained"
                        href="/gigs/create"
                        style={{ color: 'white !important' }}
                      >
                        Post a Gig
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Hidden>
      <Hidden only={['xl', 'lg']}>
        <div className="howitworks-container">
          <div className="how-container">
            <div className="howitworks-title">
              <span style={{ fontSize: 32, paddingBottom: 20, color: '#76235a' }}>How</span>{' '}
              <span style={{ fontSize: 32, fontWeight: 100 }}>it works?</span>
            </div>
            <div>
              <img src={mainImageMobile} alt="bg" style={{ width: '100%' }} />{' '}
            </div>
            <div className="howimage">
              <svg width="75" xmlns="http://www.w3.org/2000/svg">
                <image href="./public-black-48dp.svg" width="75" />
              </svg>
            </div>
            <div className="how-description">
              <div className="how-heading">Post a Gig</div>
              <div className="how-caption">
                <span>
                  Post a gig for arts, film, photography, modeling requests or a general
                  entertainment industry gig.
                </span>
              </div>
            </div>
            <Divider />
          </div>
          <div className="how-container">
            <div className="howimage">
              <svg width="75" xmlns="http://www.w3.org/2000/svg">
                <image href="./connect_without_contact-black-48dp.svg" width="75" />
              </svg>
            </div>
            <div className="how-description">
              <div className="how-heading">Creatives apply</div>
              <div className="how-caption">
                <span>
                  With thousands of creatives on our platform, you can quickly get creatives to
                  engage with.
                </span>
              </div>
            </div>
            <Divider />
          </div>
          <div className="how-container">
            <div className="howimage">
              <svg width="75" xmlns="http://www.w3.org/2000/svg">
                <image href="./design_services-black-48dp.svg" width="75" />
              </svg>
            </div>
            <div className="how-description">
              <div className="how-heading">Collaborate</div>
              <div className="how-caption">
                <span>
                  We connect you with the creative of your choice so you can get back to what's
                  important: Creating.
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'block',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={() => history.push('/gigs/create')}
              style={{ color: 'white !important' }}
            >
              Post a Gig
            </Button>
          </div>
        </div>
      </Hidden>
    </div>
  )
}

export default HowItWorksGigs
