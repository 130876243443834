import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import FollowIcon from '@material-ui/icons/AddBox'
import { createFollow, fetchFollow } from '../../store/actions/followAction'
import Button from '@material-ui/core/Button'
import FollowingIcon from '@material-ui/icons/CheckBox'
import { Mixpanel } from '../../Mixpanel'
import Api from '../../Api'

function UserFollow({ followUser, userName }) {
  const [following, setFollowing] = useState(null)

  useEffect(() => {
    const headers = {
      Authorization: 'Bearer ' + localStorage.getItem('asafo_token'),
    }
    const endPoint = 'follow/' + userName

    Api.get(endPoint, { headers: headers }).then((res) => {
      if (res.status === 204) {
        setFollowing(false)
      } else {
        setFollowing(true)
      }
    })
  })

  const handleFollow = () => {
    followUser(userName)
    setFollowing(true)
    Mixpanel.track('Follow User Profile')
  }

  if (following) {
    return <div></div>
  }

  return (
    <div>
      {following ? (
        <div>
          <Button variant="text" size="small" aria-label="Add" onClick={this.handleFollow}>
            <FollowingIcon /> Unfollow
          </Button>
        </div>
      ) : (
        <Button variant="text" size="small" color="primary" aria-label="Add" onClick={handleFollow}>
          <FollowIcon />
          Follow
        </Button>
      )}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    userName: ownProps.user,
    followCreated: state.follow.created,
    following: state.follow.following,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    followUser: (username) => dispatch(createFollow(username)),
    hasFollow: (username) => dispatch(fetchFollow(username)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserFollow)
