import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'

function FeePolicy() {
  useEffect(() => {
    document.title = 'Seensta | Maker Fee Policy'
  }, [])

  return (
    <div style={{ maxWidth: 800, color: '#000', marginTop: 150, margin: '0 auto' }}>
      <Typography variant="h5" style={{ textAlign: 'center' }}>
        Seller Fee Policy
      </Typography>

      <h4>Our fees are simple and straightforward:</h4>

      <h4>Listing is always free on Seensta</h4>

      <p>
        When you make a sale, we deduct a fee from your listing price.
        <br />
        For sales under $20, the fee is a single flat rate of $2.95. You keep the rest. For sales of
        $20 or more, the fee is 20% of the requester price and you keep 80%.
      </p>

      <h4>Here&lsquo;s what you get:</h4>
      <ul>
        <li>We handle international shipping</li>
        <li>You get free credit card processing</li>
        <li>You get free customer support.</li>
      </ul>
    </div>
  )
}

export default FeePolicy
