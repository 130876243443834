import { CREATE_SNATCH, FETCH_SNATCH } from '../actions/types'

const initialState = {
    items:[],
    item:{}
}


export default function(state=initialState,action){

    switch(action.type){
        case CREATE_SNATCH:
            return{
                ...state,
                item:action.payload
            }
        case FETCH_SNATCH:
            return{
                ...state,
                items:action.payload

            }

        default:
            return state
    }
}