import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import { seamRequests } from '../../store/actions/seamitActions'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import MoveForward from '@material-ui/icons/ArrowForwardIos'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: 20,
    marginBottom: 150,
  },
  inline: {
    display: 'inline',
  },
}))

function MessageThreads({ history, requests }) {
  const handleMessages = (event) => {
    history.push('/messages/' + event.currentTarget.dataset.message_link)
  }

  const { classes } = useStyles()

  const requestsList =
    requests &&
    requests.map((message, index) => {
      const t = message.created_at.split(/[- :]/)
      const d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]))
      const timeofcreation = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }).format(d)

      return (
        <ListItem key={index} button data-message_link={message.access_id} onClick={handleMessages}>
          <ListItemAvatar>
            <Avatar
              alt="Seam Request Photo"
              src={'https://images.seensta.com/folio/' + message.seam_photo}
            />
          </ListItemAvatar>
          <ListItemText
            primary={message.progress}
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  className={classes.inline}
                  color="textPrimary"
                  style={{ display: 'block' }}
                >
                  Designer:{' '}
                  {message.assigned_designer === null
                    ? 'Not Assigned Yet'
                    : message.assigned_designer}
                </Typography>
                {timeofcreation}
              </React.Fragment>
            }
          />

          <ListItemSecondaryAction style={{ right: 20 }}>
            <MoveForward style={{ color: '#000', fontSize: 14 }} />
          </ListItemSecondaryAction>
        </ListItem>
      )
    })

  if (requests.length) {
    return (
      <div style={{ maxWidth: 500, margin: '0 auto' }}>
        <Typography variant="h6" style={{ textAlign: 'center', marginTop: 55 }}>
          {' '}
          Messages
        </Typography>

        <List dense className={classes.root}>
          {requestsList}
        </List>
      </div>
    )
  } else {
    return (
      <div style={{ marginTop: 100, color: '#000', textAlign: 'center' }}>
        You haven't made any clothing request.
        <Typography variant="caption" style={{ textAlign: 'center', marginTop: 55 }}>
          {' '}
          To make a clothing request, you can go to a Designer's profile page or you can click the
          Own It button on photos.{' '}
        </Typography>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    requests: state.seam.seamrequests,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRequests: () => dispatch(seamRequests()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MessageThreads)
