import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { withRouter } from 'react-router-dom'
import Hidden from '@material-ui/core/Hidden'
import SearchBar from './search_bar'
import makeStyles from '@material-ui/core/styles/makeStyles'
const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    position: 'fixed',
    zIndex: 9,
    marginBottom: 50,
  },
  grow: {
    flexGrow: 1,
  },
}))

function HeaderSimple({ handleLogo }) {
  const classes = useStyles()

  return (
    <div className={classes.root} id="searchbartop">
      <AppBar position="fixed" style={{ background: '#fff' }}>
        <Hidden only={['sm', 'xs']}>
          <Toolbar style={{ minHeight: 45, maxWidth: 1380, width: 1380, margin: '0 auto' }}>
            <img
              alt="App Logo"
              src="/Seensta_African_logo.png"
              style={{ width: '110px' }}
              onClick={handleLogo}
            />

            <div className={classes.grow} />
            <div style={{ maxWidth: 650, width: '100%', justifyContent: 'center' }}>
              <SearchBar />
            </div>
            <div className={classes.grow} />
          </Toolbar>
        </Hidden>
        <Hidden only={['xl', 'lg']}>
          <Toolbar style={{ minHeight: 45 }}>
            <img
              alt="App Logo"
              src="/Seensta_African_logo.png"
              style={{ width: '100px' }}
              onClick={handleLogo}
            />
            <div className={classes.grow} />
          </Toolbar>
        </Hidden>
      </AppBar>
    </div>
  )
}
export default withRouter(HeaderSimple)
