import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  winnerImage: {
    width: 25,
    display: 'block',
    margin: '0 auto',
  },
})

function WinnerRibbon({ place }) {
  const classes = useStyles()
  switch (place) {
    case 1:
      return <img src="/first_winner.svg" alt={`winner ${place}`} className={classes.winnerImage} />
    case 2:
      return (
        <img src="/second_winner.svg" alt={`winner ${place}`} className={classes.winnerImage} />
      )
    case 3:
      return <img src="/third_winner.svg" alt={`winner ${place}`} className={classes.winnerImage} />
    default:
      return null
  }
}

export default WinnerRibbon
