import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Hidden from '@material-ui/core/Hidden'

function PageHeading(props) {
  return (
    <Box marginBottom={2} padding={1} marginTop={-2.5}>
      <Hidden only={['lg', 'xl', 'md']}>
        <Typography
          variant="h5"
          style={{ width: '100%', display: 'block', color: '#52183E', fontSize: 20 }}
        >
          {props.title}
        </Typography>
        <Typography variant="caption" style={{ color: '#504D4D', fontSize: 13 }}>
          {props.message}
        </Typography>
        {props.divider && <Divider />}
      </Hidden>

      <Hidden only={['xs', 'sm']}>
        <Typography variant="overline" style={{ width: '100%', display: 'block', fontSize: 15 }}>
          {props.title}:
          <Typography variant="caption" style={{ paddingLeft: 5 }}>
            {props.message}
          </Typography>
        </Typography>
        {props.divider && <Divider />}
      </Hidden>
    </Box>
  )
}
export default PageHeading
