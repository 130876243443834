import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './store/reducers'
var store
const initialState = {}
const middleware = [thunk]
if (process.env.NODE_ENV === 'development') {
  store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(...middleware),
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  )
} else {
  store = createStore(rootReducer, initialState, compose(applyMiddleware(...middleware)))
}

export default store
