import React from 'react'
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import IconButton from '@material-ui/core/IconButton'

function UploadProfilePictureOnProfile() {
  return (
    <div>
      <IconButton
        variant="contained"
        size="small"
        color="primary"
        aria-label="Add"
        href="/settings/avatar"
        style={{ position: 'absolute', zIndex: 3 }}
      >
        <PhotoCamera />
      </IconButton>
    </div>
  )
}

export default UploadProfilePictureOnProfile
