import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { resetPassword } from '../../store/actions/authActions'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import FormControl from '@material-ui/core/FormControl'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Alert from '@material-ui/lab/Alert'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(5))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    boxShadow: 'none',
    background: 'inherit',
  },

  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
}))

function NewPassword({ error, fetching, resetcode, history, updatepassword }) {
  const [userNewPassword, setUserNewPassword] = useState()
  const [userConfirmPassword, setUserConfirmPassword] = useState()
  const [confirm, setConfirm] = useState(null)

  useEffect(() => {
    document.title = 'Seensta - New Password '
  }, [])

  useEffect(() => {
    const timer = window.setTimeout(() => {
      if (confirm) {
        //history.push('/authloading')
        window.location.replace('/home')
      }
    }, 3000)

    return () => {
      window.clearTimeout(timer)
    }
  }, [confirm, history])

  const changePassword = async (e) => {
    e.preventDefault()
    //TODO: validate email and password

    if (userNewPassword && userConfirmPassword) {
      if (userNewPassword === userConfirmPassword) {
        var data = {
          newpassword: userNewPassword,
          confirmpassword: userConfirmPassword,
          code: resetcode,
        }
        const res = await updatepassword(data)
        if (res.status === 200) {
          setConfirm(true)
        } else {
          setConfirm(false)
        }
      } else {
        setConfirm(false)
      }
    }
  }

  const classes = useStyles()

  return (
    <React.Fragment>
      <CssBaseline />
      <main style={{ maxWidth: 320, margin: '0 auto' }}>
        <div style={{ textAlign: 'center', padding: 10 }}>
          <img
            alt="App Logo"
            src="/Seensta_African_logo.png"
            style={{ width: '80px' }}
            onClick={() => history.push('/home')}
          />
        </div>
        <div style={{ padding: '20px', display: 'block', width: '100%', textAlign: 'center' }}>
          {confirm ? (
            <div>
              <Alert severity="success">
                Great! Your password has been changed. Redirecting...
              </Alert>
            </div>
          ) : null}
        </div>
        <Paper className={classes.paper}>
          <Typography variant="h6">Create a New Password</Typography>

          <form className={classes.form}>
            <FormControl margin="normal" required fullWidth>
              <TextField
                variant="outlined"
                required
                id="newpassword"
                name="new_password"
                type="password"
                label="Password"
                onChange={(e) => setUserNewPassword(e.target.value)}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <TextField
                variant="outlined"
                required
                name="confirmpassword"
                type="password"
                id="password"
                label="Confirm Password"
                autoComplete="current-password"
                onChange={(e) => setUserConfirmPassword(e.target.value)}
              />
            </FormControl>
            {fetching && (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress />
              </div>
            )}
          </form>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={changePassword}
          >
            Continue
          </Button>
          {confirm === false ? (
            <div style={{ width: '100%', textAlign: 'center', color: 'red', padding: 10 }}>
              <Alert severity="error">Password Do Not Match</Alert>
            </div>
          ) : null}

          {error && (
            <div>
              <Alert severity="error">Please check your credentials and try again.</Alert>
            </div>
          )}
        </Paper>
      </main>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    fetching: state.auth.fetching,
    resetcode: state.auth.resetcode,
    changep: state.auth.pchange,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updatepassword: (data) => dispatch(resetPassword(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword)
