import Api from '../../Api'

const basedev = process.env.REACT_APP_API_URL

export const getFeaturedGig = (token) => async () => {
  var headers = {
    Authorization: 'Bearer ' + token,
  }
  let endpoint = basedev + 'featured/gigs'
  const res = await Api.get(endpoint, { headers: headers })
  return res
}

export const getUserGig = (token) => async () => {
  var headers = {
    Authorization: 'Bearer ' + token,
  }
  let endpoint = basedev + 'users/gig'
  const res = await Api.get(endpoint, { headers: headers })
  return res.data
}

export const getGig = (token) => async () => {
  var headers = {
    Authorization: 'Bearer ' + token,
  }
  let endpoint = basedev + 'gig'
  const res = await Api.get(endpoint, { headers: headers })
  return res
}

export const showGig = (token, gigId) => async () => {
  var headers = {
    Authorization: 'Bearer ' + token,
  }
  const endpoint = `${basedev}gig/${gigId}`
  const res = await Api.get(endpoint, { headers: headers })
  return res
}

export const postGig = (data, token) => async () => {
  var headers = {
    Authorization: 'Bearer ' + token,
  }
  let endpoint = basedev + 'gig'
  const res = await Api.post(endpoint, data, { headers: headers })
  return res
}

export const postGigNew = (data, token) => async () => {
  var headers = {
    Authorization: 'Bearer ' + token,
  }
  let endpoint = basedev + 'gig/new'
  const res = await Api.post(endpoint, data, { headers: headers })
  return res
}

export const updateGig = (data, token) => async () => {
  var headers = {
    Authorization: 'Bearer ' + token,
  }
  let endpoint = basedev + 'gig'
  const res = await Api.put(endpoint, data, { headers: headers })
  return res
}

export const acceptApplicant = (data, token) => async () => {
  var headers = {
    Authorization: 'Bearer ' + token,
  }
  let endpoint = basedev + 'applicant/accept'
  const res = await Api.post(endpoint, data, { headers: headers })
  return res
}

export const deleteApplication = (data, token) => async () => {
  var headers = {
    Authorization: 'Bearer ' + token,
  }
  let endpoint = basedev + 'applicant/' + data.resourceID
  const res = await Api.delete(endpoint, { headers: headers })
  return res
}

export const deleteGig = (data, token) => async () => {
  var headers = {
    Authorization: 'Bearer ' + token,
  }
  let endpoint = basedev + 'gig/' + data.resourceID
  const res = await Api.delete(endpoint, { headers: headers })
  return res
}

export const gigApplication = (data, token) => async () => {
  var headers = {
    Authorization: 'Bearer ' + token,
  }
  let endpoint = basedev + 'applicant'
  const res = await Api.post(endpoint, data, { headers: headers })
  return res
}

export const gigGetApplication = (token) => async () => {
  var headers = {
    Accept: 'application/json',
    Authorization: 'Bearer ' + token,
  }
  let endpoint = basedev + 'applicant/applied'
  const res = await Api.get(endpoint, { headers: headers })
  return res
}
