import {
  SEAMABLE_CUSTOM_SIZE,
  SEAM_TOKEN_REQUEST,
  SEAMABLE_PLAN,
  SEAMABLE_FIGURE,
  GET_SEAMBLE,
  SEAMABLE_PAYMENT_TOKEN,
  SEAMABLE_IMAGE,
  CREATE_SEAMIT,
  SEAMABLE_SIZE,
  SEAMABLE_SIZE_REMOVE,
  SEAMABLE_BUDGET,
  SEAMABLE_FIGURE_REMOVE,
  SEAMABLE_CLOTHESTYPE_REMOVE,
  SEAMABLE_CLOTHESTYPE,
} from './types'
import Api from '../../Api'

const basedev = process.env.REACT_APP_API_URL

export const setPlan = (data) => (dispatch) => {
  dispatch({
    type: SEAMABLE_PLAN,
    payload: data,
  })
}

export const setSeamable = (data) => (dispatch) => {
  dispatch({
    type: SEAMABLE_IMAGE,
    payload: data,
  })
}

export const setCustomSize = (data) => (dispatch) => {
  dispatch({
    type: SEAMABLE_CUSTOM_SIZE,
    payload: data,
  })
}

export const setCardToken = (data) => (dispatch) => {
  dispatch({
    type: SEAMABLE_PAYMENT_TOKEN,
    payload: data,
  })
}
export const setSize = (data) => (dispatch) => {
  dispatch({
    type: SEAMABLE_SIZE,
    payload: data,
  })
}

export const setBudget = (data) => (dispatch) => {
  dispatch({
    type: SEAMABLE_BUDGET,
    payload: data,
  })
}

export const removeSize = (data) => (dispatch) => {
  dispatch({
    type: SEAMABLE_SIZE_REMOVE,
    payload: data,
  })
}

export const addFigure = (data) => (dispatch) => {
  dispatch({
    type: SEAMABLE_FIGURE,
    payload: data,
  })
}
export const setClothes = (data) => (dispatch) => {
  dispatch({
    type: SEAMABLE_CLOTHESTYPE,
    payload: data,
  })
}
export const REMOVEClothes = (data) => (dispatch) => {
  dispatch({
    type: SEAMABLE_CLOTHESTYPE_REMOVE,
    payload: data,
  })
}

export const removeFigure = (data) => (dispatch) => {
  dispatch({
    type: SEAMABLE_FIGURE_REMOVE,
    payload: data,
  })
}

//creates the snatch

export const createSeamable = (data, token = null) => (dispatch) => {
  var headers = {
    Authorization: 'Bearer ' + this.props.token,
  }
  let endpoint = basedev + 'seam'

  Api.post(endpoint, data, { headers: headers })
    .then((res) => {
      dispatch({
        type: CREATE_SEAMIT,
        payload: res.data,
      })
    })
    .catch(() => {})
}

//creates the snatch

export const createSizes = (data, token) => () => {
  const headers = {
    Authorization: 'Bearer ' + token,
  }
  const endpoint = basedev + 'user/sizes'

  return Api.post(endpoint, data, { headers: headers }).then((res) => {
    return res
  })
}

export const fetchSeamToken = (requestID) => (dispatch) => {
  var postData = {
    token: requestID,
  }
  let endpoint = basedev + 'seam/token'

  return Api.post(endpoint, postData).then((res) => {
    dispatch({
      type: SEAM_TOKEN_REQUEST,
      payload: res.data,
    })
    return res
  })
}

//creates the snatch

export const seamRequests = () => (dispatch) => {
  var headers = {
    Authorization: 'Bearer ' + localStorage.getItem('asafo_token'),
  }
  let endpoint = basedev + 'seam'

  Api.get(endpoint, { headers: headers })
    .then((res) => {
      dispatch({
        type: GET_SEAMBLE,
        payload: res.data,
      })
    })
    .catch(() => {})
}
