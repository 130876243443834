import {
  CREATE_USER,
  STORE_RESET_CODE,
  AUTH_LOGOUT,
  PASSWORD_POST_ERROR,
  PASSWORD_POST,
  PASSWORD_RESET_ERROR,
  PASSWORD_RESET,
  LOGIN_USER,
  LOGIN_ERROR,
  CREATE_USER_ERROR,
  REFRESH_USER,
  UPDATE_USER,
  UPDATE_ERROR,
  AUTH_REQUEST,
  CREATE_REQUEST,
  VERIFY_REQUEST,
  VERIFY_USER,
  UPDATE_ABOUT,
} from './types'
import { Mixpanel } from '../../Mixpanel'
import Api from '../../Api'

const basedev = process.env.REACT_APP_API_URL
//const basedev = 'http://nsiza.api.test/api/'

export const resendVerificationCode = (token) => async () => {
  var endpoint = basedev + 'verify'
  var headers = {
    Accept: 'application/json',
    Authorization: 'Bearer ' + token,
  }
  try {
    const res = await Api.get(endpoint, { headers: headers })
    return res
  } catch (error) {
    return error
  }
}

export const verifyUser = (verify_data) => async (dispatch) => {
  var endpoint = basedev + 'verify'
  var headers = {
    Accept: 'application/json',
  }
  dispatch({
    type: VERIFY_REQUEST,
  })
  try {
    const res = await Api.post(endpoint, verify_data, { headers: headers })
    dispatch({
      type: VERIFY_USER,
      payload: res.data,
    })
    return res
  } catch (error) {
    return error
  }
}

export const createUser = (user) => (dispatch) => {
  var endpoint = basedev + 'register'
  var headers = {
    Accept: 'application/json',
  }
  dispatch({
    type: CREATE_REQUEST,
  })
  Api.post(endpoint, user, { headers: headers })
    .then((res) => {
      dispatch({
        type: CREATE_USER,
        payload: res.data,
      })

      localStorage.setItem('asafo_token', res.data.data)
    })
    .catch(() => {
      dispatch({
        type: CREATE_USER_ERROR,
        payload: null,
      })
    })
}

export const createUserandResponse = (user) => async (dispatch) => {
  var endpoint = basedev + 'register'
  var headers = {
    Accept: 'application/json',
  }
  dispatch({
    type: CREATE_REQUEST,
  })
  try {
    const res = await Api.post(endpoint, user, { headers: headers })
    if (res) {
      dispatch({
        type: CREATE_USER,
        payload: res.data,
      })
      console.log('response=>', res)
      localStorage.setItem('asafo_token', res.data.data)
      return res.data.data
    }
  } catch (error) {
    dispatch({
      type: CREATE_USER_ERROR,
      payload: null,
    })
    return false
  }
}

export const addAddress = (data, token) => async () => {
  var endpoint = basedev + 'address'
  var headers = {
    Accept: 'application/json',
    Authorization: 'Bearer ' + token,
  }
  await Api.post(endpoint, data, { headers: headers })
  return true
}

export const createUserOnFly = (user) => async (dispatch) => {
  var endpoint = basedev + 'register'
  var headers = {
    Accept: 'application/json',
  }
  const res = await Api.post(endpoint, user, { headers: headers })
  dispatch({
    type: CREATE_USER,
    payload: res.data,
  })
  localStorage.setItem('asafo_token', res.data.data)
  return res
}

//Refresh login

export const refreshUser = () => (dispatch) => {
  var headers = {
    Accept: 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('asafo_token'),
  }

  var endpoint = basedev + 'auth/refresh'

  if (localStorage.getItem('asafo_token') !== null) {
    //var endpoint = 'http://nsiza.api.test/api/auth/refresh';
    Api.get(endpoint, { headers: headers })
      .then((res) => {
        Mixpanel.track('Sign Up')

        dispatch({
          type: REFRESH_USER,
          payload: res.data,
        })
      })
      .catch((error) => {
        console.log(error.message)
      })
  }
}

export const loginUserSocial = (credentials) => async (dispatch) => {
  var endpoint = basedev + 'register/social'

  var headers = {
    Accept: 'application/json',
  }
  dispatch({
    type: AUTH_REQUEST,
  })
  try {
    const res = await Api.post(endpoint, credentials, { headers: headers })

    if (res) {
      if (res.data.status === 'exists') {
        dispatch({
          type: LOGIN_USER,
          payload: res.data,
        })
        localStorage.setItem('asafo_token', res.data.access)
      } else {
        dispatch({
          type: CREATE_USER,
          payload: res.data,
        })
        localStorage.setItem('asafo_token', res.data.data)
      }
    }
    return res.data
  } catch (e) {
    dispatch({
      type: LOGIN_ERROR,
      payload: null,
    })
  }
}

export const loginUser = (credentials) => (dispatch) => {
  var endpoint = basedev + 'login'

  var headers = {
    Accept: 'application/json',
  }
  dispatch({
    type: AUTH_REQUEST,
  })
  Api.post(endpoint, credentials, { headers: headers })
    .then((res) => {
      dispatch({
        type: LOGIN_USER,
        payload: res.data,
      })

      localStorage.setItem('asafo_token', res.data.access)

      //this.props.history.push('/');
    })
    .catch(() => {
      dispatch({
        type: LOGIN_ERROR,
        payload: null,
      })
    })
}

export const getUserLocation = (locationData) => async () => {
  const endpoint = `https://maps.googleapis.com/maps/api/geocode/json`

  try {
    const res = await Api.get(endpoint, {
      params: {
        latlng: `${locationData.latitude},${locationData.longitude}`,
        result_type: 'country',
        key: 'AIzaSyDTsb9oJVYMHUUqBFn-T8f5AbR01iOGVlM',
      },
    })

    return res.data
  } catch (e) {
    return e
  }
}

export const updateAbout = (updateData) => async (dispatch) => {
  var endpoint = basedev + 'about'

  var headers = {
    Accept: 'application/json',
    Authorization: 'Bearer ' + updateData.token,
  }
  try {
    const res = await Api.put(endpoint, { location: updateData.location }, { headers: headers })
    dispatch({
      type: UPDATE_ABOUT,
      payload: res.data,
    })
    return res
  } catch (e) {
    dispatch({
      type: UPDATE_ERROR,
      payload: null,
    })
  }
}

export const updateUser = (updateData) => async (dispatch) => {
  var endpoint = basedev + 'user'

  var headers = {
    Accept: 'application/json',
    Authorization: 'Bearer ' + updateData.token,
  }
  try {
    const res = await Api.put(endpoint, updateData.userData, { headers: headers })
    dispatch({
      type: UPDATE_USER,
      payload: res.data,
    })
    return res
  } catch (e) {
    dispatch({
      type: UPDATE_ERROR,
      payload: null,
    })
  }
}

export const passwordReset = (credentials) => (dispatch) => {
  var endpoint = basedev + 'reset/request'

  var headers = {
    Accept: 'application/json',
  }
  dispatch({
    type: AUTH_REQUEST,
  })
  Api.post(endpoint, credentials, { headers: headers })
    .then((res) => {
      dispatch({
        type: PASSWORD_RESET,
        payload: res.data,
      })
      //this.props.history.push('/');
    })
    .catch(() => {
      dispatch({
        type: PASSWORD_RESET_ERROR,
        payload: null,
      })
    })
}

export const storeCode = (code) => (dispatch) => {
  dispatch({
    type: STORE_RESET_CODE,
    payload: code,
  })
}

export const resetPassword = (credentials) => async (dispatch) => {
  var endpoint = basedev + 'password/reset'

  var headers = {
    Accept: 'application/json',
  }
  dispatch({
    type: AUTH_REQUEST,
  })
  try {
    const res = await Api.post(endpoint, credentials, { headers: headers })
    localStorage.setItem('asafo_token', res.data.access)

    dispatch({
      type: PASSWORD_POST,
      payload: res.data,
    })
    return res
  } catch (error) {
    dispatch({
      type: PASSWORD_POST_ERROR,
      payload: null,
    })
    return error
  }
}

export const authLogout = () => (dispatch) => {
  dispatch({
    type: AUTH_LOGOUT,
  })
}
