import React, { useState } from 'react'
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { fetchEntry, voteContest } from '../../store/actions/contestActions'
import { SocialIcon } from 'react-social-icons'
import { Mixpanel } from '../../Mixpanel'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share'
import { withRouter } from 'react-router-dom'
import EmptyState from '../appmessages/empty_state.'
import { awsImage } from '../../Helpers'
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles(() => ({
  card: {
    maxWidth: 400,
    marginBottom: 20,
    margin: '0 auto',
  },
  sharebuttons: {
    display: 'inline-block',
    width: 25,
    height: 25,
    padding: 15,
  },
}))

function ContestEntryProfile({ voteContest, props, contestEntry, user, auth }) {
  const [votes, setVotes] = useState(contestEntry.votes_count)
  const [error, setError] = useState('')
  const [didVote, setDidVote] = useState(false)
  const [copiedToClipboard, setCopiedToClipboard] = useState(false)

  const handleVote = async () => {
    var postdata = {
      contest_id: contestEntry.id,
    }
    const voteResponse = await voteContest(postdata)
    if (voteResponse.status === 212) {
      setError(
        `You've already voted! You can vote again in ${parseInt(
          voteResponse.data.timeLeft,
          10
        )} hours.`
      )
      return
    }
    setVotes(votes + 1)
    Mixpanel.track('Contest Voted')
    setDidVote(true)
  }

  const classes = useStyles()

  if (contestEntry.length !== 0)
    return (
      <div
        style={{
          width: '80%',
          height: '100%',
          overflow: 'scroll',
          margin: '0 auto',
          textAlign: 'center',
        }}
      >
        <Card className={classes.card} style={{ marginTop: 10, marginBottom: 50 }}>
          <CardMedia
            component="img"
            alt="Contest Image"
            className={classes.media}
            image={
              !contestEntry.image.includes('http')
                ? awsImage('folio', contestEntry.image, 500, 'cover')
                : contestEntry.image
            }
          />
          <CardContent>
            <div style={{ display: 'block', width: '100%' }}>
              <Typography gutterBottom variant="body1">
                {contestEntry.contest.title}
              </Typography>
            </div>

            {auth && !error.includes('hours') && !didVote && (
              <Button size="large" variant="contained" color="primary" onClick={handleVote}>
                Vote - {votes}
              </Button>
            )}
            {props.voted ? (
              <div style={{ display: 'block', width: '100%', textAlign: 'center' }}>
                <Typography
                  variant="body2"
                  color="secondary"
                  style={{ display: 'flex', width: '100%' }}
                >
                  Thank You, Please share.
                </Typography>
              </div>
            ) : null}
            <div style={{ display: 'block', width: '100%' }}>
              {error && <Alert severity="info">{error}</Alert>}

              <Typography
                variant="body2"
                color="primary"
                style={{ width: '100%', textAlign: 'center' }}
              >
                {didVote && `Please share this contestant so they can get more votes.`}
              </Typography>
              <br />
              <br />
              <div style={{ display: 'block', margin: '0 auto' }}>
                <Button
                  color="primary"
                  onClick={() => {
                    window.navigator.clipboard.writeText(
                      `${window.location.origin}/contests/entry/${contestEntry.random_id}`
                    )
                    setCopiedToClipboard(true)
                  }}
                >
                  {copiedToClipboard ? 'Copied!' : 'Copy Entry Url'}
                </Button>
              </div>
              <FacebookShareButton
                url={`${window.location.origin}/contests/entry/${contestEntry.random_id}`}
                className={classes.sharebuttons}
                quote={'Vote for ' + user.firstName}
              >
                <SocialIcon network="facebook" />
              </FacebookShareButton>
              <WhatsappShareButton
                url={`${window.location.origin}/contests/entry/${contestEntry.random_id}`}
                className={classes.sharebuttons}
                title={'Vote for ' + user.firstName}
              >
                <SocialIcon network="whatsapp" />
              </WhatsappShareButton>

              <TwitterShareButton
                url={`${window.location.origin}/contests/entry/${contestEntry.random_id}`}
                className={classes.sharebuttons}
                title={'Vote for ' + user.firstName}
              >
                <SocialIcon network="twitter" />
              </TwitterShareButton>
            </div>
          </CardContent>
        </Card>
      </div>
    )
  if (!contestEntry) {
    return <EmptyState title="My Contest Entry" message="You have not entered into a contest." />
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </div>
  )
}
const mapStateToProps = (state, ownProps) => {
  return {
    voted: state.contest.voted,
    auth: state.auth.auth,
    user: state.auth.user,
    props: ownProps,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntry: (contest_id) => dispatch(fetchEntry(contest_id)),
    voteContest: (contest_id) => dispatch(voteContest(contest_id)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContestEntryProfile))
