import React from 'react'
import Paper from '@material-ui/core/Paper'
import Label from '../display/label'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { Grid } from '@material-ui/core'
import AccountIcon from '@material-ui/icons/SupervisorAccount'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import Moment from 'react-moment'

function FeaturedContestCard({ contest, classes, auth, history }) {
  return (
    <div className={classes.paper}>
      <Paper
        className={classes.root}
        style={{
          padding: 10,
          background: '#ffffff',
          border: '1px solid #F0EFEF',
          boxShadow: 'none',
          width: 330,
          margin: '0 auto',
        }}
        elevation={1}
      >
        <Grid container direction="row" alignItems="center">
          <Grid item xs={8}>
            <Label label="title" fontWeight={200} />
            <Typography className={classes.heading} variant="h5" style={{ fontSize: 14 }}>
              {contest.title}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            {auth ? (
              <div>
                <Button
                  size="small"
                  variant="text"
                  style={{ color: '#504D4D', paddingRight: 0 }}
                  startIcon={<AccountIcon />}
                >
                  <span style={{ fontSize: 10, textTransform: 'lowercase' }}>
                    {contest.entry_count} participants
                  </span>
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  size="small"
                  variant="text"
                  style={{ color: '#504D4D', paddingRight: 0 }}
                  startIcon={<AccountIcon />}
                >
                  <span style={{ fontSize: 10, textTransform: 'lowercase' }}>
                    {contest.entry_count} participants
                  </span>
                </Button>
              </div>
            )}
          </Grid>
        </Grid>

        <Label label="submission dates" fontWeight={200} />

        <Typography
          variant="body1"
          style={{ paddingBottom: 5, color: '#504D4D', textTransform: 'lowercase', fontSize: 14 }}
        >
          <Moment format="D MMM YYYY">{contest.end_date}</Moment>
        </Typography>

        <Label label="prizes" fontWeight={200} />

        <Typography
          variant="body1"
          style={{ paddingBottom: 5, color: '#504D4D', textTransform: 'lowercase', fontSize: 14 }}
        >
          {contest.value}
        </Typography>
        <div
          style={{
            padding: 10,
            textAlign: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            height: 30,
          }}
        >
          <div style={{ float: 'right' }}>
            <Button
              variant="text"
              size="small"
              onClick={(e) => {
                e.stopPropagation()
                e.nativeEvent.stopImmediatePropagation()
                history.push('/contests/details/' + contest.contest_id)
              }}
              color="primary"
              style={{ textTransform: 'lowercase' }}
              endIcon={<ArrowRightAltIcon />}
            >
              View contest
            </Button>
          </div>
        </div>
      </Paper>
    </div>
  )
}
export default FeaturedContestCard
