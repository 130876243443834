import React from 'react'
import { Typography, Button } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

function SubscriptionComplete(props) {
  return (
    <div style={{ textAlign: 'center' }}>
      <img src="/sankofa.png" width="120" alt="sankofa" />
      <Typography variant="h5" style={{ fontWeight: '400' }}>
        Your subscription is complete
      </Typography>
      <Typography variant="subtitle1" style={{ fontWeight: '200', padding: 10 }}>
        Please expect an email from one of our experts, in welcoming you to the Seensta platform.
      </Typography>

      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          props.history.push('/home')
        }}
      >
        Discover Africa
      </Button>
    </div>
  )
}

export default withRouter(SubscriptionComplete)
