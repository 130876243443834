import Typography from '@material-ui/core/Typography'
import React from 'react'

function WelcomeModalIntro({ contestUrl }) {
  return (
    <div>
      <div>
        <Typography variant="h5" align="center">
          Welcome to Seensta
        </Typography>
      </div>
      <img
        src="/africa.png"
        style={{ maxWidth: 150, display: 'block', margin: '0 auto', width: '100%' }}
        alt="Welcome to Seensta Africa Map"
      />
      <div>
        <Typography variant="subtitle1" align="center">
          {contestUrl
            ? 'Great! Complete your profile to increase your chances.'
            : 'Seensta is the first social media network for Black creators across the diaspora. Tell us about you.'}
        </Typography>
      </div>
    </div>
  )
}

export default WelcomeModalIntro
