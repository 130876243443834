import React, { useState } from 'react'
import Vote from './vote'
import { Mixpanel } from '../../Mixpanel'
import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles((props) => ({
  flatVote: {
    bottom: -140,
    position: 'relative',
    float: props.float,
  },
}))

const VoteFooter = ({ photoPath, battleId, isButton, votes, props, createVote, title }) => {
  const [voteSum, setVoteSum] = useState(votes)

  const handleVoted = () => {
    votes ? setVoteSum(votes + 1) : setVoteSum(1)
    Mixpanel.track('Challenge Voted')
  }
  const classes = useStyles(props)
  return (
    <div>
      <div>
        <div className={isButton ? classes.flatVote : null}>
          <Vote
            photoPath={photoPath}
            voteSnum={voteSum}
            battleId={battleId}
            voteHandler={isButton ? handleVoted : (e) => createVote(e, title)}
          ></Vote>
        </div>
      </div>
    </div>
  )
}
export default VoteFooter
