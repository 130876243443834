import React, { useState, useEffect, useRef } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { connect } from 'react-redux'
import { Mixpanel } from '../../Mixpanel'
import CustomizedSnackbars from '../appmessages/customized_snackbars'
import { passwordReset, storeCode } from '../../store/actions/authActions'
import ReCAPTCHA from 'react-google-recaptcha'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage:
      'url(https://d1sb0aptwihzht.cloudfront.net/eyJidWNrZXQiOiJzZWVuc3RhLWNvbnRlbnQiLCJrZXkiOiJmb2xpby9jN08wcUlfMEQ0NjNENTYtNjE3Qy00QTVFLThDMDUtNzMyNDdEMDcyQUYyLmpwZyIsImVkaXRzIjp7InJlc2l6ZSI6eyJ3aWR0aCI6NzAwLCJmaXQiOiJjb250YWluIn19fQ==)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

function PasswordReset({
  resetERROR,
  postERROR,
  reset,
  resetPassword,
  passwordReset,
  fetching,
  props,
}) {
  const [email, setEmail] = useState()
  const [code, setCode] = useState()

  useEffect(() => {
    document.tilte = 'Seensta - Password Reset'
    Mixpanel.track('Login Pageview')
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()

    const captchaToken = await recaptchaRef.current.executeAsync()

    const user = {
      email: email,
      token: captchaToken,
    }
    passwordReset(user)
    Mixpanel.track('Password Reset')
  }

  const handleReset = (e) => {
    e.preventDefault()
    Mixpanel.track('Password Reset Code')
    resetPassword(code)
    props.history.push('/password/new')
  }

  const classes = useStyles()
  const recaptchaRef = useRef(0)

  if (resetERROR === true || resetERROR === null) {
    return (
      <Box>
        <Grid container component="main" className={classes.root}>
          <CssBaseline />
          <Grid item xs={false} sm={4} md={7} className={classes.image} />
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <div className={classes.paper}>
              <Box p={2}>
                <img
                  alt="App Logo"
                  src="/Seensta_African_logo.png"
                  className="avatar"
                  style={{ width: 100 }}
                />
              </Box>
              <Typography component="h5" variant="h5">
                Sign in
              </Typography>
              <form className={classes.form} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={(event) => setEmail(event.target.value)}
                />
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey="6LdC9DQbAAAAAPMMBeEDPvMd3NhUIiOTYFjzPH7Z"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleSubmit}
                >
                  Reset Password
                </Button>

                {fetching ? (
                  <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                ) : null}

                {resetERROR === true ? (
                  <CustomizedSnackbars
                    messagetype="error"
                    message="Reset error"
                  ></CustomizedSnackbars>
                ) : null}

                <Link
                  style={{ padding: 20, textDecoration: 'none' }}
                  onClick={() => props.history.push('/signin')}
                >
                  Sign In
                </Link>

                <Box mt={5}></Box>
              </form>
            </div>
          </Grid>
        </Grid>
      </Box>
    )
  } else if (!reset || !resetERROR) {
    return (
      <Box>
        <Grid container component="main" className={classes.root}>
          <CssBaseline />
          <Grid item xs={false} sm={4} md={7} className={classes.image} />
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <div className={classes.paper}>
              <Box p={2}>
                <img
                  alt="App Logo"
                  src="/Seensta_African_logo.png"
                  className="avatar"
                  style={{ width: 100 }}
                />
              </Box>
              <Typography component="h5" variant="h5">
                Sign in
              </Typography>
              <form className={classes.form} noValidate>
                <Typography variant="h6">We have emailed you a reset code.</Typography>
                <Typography variant="caption">Enter that code below</Typography>

                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="code"
                  type="number"
                  autoFocus
                  onChange={(event) => setCode(event.target.value)}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleReset}
                >
                  Continue
                </Button>

                {fetching ? (
                  <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                ) : null}

                {postERROR ? (
                  <CustomizedSnackbars
                    messagetype="error"
                    message="Please try again."
                  ></CustomizedSnackbars>
                ) : null}

                <Link style={{ padding: 20, textDecoration: 'none' }} to="/signin">
                  Sign In
                </Link>

                <Box mt={5}></Box>
              </form>
            </div>
          </Grid>
        </Grid>
      </Box>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    resetERROR: state.auth.resetERROR,
    postERROR: state.auth.postERROR,
    reset: state.auth.reset,
    fetching: state.auth.fetching,
    props: ownProps,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    passwordReset: (credentials) => dispatch(passwordReset(credentials)),
    resetPassword: (credentials) => dispatch(storeCode(credentials)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset)
