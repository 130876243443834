import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import { getLeaderboard } from '../../store/actions/contestActions'
import EmptyState from '../appmessages/empty_state.'
import { awsImage } from '../../Helpers'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Avatar from '@material-ui/core/Avatar'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import PageHeading from '../display/page_heading'
import WinnerRibbon from '../display/winner_ribbon'

function LeaderBoard({ getLeaderboard, props, history }) {
  const [leaderboard, setLeaderboard] = useState([])
  const [didComplete, setDidComplete] = useState(false)
  const [hasLoadMore, setHasLoadMore] = useState(false)
  const [page, setPage] = useState(1)
  const [title, setTitle] = useState([])
  const [isComplete, setIsComplete] = useState(false)

  useEffect(() => {
    getLeaderboard(props.match.params.contestId, page).then((res) => {
      setLeaderboard((leaderboard) => [...leaderboard, ...res.data.leaderboard])
      setTitle(res.data.title)
      setDidComplete(true)
      setIsComplete(res.data.is_complete)
      if (res.data.leaderboard.length === 20) {
        setHasLoadMore(true)
      } else {
        setHasLoadMore(false)
      }
    })
  }, [getLeaderboard, page, props.match.params.contestId])

  const handleLoadMore = () => {
    const numberofitems = leaderboard.length
    const pageNumber = Math.round(numberofitems / 20 + 1)
    if (pageNumber > page) {
      setDidComplete(false)
      setPage(pageNumber)
    }
  }

  if (leaderboard) {
    return (
      <div>
        <div style={{ paddingTop: 30 }}>
          <PageHeading title="leaderboard" message={`see who's winning it all ${title}`} />
        </div>

        <TableContainer component={Paper} padding="normal" size="small">
          <Table aria-label="leaderboard table">
            <TableHead>
              <TableRow>
                <TableCell align="center">#</TableCell>
                <TableCell align="center">entry</TableCell>
                <TableCell align="center">name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leaderboard.map((leader, key) => {
                const image = leader.image.includes('http')
                  ? leader.image
                  : awsImage('folio', leader.image, 200, 'cover')

                const imageAws =
                  leader.user.avatar && leader.user.avatar.photoPath.includes('http')
                    ? leader.user.avatar.photoPath
                    : leader.user.avatar
                    ? awsImage('profilepictures', leader.user.avatar.photoPath, 70, 'contain')
                    : '/female_avatar.png'

                return (
                  <TableRow
                    key={key}
                    onClick={() => props.history.push('/contests/entry/' + leader.random_id)}
                  >
                    <TableCell component="th" scope="row">
                      <div>
                        {!isComplete ? (
                          <Button
                            size="small"
                            aria-label="more"
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                              props.history.push('/contests/entry/' + leader.random_id)
                            }
                          >
                            Vote
                          </Button>
                        ) : (
                          <div>
                            {leader.contest_status ? (
                              <WinnerRibbon place={leader.contest_status} />
                            ) : null}
                          </div>
                        )}
                        <div>
                          <span style={{ fontWeight: 500, fontSize: 12 }}>
                            {' '}
                            {leader.votes_count} votes
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <img src={image} style={{ width: 100 }} alt={'entry contest'} />
                    </TableCell>
                    <TableCell align="left">
                      <Avatar
                        alt={leader.user.firstname}
                        style={{ margin: '0 auto' }}
                        src={imageAws}
                        onClick={() => {
                          history.push('/' + leader.user.username)
                        }}
                        variant="circle"
                      />
                      <Typography style={{ textAlign: 'center', fontSize: 12 }}>
                        {leader.user.firstName} {leader.user.lastName}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <div
          style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          {!didComplete && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </div>
          )}
          {hasLoadMore && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: 20,
              }}
            >
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                onClick={handleLoadMore}
                style={{ margin: '0 auto' }}
              >
                <ExpandMore />
                Load More
              </Button>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div>
      <EmptyState
        title="Contest Leader Board"
        message="There aren't any leaders for this contest."
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    token: state.auth.token,
    auth: state.auth.auth,
    props: ownProps,
  }
}

const mapDispatchToState = (dispatch) => {
  return {
    getLeaderboard: (contestid, page) => dispatch(getLeaderboard(contestid, page)),
  }
}

export default connect(mapStateToProps, mapDispatchToState)(LeaderBoard)
