import { FETCH_COMMENT, CREATE_COMMENT, COMMENT_REQUEST } from '../actions/types'
const initialState = {
  comments: [],
  fetching: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_COMMENT:
      return {
        ...state,
        comments: action.payload.comments,
        fetching: false,
      }
    case CREATE_COMMENT:
      return {
        ...state,
        // contest:action.payload.contest,
        fetching: false,
      }
    case COMMENT_REQUEST:
      return {
        ...state,
        comment: '',
        fetching: true,
      }
    default:
      return state
  }
}
