import { connect } from 'react-redux'

function AuthLoading({ auth, history }) {
  auth ? history.push('/home') : history.push('/signin')
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.auth.auth,
    fetching: state.auth.fetching,
  }
}

export default connect(mapStateToProps, null)(AuthLoading)
