import React from 'react'
import Header from './header'
import { Route, Switch } from 'react-router-dom'
import UserCategory from '../home/user_category'
import Tags from '../home/tags'
import Home from '../home/Home'
import { connect } from 'react-redux'
import ChallengeEntry from '../challenge/challenge_entry'
import BrowseLocation from '../home/browse_location'
import ExploreSearch from './explore_search'
import Explore from '../home/explore/explore'

function BrowsingContainer() {
  // const showPricing =
  //   !isSubscribed &&
  //   about &&
  //   (about.location.includes('United States') ||
  //     about.location.includes('United Kingdom') ||
  //     !about.location)

  return (
    <div style={{ marginTop: 50, color: '#000' }}>
      <Header />

      <Switch>
        <Route path="/challenge/:entryid" exact component={ChallengeEntry} />
        <Route path="/browse/:usertype" exact component={UserCategory} />
        <Route path="/browse/loc/:term" exact component={BrowseLocation} />
        <Route path="/" exact component={Home} />
        <Route path="/home" exact component={Home} />
        <Route path="/explore/search" component={ExploreSearch} />
        <Route path="/explore" component={Explore} />

        <Route path="/browse/tags/:tag" exact component={Tags} />
      </Switch>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    fetching: state.portfolio.fetching,
    isSubscribed: state.auth.isSubscribed,
    about: state.auth.about,
  }
}

export default connect(mapStateToProps)(BrowsingContainer)
