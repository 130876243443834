import React from 'react'
import CardHeader from '@material-ui/core/CardHeader'
import { Link } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'
import { awsImage } from '../../../Helpers'
import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles(() => ({
  avatar: {
    backgroundColor: '#52183E',
    width: 25,
    height: 25,
    marginRight: -13,
  },
  avatarTitle: {
    color: '#52183E',
    textDecoration: 'none',
    fontSize: 12,
  },
}))
function UserHeader({
  userName,
  firstName,
  lastName,
  photoPath,
  location,
  classes,
  fullwidth = false,
  children,
  isHome,
}) {
  let imageAws = ''
  if (photoPath) {
    imageAws = photoPath.includes('http')
      ? photoPath
      : photoPath
      ? awsImage('profilepictures', photoPath, 50, 'contain')
      : ''
  }
  const defaultClass = useStyle()
  return (
    <div>
      <CardHeader
        avatar={
          <Link to={`/${userName}`}>
            <Avatar
              className={classes ? classes.avatar : defaultClass.avatar}
              aria-label={`${firstName}-avatar`}
              alt={firstName}
              src={imageAws}
            />
          </Link>
        }
        // action={<Follower user={userName} />}
        title={
          <Link
            to={`/${location ? location.state.username : userName}`}
            className={classes ? classes.avatarTitle : defaultClass.avatarTitle}
          >
            {`${location ? location.state.firstname : firstName} ${
              location ? location.state.lastname : lastName
            }`}
          </Link>
        }
        subheader={children}
        style={{ padding: 5, float: fullwidth ? 'left' : 'none' }}
      />
      {isHome && children}
    </div>
  )
}
export default UserHeader
