import { POST_MESSAGE, GET_MESSAGE } from './types'
import Api from '../../Api'

const basedev = process.env.REACT_APP_API_URL
const headers = {}

export const getMessages = (data) => (dispatch) => {
  headers.Authorization = `Bearer ${localStorage.getItem('asafo_token')}`
  const endpoint = basedev + 'messenger/' + data

  Api.get(endpoint, { headers: headers })
    .then((res) => {
      dispatch({
        type: GET_MESSAGE,
        payload: res.data,
      })
    })
    .catch((error) => {
      return error
    })
}

export const postMessages = (data) => (dispatch) => {
  headers.Authorization = `Bearer ${localStorage.getItem('asafo_token')}`
  const endpoint = basedev + 'messenger'

  Api.post(endpoint, data, { headers: headers })
    .then((res) => {
      dispatch({
        type: POST_MESSAGE,
        payload: res.data,
      })
    })
    .catch((error) => {
      return error
    })
}
