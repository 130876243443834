import {
  CREATE_USER,
  STORE_RESET_CODE,
  UPDATE_USER,
  UPDATE_ERROR,
  PASSWORD_POST,
  PASSWORD_POST_ERROR,
  LOGIN_USER,
  LOGIN_ERROR,
  CREATE_USER_ERROR,
  REFRESH_USER,
  AUTH_REQUEST,
  CREATE_REQUEST,
  PASSWORD_RESET,
  PASSWORD_RESET_ERROR,
  AUTH_LOGOUT,
  UPDATE_ABOUT,
} from '../actions/types'

const initialState = {
  user: {},
  authError: null,
  createError: null,
  auth: false,
  about: null,
  avatar: null,
  fetching: false,
  resetERROR: null,
  reset: false,
  code: null,
  updateError: null,
  changeError: null,
  resetcode: null,
  pchange: null,
  token: localStorage.getItem('asafo_token'),
  isSubscribed: null,
  confirmed: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_USER:
      return {
        ...state,
        user: { ...action.payload.data, ...action.payload.user },
        about: action.payload.about,
        authError: null,
        createError: null,
        auth: true,
        token: action.payload.data ? action.payload.data : action.payload.access_token,
      }
    case LOGIN_USER:
      return {
        ...state,
        user: action.payload.user,
        authError: null,
        createError: null,
        auth: true,
        avatar: action.payload.avatar,
        about: action.payload.about,
        fetching: false,
        token: action.payload.access.access_token,
        isSubscribed: action.payload.isSubscribed,
        confirmed: Boolean(action.payload.user.confirmed),
      }
    case LOGIN_ERROR:
      return {
        ...state,
        authError: 'Login Failed',
        fetching: false,
      }
    case AUTH_LOGOUT:
      return {
        ...state,
        auth: false,
      }
    case CREATE_USER_ERROR:
      return {
        ...state,
        createError: ' User Creation Failed.',
        fetching: false,
      }
    case REFRESH_USER:
      return {
        ...state,
        isSubscribed: action.payload.isSubscribed,
        user: action.payload.user,
        authError: null,
        createError: null,
        auth: true,
        avatar: action.payload.avatar,
        about: action.payload.about,
        fetching: false,
        confirmed: Boolean(action.payload.user.confirmed),
      }
    case AUTH_REQUEST:
      return {
        ...state,
        authError: null,
        fetching: true,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        authError: null,
        fetching: true,
        createError: null,
      }
    case PASSWORD_RESET:
      return {
        ...state,
        authError: null,
        createError: null,
        fetching: false,
        resetERROR: false,
      }
    case PASSWORD_RESET_ERROR:
      return {
        ...state,
        resetERROR: true,
        reset: false,
        fetching: false,
      }
    case PASSWORD_POST:
      return {
        ...state,
        authError: null,
        reset: true,
        createError: null,
        fetching: false,
        postERROR: false,
        auth: false,
        changeError: false,
        pchange: true,
      }
    case PASSWORD_POST_ERROR:
      return {
        ...state,
        postERROR: true,
        reset: false,
        fetching: false,
        changeError: true,
      }
    case UPDATE_ERROR:
      return {
        ...state,
        updateError: true,
      }
    case UPDATE_USER:
      return {
        ...state,
        user: action.payload.user,
        updateError: false,
      }
    case STORE_RESET_CODE:
      return {
        ...state,
        resetcode: action.payload,
      }
    case UPDATE_ABOUT: {
      return {
        ...state,
        about: action.payload.about,
      }
    }

    default:
      return state
  }
}
