import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import HomeIcon from '@material-ui/icons/Home'
import SearchIcon from '@material-ui/icons/Search'
import SportsKabaddiIcon from '@material-ui/icons/SportsKabaddi'
import GavelIcon from '@material-ui/icons/Gavel'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },
  avatar: {
    width: 25,
    height: 25,
  },
})

export function LabelBottomNavigation({ user, history, auth }) {
  const classes = useStyles()
  const [value, setValue] = React.useState('home')

  useEffect(() => {
    if (history.location.pathname.includes('explore')) {
      setValue('explore')
    } else if (history.location.pathname.includes('contest')) {
      setValue('contests')
    } else if (history.location.pathname.includes('challenge')) {
      setValue('challenges')
    } else if (history.location.pathname.includes('browse')) {
      setValue('explore')
    } else if (history.location.pathname.includes('home')) {
      setValue('home')
    } else if (history.location.pathname.includes('gig')) {
      setValue('gig')
    } else {
      setValue('profile')
    }
  }, [history.location.pathname])

  const handleChange = (event, newValue) => {
    setValue(newValue)

    if (newValue === 'profile') {
      history.push(`/${user.username}`)
      return
    }
    history.push(`/${newValue}`)
  }

  if (auth) {
    return (
      <BottomNavigation showLabels value={value} onChange={handleChange} className={classes.root}>
        <BottomNavigationAction label="Home" value="home" icon={<HomeIcon />} />
        <BottomNavigationAction label="Explore" value="explore" icon={<SearchIcon />} />
        <BottomNavigationAction label="Collab" value="gigs" icon={<GroupWorkIcon />} />
        <BottomNavigationAction label="Contests" value="contests" icon={<SportsKabaddiIcon />} />
        <BottomNavigationAction label="Challenges" value="challenges" icon={<GavelIcon />} />
      </BottomNavigation>
    )
  }

  return null
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  avatar: state.auth.avatar,
  auth: state.auth.auth,
})

export default withRouter(connect(mapStateToProps, null)(LabelBottomNavigation))
