import {CREATE_VOTES,FETCH_VOTES,REQUEST_VOTE,CREATE_VOTES_ERROR } from '../actions/types'

const initialState ={

    items:[],
    item:{},
    voting:false,
    voted:false
}


export default function(state=initialState,action){

    switch(action.type){
        case CREATE_VOTES:
            return{
                ...state,
                item:action.payload,
                voting:false,
                voted:true

            }
        case FETCH_VOTES:
            return{
                ...state,
                items:action.payload,
                voting:false,
                voted:true

            }
        case REQUEST_VOTE:
            return{
                ...state,
                voting:true,
                voted:false

            } 
        case CREATE_VOTES_ERROR:
            return{
                ...state,
                voting:false,
                voted:false

            }                         
        default:
            return state

    }
}