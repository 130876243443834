import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import GavelIcon from '@material-ui/icons/Gavel'
import UserPhotos from '../profile/UserPhotos'
import { createChallenge, afterAdd } from '../../store/actions/challengeActions'
import { connect } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import BackArrow from '@material-ui/icons/ArrowBack'
import Alert from '@material-ui/lab/Alert'
import { Mixpanel } from '../../Mixpanel'

function ChallengeDialog({ createChallenge, token, props }) {
  const [open, setOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [timerId, setTimerId] = useState()

  useEffect(() => {
    if (errorMessage) {
      const timerId = window.setTimeout(() => {
        setErrorMessage(null)
      }, 5000)
      setTimerId(timerId)
    }
    return () => {
      window.clearTimeout(timerId)
    }
  }, [errorMessage, timerId])

  const handleAction = async (e) => {
    var postdata = {
      champPhoto: e.champPhoto,
      challengerPhoto: e.challengerPhoto,
    }
    const createResponse = await createChallenge(postdata, token)
    if (createResponse.data.success === false) {
      setErrorMessage(createResponse.data.message)
    }
    Mixpanel.track('Challenge Created')
  }

  const handleClickOpen = () => {
    setOpen(true)
    Mixpanel.track('Challenge Clicked')
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div style={{ display: 'inline' }}>
      <Button
        onClick={handleClickOpen}
        aria-label="Challenge a photo"
        size="small"
        style={{ padding: '2px', marginLeft: 10 }}
      >
        <GavelIcon style={{ marginLeft: 5, color: '#707070' }} />
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle
          id="form-dialog-title"
          style={{
            textAlign: 'center',
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

          <IconButton onClick={handleClose} aria-label="Back" style={{ float: 'left' }}>
            <BackArrow />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <small style={{ color: '#000', textTransform: 'uppercase' }}>
            Select your photo to challenge with.
          </small>
          <UserPhotos photo={props.photo} handleCall={handleAction} />
        </DialogContent>
      </Dialog>
    </div>
  )
}
const mapStateToProps = (state, ownProps) => {
  return {
    photos: state.user.photos,
    challengeAdd: state.challenge.challengeAdd,
    fetching: state.contest.fetching,
    token: state.auth.token,
    user: state.user.user,
    props: ownProps,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    createChallenge: async (challenge, token) => dispatch(createChallenge(challenge, token)),
    afterAdd: () => dispatch(afterAdd()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeDialog)
