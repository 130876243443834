import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import { blue } from '@material-ui/core/colors'
import { Box, DialogActions, DialogContent, Typography } from '@material-ui/core'
import Applicants from '../applicants/applicants'
import { useEffect } from 'react'
import AccountIcon from '@material-ui/icons/SupervisorAccount'

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  title: {
    letterSpacing: 0,
    color: '#504D4D',
    textTransform: 'lowercase',
    opacity: 1,
    textAlign: 'center',
  },
  dialogPaper: {
    maxHeight: 500,
    height: '100%',
  },
})

function ApplicantsDialog({ applicants, resource }) {
  const classes = useStyles()
  const [gigApplicants, setGigApplicants] = useState([])
  const [showApplicants, setShowApplicants] = useState(false)

  useEffect(() => {
    setGigApplicants([...applicants])
  }, [applicants])

  const handleClose = () => {
    setShowApplicants(false)
  }
  return (
    <Box>
      <Button
        variant="outlined"
        size="small"
        onClick={() => {
          setShowApplicants(true)
        }}
        style={{ textTransform: 'lowercase' }}
        startIcon={<AccountIcon />}
      >
        <span
          style={{ fontSize: 10, padding: 10, textTransform: 'lowercase', textAlign: 'center' }}
        >
          {applicants.length} Applicants
        </span>
      </Button>
      <Dialog
        maxWidth="lg"
        fullWidth={true}
        classes={{ paper: classes.dialogPaper }}
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={showApplicants}
      >
        <DialogTitle className={classes.title}>applicants</DialogTitle>
        <DialogContent style={{ paddingLeft: 0, paddingRight: 0, maxHeight: 500, height: '100%' }}>
          <Typography style={{ fontSize: 12, color: '#504D4D', padding: 10 }}>
            {gigApplicants.length} applicants{' '}
          </Typography>
          <Applicants applicants={gigApplicants} resource={resource} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default ApplicantsDialog
