import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import EventAvailable from '@material-ui/icons/EventAvailable'
import DraftsIcon from '@material-ui/icons/Drafts'
import StarIcon from '@material-ui/icons/Star'
import SendIcon from '@material-ui/icons/Send'

import List from '@material-ui/core/List'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import HomeIcon from '@material-ui/icons/Home'
import Divider from '@material-ui/core/Divider'
import ListSubheader from '@material-ui/core/ListSubheader'
import { withRouter } from 'react-router-dom'
import ExitApp from '@material-ui/icons/ExitToApp'
import { connect } from 'react-redux'
import SettingsIcon from '@material-ui/icons/Settings'
import { authLogout } from '../../store/actions/authActions'
import GavelIcon from '@material-ui/icons/Gavel'
import Paper from '@material-ui/core/Paper'
import TopBrowseMenu from './top_browse_menu'
import Button from '@material-ui/core/Button'
import AssignmentIcon from '@material-ui/icons/Assessment'
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar'
import ContestIcon from '@material-ui/icons/HowToVote'
import SignUpDialog from '../auth/sign_up_dialog'

const activeCountry = [
  { country: 'Kenya' },
  { country: 'Nairobi' },
  { country: 'Mombasa' },
  { country: 'Kisumu' },
  { country: 'Nakuru' },
  { country: 'Nigeria' },
  { country: 'Abuja' },
  { country: 'Lagos' },
  { country: 'Ghana' },
  { country: 'Accra' },
]

const styles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nestedtext: {
    fontSize: '14px',
  },
  divider: {
    height: theme.spacing(1),
  },
})

export const authMenuItems = (
  <div>
    <ListItem button>
      <ListItemIcon>
        <InboxIcon />
      </ListItemIcon>
      <ListItemText primary="Inbox" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <StarIcon />
      </ListItemIcon>
      <ListItemText primary="Starred" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <SendIcon />
      </ListItemIcon>
      <ListItemText primary="Send mail" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <DraftsIcon />
      </ListItemIcon>
      <ListItemText primary="Drafts" />
    </ListItem>
  </div>
)

export const staticMenuItems = <div></div>

function SideBar({ history, logout, auth }) {
  const handleLogout = () => {
    //remove token
    localStorage.removeItem('asafo_token')
    logout()
    window.location.href = '/'

    //history.push('/signin');
  }

  const handleLocation = (country) => {
    history.push('/browse/loc/' + country, { searchtype: 'location', term: country })
  }

  return (
    <Paper style={{ position: 'sticky', top: 73, maxWidth: 270 }}>
      <div style={{ textAlign: 'center', padding: 10 }}>
        {auth ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push('/gigs/create')
            }}
          >
            Post a Gig
          </Button>
        ) : (
          <SignUpDialog
            buttonText="Post a Gig"
            variant="contained"
            color="primary"
            headerMessage="Please Join to post a gig."
          />
        )}
      </div>

      <List>
        <ListItem
          button
          component="a"
          onClick={() => {
            if (auth) history.push('/gigs')
            else history.push('/signup')
          }}
          style={{ color: 'rgba(0,0,0,.54)' }}
        >
          <ListItemIcon>
            <EventAvailable />
          </ListItemIcon>
          <ListItemText
            inset
            primary="Available Gigs"
            style={{ padding: 0, color: 'rgba(0,0,0,.54)' }}
          />
        </ListItem>

        {auth && (
          <div>
            <ListItem
              button
              component="a"
              onClick={() => history.push('/gigs/admin/self')}
              style={{ color: 'rgba(0,0,0,.54)' }}
            >
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText
                inset
                primary="My Posted Gigs"
                style={{ padding: 0, color: 'rgba(0,0,0,.54)' }}
              />
            </ListItem>

            <ListItem
              button
              component="a"
              onClick={() => history.push('/gigs/admin/applications')}
              style={{ color: 'rgba(0,0,0,.54)' }}
            >
              <ListItemIcon>
                <PermContactCalendarIcon />
              </ListItemIcon>
              <ListItemText
                inset
                primary="Requested Gigs"
                style={{ padding: 0, color: 'rgba(0,0,0,.54)' }}
              />
            </ListItem>
          </div>
        )}
        <Divider />
        <ListItem
          button
          component="a"
          onClick={() => history.push('/contests')}
          style={{ color: 'rgba(0,0,0,.54)' }}
        >
          <ListItemIcon>
            <ContestIcon />
          </ListItemIcon>
          <ListItemText inset primary="Contests" style={{ padding: 0, color: 'rgba(0,0,0,.54)' }} />
        </ListItem>
        <ListItem
          button
          component="a"
          onClick={() => history.push('/challenges')}
          style={{ color: 'rgba(0,0,0,.54)' }}
        >
          <ListItemIcon>
            <GavelIcon />
          </ListItemIcon>
          <ListItemText
            inset
            primary="Challenges"
            style={{ padding: 0, color: 'rgba(0,0,0,.54)' }}
          />
        </ListItem>
        <Divider />
      </List>

      <List>
        <ListItem
          button
          component="a"
          onClick={() => history.push('/home')}
          style={{ color: 'rgba(0,0,0,.54)' }}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText inset primary="Home" style={{ padding: 0, color: 'rgba(0,0,0,.54)' }} />
        </ListItem>

        <Divider />
        <ListSubheader style={{ textTransform: 'uppercase' }}> Category</ListSubheader>
        <TopBrowseMenu />
        <Divider />
      </List>

      <List dense={true}>
        <ListSubheader style={{ textTransform: 'uppercase' }}> Countries</ListSubheader>

        {activeCountry.map((country, index) => (
          <ListItem
            onClick={() => handleLocation(country.country)}
            button
            key={index}
            style={{ color: 'rgba(0,0,0,.54)' }}
          >
            <ListItemText
              inset
              primary={'#' + country.country}
              style={{ padding: 0, color: 'rgba(0,0,0,.54)' }}
            />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List dense={true}>
        {auth && (
          <div>
            <ListItem
              component="a"
              onClick={() => history.push('/settings')}
              button
              style={{ color: 'rgba(0,0,0,.54)' }}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText
                inset
                primary="Settings"
                style={{ padding: 0, color: 'rgba(0,0,0,.54)' }}
              />
            </ListItem>
            <ListItem
              component="a"
              onClick={handleLogout}
              button
              style={{ color: 'rgba(0,0,0,.54)' }}
            >
              <ListItemIcon>
                <ExitApp />
              </ListItemIcon>
              <ListItemText
                inset
                primary="Logout"
                style={{ padding: 0, color: 'rgba(0,0,0,.54)' }}
              />
            </ListItem>
          </div>
        )}
      </List>
    </Paper>
  )
}

SideBar.propTypes = {
  classes: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => ({
  auth: state.auth.auth,
})

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(authLogout()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(SideBar)))
