import {
  VOTE_ENTRY_REQUEST,
  CREATE_ENTRY_VOTE,
  CONTEST_VOTE_ERROR,
  AFTER_ADD,
  FETCH_CHALLENGE_MORE,
  FETCH_CHALLENGE_REQUEST,
  FETCH_CONTEST,
  FETCH_CONTEST_REQUEST,
  CREATE_CONTEST_ENTRY,
  FETCH_CONTEST_ENTRY,
  ENTER_CONTEST,
} from './types'
import Api from '../../Api'
const baseUrl = process.env.REACT_APP_API_URL

const headers = {
  Authorization: 'Bearer ' + localStorage.getItem('asafo_token'),
}

export const getFeaturedContest = () => async () => {
  let endpoint = baseUrl + 'featured/contests'
  const res = await Api.get(endpoint)
  return res
}

export const fetchContest = (contestId = null) => async (dispatch) => {
  const endPoint = contestId ? `${baseUrl}contest/${contestId}` : `${baseUrl}contest`

  dispatch({
    type: FETCH_CONTEST_REQUEST,
  })

  const res = await Api.get(endPoint)
  dispatch({
    type: contestId ? ENTER_CONTEST : FETCH_CONTEST,
    payload: res.data,
  })
  return res.data
}

export const fetchChallengeMore = (username = null, page = null) => (dispatch) => {
  var endpoint
  if (username !== null) endpoint = baseUrl + 'challenges/' + username
  else endpoint = `${baseUrl}challenges/${page}/20`

  headers.Authorization = `Bearer ${localStorage.getItem('asafo_token')}`

  dispatch({
    type: FETCH_CHALLENGE_REQUEST,
  })

  Api.get(endpoint, { headers: headers }).then((res) => {
    dispatch({
      type: FETCH_CHALLENGE_MORE,
      payload: res.data,
    })
  })
}

export const createContest = (data, token = null) => async (dispatch) => {
  var endpoint = baseUrl + 'contestentry'
  headers.Authorization = `Bearer ${token ? token : localStorage.getItem('asafo_token')}`
  headers.Accept = 'application/json'

  dispatch({
    type: FETCH_CONTEST_REQUEST,
  })
  try {
    const res = await Api.post(endpoint, data, { headers: headers })
    dispatch({
      type: CREATE_CONTEST_ENTRY,
      payload: res.data,
    })
    return res
  } catch (error) {
    return error
  }
}

export const voteContest = (data, token = null) => async (dispatch) => {
  const endpoint = baseUrl + 'contestvote'
  headers.Authorization = `Bearer ${token ? token : localStorage.getItem('asafo_token')}`
  headers.Accept = 'application/json'

  dispatch({
    type: VOTE_ENTRY_REQUEST,
  })

  //create the challenge
  try {
    const res = await Api.post(endpoint, data, { headers: headers })
    dispatch({
      type: CREATE_ENTRY_VOTE,
      payload: res.data,
    })
    return res
  } catch (error) {
    dispatch({
      type: CONTEST_VOTE_ERROR,
      payload: null,
    })

    return error
  }
}

export const getLeaderboard = (contestid, page = null) => async () => {
  var endpoint = baseUrl + 'contestleaders/' + contestid + '/' + page + '/' + 20
  headers.Authorization = `Bearer  ${localStorage.getItem('asafo_token')}`
  headers.Accept = 'application/json'

  //create the challenge
  try {
    const res = await Api.get(endpoint, { headers: headers })
    return res
  } catch (error) {
    return error
  }
}

export const fetchEntry = (entryid) => async (dispatch) => {
  var endpoint = baseUrl + 'entry/' + entryid
  headers.Authorization = `Bearer  ${localStorage.getItem('asafo_token')}`
  headers.Accept = 'application/json'

  dispatch({
    type: FETCH_CONTEST_REQUEST,
  })
  //create the challenge
  try {
    const res = await Api.get(endpoint, { headers: headers })
    dispatch({
      type: FETCH_CONTEST_ENTRY,
      payload: res.data,
    })
    return res
  } catch (error) {
    return error
  }
}

export const afterAdd = () => (dispatch) => {
  //create the challenge
  dispatch({
    type: AFTER_ADD,
    payload: '',
  })
}
