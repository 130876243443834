import React from 'react'
import ContestCard from './contest_card'

function ListOfContests({ contest, auth, history, classes, index, featured }) {
  return (
    <div
      className={classes.root}
      key={index}
      onClick={(e) => {
        e.nativeEvent.stopPropagation()
        history.push(`/contests/details/${contest.contest_id}`, {
          contest,
          auth,
          classes,
          index,
        })
      }}
    >
      <ContestCard
        history={history}
        featured={featured}
        contest={contest}
        auth={auth}
        classes={classes}
      />
    </div>
  )
}
export default ListOfContests
